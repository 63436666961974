import React from 'react'
import {
    Link // , NavLink
} from 'react-router-dom'

import * as moment from 'moment-timezone'

import config from 'config'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'
import DashboardDataTable from 'components/shared/DashboardDataTable/DashboardDataTable'

import './CanaryTestDetailResultsList.scss'

class CanaryTestDetailResultsList extends DashboardModule {

    constructor (props) {

        super(props)
        this.renderFullDetailBody = this.renderFullDetailBody.bind(this)
        this.filterClickHandler = this.filterClickHandler.bind(this)
        this.renderExecutedColumnCell = this.renderExecutedColumnCell.bind(this)
        this.organization_id = false
        this.show_disabled = false
        this.show_outer_border = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id
        this.canary_test_id = params.canary_test_id

    }

    filterClickHandler (filter_key) {

        // console.log(this.props)
        // console.log("filterClickHandler: ", filter_key)
        let new_url = `/o/${this.organization_id}/canary_tests/d/${this.canary_test_id}?`

        if (filter_key !== "all") {

            new_url = `${new_url}filter=${filter_key}`

        }

        // console.log(" push: ", new_url)
        this.props.history.push(new_url)

    }

    renderExecutedColumnCell (execution_result) {

        const date_string = moment(execution_result["canary_test_execution_result_date_created"]).tz(this.props.user_timezone).format("MMM Do YYYY, h:mma z")

        const detail_url = `/o/${this.organization_id}/canary_tests/d/${this.canary_test_id}/r/${execution_result["canary_test_execution_result_id"]}/`

        return (

            <Link to={detail_url}>{date_string}</Link>

        )


    }

    renderDurationColumnCell (execution_result) {

        const end_execution = moment(execution_result["canary_test_execution_result_date_end_execution"]).valueOf()
        const start_execution = moment(execution_result["canary_test_execution_result_date_start_execution"]).valueOf()

        const execution_time = end_execution - start_execution

        return (execution_time / 1000).toLocaleString('en-EN', { minimumFractionDigits: 0 }) + " Seconds"

    }

    renderDataCenterColumnCell (execution_result) {

        // console.log(execution_result)

        return (
            <div className="d-flex flex-row">
                <div className="">
                    <div className="fa fa-fw mt-1 fa-cube execution-node-list-icon" />
                </div>
                <div className="">
                    <div className="ml-2 mt-1">{execution_result["execution_node_datacenter_cloud_provider"]} - {execution_result["execution_node_datacenter_name"]}</div>
                </div>
            </div>
        )

    }

    renderPassFailColumnCell (execution_result) {

        if (execution_result["canary_test_execution_result_success_flag"] === true) {

            return (<span className="badge badge-pill badge-success">Passed</span>)

        }

        return (<span className="badge badge-pill badge-danger px-2">Failed</span>)

    }

    renderResultColumnCell (execution_result) {

        if (execution_result["canary_test_execution_result_success_flag"] === true) {

            return execution_result["canary_test_execution_result_success_message"]

        }

        return execution_result["canary_test_execution_result_failure_message"]

    }

    renderFullDetailBody () {

        const searchParams = new URLSearchParams(this.props.location.search)

        const dashboard_data_table_body_data = this.props.canary_test_execution_results_fetch_return_data.data.map((result) => {

            const return_columns = []

            // 1 of 5
            return_columns.push(
                {
                    "column_key": "executed",
                    "column_markup": this.renderExecutedColumnCell(result),
                    "column_classes": "text-left align-middle text-nowrap"
                }
            )

            // 2 of 5
            return_columns.push(
                {
                    "column_key": "data_center",
                    "column_markup": this.renderDataCenterColumnCell(result),
                    "column_classes": "text-left align-middle text-nowrap"
                }
            )

            // 3 of 5
            return_columns.push(
                {
                    "column_key": "duration",
                    "column_markup": this.renderDurationColumnCell(result),
                    "column_classes": "text-left align-middle text-nowrap d-none d-lg-table-cell"
                }
            )

            // 4 of 5
            return_columns.push(
                {
                    "column_key": "build_date",
                    "column_markup": this.renderPassFailColumnCell(result),
                    "column_classes": "text-left align-middle text-nowrap"
                }
            )

            // 5 of 5
            return_columns.push(
                {
                    "column_key": "button_column",
                    "column_markup": this.renderResultColumnCell(result),
                    "column_classes": "text-left align-middle"
                }
            )

            return return_columns

        })

        const previous_pagination_get_parameters = {}
        const next_pagination_get_parameters = {}

        const filter = searchParams.get("filter")

        let filter_dropdown_title = "All Results"
        if (filter === "failed_results") {

            filter_dropdown_title = (<span className="text-danger">Failed Results</span>)
            previous_pagination_get_parameters["filter"] = "failed_results"
            next_pagination_get_parameters["filter"] = "failed_results"

        }

        const dashboard_data_table_header_data = {
            "left_markup": (
                <div className="navbar-text text-dark">
                    Test Execution Results
                </div>
            ),
            "right_dropdown_data": [
                {
                    "title": filter_dropdown_title,
                    "onClick": this.filterClickHandler,
                    "options": [
                        {
                            "name": "All Results",
                            "value": "all"
                        },
                        {
                            "name": (<span className="text-danger">Failed Results</span>),
                            "value": "failed_results"
                        }
                    ]
                }
            ]
        }

        const offset = parseInt(searchParams.get("offset"), 10)

        let previous_url = null
        let next_url = null
        let page_number = 1

        if (!offset || offset === 0) {

            previous_pagination_get_parameters["offset"] = 0
            next_pagination_get_parameters["offset"] = config["execution_result_page_item_count"]

            previous_url = null
            next_url = "?" + Object.keys(next_pagination_get_parameters).map((key) => {

                return encodeURIComponent(key) + '=' + encodeURIComponent(next_pagination_get_parameters[key])

            }).join('&')

        } else {

            page_number = (offset / config["execution_result_page_item_count"]) + 1

            let previous_offset = offset - config["execution_result_page_item_count"]

            if (previous_offset < 0) {

                previous_offset = 0

            }

            previous_pagination_get_parameters["offset"] = previous_offset
            next_pagination_get_parameters["offset"] = offset + config["execution_result_page_item_count"]

            previous_url = "?" + Object.keys(previous_pagination_get_parameters).map((key) => {

                return encodeURIComponent(key) + '=' + encodeURIComponent(previous_pagination_get_parameters[key])

            }).join('&')

            next_url = "?" + Object.keys(next_pagination_get_parameters).map((key) => {

                return encodeURIComponent(key) + '=' + encodeURIComponent(next_pagination_get_parameters[key])

            }).join('&')


        }


        const dashboard_data_table_data = {
            "fancy_header": dashboard_data_table_header_data,
            "body": dashboard_data_table_body_data,
            "pagination": {
                "previous_url": previous_url,
                "page_number": page_number,
                "next_url": next_url
            }
        }

        return (

            <DashboardDataTable table_data={dashboard_data_table_data} className="execution-node-list-data-table" />
        )

    }

    render () {

        if (this.props.loading_error) {

            return this.renderRawCard(
                <DashboardDataTable loading_error={this.props.loading_error} className="execution-node-list-data-table" />
            )

        } else if (
            this.props.canary_test_execution_results_fetch_return_data === null
        ) {

            return this.renderRawCard(
                " "
            )

        }

        return this.renderRawCard(
            this.renderFullDetailBody()
        )

    }

}

export default CanaryTestDetailResultsList

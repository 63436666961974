import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
// import { bindActionCreators } from 'redux'
// import { connect } from 'react-redux'
// import {
    // auth
// } from 'lib/authentication'
import './DashboardSidebar.css'

class DashboardSidebar extends Component {

    constructor (props) {

        super(props)
        this.renderSubAnchorLink = this.renderSubAnchorLink.bind(this)
        this.renderDashboardLinks = this.renderDashboardLinks.bind(this)
        this.renderMyCompanyLinks = this.renderMyCompanyLinks.bind(this)
        this.renderSidebarTopLink = this.renderSidebarTopLink.bind(this)

        // console.log("ac_session", window.ac_session)
        // console.log("organization_id", window.ac_session.current_organization.organization_id)
        // console.log("organization_role_type", window.ac_session.current_organization.organization_role_type)

    }

    renderSidebarTopLink (anchor_url, anchor_text, icon_class) {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        // console.dir(this.props)
        // console.dir(params)

        const full_anchor_url = `/o/${organization_id}/${anchor_url}`

        const classes = `fa fa-fw ${icon_class} sidebar-archor-icon sidebar-${icon_class}-ancor-icon`

        return (

            <NavLink className="sidebar-top-anchor" activeClassName="active" to={full_anchor_url}>
                <i className={classes} /> <span className="sidebar-text">{anchor_text}</span>
            </NavLink>

        )

    }

    renderSubAnchorLink (anchor_url, anchor_text) {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        // console.dir(this.props)
        // console.dir(params)

        const full_anchor_url = `/o/${organization_id}/${anchor_url}`

        return (

            <NavLink activeClassName="active-sub-anchor" className="sidebar-sub-anchor text-truncate" to={full_anchor_url}>
                {anchor_text}
            </NavLink>

        )

    }

    renderMyCompanyLinks () {

        /*
        if (window.ac_session.current_organization.organization_role_type === "READ_ONLY") {

            return (<></>)

        }


                <a className="sidebar-top-anchor collapsed" data-toggle="collapse" data-parent="#subAnchorContainer" href="#subAnchorContainer2" aria-expanded="false" aria-controls="subAnchorContainer2">
                    <i className="fa fa-fw fa-cog sidebar-archor-icon" aria-hidden="true" /> <span className="sidebar-text">My Company</span> <i className="fa fa-caret-left" aria-hidden="true" /> <i className="fa fa-caret-down" aria-hidden="true" />
                </a>
                <div id="subAnchorContainer2" className="collapse" role="tabpanel">
                    links go here
                </div>
         WORKS
            <div className="item">
                <a className="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                    Link with href
                </a>
                <div className="collapse" id="collapseExample">
                    {this.renderSubAnchorLink("settings", "Settings")}
                    {this.renderSubAnchorLink("settings/my_team", "My Team")}
                    {this.renderSubAnchorLink("settings/billing", "Billing")}
                </div>
            </div>
         ADD THESE WHEN READY
                    {this.renderSubAnchorLink("settings", "Settings")}
                    {this.renderSubAnchorLink("settings/billing", "Billing")}
        */

        // TODO: Need to update "collapsed" class when linked directly to the my team page
        return (
            <div className="item">
                <a className="sidebar-top-anchor collapsed" data-toggle="collapse" href="#company-settings-collapsed" role="button">
                    <i className="fa fa-fw fa-cog sidebar-archor-icon" /> <span className="sidebar-text">Settings</span> <i className="fa fa-caret-left" /> <i className="fa fa-caret-down" />
                </a>
                <div className="collapse" id="company-settings-collapsed">
                    {this.renderSubAnchorLink("settings/my_team", "My Team")}
                </div>
            </div>
        )

    }

    renderDashboardLinks () {

        /*
        return (

            <div className="item">
                <a className="sidebar-top-anchor activex" data-toggle="collapse" data-parent="#subAnchorContainer" href="#subAnchorContainer1" aria-expanded="true" aria-controls="subAnchorContainer1">
                    <i className="fa fa-fw fa-desktop sidebar-archor-icon" aria-hidden="true" /> <span className="sidebar-text">Dashboards</span> <i className="fa fa-caret-left" aria-hidden="true" /> <i className="fa fa-caret-down" aria-hidden="true" />
                </a>
                <div id="subAnchorContainer1" className="collapse show" role="tabpanel">
                    {this.renderSubAnchorLink("d/overview", "Overview")}
                    {this.renderSubAnchorLink("d/dashboard_one", "Dashboard One")}
                    {this.renderSubAnchorLink("d/dashboard_two", "Dashboard Two")}
                </div>
            </div>

        )
        */

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        // console.dir(this.props)
        // console.dir(params)

        const full_anchor_url = `/o/${organization_id}/d/overview`

        return (

            <div className="item">
                <NavLink className="sidebar-top-anchor" activeClassName="active" to={full_anchor_url}>
                    <i className="fa fa-fw fa-desktop sidebar-archor-icon sidebar-fa-desktop-ancor-icon" /> <span className="sidebar-text">Overview</span>
                </NavLink>
            </div>

        )

    }

    // Return the completely assembled component
    render () {

        // console.dir(this.props.match)

        let build_label_ui = ""

        if (process.env.REACT_APP_ENV_TYPE === "local") {

            build_label_ui = (
                <div id="sidebar-logo-anchor-build-label">
                    LOCAL - <span className="d-sm-none">xs</span>
                    <span className="d-none d-sm-inline d-md-none">sm</span>
                    <span className="d-none d-md-inline d-lg-none">md</span>
                    <span className="d-none d-lg-inline d-xl-none">lg</span>
                    <span className="d-none d-xl-inline">xl</span>
                </div>
            )

        } else if (process.env.REACT_APP_ENV_TYPE === "staging") {

            build_label_ui = (
                <div id="sidebar-logo-anchor-build-label">
                    STAGING - <span className="d-sm-none">xs</span>
                    <span className="d-none d-sm-inline d-md-none">sm</span>
                    <span className="d-none d-md-inline d-lg-none">md</span>
                    <span className="d-none d-lg-inline d-xl-none">lg</span>
                    <span className="d-none d-xl-inline">xl</span>
                </div>
            )

        }

        return (

            <div id="sidebar-content-container" className="h-100">
                <Link to="/" id="sidebar-logo-anchor">
                    <img src="/images/api_canary_tie_logo.png" className="img-fluid" alt="API Canary Logo" />
                    {build_label_ui}
                </Link>
                <div data-children=".item">

                    {this.renderDashboardLinks()}

                    {this.renderSidebarTopLink("canary_tests", "Tests", "fa-th-list")}

                    {/*
                    */}
                    {this.renderSidebarTopLink("variable_vault", "Variable Vault", "fa-lock")}

                    {this.renderSidebarTopLink("alert_rules", "Alert Rules", "fa-exclamation-triangle")}

                    {this.renderSidebarTopLink("data_centers", "Data Centers", "fa-cubes")}

                    {/*
                    */}
                    {this.renderMyCompanyLinks()}

                    {/*
                    <a className="sidebar-top-anchor sidebar-last-anchor" href="#xxx">
                        <i className="fa fa-fw fa-th sidebar-archor-icon" /> <span className="sidebar-text">Public Gallery</span>
                    </a>
                    */}
                </div>
            </div>

        )

    }

}

export default DashboardSidebar

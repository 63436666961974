import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    accountDataFetch
} from 'modules/user_account_module'

import DashboardSidebar from './DashboardSidebar/DashboardSidebar'
import DashboardNavbar from './DashboardNavbar/DashboardNavbar'
import DashboardBody from './DashboardBody/DashboardBody'

import './Dashboard.scss'

class Dashboard extends Component {

    constructor (props) {

        super(props)
        this.renderDashboardBody = this.renderDashboardBody.bind(this)

    }

    componentWillMount () {

        // console.log("Dashboard cwm")


    }

    componentDidMount () {

        this.props.accountDataFetch()

    }

    renderDashboardBody () {

        const { match: { params } } = this.props

        window.ac_session.current_account = this.props.user_account.user_account_user_data

        this.props.user_account_organizations.map((organization) => {

            if (organization.organization_id === params.organization_id) {

                window.ac_session.current_organization = organization

            }

            return null

        })

        return (

            <div id="sidebar-framework-container">
                <div id="sidebar-container">
                    <DashboardSidebar {...this.props} />
                </div>
                <div id="content-body-container">
                    <DashboardNavbar {...this.props} />
                    <div id="content-body-inner-container">
                        <DashboardBody {...this.props} />
                    </div>
                </div>
            </div>

        )

        /*
        */

    }

    // Return the completely assembled component
    render () {

        // console.log("Dashboard Render Called")

        const {
            user_account_data_has_been_fetched,
            user_account_organizations
        } = this.props

        if (user_account_data_has_been_fetched) {

            // We have account info
            let redirect_to_organization_id = false

            // console.dir(user_account_organizations)

            // pull organization ID from current uri
            const { match: { params } } = this.props
            // console.dir(params)

            // console.dir(this.props.match)

            if (typeof params.organization_id === "undefined") {

                // No organization ID was found in the URI, so redirect to the first org in
                // the user's account info
                redirect_to_organization_id = user_account_organizations[0]["organization_id"]

                return (
                    <Redirect to={{ pathname: `/o/${redirect_to_organization_id}/d/overview` }} />
                )

            }

            const current_organization_id = params.organization_id
            for (let i = 0; i < user_account_organizations.length; i++) {

                if (current_organization_id === user_account_organizations[i]["organization_id"]) {

                    return this.renderDashboardBody()

                }

            }

            // No organization ID was matched the org id in the URI, so redirect.
            redirect_to_organization_id = user_account_organizations[0]["organization_id"]

            return (
                <Redirect to={{ pathname: `/o/${redirect_to_organization_id}/d/overview` }} />
            )

        }

        if (this.props.user_account_data_fetch_error) {

            return (
                <div className="card" id="network-error-container">
                    <div className="card-body">
                        <h5 className="card-title">We&apos;re sorry, we&apos;ve experienced an error</h5>
                        <p className="card-text">
                            {this.props.user_account_data_fetch_error_message}
                        </p>
                        <a href="/login" className="mt-5 mb-2 btn btn-success">Back to Login</a>
                    </div>
                </div>
            )

        }

        return (

            <center>
                <i className="mt-5 fa fa-refresh fa-spin fa-fw fa-5x text-secondary" />
            </center>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    // root_state: state,
    currently_fetching_user_account_data: state.user_account.currently_fetching_user_account_data,
    user_account_data_fetch_error: state.user_account.user_account_data_fetch_error,
    user_account_data_fetch_error_message: state.user_account.user_account_data_fetch_error_message,
    user_account_data_has_been_fetched: state.user_account.user_account_data_has_been_fetched,
    user_account_organizations: state.user_account.user_account_organizations,
    user_account: state.user_account
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    accountDataFetch
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)

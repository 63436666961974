// import $ from 'jquery'
import React, { Component } from 'react'

// import {
// Link
// } from 'react-router-dom'

// import EmailTypeOption from './EmailTypeOption/EmailTypeOption'
// import SMSTypeOption from './SMSTypeOption/SMSTypeOption'

import VariableVaultListItemView from './VariableVaultListItemView/VariableVaultListItemView'
import VariableVaultListItemCreator from './VariableVaultListItemCreator/VariableVaultListItemCreator'

class VariableVaultListItem extends Component {

    // constructor (props) {

        // super(props)
        // this.handleAlertRuleTypeChange = this.handleAlertRuleTypeChange.bind(this)
        // this.handleAlertRuleDetailChange = this.handleAlertRuleDetailChange.bind(this)

    // }

    // Return the completely assembled component
    render () {

        if (
            this.props.mode === "creator" ||
            this.props.mode === "add_new"
        ) {

            return (

                <VariableVaultListItemCreator
                    /* Creating */
                    mode={this.props.mode}
                    vault_key={null}
                    vault_key_value={null}
                    onVaultKeySubmit={this.props.onVaultKeyCreate}
                    currently_sending_post={this.props.currently_sending_variable_vault_key_create_post}
                    variable_vault_key_post_error={this.props.variable_vault_key_create_error}
                    variable_vault_key_post_return_data={this.props.variable_vault_key_create_return_data}
                    variable_vault_key_post_successfully_posted={this.props.variable_vault_key_create_successfully_posted}

                    {...this.props}
                />

            )

        } else if (this.props.mode === "editor") {

            return (

                <VariableVaultListItemCreator
                    /* Editing */
                    mode={this.props.mode}
                    vault_key={this.props.vault_key}
                    vault_key_value={this.props.vault_key_value}
                    onVaultKeySubmit={this.props.onVaultKeyUpdate}
                    currently_sending_post={this.props.currently_sending_variable_vault_key_update_post}
                    variable_vault_key_post_error={this.props.variable_vault_key_update_error}
                    variable_vault_key_post_return_data={this.props.variable_vault_key_update_return_data}
                    variable_vault_key_post_successfully_posted={this.props.variable_vault_key_update_successfully_posted}

                    {...this.props}
                />

            )

        }

        return (

            <VariableVaultListItemView
                vault_key={this.props.vault_key}
                vault_key_value={this.props.vault_key_value}
                onVaultKeyDelete={this.props.onVaultKeyDelete}
                {...this.props}
            />

        )

    }

}


export default VariableVaultListItem

import React from 'react'

import FancyFormElement from 'components/shared/FancyFormElement/FancyFormElement'

import Select from 'react-select'
import makeAnimated from 'react-select/lib/animated'

import './FancyFormElementSingleSelectDropdown.scss'


class FancyFormElementSingleSelectDropdown extends FancyFormElement {

    constructor (props) {

        super(props)
        this.renderLoadingUI = this.renderLoadingUI.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)

    }

    renderFormElement () {

        // console.log("fancysingle defaultValue", this.props.value)
        // console.log("fancysingle options", this.props.dropdown_options)

        let default_value_index = 0

        for (let i = 0; i < this.props.dropdown_options.length; i++) {

            if (this.props.dropdown_options[i].value === this.props.value) {

                default_value_index = i
                break

            }

        }

        // console.group("render")
        // console.log("this.props.value", this.props.value)
        // console.log("this.props.name", this.props.name)
        // console.log("defaultValue", this.props.dropdown_options[default_value_index])
        // console.log("onChange", this.handleOnChange)
        // console.log("onBlur", this.props.onBlur)
        // console.log("id", this.props.id)
        // console.log("disabled", this.props.disabled)
        // console.log("name", this.props.name)
        // console.log("options", this.props.dropdown_options)
        // console.groupEnd()

        // console.log("FancyFormElementSingleSelectDropdown this.props.disabled", this.props.disabled)
        return (
            <Select
                defaultValue={this.props.dropdown_options[default_value_index]}
                onChange={this.handleOnChange}
                /* onChange={this.props.onChange} */
                onBlur={this.props.onBlur}
                id={this.props.id}
                isDisabled={this.props.disabled}
                name={this.props.name}
                options={this.props.dropdown_options}
                className="fancy-single-select-dropdown"
                classNamePrefix="fancy-single-select-dropdown"
                components={makeAnimated()}
            />
        )

    }

    handleOnChange (selectedValue) {

        // console.log("handleOnChange", selectedValue)
        this.props.onChange(selectedValue["value"])

    }

}

export default FancyFormElementSingleSelectDropdown

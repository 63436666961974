// import $ from 'jquery'
import React, { Component } from 'react'

// https://www.npmjs.com/package/validator
import isEmail from 'validator/lib/isEmail'

// import {
// Link
// } from 'react-router-dom'

import './EmailField.css'


class EmailField extends Component {

    constructor (props) {

        super(props)
        this.handleFieldOnChange = this.handleFieldOnChange.bind(this)

        this.minimum_field_value_length = 2

        this.component_alert_rule_type = "EMAIL"

        this.show_success_flag = false

        this.state = {
            email_address: this.props.email_address,
            email_address_error_flag: false
        }

    }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps hit:", nextProps)

        if (nextProps.email_address !== null) {

            this.setState({ email_address: nextProps.email_address })

        }

    }

    handleFieldOnChange (e) {

        // console.log("handleFieldOnChange hit")

        const new_email_address = e.target.value.trim()

        let error_flag = false
        if (!isEmail(new_email_address)) {

            error_flag = true

        }

        this.setState({
            email_address: new_email_address,
            email_address_error_flag: error_flag
        })

        if (this.props.handleEmailAddressChange) {

            this.props.handleEmailAddressChange(error_flag, new_email_address)

        }

    }

    // Return the completely assembled component
    render () {

        // console.log("Render hit: ", this.state.email_address)

        let field_class_string = `form-control border`
        let fail_success = (<i className="fa fa-2x fa-fw" />)

        if (this.state.email_address_error_flag) {

            this.show_success_flag = true

            field_class_string = `${field_class_string} border-danger`
            fail_success = (<i className="fa fa-2x fa-fw fa-times text-danger" />)

        } else if (this.show_success_flag) {

            fail_success = (<i className="fa fa-2x fa-fw fa-check text-success" />)

        }

        return (

            <div className="row form-group mt-4 mb-4 mr-4">
                <div className="col editor-label-column">
                    <label className="col-form-label">Email Address:</label>
                </div>
                <div className="col">
                    <div className="d-flex flex-row">

                        <div className="w-100">
                            <input
                                type="text"
                                placeholder="name@email.com"
                                name="alert_rule_email_address"
                                value={this.state.email_address}
                                id="email_alert_type_email_address_field"
                                onChange={this.handleFieldOnChange}
                                className={field_class_string}
                            />
                        </div>
                        <div className="">
                            {fail_success}
                        </div>

                    </div>

                </div>
            </div>

        )

    }

}


export default EmailField

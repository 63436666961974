import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'


// Initial Module State
const initialState = {
    currently_sending_canary_test_manually_run_post: false,
    canary_test_manually_run_execution_node_id: null,
    canary_test_manually_run_error: null,
    canary_test_manually_run_success: null,
    canary_test_manually_run_return_data: null
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const CANARY_TEST_MANUALLY_RUN_RESET = 'canary_test_manually_run/CANARY_TEST_MANUALLY_RUN_RESET'
export const CANARY_TEST_MANUALLY_RUN_POST_REQUESTED = 'canary_test_manually_run/CANARY_TEST_MANUALLY_RUN_POST_REQUESTED'
export const CANARY_TEST_MANUALLY_RUN_POST_SUCCESS = 'canary_test_manually_run/CANARY_TEST_MANUALLY_RUN_POST_SUCCESS'
export const CANARY_TEST_MANUALLY_RUN_POST_ERROR = 'canary_test_manually_run/CANARY_TEST_MANUALLY_RUN_POST_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const canaryTestManuallyRunReset = () => {

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_MANUALLY_RUN_RESET
        })

    }

}

export const canaryTestManuallyRunPost = (organization_id, canary_test_id, execution_node_id, new_code_to_execute) => {

    // console.log("canary_testPost called")

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_MANUALLY_RUN_RESET
        })

        dispatch({
            type: CANARY_TEST_MANUALLY_RUN_POST_REQUESTED,
            payload: execution_node_id
        })

        const auth_token = auth.get("auth_token")

        let url = `//${config["api_hostname"]}/v1/o/${organization_id}/canary_tests`
        let action_payload = {
            'canary_test_code': new_code_to_execute,
            'execution_node_id': execution_node_id,
            'fire_alert_rules': false,
            'record_execution_result': false
        }

        if (canary_test_id) {

            url = `//${config["api_hostname"]}/v1/o/${organization_id}/canary_tests/${canary_test_id}`
            action_payload = {
                'new_code_to_execute': new_code_to_execute,
                'execution_node_id': execution_node_id,
                'fire_alert_rules': false,
                'record_execution_result': false
            }

        }

        const json_payload = {
            "action": "MANUALLY_EXECUTE_CANARY_TEST",
            "action_payload": action_payload
        }

        // console.log("json_payload[action_payload]...")
        // console.dir(json_payload["action_payload"])

        return axios({
            method: 'post',
            url: url,
            headers: {
                Authorization: `Token ${auth_token}`
            },
            data: json_payload
        })

            .then((response) => {

                const canary_test_data = response.data

                // console.log("canary test create response", response)

                dispatch({
                    type: CANARY_TEST_MANUALLY_RUN_POST_SUCCESS,
                    payload: canary_test_data
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // console.log("hit1")
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }

                // console.log("error", error)
                // console.log("error_string", error_string)
                // console.log("error.config", error.config)

                dispatch({
                    type: CANARY_TEST_MANUALLY_RUN_POST_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case CANARY_TEST_MANUALLY_RUN_RESET:
            return {
                ...state,
                currently_sending_canary_test_manually_run_post: false,
                canary_test_manually_run_execution_node_id: null,
                canary_test_manually_run_error: null,
                canary_test_manually_run_success: null,
                canary_test_manually_run_return_data: null
            }

        case CANARY_TEST_MANUALLY_RUN_POST_REQUESTED:
            return {
                ...state,
                currently_sending_canary_test_manually_run_post: true,
                canary_test_manually_run_execution_node_id: action.payload,
                canary_test_manually_run_error: null,
                canary_test_manually_run_success: null,
                canary_test_manually_run_return_data: null
            }

        case CANARY_TEST_MANUALLY_RUN_POST_SUCCESS:
            return {
                ...state,
                currently_sending_canary_test_manually_run_post: false,
                canary_test_manually_run_error: null,
                canary_test_manually_run_success: true,
                canary_test_manually_run_return_data: action.payload.data[0]
            }

        case CANARY_TEST_MANUALLY_RUN_POST_ERROR:
            return {
                ...state,
                currently_sending_canary_test_manually_run_post: false,
                canary_test_manually_run_error: action.payload, // Loading error text
                canary_test_manually_run_success: false,
                canary_test_manually_run_return_data: null
            }

        default:
            return state

    }

}

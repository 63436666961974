import React, { Component } from 'react'
import {
    Redirect
    // Link,
    // , NavLink
} from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Fetch
import {
    teamMembersReset,
    teamMembersFetch
} from 'modules/team_members_module'

// Add Email
import {
    teamMemberAddEmailReset,
    teamMemberAddEmailPost
} from 'modules/team_member_add_email_module'

// Create Account
import {
    teamMemberCreateAccountReset,
    teamMemberCreateAccountPost
} from 'modules/team_member_create_account_module'

// Update
import {
    teamMemberUpdateReset,
    teamMemberUpdatePost
} from 'modules/team_member_update_module'

// Delete
import {
    teamMemberDeleteReset,
    teamMemberDeletePost
} from 'modules/team_member_delete_module'


import MyTeamListBody from './MyTeamListBody/MyTeamListBody'


class MyTeamList extends Component {

    constructor (props) {

        super(props)

        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.fetchAllData = this.fetchAllData.bind(this)
        this.resetAllData = this.resetAllData.bind(this)
        this.resetAndFetchAllData = this.resetAndFetchAllData.bind(this)
        this.onTeamMemberCreateAccount = this.onTeamMemberCreateAccount.bind(this)
        this.onTeamMemberEmailAdd = this.onTeamMemberEmailAdd.bind(this)
        this.onTeamMemberEmailUpdate = this.onTeamMemberEmailUpdate.bind(this)
        this.onTeamMemberDelete = this.onTeamMemberDelete.bind(this)

        this.state = {
            fireRedirectOnSuccess: false
        }

        this.my_team_url = `/o/${this.organization_id}/settings/my_team`

    }

    componentDidMount () {

        // console.log("MyTeamList mount")

        this.resetAndFetchAllData()

    }

    componentDidUpdate (prevProps, prevState) {

        // console.dir("componentDidUpdate")

        if (
            (prevProps.location.pathname !== this.props.location.pathname) &&
            this.props.location.pathname === this.my_team_url
        ) {

            // console.log("pull and reset all data here?")
            // this.resetAndFetchAllData()
            this.setState({ fireRedirectOnSuccess: false })

        }

    }

    resetAndFetchAllData () {

        this.resetAllData()
        this.fetchAllData()

    }

    resetAllData () {

        this.props.teamMemberAddEmailReset()
        this.props.teamMemberCreateAccountReset()
        this.props.teamMemberDeleteReset()
        this.props.teamMemberUpdateReset()

    }

    fetchAllData () {

        // console.log("FetchAllData hit - MyTeamList.js")
        this.props.teamMembersFetch(this.organization_id)

    }

    onTeamMemberCreateAccount (email_address, username, password, role_type) {

        this.setState({ fireRedirectOnSuccess: true })
        this.props.teamMemberCreateAccountPost(this.organization_id, email_address, username, password, role_type, this.resetAndFetchAllData)

    }

    onTeamMemberEmailAdd (email_address, role_type) {

        this.setState({ fireRedirectOnSuccess: true })
        this.props.teamMemberAddEmailPost(this.organization_id, email_address, role_type, this.resetAndFetchAllData)

    }

    onTeamMemberEmailUpdate (user_account_id, role_type) {

        this.setState({ fireRedirectOnSuccess: true })
        this.props.teamMemberUpdatePost(this.organization_id, user_account_id, role_type, this.resetAndFetchAllData)

    }

    onTeamMemberDelete (e) {

        const user_account_id = e.target.getAttribute('data-user-account-id')
        this.props.teamMemberDeletePost(this.organization_id, user_account_id, this.resetAndFetchAllData)

    }

    // Return the completely assembled component
    render () {

        if (
            (
                (this.props.team_member_create_account_successfully_posted && this.state.fireRedirectOnSuccess) ||
                (this.props.team_member_add_email_successfully_posted && this.state.fireRedirectOnSuccess) ||
                (this.props.team_member_update_successfully_posted && this.state.fireRedirectOnSuccess)
            ) &&
            this.props.location.pathname !== this.my_team_url
        ) {

            // console.log("redir hit")
            return (
                <Redirect to={this.my_team_url} />
            )

        }

        return (

            <MyTeamListBody
                /* Fetch */
                is_currently_loading={this.props.currently_fetching_team_members}
                loading_error={this.props.team_members_fetch_error}
                loaded_data={this.props.team_members_data}

                /* Add Email */
                handleOnTeamMemberEmailAdd={this.onTeamMemberEmailAdd}
                currently_sending_team_member_add_email_post={this.props.currently_sending_team_member_add_email_post}
                team_member_add_email_error={this.props.team_member_add_email_error}
                team_member_add_email_return_data={this.props.team_member_add_email_return_data}
                team_member_add_email_successfully_posted={this.props.team_member_add_email_successfully_posted}
                team_member_add_email_not_found_error={this.props.team_member_add_email_not_found_error}
                handleResetCreateData={this.props.teamMemberAddEmailReset}

                /* Create Account */
                handleOnTeamMemberCreateAccount={this.onTeamMemberCreateAccount}
                currently_sending_team_member_create_account_post={this.props.currently_sending_team_member_create_account_post}
                team_member_create_account_error={this.props.team_member_create_account_error}
                team_member_create_account_return_data={this.props.team_member_create_account_return_data}
                team_member_create_account_successfully_posted={this.props.team_member_create_account_successfully_posted}
                handleResetCreateAccountData={this.props.teamMemberCreateAccountReset}

                /* Update */
                handleOnTeamMemberEmailUpdate={this.onTeamMemberEmailUpdate}
                currently_sending_team_member_update_post={this.props.currently_sending_team_member_update_post}
                team_member_update_error={this.props.team_member_update_error}
                team_member_update_return_data={this.props.team_member_update_return_data}
                team_member_update_successfully_posted={this.props.team_member_update_successfully_posted}
                handleResetUpdateData={this.props.teamMemberUpdateReset}

                /* Delete */
                handleOnTeamMemberDelete={this.onTeamMemberDelete}
                currently_sending_team_member_delete_post={this.props.currently_sending_team_member_delete_post}
                team_member_delete_error={this.props.team_member_delete_error}
                team_member_delete_return_data={this.props.team_member_delete_return_data}
                team_member_delete_successfully_posted={this.props.team_member_delete_successfully_posted}

                {...this.props}
            />

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    // Fetch
    currently_fetching_team_members:              state.team_members.currently_fetching_team_members,
    team_members_fetch_error:                     state.team_members.team_members_fetch_error,
    team_members_data:                            state.team_members.team_members_data,
    // Add Email
    currently_sending_team_member_add_email_post: state.team_member_add_email.currently_sending_team_member_add_email_post,
    team_member_add_email_error:                  state.team_member_add_email.team_member_add_email_error,
    team_member_add_email_return_data:            state.team_member_add_email.team_member_add_email_return_data,
    team_member_add_email_successfully_posted:    state.team_member_add_email.team_member_add_email_successfully_posted,
    team_member_add_email_not_found_error:        state.team_member_add_email.team_member_add_email_not_found_error,
    // Create Account
    currently_sending_team_member_create_account_post: state.team_member_create_account.currently_sending_team_member_create_account_post,
    team_member_create_account_error:                  state.team_member_create_account.team_member_create_account_error,
    team_member_create_account_return_data:            state.team_member_create_account.team_member_create_account_return_data,
    team_member_create_account_successfully_posted:    state.team_member_create_account.team_member_create_account_successfully_posted,
    // Update
    currently_sending_team_member_update_post:    state.team_member_update.currently_sending_team_member_update_post,
    team_member_update_error:                     state.team_member_update.team_member_update_error,
    team_member_update_return_data:               state.team_member_update.team_member_update_return_data,
    team_member_update_successfully_posted:       state.team_member_update.team_member_update_successfully_posted,
    // Delete
    currently_sending_team_member_delete_post:    state.team_member_delete.currently_sending_team_member_delete_post,
    team_member_delete_error:                     state.team_member_delete.team_member_delete_error,
    team_member_delete_return_data:               state.team_member_delete.team_member_delete_return_data,
    team_member_delete_successfully_posted:       state.team_member_delete.team_member_delete_successfully_posted,

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    // Fetch
    teamMembersReset,
    teamMembersFetch,
    // Add Email
    teamMemberAddEmailReset,
    teamMemberAddEmailPost,
    // Create Account
    teamMemberCreateAccountReset,
    teamMemberCreateAccountPost,
    // Update
    teamMemberUpdateReset,
    teamMemberUpdatePost,
    // Delete
    teamMemberDeleteReset,
    teamMemberDeletePost
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyTeamList)

// This file is included by

import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import { Route } from 'react-router-dom'

import NavBreadcrumbLink from '../NavBreadcrumbLink/NavBreadcrumbLink'

class NavBreadcrumbSettingsMyTeam extends Component {

    // constructor (props) {

        // super(props)

    // }

    // componentWillMount () {

        // console.log("NavBreadcrumbSettingsMyTeam")
        // console.dir(this.props)

    // }

    // Return the completely assembled component
    render () {

        return (

            <>
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Settings"
                />
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="My Team"
                />
            </>

        )

    }

}


export default NavBreadcrumbSettingsMyTeam


// import $ from 'jquery'
import React, { Component } from 'react'
// import {
// Link
// } from 'react-router-dom'
// import './AlertRuleForm.css'

import FancyTextInputField from 'components/shared/FancyTextInputField/FancyTextInputField'


class AlertRuleTitleField extends Component {

    constructor (props) {

        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.validateTitleField = this.validateTitleField.bind(this)
        this.renderFieldErrorMessage = this.renderFieldErrorMessage.bind(this)

        this.minimum_field_value_length = 2
        this.maximum_field_value_length = 100

        this.state = {
            field_value: this.props.fieldValue || "X",
            field_error_flag: this.props.fieldErrorFlag || false,
            field_error_message: this.props.fieldErrorMessage || ''
        }

        // console.log("Constructor - AlertRuleTitleField", this.props.fieldValue)

    }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps", nextProps)

        if (nextProps.fieldValue !== null) {

            this.setState({ field_value: nextProps.fieldValue })

        }

    }

    validateTitleField (alert_rule_title) {

        if (
            alert_rule_title.length < this.minimum_field_value_length ||
            alert_rule_title.length > this.maximum_field_value_length
        ) {

            return false

        }

        return true

    }

    handleChange (e) {

        if (this.validateTitleField(e.target.value)) {

            // No errors in entered text
            this.setState({
                field_value: e.target.value,
                field_error_flag: false,
                field_error_message: ''
            })

            this.props.onFieldChange(false, e.target.value)

        } else {

            // Entered text is too short
            this.setState({
                field_value: e.target.value,
                field_error_flag: true,
                field_error_message: `Alert rule titles must be between ${this.minimum_field_value_length} and ${this.maximum_field_value_length} characters long`
            })

            this.props.onFieldChange(true, e.target.value)

        }

    }

    renderFieldErrorMessage () {

        if (this.state.field_error_flag !== true) {

            return (<div />)

        }

        return (

            <div className="text-danger mt-1">
                {this.state.field_error_message}
            </div>

        )

    }

    // Return the completely assembled component
    render () {

        const currently_loading_flag = typeof this.state.field_value === "undefined"

        let container_classes = `row form-group`
        if (this.props.container_classes) {

            container_classes = `${container_classes} ${this.props.container_classes}`

        }

        return (

            <div className={container_classes}>

                <div className="col editor-label-column">

                    <label className="col-form-label">Title</label>

                </div>
                <div className="col">

                    {/*
                    {this.renderFieldErrorMessage()}
                    <input
                        value={this.state.field_value}
                        type="text"
                        className={field_class_string}
                        id="alert_rule_title"
                        onChange={this.handleChange}
                        placeholder="API Server Available"
                    />
                    */}

                    <FancyTextInputField
                        value={this.state.field_value}
                        id="alert_rule_title"
                        onChange={this.handleChange}
                        placeholder="Send An Email Alert To The OPs Team"
                        currently_loading={currently_loading_flag}
                        has_error={this.state.field_error_flag}
                        error_message={this.state.field_error_message}
                    />

                </div>

            </div>

        )

    }

}


export default AlertRuleTitleField

// This file is included by modules/all_modules.js (For the reducers)
// This file is also included by stuff (For the actions)

import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'


// Initial Module State
const initialState = {
    canary_test_reports_data: {}
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const CANARY_TEST_REPORTS_RESET_FLAGS = 'canary_test_reports/CANARY_TEST_REPORTS_RESET_FLAGS'
export const CANARY_TEST_REPORTS_RESET_ALL = 'canary_test_reports/CANARY_TEST_REPORTS_RESET_ALL'
export const CANARY_TEST_REPORTS_FETCH_REQUESTED = 'canary_test_reports/CANARY_TEST_REPORTS_FETCH_REQUESTED'
export const CANARY_TEST_REPORTS_FETCH_SUCCESS = 'canary_test_reports/CANARY_TEST_REPORTS_FETCH_SUCCESS'
export const CANARY_TEST_REPORTS_FETCH_ERROR = 'canary_test_reports/CANARY_TEST_REPORTS_FETCH_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const canaryTestReportsReset = () => {

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_REPORTS_RESET_FLAGS
        })

    }

}

export const canaryTestReportsFetch = ({ organization_id, canary_test_id, report_id, time_range }) => {

    // console.log("canary_testPost called")

    // DEBUG
    // report_id = "PULL_CANARY_TEST_OVER_TIME_AVERAGE_REPORT"
    // report_id = "PULL_CANARY_TEST_SUCCESS_FAILURE_REPORT"

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_REPORTS_RESET_FLAGS,
            report_id: report_id
        })

        dispatch({
            type: CANARY_TEST_REPORTS_FETCH_REQUESTED,
            report_id: report_id
        })

        const auth_token = auth.get("auth_token")

        const json_payload = {
            "action": report_id,
            "action_payload": {
                "time_range": "MONTH", // "DAY", // "NINETY_DAYS", // "WEEK", //"MONTH", //"WEEK", // time_range,
                "timezone": "America/Los_Angeles"
            }
        }

        return axios({
            method: 'post',
            // url: `//${config["api_hostname"]}/v1/o/${organization_id}/reports/canary_tests/2fd0103d-96c7-4174-b909-9a655902e765`,
            // url: `//${config["api_hostname"]}/v1/o/${organization_id}/reports/canary_tests/c5c06783-4229-4b83-aadf-b4fa6e6d181b`,
            url: `//${config["api_hostname"]}/v1/o/${organization_id}/reports/canary_tests/${canary_test_id}`,
            headers: {
                Authorization: `Token ${auth_token}`
            },
            data: json_payload
        })


            .then((response) => {

                const canary_test_reports_data = response.data

                dispatch({
                    type: CANARY_TEST_REPORTS_FETCH_SUCCESS,
                    report_id: report_id,
                    payload: canary_test_reports_data.data
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    console.log("error1", error)
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    console.log("error2", error)
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    console.log("error3", error)
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: CANARY_TEST_REPORTS_FETCH_ERROR,
                    report_id: report_id,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case CANARY_TEST_REPORTS_RESET_FLAGS: {

            const new_data = {
                _meta: {
                    currently_fetching: false,
                    fetching_success: false,
                    fetch_error: null
                },
                data: []
            }

            return {
                ...state,
                canary_test_reports_data: {
                    ...state.canary_test_reports_data,
                    [action.report_id]: new_data
                }
            }

        }

        case CANARY_TEST_REPORTS_RESET_ALL: {

            // Clears all report data
            return {
                ...state,
                canary_test_reports_data: {}
            }

        }

        case CANARY_TEST_REPORTS_FETCH_REQUESTED: {

            const new_data = {
                _meta: {
                    currently_fetching: true,
                    fetching_success: false,
                    fetch_error: null
                },
                data: []
            }

            return {
                ...state,
                canary_test_reports_data: {
                    ...state.canary_test_reports_data,
                    [action.report_id]: new_data
                }
            }

        }

        case CANARY_TEST_REPORTS_FETCH_SUCCESS: {

            const new_data = {
                _meta: {
                    currently_fetching: false,
                    fetching_success: true,
                    fetch_error: null
                },
                data: action.payload
            }

            return {
                ...state,
                canary_test_reports_data: {
                    ...state.canary_test_reports_data,
                    [action.report_id]: new_data
                }
            }

        }

        case CANARY_TEST_REPORTS_FETCH_ERROR: {

            const new_data = {
                _meta: {
                    currently_fetching: false,
                    fetching_success: false,
                    fetch_error: action.payload
                },
                data: []
            }

            return {
                ...state,
                canary_test_reports_data: {
                    ...state.canary_test_reports_data,
                    [action.report_id]: new_data
                }
            }

        }

        default:
            return state

    }

}

import React, { Component } from 'react'
import {
    Link // , NavLink
} from 'react-router-dom'

import DashboardDataTableFancyHeader from './DashboardDataTableFancyHeader/DashboardDataTableFancyHeader'
import './DashboardDataTable.scss'

class DashboardDataTable extends Component {

    constructor (props) {

        super(props)

        this.renderDataTableHeader = this.renderDataTableHeader.bind(this)
        this.renderDataTableRawHeader = this.renderDataTableRawHeader.bind(this)
        this.renderDataTableHeaderColumn = this.renderDataTableHeaderColumn.bind(this)

        this.renderDataTableBody = this.renderDataTableBody.bind(this)
        this.renderDataTableRow = this.renderDataTableRow.bind(this)
        this.renderDataTableColumn = this.renderDataTableColumn.bind(this)

        this.renderDataTablePagination = this.renderDataTablePagination.bind(this)

    }

    // componentDidMount () {
    // }

    renderDataTableRawHeader (markup) {

        let col_span = 1

        // console.log("col_span", col_span)
        if (this.props.table_data.body.length > 0) {

            // console.log("col_span2", col_span)
            // console.log("this.props.table_data.body[0]", this.props.table_data.body[0])
            col_span = this.props.table_data.body[0].length

        }

        // console.log("col_span3", col_span)
        return (
            <thead className="rawTableHeader">
                <tr>
                    <th colSpan={col_span}>{ markup }</th>
                </tr>
            </thead>
        )

    }

    renderDataTableHeader () {

        if (this.props.table_data.fancy_header) {

            return this.renderDataTableRawHeader(
                <DashboardDataTableFancyHeader header_data={this.props.table_data.fancy_header} />
            )

        }

        if (!this.props.table_data.header) {

            return null

        }

        const header_columns = this.props.table_data.header.map(this.renderDataTableHeaderColumn)

        return (
            <thead>
                <tr>
                    { header_columns }
                </tr>
            </thead>
        )

    }

    renderDataTableHeaderColumn ({ column_key, column_markup, on_click_function, column_classes }) {

        const column_class = `${column_classes}`
        return (
            <th key={column_key} data-column-key={column_key} className={column_class} onClick={on_click_function}>
                {column_markup}
            </th>
        )

    }

    renderDataTableBody () {

        let body_rows = (
            <tr>
                <td>-</td>
            </tr>
        )

        if (this.props.table_data.body.length > 0) {

            body_rows = this.props.table_data.body.map(this.renderDataTableRow)

        }

        return (
            <tbody>
                { body_rows }
            </tbody>
        )

    }

    renderDataTableRow (columns_data, index) {

        // console.log("columns_data", columns_data)
        // return null

        return (
            <tr key={index}>
                { columns_data.map(this.renderDataTableColumn) }
            </tr>
        )

    }

    renderDataTableColumn ({ column_key, column_markup, on_click_function, column_classes, colspan }) {

        const column_class = `${column_classes}`
        return (
            <td key={column_key} className={column_class} onClick={on_click_function} colSpan={colspan}>
                {column_markup}
            </td>
        )

    }

    renderPaginationLink (text, url) {

        // console.log(text, url)

        if (url) {

            return (
                <li className="page-item">
                    <Link className="page-link pointer-clickable pagination-page-link" to={url}>{text}</Link>
                </li>
            )

        }

        return (
            <li className="page-item disabled pointer-disabled">
                <div className="page-link pagination-page-link">{text}</div>
            </li>
        )

    }

    renderPage (page_number) {

        if (!page_number) {

            return null

        }

        return (
            <li className="page-item disabled">
                <div className="page-link">Page {page_number}</div>
            </li>
        )

    }

    renderDataTablePagination () {

        if (!this.props.table_data.pagination) {

            return null

        }

        return (
            <nav className="mt-4">
                <ul className="pagination justify-content-center">
                    {this.renderPaginationLink("Previous", this.props.table_data.pagination.previous_url)}
                    {this.renderPage(this.props.table_data.pagination.page_number)}
                    {this.renderPaginationLink("Next", this.props.table_data.pagination.next_url)}
                </ul>
            </nav>
        )

    }

    handleRefreshPageClick () {

        // fixing react so it doesn't yell in my console.log :)
        const current_url = window.location.href
        window.location.href = current_url

    }

    render () {

        // if (true) {
        if (this.props.loading_error) {

            return (
                <div className="api-canary-data-table-outer-container">
                    <div className="api-canary-data-table-container">
                        <table className="table table-responsive api-canary-data-table-failure">
                            <thead>
                                <tr>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="mb-5">{this.props.loading_error}</div>
                                        <button type="button" className="btn btn-sm pointer-clickable" onClick={this.handleRefreshPageClick}>Reload page</button> to try again.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )

        }

        const tableClassNames = `table table-hover api-canary-data-table ${this.props.className}`

        return (
            <div className="api-canary-data-table-outer-container">
                <div className="api-canary-data-table-container table-responsive">
                    <table className={tableClassNames}>
                        { this.renderDataTableHeader() }
                        { this.renderDataTableBody() }
                    </table>
                </div>
                { this.renderDataTablePagination() }
            </div>
        )

    }

}

export default DashboardDataTable

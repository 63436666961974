// import $ from 'jquery'
import React, { Component } from 'react'

// https://www.npmjs.com/package/validator
// import isMobilePhone from 'validator/lib/isMobilePhone'

// https://www.npmjs.com/package/awesome-phonenumber
import PhoneNumber from "awesome-phonenumber"

// import {
// Link
// } from 'react-router-dom'

import './SMSField.css'


class SMSField extends Component {

    constructor (props) {

        super(props)
        this.handleFieldOnChange = this.handleFieldOnChange.bind(this)

        this.supported_locales = [
            'en-US', 'en-AU', 'en-CA', 'en-GB', 'en-HK', 'en-IN', 'en-KE',
            'en-NG', 'en-NZ', 'en-RW', 'en-SG', 'en-UG', 'en-US', 'en-TZ',
            'en-ZA', 'en-ZM', 'en-PK'
        ]

        this.minimum_field_value_length = 2

        this.component_alert_rule_type = "SMS"

        this.show_success_flag = false

        this.state = {
            phone_number: this.props.phone_number,
            phone_number_error_flag: false
        }

    }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps hit:", nextProps)

        if (nextProps.phone_number !== null) {

            this.setState({ phone_number: nextProps.phone_number })

        }

    }

    getValidationOfPhoneNumber (phone_number) {

        if (phone_number.startsWith("+")) {

            return new PhoneNumber(phone_number)

        }

        return new PhoneNumber(phone_number, 'US')

    }

    handleFieldOnChange (e) {

        // console.log("handleFieldOnChange hit")

        const new_phone_number = e.target.value.trimStart()

        // console.log(Phonenumber

        // console.log(PhoneNumber.getCountryCodeForRegionCode( 'SE' ))
        // console.log(PhoneNumber.getCountryCodeForRegionCode( 'US' ))

        let error_flag = false

        const pn = this.getValidationOfPhoneNumber(new_phone_number)
        if (!pn.isValid()) {

            error_flag = true

        }

        this.setState({
            phone_number: new_phone_number,
            phone_number_error_flag: error_flag
        })

        if (this.props.handlePhoneNumberChange) {

            this.props.handlePhoneNumberChange(error_flag, new_phone_number)

        }

    }

    // Return the completely assembled component
    render () {

        // console.log("Render hit: ", this.state.phone_number)

        let field_class_string = `form-control border`
        let fail_success = (<i className="fa fa-2x fa-fw" />)

        if (this.state.phone_number_error_flag) {

            this.show_success_flag = true

            field_class_string = `${field_class_string} border-danger`
            fail_success = (<i className="fa fa-2x fa-fw fa-times text-danger" />)

        } else if (this.show_success_flag) {

            fail_success = (<i className="fa fa-2x fa-fw fa-check text-success" />)

        }

        const pn = this.getValidationOfPhoneNumber(this.state.phone_number)

        let interpreted_as_string = ""
        let valid_string = ""

        if (this.state.phone_number.length > 0) {

            valid_string = (<span className="text-danger">Invalid phone number</span>)

        }

        if (pn.isValid()) {

            interpreted_as_string = pn.getNumber('rfc3966')
            valid_string = (<span className="text-success">Valid phone number</span>)

        }

        let validation_markup = (<div />)
        if (this.state.phone_number.length > 0) {

            validation_markup = (
                <div>
                    <p className="ml-1 mt-2 mb-0">
                        You entered: {this.state.phone_number}
                    </p>
                    <p className="ml-1 my-0">
                        Assumed country: {pn.getRegionCode()}
                    </p>
                    <p className="ml-1 my-0">
                        Interpreted as: {interpreted_as_string} {valid_string}
                    </p>

                    {/*
                    <pre>
                        {JSON.stringify(pn.toJSON(), null, 2)}
                        {JSON.stringify(PhoneNumber.getCountryCodeForRegionCode(pn.getRegionCode()), null, 2)}
                    </pre>
                    */}
                </div>
            )

        }

        return (

            <div className="row form-group mt-4 mb-4 mr-4">
                <div className="col editor-label-column">
                    <label className="col-form-label">Mobile Phone Number:</label>
                </div>
                <div className="col">
                    <div className="d-flex flex-row">

                        <div className="w-100">
                            <input
                                type="text"
                                placeholder="415-555-1212"
                                name="alert_rule_phone_number"
                                value={this.state.phone_number}
                                id="sms_alert_type_phone_number_field"
                                onChange={this.handleFieldOnChange}
                                className={field_class_string}
                            />
                            <small className="ml-1 text-secondary">
                                Use full country codes for non-USA based numbers. Example: +44 7712 345678
                                {validation_markup}
                            </small>
                        </div>
                        <div className="">
                            {fail_success}
                        </div>

                    </div>

                </div>
            </div>

        )

    }

}


export default SMSField

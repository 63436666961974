import React, { Component } from 'react'


class FancyFormElementErrorMessage extends Component {

    renderIcon (error_level) {

        if (error_level === "success") {

            return (<i className="fa fa-check text-success mr-2" />)

        } else if (error_level === "danger") {

            // return (<i className="fa fa-times text-danger mr-2" />)
            return (<i className="fa fa-exclamation-circle text-danger mr-1" />)

        }

        return ""

    }

    renderEmptyErrorMessage () {

        return ""

    }

    renderErrorMessage (error_message) {

        // const error_message_class_string = `alert alert-${this.props.error_level} alert-dismissiblex fade show`
        const error_message_class_string = `text-danger fade show`
        return (

            <div className={error_message_class_string}>
                { this.renderIcon("danger") } {error_message}
            </div>

        )

    }

    // Return the completely assembled component
    render () {

        if (this.props.show_error_message) {

            return this.renderErrorMessage(this.props.error_message)

        }

        return this.renderEmptyErrorMessage()

    }

}


export default FancyFormElementErrorMessage

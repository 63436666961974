// import $ from 'jquery'
import React, { Component } from 'react'

import MyTeamListItemView from './MyTeamListItemView/MyTeamListItemView'
import MyTeamListItemEditor from './MyTeamListItemEditor/MyTeamListItemEditor'

class MyTeamListItem extends Component {

    // Return the completely assembled component
    render () {

        if (this.props.mode === "editor") {

            return (

                <MyTeamListItemEditor
                    /* Editing */
                    mode={this.props.mode}

                    user_account_id={this.props.user_account_id}
                    user_account_email_address={this.props.user_account_email_address}
                    organization_role_type={this.props.organization_role_type}

                    /* OLD */
                    vault_key={this.props.vault_key}
                    vault_key_value={this.props.vault_key_value}

                    onTeamMemberFormSubmit={this.props.onTeamMemberEmailUpdate}
                    currently_sending_post={this.props.currently_sending_team_member_update_post}
                    team_member_post_error={this.props.team_member_update_error}
                    team_member_post_return_data={this.props.team_member_update_return_data}
                    team_member_post_successfully_posted={this.props.team_member_update_successfully_posted}

                    {...this.props}
                />

            )

        }

        return (

            <MyTeamListItemView

                user_account_id={this.props.user_account_id}
                user_account_username={this.props.user_account_username}
                user_account_email_address={this.props.user_account_email_address}
                organization_role_type={this.props.organization_role_type}

                /* OLD */
                vault_key={this.props.vault_key}
                vault_key_value={this.props.vault_key_value}
                onTeamMemberDelete={this.props.onTeamMemberDelete}

                {...this.props}
            />

        )

    }

}


export default MyTeamListItem

import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as moment from 'moment-timezone'

import NavBreadcrumbLink from '../NavBreadcrumbLink/NavBreadcrumbLink'

class NavBreadcrumbCanaryTestExecutionResultDetail extends Component {

    constructor (props) {

        super(props)
        this.renderCanaryTestLink = this.renderCanaryTestLink.bind(this)
        this.renderExecutionResultText = this.renderExecutionResultText.bind(this)

        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.canary_test_id = params.canary_test_id

    }

    renderExecutionResultText () {

        // currently_fetching_canary_test_execution_result: state.canary_test_execution_result.currently_fetching_canary_test_execution_result,
        // canary_test_execution_result_fetch_error: state.canary_test_execution_result.canary_test_execution_result_fetch_error,
        // canary_test_execution_result_fetch_successful: state.canary_test_execution_result.canary_test_execution_result_fetch_successful,
        // canary_test_execution_result_data: state.canary_test_execution_result.canary_test_execution_result_data,

        if (this.props.canary_test_execution_result_fetch_successful) {

            // console.log("this.props.canary_test_execution_result_data", this.props.canary_test_execution_result_data)

            const date_string = moment(this.props.canary_test_execution_result_data["canary_test_execution_result_date_created"]).tz(this.props.user_timezone).format("MMM Do YYYY, h:mma z")

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={date_string}
                    />
                </>

            )

        }

        if (this.props.currently_fetching_canary_test_execution_result) {

            const spinner = (

                <i className="fa fa-refresh fa-spin fa-fw text-secondary" />

            )

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={spinner}
                    />
                </>

            )

        }

        if (this.props.canary_test_execution_result_fetch_error) {

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text="Error"
                    />
                </>

            )

        }

        return ""

    }

    renderCanaryTestLink () {

        if (this.props.canary_test_title !== null) {

            const canary_test_url = `/o/${this.organization_id}/canary_tests/d/${this.canary_test_id}`

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={this.props.canary_test_title}
                        breadcrumb_link_url={canary_test_url}
                    />
                </>

            )

        }

        if (this.props.currently_fetching_canary_test) {

            const spinner = (

                <i className="fa fa-refresh fa-spin fa-fw text-secondary" />

            )

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={spinner}
                    />
                </>

            )

        }

        if (this.props.canary_test_fetch_error) {

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text="Error"
                    />
                </>

            )

        }

        return ""

    }

    // Return the completely assembled component
    render () {

        const canary_tests_url = `/o/${this.organization_id}/canary_tests`

        return (

            <>
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Tests"
                    breadcrumb_link_url={canary_tests_url}
                />
                <span className="navbar-text">/</span>
                {this.renderCanaryTestLink()}
                <span className="navbar-text">/</span>
                {this.renderExecutionResultText()}
            </>

        )

    }

}


// export default NavBreadcrumbCanaryTestExecutionResultDetail


// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    currently_fetching_canary_test: state.canary_test.currently_fetching_canary_test,
    canary_test_fetch_error: state.canary_test.canary_test_fetch_error,
    canary_test_title: state.canary_test.canary_test_title,

    currently_fetching_canary_test_execution_result: state.canary_test_execution_result.currently_fetching_canary_test_execution_result,
    canary_test_execution_result_fetch_error: state.canary_test_execution_result.canary_test_execution_result_fetch_error,
    canary_test_execution_result_fetch_successful: state.canary_test_execution_result.canary_test_execution_result_fetch_successful,
    canary_test_execution_result_data: state.canary_test_execution_result.canary_test_execution_result_data,

    user_timezone: state.user_account.user_timezone
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBreadcrumbCanaryTestExecutionResultDetail)

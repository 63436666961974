// import $ from 'jquery'
import React, { Component } from 'react'

// import {
// Link
// } from 'react-router-dom'

import SMSField from './SMSField/SMSField'

import './SMSTypeOption.css'


class SMSTypeOption extends Component {

    constructor (props) {

        super(props)
        this.handleAlertTypeClick = this.handleAlertTypeClick.bind(this)
        this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this)

        this.minimum_field_value_length = 2

        this.component_alert_rule_type = "SMS"

        this.default_alert_rule_details = { "phone_number": "" }

        // console.log("1", this.props.alert_rule_details)

        this.state = {
            selected_alert_rule_type: this.props.selected_alert_rule_type,
            alert_rule_details: this.props.alert_rule_details || this.default_alert_rule_details,
            alert_rule_detail_error_flag: false
        }

    }

    // componentWillMount () {

        // console.log("componentWillMount - SMSTypeOption", this.props.selected_alert_rule_type)

    // }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps hit:", nextProps)

        if (nextProps.selected_alert_rule_type !== null) {

            // console.log("2", nextProps.alert_rule_details)

            this.setState({
                selected_alert_rule_type: nextProps.selected_alert_rule_type,
                alert_rule_details: nextProps.alert_rule_details || this.default_alert_rule_details
            })

        }

    }

    handleAlertTypeClick () {

        this.setState({
            selected_alert_rule_type: this.component_alert_rule_type,
            alert_rule_detail_error_flag: false
        })

        if (this.props.onAlertRuleTypeChange) {

            this.props.onAlertRuleTypeChange(this.component_alert_rule_type)

        }

    }

    onPhoneNumberChange (error_flag, new_phone_number) {

        // console.log("onPhoneNumberChange hit", new_phone_number)

        const new_details_object = {
            "phone_number": new_phone_number
        }

        this.setState({
            alert_rule_details: new_details_object,
            alert_rule_detail_error_flag: error_flag
        })

        if (this.props.onAlertRuleDetailsChange) {

            this.props.onAlertRuleDetailsChange(error_flag, new_details_object)

        }

    }

    // Return the completely assembled component
    render () {

        // console.log("Render hit: ", this.state.selected_alert_rule_type)

        let checkbox_markup = (<i className="fa fa-square-o fa-2x" />)
        let checkbox_container_classes = "d-flex pl-2 mb-3 flex-row alert-rule-type-checkbox-container"
        let phone_number_field = (<div />)

        if (this.component_alert_rule_type === this.state.selected_alert_rule_type) {

            checkbox_markup = (<i className="fa fa-check-square fa-2x" />)
            checkbox_container_classes += " selected"

            // console.log("this.state.alert_rule_details[phone_number]", this.state.alert_rule_details["phone_number"])

            phone_number_field = (
                <SMSField
                    phone_number={this.state.alert_rule_details["phone_number"]}
                    handlePhoneNumberChange={this.onPhoneNumberChange}
                />
            )

        }

        let checkbox_classes = "alert-rule-type-checkbox mr-auto pb-1"

        if (this.props.edit_mode) {

            checkbox_classes += " border border-danger position-absolute hidden-checkbox"

        }

        return (

            <div className={checkbox_container_classes} data-alert-type={this.component_alert_rule_type} onClick={this.handleAlertTypeClick} >

                <div className={checkbox_classes}>
                    {checkbox_markup}
                </div>

                <div className="ml-1 mt-1 pr-2 pb-2 alert-rule-type-checkbox-description w-100">

                    {/*
                    <i className="fa fa-2x fa-envelope mr-2" />
                    */}

                    Receive an SMS text message when a test fails.

                    {phone_number_field}

                </div>

            </div>

        )

    }

}


export default SMSTypeOption

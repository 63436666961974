import React, { Component } from 'react'


class AlertBox extends Component {

    renderIcon (error_level) {

        if (error_level === "success") {

            return (<i className="fa fa-check text-success mr-2" />)

        } else if (error_level === "danger") {

            // return (<i className="fa fa-fw fa-times text-danger mr-2" />)
            return (<i className="fa fa-exclamation-circle text-danger mr-2" />)

        }

        return ""

    }

    // Return the completely assembled component
    render () {

        // const { match: { params } } = this.props
        // const organization_id = params.organization_id
        // const canary_test_id = params.canary_test_id
        // const cancel_anchor_url = `/o/${organization_id}/canary_tests`

        let alert_class_string = `alert alert-${this.props.error_level} alert-dismissiblex fade show`

        if (this.props.className) {

            alert_class_string = `${alert_class_string} ${this.props.className}`

        }

        return (

            <div className={alert_class_string} role="alert">
                {/*
                <button type="button" className="close" data-dismiss="alert">
                    <span>&times;</span>
                </button>
                */}
                { this.renderIcon(this.props.error_level) }<strong>{this.props.error_title}</strong> {this.props.error_description}
            </div>

        )

    }

}


export default AlertBox

// This file is included by

import React, { Component } from 'react'
// import { Route } from 'react-router-dom'
// import { bindActionCreators } from 'redux'
// import { connect } from 'react-redux'
import CanaryTests from './CanaryTests/CanaryTests'
import VariableVault from './VariableVault/VariableVault'
import OverviewDashboard from './OverviewDashboard/OverviewDashboard'
import AlertRules from './AlertRules/AlertRules'
import ExecutionNodes from './ExecutionNodes/ExecutionNodes'
// import Settings from './Settings/Settings'
import MyTeam from './Settings/MyTeam/MyTeam'

import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import './DashboardBody.scss'

class DashboardBody extends Component {

    render () {

        // console.log("r this.props.login_return_data", this.props.login_return_data)
        // console.log("typeof this.props.login_return_data", typeof this.props.login_return_data)
        return (

            <div id="dashboard-body-container">
                <PrivateRoute path="/o/:organization_id/d/overview" component={OverviewDashboard} />
                <PrivateRoute path="/o/:organization_id/canary_tests" component={CanaryTests} />
                <PrivateRoute path="/o/:organization_id/variable_vault" component={VariableVault} />
                <PrivateRoute path="/o/:organization_id/alert_rules" component={AlertRules} />
                <PrivateRoute path="/o/:organization_id/data_centers" component={ExecutionNodes} />
                <PrivateRoute path="/o/:organization_id/settings/my_team" component={MyTeam} />
            </div>

        )

    }

}


export default DashboardBody

// import $ from 'jquery'
import React, { Component } from 'react'

import {
    Link
} from 'react-router-dom'

import './VariableVaultListItemView.scss'


class VariableVaultListItemView extends Component {

    constructor (props) {

        super(props)
        // this.handleAlertRuleTypeChange = this.handleAlertRuleTypeChange.bind(this)
        // this.handleAlertRuleDetailChange = this.handleAlertRuleDetailChange.bind(this)
        const { match: { params } } = this.props
        this.organization_id = params.organization_id

    }

    // Return the completely assembled component
    render () {

        const value_string = Array(this.props.vault_key_value.length + 1).join("*")
        const edit_link_url = `/o/${this.organization_id}/variable_vault/${this.props.vault_key}`

        return (

            <div className="row variable-vault-list-item-view-row" key={this.props.vault_key} data-vault-key={this.props.vault_key}>
                <div className="col-3 variable-vault-list-item-view-key-col">
                    {this.props.vault_key}
                </div>
                <div className="col-5 col-lg-6 variable-vault-list-item-view-key-value-col">
                    <i className="fa fa-fw fa-lock sidebar-archor-icon sidebar-fa-lock-ancor-icon" /><span>{value_string}</span>
                </div>
                <div className="col-4 col-lg-3 variable-vault-list-item-view-action-col">
                    <Link to={edit_link_url} type="button" className="btn btn-light btn-sm mr-2" >Edit</Link>
                    <button type="button" data-vault-key={this.props.vault_key} onClick={this.props.handleOnVaultKeyDelete} className="btn btn-light btn-sm" >Delete</button>
                </div>
            </div>

        )

    }

}


export default VariableVaultListItemView

// This file is included by /index.js

// Exports the main "ROOT" of the react application

import React, { Component } from 'react'
import {
    Route,
    Switch
} from 'react-router-dom'
import Homepage from './Homepage/Homepage'
import Dashboard from './Dashboard/Dashboard'
import ObtainAuth from './ObtainAuth/ObtainAuth'
import AccountSettings from './AccountSettings/AccountSettings'
import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import './Root.scss'

class Root extends Component {

    constructor (props) {

        super(props)

        window.ac_session = {
            current_organization: null,
            current_account: null
        }

    }

    // Return the completely assembled component
    render () {

        return (
            <div>
                <main>
                    <Route path="/(signup|login|forgot_password)" component={ObtainAuth} />
                    <Switch>
                        <PrivateRoute path="/account_settings" component={AccountSettings} />
                        <PrivateRoute path="/o/:organization_id" component={Dashboard} />
                        <PrivateRoute path="/o" component={Dashboard} />
                    </Switch>
                    <Route exact path="/" component={Homepage} />
                </main>
            </div>
        )

    }

}

export default Root

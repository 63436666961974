// import $ from 'jquery'
import React, { Component } from 'react'
// import {
    // Link // , NavLink
// } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    Redirect
} from 'react-router-dom'

import {
    canaryTestReset,
    canaryTestFetch
} from 'modules/canary_test_module'

import {
    allExecutionNodesReset,
    allExecutionNodesFetch
} from 'modules/execution_nodes_module'

import {
    allDatacentersReset,
    allDatacentersFetch
} from 'modules/datacenters_module'

import {
    canaryTestExecutionResultReset,
    canaryTestExecutionResultFetch
} from 'modules/canary_test_execution_result_module'

import {
    canaryTestExecutionResultDeleteReset,
    canaryTestExecutionResultDeletePost
} from 'modules/canary_test_execution_result_delete_module'

import AlertBox from 'components/shared/AlertBox/AlertBox'
import ExecutionInformation from './ExecutionInformation/ExecutionInformation'
import ActionsButton from './ActionsButton/ActionsButton'
import LogLines from './LogLines/LogLines'
import ExecutionNodeMap from './ExecutionNodeMap/ExecutionNodeMap'

// import CanaryTestDetailTimeGraphModule from './CanaryTestDetailTimeGraphModule/CanaryTestDetailTimeGraphModule'
// import CanaryTestDetailOverviewModule from './CanaryTestDetailOverviewModule/CanaryTestDetailOverviewModule'
// import CanaryTestDetailFrequencyModule from './CanaryTestDetailFrequencyModule/CanaryTestDetailFrequencyModule'
// import CanaryTestDetailResultsList from './CanaryTestDetailResultsList/CanaryTestDetailResultsList'


class CanaryTestExecutionResultDetail extends Component {

    constructor (props) {

        super(props)

        const { match: { params } } = this.props
        this.canary_test_id = params.canary_test_id
        this.execution_result_id = params.execution_result_id

        this.resetAllData = this.resetAllData.bind(this)
        this.pullAllData = this.pullAllData.bind(this)
        this.renderAlertBox = this.renderAlertBox.bind(this)

        this.onClearDataClick = this.onClearDataClick.bind(this)

        this.state = {
            fireRedirectOnSuccess: false
        }

    }

    componentDidMount () {

        this.resetAllData()
        this.pullAllData()

    }

    resetAllData () {

        // console.log("reset running")
        // Canary test details
        this.props.canaryTestReset()

        // Reports
        // this.props.canaryTestReportsReset()

        // execution result
        this.props.canaryTestExecutionResultReset()
        // console.log("reset done")

        // data centers for map
        this.props.allExecutionNodesReset()
        this.props.allDatacentersReset()

        // delete
        this.props.canaryTestExecutionResultDeleteReset()

    }

    pullAllData () {

        // Canary test details
        this.props.canaryTestFetch(window.ac_session.current_organization.organization_id, this.canary_test_id)

        // execution result
        this.props.canaryTestExecutionResultFetch(window.ac_session.current_organization.organization_id, this.canary_test_id, this.execution_result_id)

        // for map
        this.props.allExecutionNodesFetch(window.ac_session.current_organization.organization_id)
        this.props.allDatacentersFetch(window.ac_session.current_organization.organization_id)

    }

    onClearDataClick () {

        if (window.confirm("Are you sure you want to delete this execution result?\n\nNote: All its data will be purged from our system.")) {

            // this.props.onCanaryTestDeleteClick()

            this.setState({ fireRedirectOnSuccess: true })
            this.props.canaryTestExecutionResultDeletePost(window.ac_session.current_organization.organization_id, this.canary_test_id, this.execution_result_id)

        }

    }

    renderAlertBox () {

        let error_message = false

        if (this.props.canary_test_execution_result_delete_error) {

            error_message = this.props.canary_test_execution_result_delete_error

        }

        if (error_message) {

            return (

                <div className="row">

                    <div className="col-sm">

                        <AlertBox
                            error_level="danger"
                            error_title="Error:"
                            error_description={error_message}
                        />

                    </div>

                </div>

            )

        }

        return (<div />)

    }

    // Return the completely assembled component
    render () {

        // const { match: { params } } = this.props
        // const organization_id = params.organization_id
        // const canary_test_id = params.canary_test_id

        // if we just deleted successfully, redirect back to the canary test detail page.
        if (this.props.canary_test_execution_result_delete_successfully_posted && this.state.fireRedirectOnSuccess) {

            const canary_test_detail_uri = `/o/${window.ac_session.current_organization.organization_id}/canary_tests/d/${this.canary_test_id}?sm=deleted`
            // this.props.canaryTestUpdateReset()

            return (
                <Redirect to={canary_test_detail_uri} />
            )

        }

        // const edit_canary_test_anchor_url = `/o/${organization_id}/canary_tests/d/${canary_test_id}/edit`

        let title_string = (<i className="fa fa-refresh fa-spin fa-fw text-secondary" />)
        // let title_string = (<div style={{ color: 'yellow' }}>Loading</div>)

        if (this.props.canary_test_fetch_error) {

            title_string = "Error"

        } else if (this.props.canary_test_title !== null) {

            title_string = this.props.canary_test.canary_test_title

        }

        return (

            <div>

                <div className="container-fluid dashboard-body-header-container">

                    {this.renderAlertBox()}

                    <div className="row">

                        <div className="col-12 col-md-8">
                            <h1 className="text-truncate">Test Execution Result</h1>
                        </div>

                        {/*
                        <div className="col-12 col-md-8">
                            <h1 className="text-truncate">{title_string}</h1>
                        </div>
                        */}

                        <div className="col-12 col-md-4 text-md-right">
                            <ActionsButton
                                handleClearDataClick={this.onClearDataClick}
                            />
                        </div>
                        {/*
                            <Link className="btn btn-success" to={edit_canary_test_anchor_url}><i className="fa fa-plus" aria-hidden="true" /> Edit Test</Link>
                        */}

                    </div>
                </div>

                <div className="container-fluid">

                    {/* start row */}
                    <div className="row dashboard-body-row mb-4">

                        <div className="col-12 col-lg-4">
                            {/* start col */}

                            <div className="mb-4">
                                <ExecutionNodeMap
                                    is_currently_loading={this.props.currently_fetching_canary_test_execution_result || this.props.currently_fetching_all_execution_nodes || this.props.currently_fetching_datacenters}
                                    loading_error={this.props.canary_test_execution_result_fetch_error || this.props.all_execution_nodes_fetch_error || this.props.datacenters_fetch_error}
                                    all_execution_nodes_data={this.props.all_execution_nodes_data}
                                    datacenters_data={this.props.datacenters_data}
                                    canary_test_execution_result_data={this.props.canary_test_execution_result_data}
                                    user_timezone={this.props.user_timezone}
                                    {...this.props}
                                />
                            </div>

                            <div className="mb-4">
                                <ExecutionInformation
                                    is_currently_loading={this.props.currently_fetching_canary_test_execution_result}
                                    loading_error={this.props.canary_test_execution_result_fetch_error}
                                    canary_test_execution_result_data={this.props.canary_test_execution_result_data}
                                    canary_test_title_string={title_string}
                                    user_timezone={this.props.user_timezone}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>

                        <div className="col-12 col-lg-8">
                            {/* start col */}

                            <LogLines
                                is_currently_loading={this.props.currently_fetching_canary_test_execution_result}
                                loading_error={this.props.canary_test_execution_result_fetch_error}
                                canary_test_execution_result_data={this.props.canary_test_execution_result_data}
                                user_timezone={this.props.user_timezone}
                                {...this.props}
                            />

                            {/* end col */}
                        </div>

                    </div>
                    {/* end row */}

                </div>

            </div>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({

    // fetching execution nodes
    currently_fetching_all_execution_nodes: state.all_execution_nodes.currently_fetching_all_execution_nodes,
    all_execution_nodes_fetch_error: state.all_execution_nodes.all_execution_nodes_fetch_error,
    all_execution_nodes_data: state.all_execution_nodes.all_execution_nodes_data,

    // fetching data centers
    currently_fetching_datacenters: state.datacenters.currently_fetching_datacenters,
    datacenters_fetch_error: state.datacenters.datacenters_fetch_error,
    datacenters_data: state.datacenters.datacenters_data,

    // fetching canary test
    currently_fetching_canary_test: state.canary_test.currently_fetching_canary_test,
    canary_test_fetch_error: state.canary_test.canary_test_fetch_error,
    canary_test: state.canary_test,

    // fetching execution result
    currently_fetching_canary_test_execution_result: state.canary_test_execution_result.currently_fetching_canary_test_execution_result,
    canary_test_execution_result_fetch_error: state.canary_test_execution_result.canary_test_execution_result_fetch_error,
    canary_test_execution_result_fetch_successful: state.canary_test_execution_result.canary_test_execution_result_fetch_successful,
    canary_test_execution_result_data: state.canary_test_execution_result.canary_test_execution_result_data,

    // delete execution result
    currently_sending_canary_test_execution_result_delete_post: state.canary_test_execution_result_delete.currently_sending_canary_test_execution_result_delete_post,
    canary_test_execution_result_delete_error: state.canary_test_execution_result_delete.canary_test_execution_result_delete_error,
    canary_test_execution_result_delete_return_data: state.canary_test_execution_result_delete.canary_test_execution_result_delete_return_data,
    canary_test_execution_result_delete_successfully_posted: state.canary_test_execution_result_delete.canary_test_execution_result_delete_successfully_posted,

    // from account
    user_timezone: state.user_account.user_timezone

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    allExecutionNodesReset,
    allExecutionNodesFetch,
    allDatacentersReset,
    allDatacentersFetch,
    canaryTestReset,
    canaryTestFetch,
    canaryTestExecutionResultReset,
    canaryTestExecutionResultFetch,
    canaryTestExecutionResultDeleteReset,
    canaryTestExecutionResultDeletePost,
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CanaryTestExecutionResultDetail)

// This file is included by

import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import { Route } from 'react-router-dom'

import NavBreadcrumbLink from '../NavBreadcrumbLink/NavBreadcrumbLink'

class NavBreadcrumbAlertRulesList extends Component {

    // constructor (props) {

        // super(props)

    // }

    // componentWillMount () {

        // console.log("NavBreadcrumbAlertRulesList")
        // console.dir(this.props)

    // }

    // Return the completely assembled component
    render () {

        return (

            <React.Fragment>
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Alert Rules"
                />
            </React.Fragment>

        )

    }

}


export default NavBreadcrumbAlertRulesList


import $ from 'jquery'
// import queryString from 'query-string'
import React, { Component } from 'react'
import {
    Link,
    Redirect
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    loginPost,
    loginReset
} from 'modules/login_module'
import { auth } from 'lib/authentication'
import config from 'config'


class Login extends Component {

    constructor (props) {

        super(props)
        this.handleLoginFormSubmit = this.handleLoginFormSubmit.bind(this)
        this.renderLoginButton = this.renderLoginButton.bind(this)

    }

    componentWillMount () {

        if (auth.get("temp_un")) {

            // we just signed up so now we log in.
            this.props.loginPost(auth.get("temp_un"), auth.get("temp_pw"))

        } else {

            this.props.loginReset()

        }

    }

    handleLoginFormSubmit (event) {

        event.preventDefault()
        // console.log(event)
        // console.log("fs this.props", this.props)
        // console.log("calling loginPost()...")
        const username = $("#usernameLoginFormInput").val()
        const password = $("#passwordLoginFormInput").val()
        this.props.loginPost(username, password)

    }

    renderLoginStatus () {

        // console.log("re this.props", this.props)
        // If we have a login error, show this...
        const { login_error } = this.props
        if (login_error !== null) {

            return (

                <div className="alert alert-danger" role="alert">
                    <strong>{ login_error }</strong>
                </div>

            )

        }

        // console.log("this.props.match", this.props.match)
        // console.log("this.props.location.search", this.props.location.search)
        // const url_get_params = queryString.parse(this.props.location.search)
        // console.log("url_get_params", url_get_params)

        const searchParams = new URLSearchParams(this.props.location.search)

        // console.log(searchParams.has("sm"))
        // console.log(searchParams.get("sm"))


        if (searchParams.has("sm")) {

            if (searchParams.get("sm") === "a") {

                return (
                    <div className="alert alert-success" role="alert">
                        <strong>You&apos;ve succeeded in signing up, now log in to your new account...</strong>
                    </div>
                )

            } else if (searchParams.get("sm") === "b") {

                return (
                    <div className="alert alert-success" role="alert">
                        <strong>You&apos;ve successfully reset your password, now log in to your account...</strong>
                    </div>
                )

            }

        } else if (searchParams.has("em")) {

            if (searchParams.get("em") === "a") {

                return (
                    <div className="alert alert-danger" role="alert">
                        <strong>We had trouble connecting to the internet, please login again.</strong>
                    </div>
                )

            }

        }


        return (

            <div />

        )

    }

    renderLoginButton () {

        const { currently_sending_login_post } = this.props

        if (currently_sending_login_post === true) {

            return (
                <button type="submit" className="btn mr-2 btn-secondary" disabled>
                    <i className="fa fa-refresh fa-spin fa-fw mr-2" />
                    Logging In
                </button>
            )

        }

        return (
            <button type="submit" className="btn mr-2 btn-success">
                <i className="fa fa-lock fa-fw mr-2" />
                Login
            </button>
        )

    }

    getSignupUI () {

        if (config["allow_signups"]) {

            return (
                <div className="card-footer text-muted">
                    Don&apos;t have an account?&nbsp;&nbsp;&nbsp;
                    <Link to="/signup">Signup</Link>
                </div>
            )

        }

        return (<div />)

    }

    // Return the completely assembled component
    render () {

        if (auth.get("auth_token")) {

            return (
                <Redirect to="/o" />
            )

        }

        // console.log("r this.props.login_return_data", this.props.login_return_data)
        // console.log("typeof this.props.login_return_data", typeof this.props.login_return_data)

        return (

            <form method="POST" onSubmit={this.handleLoginFormSubmit}>

                <h1 className="text-center text-dark">Sign in to API Canary</h1>

                <div className="card">
                    <div className="card-body">

                        { this.renderLoginStatus() }

                        <div className="form-group">
                            <label>Username or Email Address</label>
                            <input defaultValue="" type="text" className="form-control" id="usernameLoginFormInput" placeholder="mattccrampton" />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input defaultValue="" type="password" className="form-control" id="passwordLoginFormInput" placeholder="password" />
                            <small className="form-text text-muted">
                                <Link to="/forgot_password">Forgot password</Link>
                            </small>
                        </div>
                        <div className="form-group">
                            { this.renderLoginButton() }
                        </div>

                    </div>

                    {this.getSignupUI()}

                </div>

            </form>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    login_return_data: state.login.login_return_data,
    currently_sending_login_post: state.login.currently_sending_login_post,
    login_error: state.login.login_error
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    loginPost,
    loginReset
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

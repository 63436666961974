import React from 'react'
import {
    Link
} from 'react-router-dom'

import * as moment from 'moment'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import "./RecentFailuresModule.scss"

class RecentFailuresModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.generateListArray = this.generateListArray.bind(this)
        this.organization_id = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    generateListArray () {

        if (
            this.props.datacenters_data === null ||
            this.props.all_execution_nodes_data === null
        ) {

            return []

        }

        // console.log("START")
        const markup_data = this.props.report_data["data"].map((execution_result) => {

            // console.log("i", i)
            // console.log("ER", execution_result)

            const detail_url = `/o/${this.organization_id}/canary_tests/d/${execution_result["canary_test_id"]}/r/${execution_result["canary_test_execution_result_id"]}/`
            // {execution_result["canary_test_execution_result_failure_message"]}

            return (

                <li key={execution_result["canary_test_execution_result_id"]} className="list-group-item">
                    <Link to={detail_url}>

                        <dl className="d-flex">
                            <dt className="flex-grow-1">{execution_result["canary_test_title"]}</dt>
                            <dd className="failure-badge">
                                <span className="badge badge-pill badge-danger px-2">Failed</span>
                            </dd>
                            <dd className="failure-time">
                                {moment(execution_result["canary_test_execution_result_date_created"]).fromNow()}
                            </dd>
                        </dl>

                    </Link>
                </li>

            )

        })
        // console.log("END")

        // console.log("markup", markup_data)
        // return []
        return markup_data

    }

    render () {

        if (!this.props.report_data || this.props.report_data["_meta"].currently_fetching) {

            return this.renderCard(
                "Recently Failed Tests",
                (
                    <div style={{ height: '100px' }} />
                )
            )

        }

        if (this.props.report_data["_meta"].fetch_error) {

            return this.renderCard(
                "Recently Failed Tests",
                (
                    <p className="m-4">There was a problem fetching data</p>
                )
            )

        }

        return this.renderCard(
            "Recently Failed Tests",
            (
                <div className="recent-failures-overview-module">
                    <ul className="list-group list-group-flush">
                        {this.generateListArray()}
                    </ul>
                </div>
            )
        )

    }

}

export default RecentFailuresModule


import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import NavBreadcrumbLink from '../NavBreadcrumbLink/NavBreadcrumbLink'

class NavBreadcrumbAlertRuleEdit extends Component {

    constructor (props) {

        super(props)
        this.renderAlertRuleLink = this.renderAlertRuleLink.bind(this)

    }

    renderAlertRuleLink () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        // const alert_rule_id = params.alert_rule_id

        if (this.props.alert_rule_title !== null) {

            const alert_rule_anchor_url = `/o/${organization_id}/alert_rules`
            // const alert_rule_anchor_url = `/o/${organization_id}/alert_rules/${alert_rule_id}`

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={this.props.alert_rule_title}
                        breadcrumb_link_url={alert_rule_anchor_url}
                    />
                </>

            )

        }

        if (this.props.currently_fetching_alert_rule) {

            const spinner = (

                <i className="fa fa-refresh fa-spin fa-fw text-secondary" />

            )

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={spinner}
                    />
                </>

            )

        }

        if (this.props.alert_rule_fetch_error) {

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text="Error"
                    />
                </>

            )

        }

        return ""

    }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        const alert_rules_url = `/o/${organization_id}/alert_rules`

        return (

            <>
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Alert Rules"
                    breadcrumb_link_url={alert_rules_url}
                />
                <span className="navbar-text">/</span>
                {this.renderAlertRuleLink()}
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Edit"
                />
            </>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    currently_fetching_alert_rule: state.alert_rule.currently_fetching_alert_rule,
    alert_rule_fetch_error: state.alert_rule.alert_rule_fetch_error,
    alert_rule_title: state.alert_rule.alert_rule_title
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBreadcrumbAlertRuleEdit)

// import $ from 'jquery'
import React, { Component } from 'react'

import FancyFormElementCodeEditor from 'components/shared/FancyFormElementCodeEditor/FancyFormElementCodeEditor'
import ActionBar from './ActionBar/ActionBar'

import './CanaryTestCodeField.scss'

class CanaryTestCodeField extends Component {

    constructor (props) {

        super(props)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnBlur = this.handleOnBlur.bind(this)

    }

    handleOnChange (new_value) {

        // console.log("handleOnChange", this.props.name, new_value.substring(0, 50))
        this.props.onChange(this.props.name, new_value)

    }

    handleOnBlur () {

        // console.log("handleOnBlur")
        this.props.onBlur(this.props.name)

    }

    // Return the completely assembled component
    render () {

        return (
            <div className="form-row pb-4">
                <div className="form-group col-lg-9">
                    <label>NodeJS Code</label>
                    <div className="border border-muted p-0 rounded overflow-hidden">
                        <FancyFormElementCodeEditor
                            id={this.props.id}
                            name={this.props.name}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            value={this.props.value}
                            disabled={this.props.disabled}
                            error_message={this.props.error_message}
                            show_error_message={this.props.show_error_message}
                            height="35em"
                        />
                        <ActionBar
                            disabled={this.props.disabled}
                            currently_sending_canary_test_manually_run_post={this.props.currently_sending_canary_test_manually_run_post}
                            canary_test_manually_run_execution_node_id={this.props.canary_test_manually_run_execution_node_id}
                            canary_test_manually_run_error={this.props.canary_test_manually_run_error}
                            canary_test_manually_run_success={this.props.canary_test_manually_run_success}
                            canary_test_manually_run_return_data={this.props.canary_test_manually_run_return_data}
                            onCanaryTestManualRunRequest={this.props.onCanaryTestManualRunRequest}
                            onCanaryTestManualRunReset={this.props.canaryTestManuallyRunReset}

                            selected_execution_nodes={this.props.selected_execution_nodes}

                            /* fetching execution nodes */
                            currently_fetching_execution_nodes={this.props.currently_fetching_execution_nodes}
                            all_execution_nodes_fetch_error={this.props.all_execution_nodes_fetch_error}
                            all_execution_nodes_data={this.props.all_execution_nodes_data}

                            /* datacenters */
                            currently_fetching_datacenters={this.props.currently_fetching_datacenters}
                            datacenters_fetch_error={this.props.datacenters_fetch_error}
                            datacenters_data={this.props.datacenters_data}
                        />
                    </div>
                    {/*
                    <pre className="border border-muted p-2 rounded">{this.props.value}</pre>
                    */}

                </div>
                <div className="form-group col-lg-3">
                    <label className="d-none d-lg-block">&nbsp;</label>
                    <div className="form-info-bubble">
                        <i className="fa fa-lightbulb-o" />
                        <p>
                            Each production test deployed on API Canary is written in javascript (NodeJS).
                        </p>
                    </div>
                </div>
            </div>
        )

    }

}


export default CanaryTestCodeField


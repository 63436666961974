import React from 'react'
// import {
    // Link
// } from 'react-router-dom'

import {
    Redirect
} from 'react-router-dom'

import * as moment from 'moment'

import { PRETTY_TEXT_STRINGS } from 'lib/constants'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'
import DashboardDataTable from 'components/shared/DashboardDataTable/DashboardDataTable'
import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'

import './ExecutionNodesListBody.css'

class ExecutionNodesListBody extends DashboardModule {

    constructor (props) {

        super(props)
        this.renderFullListBody = this.renderFullListBody.bind(this)
        this.sortClickHandler = this.sortClickHandler.bind(this)
        this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this)
        this.show_outer_border = false

        this.state = {
            fireRedirectOnSuccess: false
        }

    }

    handleCreateDatacenterButtonClick = (e) => {

        // console.log("handleCreateDatacenterButtonClick: ", e.target.getAttribute('data-passthrough'))
        // this.setState({ fireRedirectOnSuccess: true })
        this.props.onExecutionNodeCreate(e.target.getAttribute('data-passthrough'), "JAVASCRIPT")

    }

    handleRebuildButtonClick = (e) => {

        // console.log("handleRebuildButtonClick: ", e.target.getAttribute('data-passthrough'))
        // this.setState({ fireRedirectOnSuccess: true })
        this.props.onExecutionNodeRebuild(e.target.getAttribute('data-passthrough'))

    }

    handleRefreshButtonClick () {

        this.props.onRefresh()

    }

    sortClickHandler () {

        // console.log("sortClickHandler: ", e.target.getAttribute('data-column-key'))

    }

    renderActionsColumn = (datacenter_key, execution_node) => {

        let button_content = null
        // let button_classes = null
        // let button_disabled = null

        if (!execution_node) {

            // console.log("this.props.execution_node_datacenter_being_created", this.props.execution_node_datacenter_being_created)
            // console.log("datacenter_key", datacenter_key)

            // We havent built an execution node for this datacenter yet.
            if (this.props.execution_node_datacenter_being_created === datacenter_key) {

                // We are currently building THIS datacenter
                button_content = (
                    <FancyLoadingButton
                        button_type="success"
                        button_disabled_type="light"
                        button_loading_type="light"
                        button_submit_type="button"
                        currently_loading={true}
                        disabled={false}
                        className="btn-sm"
                        data_passthrough={datacenter_key}
                        non_loading_icon="fa-plus"
                    >
                        Building
                    </FancyLoadingButton>
                )

            } else {

                button_content = (
                    <FancyLoadingButton
                        button_type="success"
                        button_disabled_type="light"
                        button_loading_type="light"
                        button_submit_type="button"
                        currently_loading={false}
                        disabled={false}
                        className="btn-sm execution-node-edit-button-hover-hidden"
                        onClick={this.handleCreateDatacenterButtonClick}
                        data_passthrough={datacenter_key}
                        non_loading_icon="fa-plus"
                    >
                        Enable
                    </FancyLoadingButton>
                )

            }

            return {
                "column_key": "button_column",
                "column_classes": "text-left",
                "column_markup": button_content
            }

        }

        // button_classes = "btn btn-light btn-sm mr-3 execution-node-edit-button execution-node-edit-button-hover-hidden"
        // button_disabled = false

        // console.log("debug", execution_node["execution_node_status_flag"])

        // if (this.props.currently_sending_execution_node_rebuild_post) {

            // We are currently rebuilding an execution node somewhere.
            // button_classes = `${button_classes} disabled`
            // button_disabled = true

        // }


        if (
            this.props.execution_node_id_being_rebuilt === execution_node["execution_node_id"] ||
            execution_node["execution_node_status_flag"] === "BUILDING"
        ) {

            // We are currently building THIS execution node.
            button_content = (
                <FancyLoadingButton
                    button_type="light"
                    button_disabled_type="light"
                    button_loading_type="light"
                    button_submit_type="button"
                    currently_loading={true}
                    disabled={false}
                    className="btn-sm"
                    data_passthrough={datacenter_key}
                    non_loading_icon="fa-plus"
                >
                    Building
                </FancyLoadingButton>
            )

        } else {

            // console.log("execution_node[execution_node_id]", execution_node["execution_node_id"])

            button_content = (
                <FancyLoadingButton
                    button_type="light"
                    button_disabled_type="light"
                    button_loading_type="light"
                    button_submit_type="button"
                    currently_loading={false}
                    disabled={false}
                    className="btn-sm execution-node-edit-button-hover-hidden"
                    onClick={this.handleRebuildButtonClick}
                    data_passthrough={execution_node["execution_node_id"]}
                    non_loading_icon="fa-repeat"
                >
                    Rebuild
                </FancyLoadingButton>
            )

        }


        /*
            "column_markup": (
                <button type="button" disabled={button_disabled} aria-disabled={button_disabled} className={button_classes} onClick={buttonOnClick} data_passthrough={execution_node["execution_node_id"]}>
                    {button_content}
                </button>
            )
        */
        return {
            "column_key": "button_column",
            "column_classes": "",
            "column_markup": button_content
        }

    }

    renderFullListBody () {

        const dashboard_data_table_body_data = Object.keys(this.props.datacenters_data["EXECUTION_NODE_DATACENTERS"]).map((datacenter_key) => {

            const return_columns = []

            const datacenter = this.props.datacenters_data["EXECUTION_NODE_DATACENTERS"][datacenter_key]

            const execution_node = this.props.all_execution_nodes_data.find((execution_node_item) => {

                if (execution_node_item["execution_node_datacenter"] === datacenter_key) {

                    return execution_node_item

                }

                return null

            })


            let row_icon_classes = "fa fa-fw fa-2x fa-cube fa-inverse datacenter-list-icon"

            if (execution_node) {

                row_icon_classes = "fa fa-fw fa-2x fa-cube execution-node-list-icon"

            }

            const data_center_column_markup = (
                <div className="d-flex flex-row">
                    <div className="">
                        <div className={row_icon_classes} />
                    </div>
                    <div className="">
                        <div className="ml-2 mt-1">{datacenter["name"]}</div>
                    </div>
                </div>
            )

            // 1 of 6
            return_columns.push(
                {
                    "column_key": "data_center",
                    "column_markup": data_center_column_markup,
                    "column_classes": "align-middle text-nowrap"
                }
            )

            // 2 of 6
            return_columns.push(
                {
                    "column_key": "cloud_provider",
                    "column_markup": this.props.datacenters_data["CLOUD_PROVIDERS"][datacenter["cloud_provider"]]["name"],
                    "column_classes": "align-middle text-nowrap d-none d-lg-table-cell"
                }
            )

            // 3 of 6
            if (execution_node) {

                // console.log("execution_node", execution_node)

                let icon_markup = ""
                if (execution_node["execution_node_status_flag"] === "BUILDING") {

                    icon_markup = (<i className="fa fa-refresh fa-spin fa-fw text-secondary" />)

                } else if (
                    execution_node["execution_node_status_flag"] === "BUILD_FAILED" ||
                    execution_node["execution_node_status_flag"] === "FAILING_HEALTH_CHECK"
                ) {

                    icon_markup = (<i className="fa fa-fw fa-times-circle text-danger" />)

                } else {

                    icon_markup = (<i className="fa fa-fw fa-check-circle text-success" />)

                }


                const status_string = PRETTY_TEXT_STRINGS[execution_node["execution_node_status_flag"]]

                return_columns.push(
                    {
                        "column_key": "status",
                        "column_markup": (<div>{icon_markup} {status_string}</div>),
                        "column_classes": "align-middle text-nowrap"
                    }
                )

            } else {

                return_columns.push(
                    {
                        "column_key": "status",
                        "column_markup": "",
                        "column_classes": "align-middle text-nowrap"
                    }
                )

            }

            // 4 of 6
            if (execution_node) {

                return_columns.push(
                    {
                        "column_key": "assigned_canary_tests",
                        "column_markup": (<span className="badge badge-pill badge-secondary p-2 px-3">{execution_node["num_tests_assigned"]} Tests</span>),
                        "column_classes": "text-left align-middle text-nowrap d-none d-lg-table-cell"
                    }
                )

            } else {

                return_columns.push(
                    {
                        "column_key": "assigned_canary_tests",
                        "column_markup": "",
                        "column_classes": "text-left align-middle d-none d-lg-table-cell"
                    }
                )

            }

            // 5 of 6
            if (execution_node) {

                return_columns.push(
                    {
                        "column_key": "build_date",
                        "column_markup": moment(execution_node["execution_node_date_last_build"]).tz(this.props.user_timezone).format("MMM Do YYYY, h:mma"),
                        "column_classes": "text-left align-middle text-nowrap d-none d-xl-table-cell"
                    }
                )

            } else {

                return_columns.push(
                    {
                        "column_key": "build_date",
                        "column_markup": "",
                        "column_classes": "text-left align-middle text-nowrap d-none d-xl-table-cell"
                    }
                )

            }

            // 6 of 6

            return_columns.push(this.renderActionsColumn(datacenter_key, execution_node))

            return return_columns

        })

        let refresh_button_markup = (
            <button type="button" className="btn btn-secondary btn-sm" onClick={this.handleRefreshButtonClick}>
                <i className="fa fa-repeat fa-fw" />
            </button>
        )

        if (this.props.is_currently_loading) {

            refresh_button_markup = (
                <button type="button" className="btn btn-secondary btn-sm pointer-disabled" disabled onClick={this.handleRefreshButtonClick}>
                    <i className="fa fa-repeat fa-spin fa-fw" />
                </button>
            )

        }

        const dashboard_data_table_header_data = [
            {
                "column_key": "data_center",
                "column_markup": "Data Center",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap"
            },
            {
                "column_key": "cloud_provider",
                "column_markup": "Cloud Provider",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap d-none d-lg-table-cell"
            },
            {
                "column_key": "status",
                "column_markup": "Status",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap"
            },
            {
                "column_key": "assigned_canary_tests",
                "column_markup": "Assigned",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap d-none d-lg-table-cell"
            },
            {
                "column_key": "build_date",
                "column_markup": "Last Build Date",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap d-none d-xl-table-cell"
            },
            {
                "column_key": "button_column",
                "column_markup": refresh_button_markup,
                "column_classes": "text-nowrap text-right"
            }
        ]

        const dashboard_data_table_data = {
            "header": dashboard_data_table_header_data,
            "body": dashboard_data_table_body_data
        }

        return (
            <DashboardDataTable table_data={dashboard_data_table_data} className="execution-node-list-data-table" />
        )

    }

    render () {

        if (this.props.execution_node_successfully_posted && this.state.fireRedirectOnSuccess) {

            const data_centers_list_uri = `/o/${window.ac_session.current_organization.organization_id}/data_centers?sm=created`
            return (
                <Redirect to={data_centers_list_uri} />
            )

        }

        if (this.props.loadingError) {

            return this.renderRawCard(
                <div className="p-5">
                    We&apos;re having a problem pulling your data.
                    Please <a href="/">refresh</a> and try again.
                    <div className="mt-5">
                        {this.props.loading_error}
                    </div>
                </div>
            )

        } else if (
            this.props.all_execution_nodes_data === null ||
            this.props.datacenters_data === null
        ) {

            return this.renderRawCard(
                " "
            )

        }

        return this.renderRawCard(
            this.renderFullListBody()
        )

    }

}

export default ExecutionNodesListBody

// This file is included by

import React, { Component } from 'react'
// import { Link } from 'react-router-dom'

import { Switch } from 'react-router'

import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import NavBreadcrumbCanaryTestEdit from './NavBreadcrumbCanaryTestEdit/NavBreadcrumbCanaryTestEdit'
import NavBreadcrumbCanaryTestNew from './NavBreadcrumbCanaryTestNew/NavBreadcrumbCanaryTestNew'
import NavBreadcrumbCanaryTestDetail from './NavBreadcrumbCanaryTestDetail/NavBreadcrumbCanaryTestDetail'
import NavBreadcrumbCanaryTestExecutionResultDetail from './NavBreadcrumbCanaryTestExecutionResultDetail/NavBreadcrumbCanaryTestExecutionResultDetail'
import NavBreadcrumbCanaryTestList from './NavBreadcrumbCanaryTestList/NavBreadcrumbCanaryTestList'
import NavBreadcrumbDatacenterList from './NavBreadcrumbDatacenterList/NavBreadcrumbDatacenterList'
import NavBreadcrumbAlertRulesList from './NavBreadcrumbAlertRulesList/NavBreadcrumbAlertRulesList'
import NavBreadcrumbAlertRuleEdit from './NavBreadcrumbAlertRuleEdit/NavBreadcrumbAlertRuleEdit'
import NavBreadcrumbAlertRuleNew from './NavBreadcrumbAlertRuleNew/NavBreadcrumbAlertRuleNew'
import NavBreadcrumbOverviewDashboard from './NavBreadcrumbOverviewDashboard/NavBreadcrumbOverviewDashboard'
import NavBreadcrumbSettingsMyTeam from './NavBreadcrumbSettingsMyTeam/NavBreadcrumbSettingsMyTeam'
import NavBreadcrumbVariableVault from './NavBreadcrumbVariableVault/NavBreadcrumbVariableVault'

class NavBreadcrumb extends Component {

    renderDropdownList = () => {

        // const { user_account_data_has_been_fetched } = this.props

        const dropdown_rows = []

        for (let i = 0; i < this.props.user_account_organizations.length; i++) {

            const org = this.props.user_account_organizations[i]

            // console.log(org)

            const org_url = `/o/${org.organization_id}/d/overview`

            dropdown_rows.push(
                <a className="dropdown-item small" key={i} href={org_url}>{org.organization_name}</a>
            )

        }


        /*
                <div className="dropdown-divider" />
                <Link className="dropdown-item small" to="/" data-toggle="tooltip" data-placement="right" title="Create a new company account (with new subscription plan and billing information)">
                    <i className="fa fa-plus" /> Create New Company Account
                </Link>
        */

        return (

            <div className="dropdown-menu dropdown-menu-left">
                {dropdown_rows}
            </div>

        )

    }

    renderOrgName = () => {

        for (let i = 0; i < this.props.user_account_organizations.length; i++) {

            const org = this.props.user_account_organizations[i]

            if (org.organization_id === window.ac_session.current_organization.organization_id) {

                return org.organization_name

            }

        }

        return "UNKNOWN"

    }

    renderOrgLinkMulti = () => {

        const org_url = `/o/${window.ac_session.current_organization.organization_id}/d/overview`

        return (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href={org_url} id="navbarDropdownMenuLink" data-toggle="dropdown">
                    { this.renderOrgName() }
                </a>
                { this.renderDropdownList() }
            </li>
        )

    }

    renderOrgLinkSingle = () => {

        const org_url = `/o/${window.ac_session.current_organization.organization_id}/d/overview`

        return (
            <li className="nav-item dropdown">
                <a className="nav-link" href={org_url} id="navbarDropdownMenuLink">
                    { this.renderOrgName() }
                </a>
            </li>
        )

    }

    renderOrgLink = () => {

        // should we remove the dropdown for single-org users?
        return this.renderOrgLinkMulti()

    }


    // Return the completely assembled component
    render () {

        return (

            <ul className="navbar-nav mr-auto">

                { this.renderOrgLink() }

                <Switch>
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/new" component={NavBreadcrumbCanaryTestNew} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/d/:canary_test_id/edit" component={NavBreadcrumbCanaryTestEdit} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/d/:canary_test_id/r/:execution_result_id" component={NavBreadcrumbCanaryTestExecutionResultDetail} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/d/:canary_test_id" component={NavBreadcrumbCanaryTestDetail} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests" component={NavBreadcrumbCanaryTestList} />
                    <PrivateRoute exact path="/o/:organization_id/data_centers" component={NavBreadcrumbDatacenterList} />
                    <PrivateRoute exact path="/o/:organization_id/alert_rules" component={NavBreadcrumbAlertRulesList} />
                    <PrivateRoute exact path="/o/:organization_id/alert_rules/new" component={NavBreadcrumbAlertRuleNew} />
                    <PrivateRoute exact path="/o/:organization_id/alert_rules/:alert_rule_id/edit" component={NavBreadcrumbAlertRuleEdit} />
                    <PrivateRoute exact path="/o/:organization_id/d/overview" component={NavBreadcrumbOverviewDashboard} />
                    <PrivateRoute exact path="/o/:organization_id/settings/my_team" component={NavBreadcrumbSettingsMyTeam} />
                    <PrivateRoute exact path="/o/:organization_id/variable_vault" component={NavBreadcrumbVariableVault} />
                    {/*
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/new" component={CanaryTestCreator} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/disabled" component={CanaryTestsList} />
                    <PrivateRoute path="/o/:organization_id/d/overview" component={OverviewDashboard} />
                    <PrivateRoute path="/o/:organization_id/canary_tests" component={NavBreadcrumbCanaryTestDetail} />
                    <PrivateRoute path="/o/:organization_id/variable_vault" component={VariableVault} />
                    <PrivateRoute path="/o/:organization_id/alert_rules" component={AlertRules} />
                    */}
                </Switch>


            </ul>

        )

    }

}


export default NavBreadcrumb

import React from 'react'
import './FancySingleSelectDropdown.css'

// https://react-select.com/home#getting-started

import Select from 'react-select'
import makeAnimated from 'react-select/lib/animated'

import FancySelectDropdown from 'components/shared/FancySelectDropdown/FancySelectDropdown'


class FancySingleSelectDropdown extends FancySelectDropdown {

    renderSelectDropdown () {

        // console.log("fancysingle defaultValue", this.props.selected_option)
        // console.log("fancysingle options", this.props.options)

        let default_value_index = 0

        for (let i = 0; i < this.props.options.length; i++) {
            if (this.props.options[i].value === this.props.selected_option){
                default_value_index = i
                break
            }
        }

        return (
            <Select
                defaultValue={this.props.options[default_value_index]}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                id={this.props.id}
                disabled={this.props.disabled}
                name={this.props.name}
                options={this.props.options}
                className="fancy-single-select-dropdown"
                classNamePrefix="fancy-single-select-dropdown"
                components={makeAnimated()}
            />
        )

    }

}


export default FancySingleSelectDropdown

import React, { Component } from 'react'
import './FancySelectDropdown.scss'


class FancySelectDropdown extends Component {

    constructor (props) {

        super(props)
        // this.handleSelectChange = this.handleSelectChange.bind(this)
        this.renderLoadingUI = this.renderLoadingUI.bind(this)

    }

    renderLoadingUI () {

        if (!this.props.currently_loading) {

            return (<div />)

        }

        return (
            <div className="fancy-select-dropdown-loading-spinner">
                <i className="fa fa-refresh fa-spin text-secondary" />
            </div>
        )

    }

    render () {

        let error_message_ui = (<div />)
        let error_message_icon = (<div />)
        if (this.props.has_error === true) {

            error_message_ui = (
                <div>
                    {this.props.error_message}
                </div>
            )
            error_message_icon = (
                <div className="fancy-select-dropdown-error-icon text-danger">
                    <i className="fa fa-2x fa-exclamation-circle" />
                </div>
            )


        }

        return (
            <div className="fancy-select-dropdown-field-outer-container">
                {this.renderLoadingUI()}
                <div className="fancy-select-dropdown-field-container">
                    {error_message_icon}
                    {this.renderSelectDropdown()}
                </div>
                <div className="fancy-select-dropdown-error-box text-danger">
                    {error_message_ui}
                </div>
            </div>
        )

    }

}


export default FancySelectDropdown

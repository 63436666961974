import React, { Component } from 'react'

import MethodField from './MethodField/MethodField'
import URLField from './URLField/URLField'

import './WebhookTypeOption.scss'

class WebhookTypeOption extends Component {

    constructor (props) {

        super(props)
        this.handleAlertTypeClick = this.handleAlertTypeClick.bind(this)
        this.onMethodChange = this.onMethodChange.bind(this)
        this.onURLChange = this.onURLChange.bind(this)

        this.minimum_field_value_length = 2

        this.component_alert_rule_type = "WEBHOOK"

        this.default_alert_rule_details = {
            'url': '',
            'method': 'POST',
            'webhook_request_params': {
                'organization_id': '{organization_id}',
                'execution_node_id': '{execution_node_id}',
                'execution_node_datacenter_name': '{execution_node_datacenter_name}',
                'execution_node_datacenter_cloud_provider_name': '{execution_node_datacenter_cloud_provider_name}',
                'canary_test_title': '{canary_test_title}',
            },
        }

        // console.log("1", this.props.alert_rule_details)

        this.state = {
            selected_alert_rule_type: this.props.selected_alert_rule_type,
            alert_rule_details: this.props.alert_rule_details || this.default_alert_rule_details,
            alert_rule_detail_error_flag: false
        }

    }

    // componentWillMount () {

        // console.log("componentWillMount - SMSTypeOption", this.props.selected_alert_rule_type)

    // }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps hit:", nextProps)

        if (nextProps.selected_alert_rule_type !== null) {

            // console.log("2", nextProps.alert_rule_details)

            this.setState({
                selected_alert_rule_type: nextProps.selected_alert_rule_type,
                alert_rule_details: nextProps.alert_rule_details || this.default_alert_rule_details
            })

        }

    }

    handleAlertTypeClick () {

        this.setState({
            selected_alert_rule_type: this.component_alert_rule_type,
            alert_rule_detail_error_flag: false
        })

        if (this.props.onAlertRuleTypeChange) {

            this.props.onAlertRuleTypeChange(this.component_alert_rule_type)

        }

    }

    onURLChange (error_flag, new_url) {

        const new_details_object = {
            ...this.state.alert_rule_details
        }

        new_details_object["url"] = new_url

        this.setState(() => ({
            alert_rule_details: new_details_object,
            alert_rule_detail_error_flag: error_flag
        }))

        if (this.props.onAlertRuleDetailsChange) {

            this.props.onAlertRuleDetailsChange(error_flag, new_details_object)

        }

    }

    onMethodChange (error_flag, new_method) {

        const new_details_object = {
            ...this.state.alert_rule_details
        }

        new_details_object["method"] = new_method

        this.setState(() => ({
            alert_rule_details: new_details_object,
            alert_rule_detail_error_flag: error_flag
        }))

        if (this.props.onAlertRuleDetailsChange) {

            this.props.onAlertRuleDetailsChange(error_flag, new_details_object)

        }

    }

    // Return the completely assembled component
    render () {

        // console.dir("Render hit: ", this.state)

        let checkbox_markup = (<i className="fa fa-square-o fa-2x" />)
        let checkbox_container_classes = "d-flex pl-2 mb-3 flex-row alert-rule-type-checkbox-container"
        let method_field = (<div />)
        let url_field = (<div />)

        if (this.component_alert_rule_type === this.state.selected_alert_rule_type) {

            checkbox_markup = (<i className="fa fa-check-square fa-2x" />)
            checkbox_container_classes += " selected"

            // console.log("this.state.alert_rule_details[phone_number]", this.state.alert_rule_details["phone_number"])

            method_field = (
                <MethodField
                    method={this.state.alert_rule_details["method"]}
                    handleMethodChange={this.onMethodChange}
                />
            )

            // console.log(this.state.alert_rule_details)

            url_field = (
                <URLField
                    url={this.state.alert_rule_details["url"]}
                    handleURLChange={this.onURLChange}
                />
            )

        }

        let checkbox_classes = "alert-rule-type-checkbox mr-auto pb-1"

        if (this.props.edit_mode) {

            checkbox_classes += " border border-danger position-absolute hidden-checkbox"

        }

        return (

            <div className={checkbox_container_classes} data-alert-type={this.component_alert_rule_type} onClick={this.handleAlertTypeClick} >

                <div className={checkbox_classes}>
                    {checkbox_markup}
                </div>

                <div className="ml-1 mt-1 pr-2 pb-2 alert-rule-type-checkbox-description w-100">

                    {/*
                    <i className="fa fa-2x fa-envelope mr-2" />
                    */}

                    Receive an webhook request when a test fails.

                    {method_field}
                    {url_field}

                </div>

            </div>

        )

    }

}


export default WebhookTypeOption

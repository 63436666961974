import React from 'react'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

// import './MyTeamListReadOnly.scss'

class MyTeamListReadOnly extends DashboardModule {

    render () {

        return this.renderRawCard(
            <div className="p-4">
                {/* empty_vault_message */}
                <div className="p-1">

                    <i className="fa fa-fw fa-lock" /> You must be an administrator to manage team members in this organization.

                </div>
            </div>
        )

    }

}

export default MyTeamListReadOnly

import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import NavBreadcrumbLink from '../NavBreadcrumbLink/NavBreadcrumbLink'

class NavBreadcrumbCanaryTestDetail extends Component {

    constructor (props) {

        super(props)
        this.renderCanaryTestLink = this.renderCanaryTestLink.bind(this)

    }

    renderCanaryTestLink () {

        if (this.props.canary_test_title !== null) {

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={this.props.canary_test_title}
                    />
                </>

            )

        }

        if (this.props.currently_fetching_canary_test) {

            const spinner = (

                <i className="fa fa-refresh fa-spin fa-fw text-secondary" />

            )

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text={spinner}
                    />
                </>

            )

        }

        if (this.props.canary_test_fetch_error) {

            return (

                <>
                    <NavBreadcrumbLink
                        breadcrumb_link_text="Error"
                    />
                </>

            )

        }

        return ""

    }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        const canary_tests_url = `/o/${organization_id}/canary_tests`

        return (

            <>
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Tests"
                    breadcrumb_link_url={canary_tests_url}
                />
                <span className="navbar-text">/</span>
                {this.renderCanaryTestLink()}
            </>

        )

    }

}


// export default NavBreadcrumbCanaryTestDetail


// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    currently_fetching_canary_test: state.canary_test.currently_fetching_canary_test,
    canary_test_fetch_error: state.canary_test.canary_test_fetch_error,
    canary_test_title: state.canary_test.canary_test_title
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBreadcrumbCanaryTestDetail)

import React from 'react'
// import {
    // Link // , NavLink
// } from 'react-router-dom'

import * as moment from 'moment-timezone'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'
import ExecutionLog from 'components/shared/ExecutionLog/ExecutionLog'

import './LogLines.css'

class LogLines extends DashboardModule {

    constructor (props) {

        super(props)

        this.renderLogLine = this.renderLogLine.bind(this)

        this.organization_id = false
        this.show_disabled = false
        this.show_outer_border = false

        this.begin_execution_timestamp = null

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id
        this.canary_test_id = params.canary_test_id

    }

    renderTimeSinceString (valid_current_time_string) {

        const begin_time_milliseconds = moment(this.begin_execution_timestamp).valueOf()
        const current_time_milliseconds = moment(valid_current_time_string).valueOf()
        const diff_milliseconds = current_time_milliseconds - begin_time_milliseconds

        if (diff_milliseconds < 1) {

            return ""

        }

        return "" + (diff_milliseconds / 1000).toLocaleString('en-EN', { minimumFractionDigits: 0 }) + " Seconds"

    }

    renderLogLine (log_line_string, i) {

        const classes = `fa fa-fw fa-th-list log-line-icon sidebar-fa-th-list-ancor-icon`

        // console.log(log_line_string)

        return (
            <div key={i} className="log_line_item_container" >
                <i className={classes} />
                <dl>
                    <dt>
                        {log_line_string}
                    </dt>
                    <dd>yyy</dd>
                </dl>
            </div>
        )

    }

    renderLogLineMarkup (key, log_string, time_string, icon_class) {

        // console.log(log_string)

        // log_string = "xxx"

        const classes = `fa fa-fw ${icon_class} log-line-icon sidebar-${icon_class}-ancor-icon`

        return (
            <div key={key} className="log_line_item_container" >
                <i className={classes} />
                <dl>
                    <dt>
                        {log_string}
                    </dt>
                    <dd>{time_string}</dd>
                </dl>
            </div>
        )

    }

    render () {

        if (this.isCurrentlyLoading()) {

            return this.renderRawCard(
                <div style={{ height: '500px' }} />
            )

        } else if (!this.wasFetchingSuccessful(this.props.canary_test_execution_result_fetch_successful)) {

            return this.renderRawCard(
                <div className="p-5">
                    We&apos;re having a problem pulling your data.
                    Please <a href="/">refresh</a> and try again.
                    <div className="mt-5">
                        {this.props.loading_error}
                    </div>
                </div>
            )

        }

        // console.dir(this.props.canary_test_execution_result_data)

        this.begin_execution_timestamp = this.props.canary_test_execution_result_data["canary_test_execution_result_date_start_execution"]

        // const start_string = this.renderTimeSinceString(this.props.canary_test_execution_result_data["canary_test_execution_result_date_start_execution"])
        // const end_string = this.renderTimeSinceString(this.props.canary_test_execution_result_data["canary_test_execution_result_date_end_execution"])

        // console.log(this.props.canary_test_execution_result_data)

        // const actual_log_lines = this.props.canary_test_execution_result_data["canary_test_execution_result_log_lines"].map((log_line, index) => {

            // console.log(log_line)
            // console.log("this.begin_execution_timestamp", this.begin_execution_timestamp)

            // return this.renderLogLineMarkup(`logline${index}`, log_line["log_line"], this.renderTimeSinceString(log_line["timestamp"]), "fa-th-list")

        // })

        /*
        return this.renderRawCard(
            <div className="executionResultDetailLogLinesContainer">
                <div className="vertLineContainer">
                    {this.renderLogLineMarkup("start", "STARING", start_string, "fa-arrow-down")}
                    {actual_log_lines}
                    {this.renderLogLineMarkup("end", "ENDING", end_string, "fa-check-square")}
                </div>
            </div>
        )
        */
        // console.error(this.props.canary_test_execution_result_data)
        return this.renderRawCard(
            <div className="executionResultDetailLogLinesContainer">
                <ExecutionLog
                    start_execution_timestamp={this.props.canary_test_execution_result_data.canary_test_execution_result_date_start_execution}
                    log_lines={this.props.canary_test_execution_result_data["canary_test_execution_result_log_lines"]}
                    end_execution_timestamp={this.props.canary_test_execution_result_data.canary_test_execution_result_date_end_execution}
                    execution_successs_flag={this.props.canary_test_execution_result_data.canary_test_execution_result_success_flag}
                    failure_message={this.props.canary_test_execution_result_data.canary_test_execution_result_failure_message}
                    success_message={this.props.canary_test_execution_result_data.canary_test_execution_result_success_message}

                    /*
                        We arent using these right now, but maybe we will...
                        error_text={this.props.canary_test_manually_run_return_data.error_text}
                        status_flag={this.props.canary_test_manually_run_return_data.status_flag}
                        success_flag={this.props.canary_test_manually_run_return_data.success_flag}
                        success_value={this.props.canary_test_manually_run_return_data.success_value}
                    */
                />
            </div>
        )


    }

}

export default LogLines

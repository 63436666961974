import React, { Component } from 'react'
// import {
    // Link // , NavLink
// } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    allExecutionNodesReset,
    allExecutionNodesFetch
} from 'modules/execution_nodes_module'

import {
    allDatacentersReset,
    allDatacentersFetch
} from 'modules/datacenters_module'

import {
    executionNodeCreateReset,
    executionNodeCreatePost
} from 'modules/execution_node_create_module'

import {
    executionNodeRebuildReset,
    executionNodeRebuildPost
} from 'modules/execution_node_rebuild_module'


import ExecutionNodesMap from './ExecutionNodesMap/ExecutionNodesMap'
import ExecutionNodesListBody from './ExecutionNodesListBody/ExecutionNodesListBody'


class ExecutionNodesList extends Component {

    constructor (props) {

        super(props)

        const { match: { params } } = this.props
        this.organization_id = params.organization_id

        this.fetchAll = this.fetchAll.bind(this)
        this.renderAlertBox = this.renderAlertBox.bind(this)

        this.handleExecutionNodeCreate = this.handleExecutionNodeCreate.bind(this)
        this.handleExecutionNodeRebuild = this.handleExecutionNodeRebuild.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)

    }

    componentDidMount () {

        // Reset all data in redux state
        this.props.executionNodeCreateReset()
        this.props.executionNodeRebuildReset()
        this.props.allExecutionNodesReset()
        this.props.allDatacentersReset()

        this.fetchAll()

    }

    fetchAll () {

        this.props.allExecutionNodesFetch(this.organization_id)
        this.props.allDatacentersFetch(this.organization_id)

    }

    handleExecutionNodeCreate (datacenter) {

        // console.log("handleExecutionNodeCreate hit")
        // this.props.onExecutionNodeCreate(this.organization_id, e.target.getAttribute('data-datacenter'), "JAVASCRIPT")
        this.props.executionNodeCreatePost(this.organization_id, datacenter, "JAVASCRIPT", () => { this.props.allExecutionNodesFetch(this.organization_id) })

    }

    handleExecutionNodeRebuild (execution_node_id) {

        // console.log("handleExecutionNodeRebuild hit", execution_node_id)
        this.props.executionNodeRebuildPost(this.organization_id, execution_node_id, () => { this.props.allExecutionNodesFetch(this.organization_id) })

    }

    handleRefresh () {

        this.fetchAll()

    }

    renderAlertBox () {

        const searchParams = new URLSearchParams(this.props.location.search)

        if (searchParams.has("sm")) {

            if (searchParams.get("sm") === "deleted") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your Datacenter has been deleted</strong>
                    </div>
                )

            } else if (searchParams.get("sm") === "created") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your Datacenter has been created</strong>
                    </div>
                )

            } else if (searchParams.get("sm") === "rebuilding") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your Datacenter rebuild was started</strong>
                    </div>
                )

            }

        } else if (searchParams.has("em")) {

            if (searchParams.get("em") === "a") {

                return (
                    <div className="alert alert-danger mb-5" role="alert">
                        <i className="fa fa-fw fa-times text-danger mr-2" />
                        <strong>We had trouble</strong>
                    </div>
                )

            }

        }

        return (

            <div />

        )

    }

    // Return the completely assembled component
    render () {

        // const { match: { params } } = this.props
        // const organization_id = params.organization_id

        return (

            <div>

                <div className="container-fluid dashboard-body-header-container">
                    { this.renderAlertBox() }
                    <div className="row">
                        <div className="col-6 col-lg-8">
                            <h1>Data Centers</h1>
                        </div>
                        <div className="col-6 col-lg-4 text-right" />
                    </div>
                </div>

                <div className="container-fluid">

                    {/*
                    */}
                    <div className="row dashboard-body-row">

                        <div className="col-12">

                            <div className="card">
                                <ExecutionNodesMap
                                    is_currently_loading={this.props.currently_fetching_all_execution_nodes || this.props.currently_fetching_datacenters}
                                    loading_error={this.props.all_execution_nodes_fetch_error || this.props.datacenters_fetch_error}
                                    all_execution_nodes_data={this.props.all_execution_nodes_data}
                                    datacenters_data={this.props.datacenters_data}
                                    {...this.props}
                                />
                            </div>

                        </div>

                    </div>

                    <div className="row dashboard-body-row">

                        <div className="col-12 pt-4 mb-5">

                            <ExecutionNodesListBody
                                is_currently_loading={this.props.currently_fetching_all_execution_nodes || this.props.currently_fetching_datacenters}
                                loadingError={this.props.all_execution_nodes_fetch_error || this.props.datacenters_fetch_error}
                                loadedData={this.props.all_execution_nodes_data && this.props.all_execution_nodes_data}

                                all_execution_nodes_data={this.props.all_execution_nodes_data}
                                datacenters_data={this.props.datacenters_data}

                                onExecutionNodeCreate={this.handleExecutionNodeCreate}
                                onExecutionNodeRebuild={this.handleExecutionNodeRebuild}
                                onRefresh={this.handleRefresh}

                                user_timezone={this.props.user_timezone}

                                currently_sending_execution_node_create_post={this.props.currently_sending_execution_node_create_post}
                                execution_node_create_error={this.props.execution_node_create_error}
                                execution_node_create_return_data={this.props.execution_node_create_return_data}
                                execution_node_successfully_posted={this.props.execution_node_successfully_posted}
                                execution_node_datacenter_being_created={this.props.execution_node_datacenter_being_created}

                                {...this.props}
                            />

                        </div>

                    </div>

                </div>

            </div>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({

    currently_fetching_all_execution_nodes: state.all_execution_nodes.currently_fetching_all_execution_nodes,
    all_execution_nodes_fetch_error: state.all_execution_nodes.all_execution_nodes_fetch_error,
    all_execution_nodes_data: state.all_execution_nodes.all_execution_nodes_data,

    currently_fetching_datacenters: state.datacenters.currently_fetching_datacenters,
    datacenters_fetch_error: state.datacenters.datacenters_fetch_error,
    datacenters_data: state.datacenters.datacenters_data,

    currently_sending_execution_node_create_post: state.execution_node_create.currently_sending_execution_node_create_post,
    execution_node_create_error: state.execution_node_create.execution_node_create_error,
    execution_node_create_return_data: state.execution_node_create.execution_node_create_return_data,
    execution_node_successfully_posted: state.execution_node_create.execution_node_successfully_posted,
    execution_node_datacenter_being_created: state.execution_node_create.execution_node_datacenter_being_created,

    currently_sending_execution_node_rebuild_post: state.execution_node_rebuild.currently_sending_execution_node_rebuild_post,
    execution_node_rebuild_error: state.execution_node_rebuild.execution_node_rebuild_error,
    execution_node_rebuild_return_data: state.execution_node_rebuild.execution_node_rebuild_return_data,
    execution_node_id_being_rebuilt: state.execution_node_rebuild.execution_node_id_being_rebuilt,
    execution_node_rebuild_successful: state.execution_node_rebuild.execution_node_rebuild_successful,

    user_timezone: state.user_account.user_timezone

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    allExecutionNodesReset,
    allExecutionNodesFetch,
    allDatacentersReset,
    allDatacentersFetch,
    executionNodeCreateReset,
    executionNodeCreatePost,
    executionNodeRebuildReset,
    executionNodeRebuildPost
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionNodesList)

// import $ from 'jquery'
import React, { Component } from 'react'

import {
    Link
} from 'react-router-dom'

import { PRETTY_TEXT_STRINGS } from 'lib/constants'

import './MyTeamListItemView.scss'


class MyTeamListItemView extends Component {

    // constructor (props) {

        // super(props)

    // }

    // Return the completely assembled component
    render () {

        const edit_link_url = `/o/${window.ac_session.current_organization.organization_id}/settings/my_team/${this.props.user_account_id}`

        let edit_button_markup = (
            <Link to={edit_link_url} type="button" className="btn btn-light btn-sm mr-2" >Edit</Link>
        )

        let delete_button_markup = (
            <button type="button" data-user-account-id={this.props.user_account_id} onClick={this.props.handleOnTeamMemberDelete} className="btn btn-light btn-sm" >Delete</button>
        )

        if (
            window.ac_session.current_account.user_account_id === this.props.user_account_id ||
            this.props.organization_role_type === "OWNER"
        ) {

            edit_button_markup = (<></>)
            delete_button_markup = (<></>)

        }

        return (

            <div className="row my-team-list-item-view-row" key={this.props.user_account_id} data-user-account-id={this.props.user_account_id}>
                <div className="col-4 col-xl-3 my-team-list-item-view-key-value-col bg-primaryx">
                    <i className="fa fa-user-o" /> {this.props.user_account_username}
                </div>
                <div className="col-xl-3 d-none d-xl-block my-team-list-item-view-key-value-col bg-secondaryx">
                    {this.props.user_account_email_address}
                </div>
                <div className="col-4 col-xl-3 my-team-list-item-view-key-value-col bg-successx">
                    {PRETTY_TEXT_STRINGS[this.props.organization_role_type]}
                </div>
                <div className="col-4 col-xl-3 text-right my-team-list-item-view-action-col bg-dangerx">
                    {edit_button_markup}
                    {delete_button_markup}
                </div>
            </div>

        )

    }

}


export default MyTeamListItemView

import React, { Component } from 'react'

import HeaderDropdown from './HeaderDropdown/HeaderDropdown'

import './DashboardDataTableFancyHeader.scss'

let id_count = 0

class DashboardDataTableFancyHeader extends Component {

    constructor (props) {

        super(props)
        this.renderNavbarContent = this.renderNavbarContent.bind(this)
        this.renderDropdowns = this.renderDropdowns.bind(this)

        this.guid = `fancy-header-id-${id_count++}`
        // console.log(this.guid)

    }

    renderDropdowns () {

        return (
            <>
                {
                    this.props.header_data.right_dropdown_data.map((dropdown_data_item) => {

                        return (<HeaderDropdown dropdown_data={dropdown_data_item} key={dropdown_data_item["title"]} />)

                    })
                }
            </>
        )

    }

    renderNavbarContent () {

        return (
            <>
                <div className="navbar-nav mr-auto">
                    {this.props.header_data.left_markup}
                </div>
                <ul className="navbar-nav">
                    {this.renderDropdowns()}
                </ul>
            </>
        )

    }

    // Return the completely assembled component
    render () {

        // console.log("rendering", this.guid)

        // const full_button_classes = `btn mr-2 btn-${button_type} fancy-button-clickable`

        // const data_target_value = `#${this.guid}`

        return (

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                { this.renderNavbarContent() }
            </nav>

        )

    }

}


export default DashboardDataTableFancyHeader

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    Redirect
} from 'react-router-dom'

import {
    canaryTestCreatePost,
    canaryTestCreateReset
} from 'modules/canary_test_create_module'

import {
    allExecutionNodesFetch
} from 'modules/execution_nodes_module'

import {
    allDatacentersFetch
} from 'modules/datacenters_module'

import {
    alertRulesFetch
} from 'modules/alert_rules_module'

import {
    canaryTestManuallyRunReset,
    canaryTestManuallyRunPost
} from 'modules/canary_test_manually_run_module'


// import CanaryTestForm from '../CanaryTestForm/CanaryTestForm'
import CanaryTestForm2 from '../CanaryTestForm2/CanaryTestForm2'


class CanaryTestCreator extends Component {

    constructor (props) {

        super(props)
        this.handleCanaryTestCreatorFormSubmit = this.handleCanaryTestCreatorFormSubmit.bind(this)
        this.handleCanaryTestCreatorManualRunRequest = this.handleCanaryTestCreatorManualRunRequest.bind(this)
        this.runAllResets = this.runAllResets.bind(this)
        this.runAllFetches = this.runAllFetches.bind(this)

        this.fake_javascript_code = `
module.exports = function(event, context, callback){
    
    if (false) {
    
        callback("An error occured")
        
    }
    
    callback(null, "Test passed!")

}
        `.trim()

        this.state = {
            fireRedirectOnSuccess: false
        }

    }

    componentDidMount () {

        const { match: { params } } = this.props
        this.organization_id = params.organization_id

        this.runAllResets()
        this.runAllFetches()

    }

    runAllResets () {

        this.props.canaryTestCreateReset()
        this.props.canaryTestManuallyRunReset()

    }

    runAllFetches () {

        this.props.alertRulesFetch(this.organization_id)
        this.props.allExecutionNodesFetch(this.organization_id)
        this.props.allDatacentersFetch(this.organization_id)

    }

    handleCanaryTestCreatorManualRunRequest (execution_node_id, code_to_run) {

        // console.log("TOP:  handleCanaryTestCreatorManualRunRequest hit: ", execution_node_id, code_to_run)
        this.props.canaryTestManuallyRunPost(this.organization_id, null, execution_node_id, code_to_run)

    }

    handleCanaryTestCreatorFormSubmit (
        canary_test_title,
        canary_test_language,
        canary_test_code,
        canary_test_schedule_type,
        canary_test_execution_node_ids,
        canary_test_alert_rule_ids,
        canary_test_fire_alert_rules_on_timeout
    ) {

        this.setState({ fireRedirectOnSuccess: true })

        this.props.canaryTestCreatePost(
            this.organization_id,
            canary_test_title,
            canary_test_language,
            canary_test_code,
            canary_test_schedule_type,
            canary_test_execution_node_ids,
            canary_test_alert_rule_ids,
            canary_test_fire_alert_rules_on_timeout
        )

    }

    // Return the completely assembled component
    render () {

        if (this.props.canary_test_submit_success && this.state.fireRedirectOnSuccess) {

            const canary_test_list_uri = `/o/${this.organization_id}/canary_tests?sm=created`

            return (
                <Redirect to={canary_test_list_uri} />
            )

        }

        let is_currently_loading = false
        if (
            this.props.currently_fetching_all_execution_nodes ||
            this.props.currently_fetching_all_alert_rules ||
            this.props.all_execution_nodes_data === null ||
            this.props.currently_fetching_all_alert_rules === null
        ) {

            is_currently_loading = true

            return (<div />)

        }

        // console.log("this.props.all_execution_nodes_data", this.props.all_execution_nodes_data)

        const selected_execution_nodes = []

        // if (this.props.all_execution_nodes_data.length > 0) {

            // selected_execution_nodes.push(this.props.all_execution_nodes_data[0]["execution_node_id"])

        // }
        this.props.all_execution_nodes_data.map((execution_node) => {

            selected_execution_nodes.push(execution_node["execution_node_id"])

            return null

        })

        return (

            <CanaryTestForm2

                {...this.props}

                form_title="Test - New"
                edit_mode={false}

                /* Generic */
                is_currently_loading={is_currently_loading}
                loading_error={this.props.canary_test_fetch_error}
                token_loaded_data_for_verification="foo"

                /* fetching canary test */
                currently_fetching_canary_test={false}
                canary_test_fetch_error={this.props.canary_test_fetch_error}
                canary_test_code={this.fake_javascript_code}
                canary_test_id={this.props.canary_test_id}
                canary_test_language="JAVASCRIPT"
                canary_test_schedule_type="EVERY_HOUR"
                canary_test_title=""
                canary_test_execution_nodes={selected_execution_nodes}
                canary_test_fire_alert_rules_on_timeout={false}
                canary_test_alert_rules={[]}

                /* fetching execution nodes */
                currently_fetching_all_execution_nodes={this.props.currently_fetching_all_execution_nodes}
                all_execution_nodes_fetch_error={this.props.all_execution_nodes_fetch_error}
                all_execution_nodes_data={this.props.all_execution_nodes_data}

                /* datacenters */
                currently_fetching_datacenters={this.props.currently_fetching_datacenters}
                datacenters_fetch_error={this.props.datacenters_fetch_error}
                datacenters_data={this.props.datacenters_data}

                /* fetching alert rules */
                currently_fetching_all_alert_rules={this.props.currently_fetching_all_alert_rules}
                alert_rules_fetch_error={this.props.alert_rules_fetch_error}
                all_alert_rules={this.props.all_alert_rules}

                /* saving */
                currently_submitting_canary_test_form_post={this.props.currently_submitting_canary_test_form_post}
                canary_test_submit_error={this.props.canary_test_submit_error}
                canary_test_submit_success={this.props.canary_test_submit_success}
                canary_test_submit_return_data={this.props.canary_test_submit_return_data}

                /* manually executing */
                currently_sending_canary_test_manually_run_post={this.props.currently_sending_canary_test_manually_run_post}
                canary_test_manually_run_execution_node_id={this.props.canary_test_manually_run_execution_node_id}
                canary_test_manually_run_error={this.props.canary_test_manually_run_error}
                canary_test_manually_run_success={this.props.canary_test_manually_run_success}
                canary_test_manually_run_return_data={this.props.canary_test_manually_run_return_data}

                /* Actions */
                onCanaryTestFormSubmit={this.handleCanaryTestCreatorFormSubmit}
                onCanaryTestManualRunRequest={this.handleCanaryTestCreatorManualRunRequest}
                canaryTestManuallyRunReset={this.props.canaryTestManuallyRunReset}
            />

        )

    }

}

// Makes the following data available in the Components this.props object
const mapStateToProps = (state) => ({

    // fetching execution nodes
    currently_fetching_all_execution_nodes: state.all_execution_nodes.currently_fetching_all_execution_nodes,
    all_execution_nodes_fetch_error: state.all_execution_nodes.all_execution_nodes_fetch_error,
    all_execution_nodes_data: state.all_execution_nodes.all_execution_nodes_data,

    // datacenters
    currently_fetching_datacenters: state.datacenters.currently_fetching_datacenters,
    datacenters_fetch_error: state.datacenters.datacenters_fetch_error,
    datacenters_data: state.datacenters.datacenters_data,

    // fetching alert rules
    currently_fetching_all_alert_rules: state.alert_rules.currently_fetching_alert_rules,
    alert_rules_fetch_error: state.alert_rules.alert_rules_fetch_error,
    all_alert_rules: state.alert_rules.alert_rules_fetch_return_data,

    // saving
    currently_submitting_canary_test_form_post: state.canary_test_create.currently_sending_canary_test_create_post,
    canary_test_submit_error: state.canary_test_create.canary_test_create_error,
    canary_test_submit_success: state.canary_test_create.canary_test_create_success,
    canary_test_submit_return_data: state.canary_test_create.canary_test_create_return_data,

    // manually executing
    currently_sending_canary_test_manually_run_post: state.canary_test_manually_run.currently_sending_canary_test_manually_run_post,
    canary_test_manually_run_execution_node_id: state.canary_test_manually_run.canary_test_manually_run_execution_node_id,
    canary_test_manually_run_error: state.canary_test_manually_run.canary_test_manually_run_error,
    canary_test_manually_run_success: state.canary_test_manually_run.canary_test_manually_run_success,
    canary_test_manually_run_return_data: state.canary_test_manually_run.canary_test_manually_run_return_data,

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    canaryTestCreatePost,
    canaryTestCreateReset,
    allExecutionNodesFetch,
    alertRulesFetch,
    allDatacentersFetch,
    canaryTestManuallyRunReset,
    canaryTestManuallyRunPost
}, dispatch)


// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CanaryTestCreator)

import React, { Component } from 'react'

import { Switch } from 'react-router'

import CanaryTestsList from './CanaryTestsList/CanaryTestsList'
import CanaryTestEditor from './CanaryTestEditor/CanaryTestEditor'
import CanaryTestCreator from './CanaryTestCreator/CanaryTestCreator'
import CanaryTestDetail from './CanaryTestDetail/CanaryTestDetail'
import CanaryTestExecutionResultDetail from './CanaryTestExecutionResultDetail/CanaryTestExecutionResultDetail'

import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import './CanaryTests.scss'

class CanaryTests extends Component {

    // Return the completely assembled component
    render () {

        return (

            <div className="canary-tests-top-container mb-5">
                <Switch>
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/new" component={CanaryTestCreator} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/disabled" component={CanaryTestsList} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/d/:canary_test_id/r/:execution_result_id" component={CanaryTestExecutionResultDetail} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/d/:canary_test_id/edit" component={CanaryTestEditor} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests/d/:canary_test_id" component={CanaryTestDetail} />
                    <PrivateRoute exact path="/o/:organization_id/canary_tests" component={CanaryTestsList} />
                </Switch>
            </div>

        )

    }

}


export default CanaryTests

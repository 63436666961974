// import $ from 'jquery'
import React, { Component } from 'react'

// import {
// Link
// } from 'react-router-dom'

import EmailTypeOption from './EmailTypeOption/EmailTypeOption'
import SMSTypeOption from './SMSTypeOption/SMSTypeOption'
import WebhookTypeOption from './WebhookTypeOption/WebhookTypeOption'

import './AlertRuleTypeField.css'


class AlertRuleTypeField extends Component {

    constructor (props) {

        super(props)
        this.handleAlertRuleTypeChange = this.handleAlertRuleTypeChange.bind(this)
        this.handleAlertRuleDetailChange = this.handleAlertRuleDetailChange.bind(this)

        this.state = {
            selected_alert_rule_type: this.props.selectedAlertRuleType,
            alert_rule_details: this.props.alert_rule_details,
            field_error_flag: this.props.fieldErrorFlag || false,
            field_error_message: this.props.fieldErrorMessage || ''
        }

        // console.log("Constructor - AlertRuleTypeField", this.props.selectedAlertRuleType)

    }

    // componentWillMount () {

        // console.log("componentWillMount - AlertRuleTypeField", this.props.selectedAlertRuleType)

    // }

    componentWillReceiveProps (nextProps) {

        // console.log("AlertRuleTypeField nextProps", nextProps)

        if (nextProps.selectedAlertRuleType !== null) {

            this.setState({ selected_alert_rule_type: nextProps.selectedAlertRuleType })

        }

    }

    handleAlertRuleDetailChange (error_flag, new_alert_rule_details) {

        // console.log("HIT!!! handleAlertRuleDetailChange fired:", new_alert_rule_details)

        this.setState({
            alert_rule_details: new_alert_rule_details,
            field_error_flag: error_flag
            // field_error_message: ''
        })

        this.props.onAlertRuleDetailChange(error_flag, new_alert_rule_details)

    }

    handleAlertRuleTypeChange (new_alert_type) {

        // console.log("handleAlertRuleTypeChange fired:", new_alert_type)

        if (this.state.selected_alert_rule_type === new_alert_type) {

            // console.log("SKIPPING")
            return

        }

        this.setState({
            selected_alert_rule_type: new_alert_type,
            alert_rule_details: null
        })

        this.props.onAlertRuleTypeChange(false, new_alert_type)

    }

    // Return the completely assembled component
    render () {

        if (typeof this.state.selected_alert_rule_type === "undefined") {

            return (
                <span>Loading</span>
            )

        }

        // TODO: Make this more streamlined.

        let email_type_markup = (<div />)
        if (
            !this.props.edit_mode ||
            (this.props.edit_mode && this.state.selected_alert_rule_type === "EMAIL")
        ) {

            email_type_markup = (
                <EmailTypeOption
                    selected_alert_rule_type={this.state.selected_alert_rule_type}
                    alert_rule_details={this.state.alert_rule_details}
                    onAlertRuleTypeChange={this.handleAlertRuleTypeChange}
                    onAlertRuleDetailsChange={this.handleAlertRuleDetailChange}
                    edit_mode={this.props.edit_mode}
                />
            )

        }

        let sms_type_markup = (<div />)
        if (
            !this.props.edit_mode ||
            (this.props.edit_mode && this.state.selected_alert_rule_type === "SMS")
        ) {

            sms_type_markup = (
                <SMSTypeOption
                    selected_alert_rule_type={this.state.selected_alert_rule_type}
                    alert_rule_details={this.state.alert_rule_details}
                    onAlertRuleTypeChange={this.handleAlertRuleTypeChange}
                    onAlertRuleDetailsChange={this.handleAlertRuleDetailChange}
                    edit_mode={this.props.edit_mode}
                />
            )

        }

        /*
        */
        let webhook_type_markup = (<div />)
        if (
            !this.props.edit_mode ||
            (this.props.edit_mode && this.state.selected_alert_rule_type === "WEBHOOK")
        ) {


            webhook_type_markup = (
                <WebhookTypeOption
                    selected_alert_rule_type={this.state.selected_alert_rule_type}
                    alert_rule_details={this.state.alert_rule_details}
                    onAlertRuleTypeChange={this.handleAlertRuleTypeChange}
                    onAlertRuleDetailsChange={this.handleAlertRuleDetailChange}
                    edit_mode={this.props.edit_mode}
                />
            )

        }

        // console.log("selected_alert_rule_type", this.state.selected_alert_rule_type)
        // console.log("alert_rule_details", this.state.alert_rule_details)
        // console.log("onAlertRuleTypeChange", this.handleAlertRuleTypeChange)
        // console.log("onAlertRuleDetailsChange", this.handleAlertRuleDetailChange)
        // console.log("edit_mode", this.props.edit_mode)

        return (

            <div className="row form-group mb-4">

                <div className="col editor-label-column">

                    <label className="col-form-label">Details</label>

                </div>
                <div className="col">

                    <div className="alert-rule-type-checkbox-outer-container">

                        {email_type_markup}
                        {sms_type_markup}
                        {webhook_type_markup}

                    </div>

                </div>

            </div>

        )

    }

}


export default AlertRuleTypeField

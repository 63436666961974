// This file is included by store.js

// Combines all the reducers together to be pushed into the Redux store.

import { combineReducers } from 'redux'
// import { routerReducer } from 'react-router-redux'
import login from './login_module'
import forgot_password from './forgot_password_module'
import forgot_password_reset from './forgot_password_reset_module'
import signup from './signup_module'
import user_account from './user_account_module'
import user_account_email_address_update from './user_account_email_address_update_module'
import user_account_password_update from './user_account_password_update_module'
import canary_tests from './canary_tests_module'
import canary_tests_reports from './canary_tests_reports_module'
import canary_test from './canary_test_module'
import canary_test_create from './canary_test_create_module'
import canary_test_update from './canary_test_update_module'
import canary_test_delete from './canary_test_delete_module'
import canary_test_manually_run from './canary_test_manually_run_module'
import canary_test_execution_results from './canary_test_execution_results_module'
import canary_test_execution_results_delete from './canary_test_execution_results_delete_module'
import canary_test_execution_result from './canary_test_execution_result_module'
import canary_test_execution_result_delete from './canary_test_execution_result_delete_module'
import canary_test_reports from './canary_test_reports_module'
import all_execution_nodes from './execution_nodes_module'
import execution_node_create from './execution_node_create_module'
import execution_node_rebuild from './execution_node_rebuild_module'
import datacenters from './datacenters_module'
import alert_rules from './alert_rules_module'
import alert_rule from './alert_rule_module'
import alert_rule_create from './alert_rule_create_module'
import alert_rule_update from './alert_rule_update_module'
import alert_rule_delete from './alert_rule_delete_module'
import variable_vault_keys from './variable_vault_keys_module'
import variable_vault_key_create from './variable_vault_key_create_module'
import variable_vault_key_update from './variable_vault_key_update_module'
import variable_vault_key_delete from './variable_vault_key_delete_module'
import team_members from './team_members_module'
import team_member_add_email from './team_member_add_email_module'
import team_member_create_account from './team_member_create_account_module'
import team_member_update from './team_member_update_module'
import team_member_delete from './team_member_delete_module'
// Include other reducers from other modules here.


export default combineReducers({
    // router: routerReducer,
    login,
    forgot_password,
    forgot_password_reset,
    signup,
    user_account,
    user_account_email_address_update,
    user_account_password_update,
    canary_tests,
    canary_tests_reports,
    canary_test,
    canary_test_create,
    canary_test_update,
    canary_test_delete,
    canary_test_manually_run,
    canary_test_execution_results,
    canary_test_execution_results_delete,
    canary_test_execution_result,
    canary_test_execution_result_delete,
    canary_test_reports,
    all_execution_nodes,
    execution_node_create,
    execution_node_rebuild,
    datacenters,
    alert_rules,
    alert_rule,
    alert_rule_create,
    alert_rule_update,
    alert_rule_delete,
    variable_vault_keys,
    variable_vault_key_create,
    variable_vault_key_update,
    variable_vault_key_delete,
    team_members,
    team_member_add_email,
    team_member_create_account,
    team_member_update,
    team_member_delete
    // Add other reducers included above here.
})

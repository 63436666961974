import React, { Component } from 'react'
import {
    Link
} from 'react-router-dom'

import isEmpty from 'validator/lib/isEmpty'

import AlertBox from 'components/shared/AlertBox/AlertBox'

import AlertRuleTitleField from './AlertRuleTitleField/AlertRuleTitleField'
import AlertRuleTypeField from './AlertRuleTypeField/AlertRuleTypeField'
import AlertRuleFrequencyField from './AlertRuleFrequencyField/AlertRuleFrequencyField'

import './AlertRuleFormBody.css'

class AlertRuleFormBody extends Component {

    constructor (props) {

        super(props)

        // console.log("AlertRuleFormBody constructor", this.props)

        this.state = {
            alert_rule_title: this.props.alert_rule_title,
            alert_rule_title_error: false,

            alert_rule_type: this.props.alert_rule_type,
            alert_rule_type_error: false,

            alert_rule_details: this.props.alert_rule_details,
            alert_rule_details_error: false,

            alert_rule_frequency: this.props.alert_rule_frequency,
            alert_rule_frequency_error: false,

            show_form_errors : false
        }

        this.handleAlertRuleTitleChange = this.handleAlertRuleTitleChange.bind(this)
        this.handleAlertRuleTitleChangeError = this.handleAlertRuleTitleChangeError.bind(this)
        this.handleAlertRuleTypeChange = this.handleAlertRuleTypeChange.bind(this)
        this.handleAlertRuleDetailChange = this.handleAlertRuleDetailChange.bind(this)
        this.handleAlertRuleFrequencyChange = this.handleAlertRuleFrequencyChange.bind(this)

        this.handleAlertRuleFormSubmit = this.handleAlertRuleFormSubmit.bind(this)

        this.areThereFieldErrors = this.areThereFieldErrors.bind(this)
        this.renderAlertBox = this.renderAlertBox.bind(this)
        this.renderSubmitButton = this.renderSubmitButton.bind(this)
        this.renderDeleteButton = this.renderDeleteButton.bind(this)

    }

    componentWillMount () {

        // console.log("AlertRuleFormBody props", this.props)

    }

    componentWillReceiveProps (nextProps) {

        // console.log("AlertRuleFormBody nextProps", nextProps)
        // why is this here?
        this.setState({ alert_rule_title: nextProps.alert_rule_title })

    }

    areThereFieldErrors () {

        if (
            this.state.alert_rule_title_error ||
            this.state.alert_rule_type_error ||
            this.state.alert_rule_details_error ||
            this.state.alert_rule_frequency_error
        ) {

            return true

        }

        if (
            isEmpty(this.state.alert_rule_title) ||
            isEmpty(this.state.alert_rule_type) ||
            this.state.alert_rule_details === null ||
            isEmpty(this.state.alert_rule_frequency)
        ) {

            return true

        }

        // stupid doublecheck.  need to make this better.

        if (
            this.state.alert_rule_type === "SMS" &&
            "email_address" in this.state.alert_rule_details
        ) {

            return true

        }

        if (
            this.state.alert_rule_type === "EMAIL" &&
            "phone_number" in this.state.alert_rule_details
        ) {

            return true

        }

        return false

    }

    handleAlertRuleTitleChangeError (error_flag) {

        this.setState({
            alert_rule_title_error: error_flag
        })

    }

    handleAlertRuleTitleChange (error_flag, new_value) {

        // console.log("handleAlertRuleTitleChange hit")

        this.setState({
            alert_rule_title: new_value,
            alert_rule_title_error: error_flag
        })

    }

    handleAlertRuleTypeChange (error_flag, new_value) {

        // console.log("handleAlertRuleTypeChange hit")

        this.setState({
            alert_rule_type: new_value,
            alert_rule_type_error: error_flag,

            // killing old detail values when switching types.
            alert_rule_details: null,
            alert_rule_details_error: false
        })

    }

    handleAlertRuleDetailChange (error_flag, new_value) {

        // console.log("handleAlertRuleDetailChange hit")

        this.setState({
            alert_rule_details: new_value,
            alert_rule_details_error: error_flag
        })

    }

    handleAlertRuleFrequencyChange (error_flag, new_value) {

        console.log("handleAlertRuleFrequencyChange hit")

        this.setState({
            alert_rule_frequency: new_value,
            alert_rule_frequency_error: error_flag
        })

    }

    handleAlertRuleFormSubmit (event) {

        event.preventDefault()

        if (!this.props.onAlertRuleFormSubmit) {

            return

        }

        this.props.onAlertRuleFormSubmit(
            this.state.alert_rule_title,
            this.state.alert_rule_type,
            this.state.alert_rule_details,
            this.state.alert_rule_frequency
        )

    }

    renderAlertBox () {

        if (!this.props.alert_rule_submit_error) {

            return (<div />)

        }

        return (

            <AlertBox error_level="danger" error_title="Error" error_description={this.props.alert_rule_submit_error} />

        )

    }

    renderDeleteButton () {

        if (!this.props.edit_mode) {

            return (<div />)

        }

        return (
            <button type="button" className="btn btn-link mt-1" onClick={this.props.onAlertRuleDeleteSubmit}>Delete Alert Rule</button>
        )

    }

    renderSubmitButton () {

        let spinner_ui = ""
        let disabled_button = false

        if (this.props.currently_submitting_alert_rule_form_post) {

            spinner_ui = (

                <i className="fa fa-refresh fa-spin fa-fw text-secondary" />

            )

            disabled_button = true

        }

        let button_class = "btn btn-success"

        if (this.areThereFieldErrors()) {

            disabled_button = true
            button_class = "btn"

        }

        return (
            <button type="submit" className={button_class} disabled={disabled_button}>{spinner_ui} Save My Changes</button>
        )

    }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        // const alert_rule_id = params.alert_rule_id
        const cancel_anchor_url = `/o/${organization_id}/alert_rules`

        // console.dir("this.state.alert_rule_execution_node_ids", this.state.alert_rule_execution_node_ids)

        return (

            <form method="POST" className="p-4" onSubmit={this.handleAlertRuleFormSubmit}>

                {this.renderAlertBox()}

                <AlertRuleTitleField
                    container_classes="mb-1 mb-md-4"
                    fieldValue={this.state.alert_rule_title}
                    onFieldChange={this.handleAlertRuleTitleChange}
                    onFieldChangeError={this.handleAlertRuleTitleChangeError}
                    edit_mode={this.props.edit_mode}
                />

                <AlertRuleTypeField
                    selectedAlertRuleType={this.state.alert_rule_type}
                    alert_rule_details={this.state.alert_rule_details}
                    onAlertRuleTypeChange={this.handleAlertRuleTypeChange}
                    onAlertRuleDetailChange={this.handleAlertRuleDetailChange}
                    edit_mode={this.props.edit_mode}
                />

                <AlertRuleFrequencyField
                    fieldValue={this.state.alert_rule_frequency}
                    onFieldChange={this.handleAlertRuleFrequencyChange}
                    edit_mode={this.props.edit_mode}
                />

                <div className="row form-group mt-5">

                    <div className="col editor-label-column" />
                    <div className="col">
                        {this.renderSubmitButton()}
                    </div>
                    <div className="col">
                        <Link className="btn btn-lg btn-default ml-1" to={cancel_anchor_url}>Cancel</Link>
                    </div>
                    <div className="col-md-5 text-md-right">
                        {this.renderDeleteButton()}
                    </div>

                </div>

                {/*
                <pre>{JSON.stringify(this.state, null, 2)}</pre>
                */}

            </form>

        )

    }

}

export default AlertRuleFormBody

// This file is included by

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import { Route } from 'react-router-dom'


class NavBreadcrumbLink extends Component {

    constructor (props) {

        super(props)
        this.renderBreadcrumbLink = this.renderBreadcrumbLink.bind(this)
        this.renderBreadcrumbText = this.renderBreadcrumbText.bind(this)

    }

    renderBreadcrumbLink () {

        return (

            <li className="nav-item">
                <Link className="nav-link nav-link-underline" to={this.props.breadcrumb_link_url}>
                    {this.props.breadcrumb_link_text}
                </Link>
            </li>

        )

    }

    renderBreadcrumbText () {

        return (

            <li className="nav-item">
                <span className="nav-link active">
                    {this.props.breadcrumb_link_text}
                </span>
            </li>

        )

    }

    // Return the completely assembled component
    render () {

        if (this.props.breadcrumb_link_url) {

            return this.renderBreadcrumbLink()

        }

        return this.renderBreadcrumbText()

    }

}


export default NavBreadcrumbLink


import React from 'react'

import FancyFormElement from 'components/shared/FancyFormElement/FancyFormElement'

import Select from 'react-select'
import makeAnimated from 'react-select/lib/animated'

import './FancyFormElementMultiSelectDropdown.scss'


class FancyFormElementMultiSelectDropdown extends FancyFormElement {

    constructor (props) {

        super(props)
        this.renderLoadingUI = this.renderLoadingUI.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)

    }

    renderFormElement () {

        // console.log("fancysingle defaultValue", this.props.value)
        // console.log("fancysingle options", this.props.dropdown_options)

        let default_values = []

        this.props.dropdown_options.map((dropdown_option) => {

            this.props.value.map((value) => {

                if (dropdown_option["value"] === value) {

                    default_values.push(dropdown_option)

                }

                return true

            })

            return true

        })


        // console.log("default_values", default_values)

        // console.group("render")
        // console.log("this.props.value", this.props.value)
        // console.log("this.props.name", this.props.name)
        // console.log("defaultValue", this.props.dropdown_options[default_value_index])
        // console.log("onChange", this.handleOnChange)
        // console.log("onBlur", this.props.onBlur)
        // console.log("id", this.props.id)
        // console.log("disabled", this.props.disabled)
        // console.log("name", this.props.name)
        // console.log("options", this.props.dropdown_options)
        // console.groupEnd()

        return (
            <Select
                defaultValue={default_values}
                onChange={this.handleOnChange}
                onBlur={this.props.onBlur}
                isMulti
                /* onChange={this.props.onChange} */
                id={this.props.id}
                disabled={this.props.disabled}
                name={this.props.name}
                options={this.props.dropdown_options}
                className="fancy-single-select-dropdown"
                classNamePrefix="fancy-single-select-dropdown"
                components={makeAnimated()}
            />
        )

    }

    handleOnChange (selectedValue) {

        this.props.onChange(selectedValue.map((item) => {

            return item["value"]

        }))

    }

}

export default FancyFormElementMultiSelectDropdown

import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'

// Initial Module State
const initialState = {
    currently_sending_execution_node_create_post: false,
    execution_node_datacenter_being_created: null,
    execution_node_create_error: null,
    execution_node_create_return_data: null,
    execution_node_successfully_posted: false
}

// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const EXECUTION_NODE_CREATE_RESET = 'execution_node_create/EXECUTION_NODE_CREATE_RESET'
export const EXECUTION_NODE_CREATE_POST_REQUESTED = 'execution_node_create/EXECUTION_NODE_CREATE_POST_REQUESTED'
export const EXECUTION_NODE_CREATE_POST_SUCCESS = 'execution_node_create/EXECUTION_NODE_CREATE_POST_SUCCESS'
export const EXECUTION_NODE_CREATE_POST_ERROR = 'execution_node_create/EXECUTION_NODE_CREATE_POST_ERROR'

// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const executionNodeCreateReset = () => {

    return (dispatch) => {

        dispatch({
            type: EXECUTION_NODE_CREATE_RESET
        })

    }

}

export const executionNodeCreatePost = (organization_id, execution_node_datacenter, execution_node_language, success) => {

    return (dispatch) => {

        dispatch({
            type: EXECUTION_NODE_CREATE_RESET
        })

        dispatch({
            type: EXECUTION_NODE_CREATE_POST_REQUESTED,
            execution_node_datacenter: execution_node_datacenter
        })

        const auth_token = auth.get("auth_token")

        const json_payload = {
            "action": "CREATE_EXECUTION_NODE",
            "action_payload": {
                "execution_node_datacenter": execution_node_datacenter,
                "execution_node_language": execution_node_language
            }
        }

        // console.log("json_payload[action_payload]...")
        // console.dir(json_payload["action_payload"])

        return axios({
            method: 'post',
            url: `//${config["api_hostname"]}/v1/o/${organization_id}/execution_nodes`,
            headers: {
                Authorization: `Token ${auth_token}`
            },
            data: json_payload
        })

        /*
        return new Promise((resolve) => {

            console.log("Faking axios call")

            setTimeout(() => {

                resolve({ "data": "fake" })

            }, 10000)

        })
        */

            .then((response) => {

                const execution_node_data = response.data

                // console.log("execution node create response", response)

                dispatch({
                    type: EXECUTION_NODE_CREATE_POST_SUCCESS,
                    payload: execution_node_data
                })

                if (success) {

                    success()

                }

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // console.log("hit1")
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }

                // console.log("error", error)
                // console.log("error_string", error_string)
                // console.log("error.config", error.config)

                dispatch({
                    type: EXECUTION_NODE_CREATE_POST_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case EXECUTION_NODE_CREATE_RESET:
            return {
                ...state,
                currently_sending_execution_node_create_post: false,
                execution_node_datacenter_being_created: null,
                execution_node_create_error: null,
                execution_node_create_return_data: null,
                execution_node_successfully_posted: false
            }

        case EXECUTION_NODE_CREATE_POST_REQUESTED:
            return {
                ...state,
                currently_sending_execution_node_create_post: true,
                execution_node_datacenter_being_created: action.execution_node_datacenter,
                execution_node_create_return_data: null
            }

        case EXECUTION_NODE_CREATE_POST_SUCCESS:
            return {
                ...state,
                currently_sending_execution_node_create_post: false,
                execution_node_datacenter_being_created: null,
                execution_node_create_error: null,
                execution_node_create_return_data: action.payload, // load the return data
                execution_node_successfully_posted: true
            }

        case EXECUTION_NODE_CREATE_POST_ERROR:
            return {
                ...state,
                currently_sending_execution_node_create_post: false,
                execution_node_datacenter_being_created: null,
                execution_node_create_error: action.payload, // Loading error text
                execution_node_create_return_data: null,
                execution_node_successfully_posted: false
            }

        default:
            return state

    }

}

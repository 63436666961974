import React from 'react'
import {
    // Link,
    Redirect
} from 'react-router-dom'


const Homepage = () => (
    <Redirect to="/login" />
)

export default Homepage

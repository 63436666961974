import React, { Component } from 'react'

import { Switch } from 'react-router'

import VariableVaultList from './VariableVaultList/VariableVaultList'
import VariableVaultReadOnly from './VariableVaultReadOnly/VariableVaultReadOnly'

import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import './VariableVault.scss'

class VariableVault extends Component {

    renderVault () {

        if (window.ac_session.current_organization.organization_role_type === "READ_ONLY") {

            return (<VariableVaultReadOnly />)

        }

        return (
            <Switch>
                <PrivateRoute exact path="/o/:organization_id/variable_vault/:vault_key" component={VariableVaultList} />
                <PrivateRoute exact path="/o/:organization_id/variable_vault" component={VariableVaultList} />
            </Switch>
        )

    }

    render () {

        // console.log("r this.props.login_return_data", this.props.login_return_data)
        // console.log("typeof this.props.login_return_data", typeof this.props.login_return_data)
        return (

            <div className="variable-vault-top-container">

                <div className="container-fluid dashboard-body-header-container">
                    <div className="row">
                        <div className="col-sm">
                            <h1>Variable Vault</h1>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row dashboard-body-row">

                        <div className="col-12 col-md-8 col-lg-9">

                            {this.renderVault()}

                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-3">

                            <div className="card border-0">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <i className="fa fa-lightbulb-o" aria-hidden="true" /> Variable Vault
                                    </h5>
                                    <p className="card-text">
                                        Store passwords, SSH keys and other
                                        secret information inside variable vault keys.  They will be
                                        encrypted and available to your tests by accessing
                                        the <strong>process.env</strong> variable during test execution.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        )

    }

}

export default VariableVault

import axios from 'axios'
import {
    auth
} from '../lib/authentication'
import config from 'config'


// Initial Module State
const initialState = {
    currently_sending_forgot_password_reset_post: false,
    forgot_password_reset_error: null,
    forgot_password_reset_return_data: null
    // make a success flag here and check for it to show the success message on the forgot password page.
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const FORGOT_PASSWORD_RESET_RESET = 'forgot_password_reset/FORGOT_PASSWORD_RESET_RESET'
export const FORGOT_PASSWORD_RESET_POST_REQUESTED = 'forgot_password_reset/FORGOT_PASSWORD_RESET_POST_REQUESTED'
export const FORGOT_PASSWORD_RESET_POST_SUCCESS = 'forgot_password_reset/FORGOT_PASSWORD_RESET_POST_SUCCESS'
export const FORGOT_PASSWORD_RESET_POST_ERROR = 'forgot_password_reset/FORGOT_PASSWORD_RESET_POST_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const forgotPasswordResetReset = () => {

    return (dispatch) => {

        dispatch({
            type: FORGOT_PASSWORD_RESET_RESET
        })

    }

}

export const forgotPasswordResetPost = (reset_token, email_address, new_password, new_password_confirm) => {

    // console.dir(["forgotPasswordResetPost called", reset_token, email_address, new_password, new_password_confirm])
    // return

    return (dispatch) => {

        auth.clear()

        dispatch({
            type: FORGOT_PASSWORD_RESET_POST_REQUESTED
        })

        if (new_password !== new_password_confirm) {

            return dispatch({
                type: FORGOT_PASSWORD_RESET_POST_ERROR,
                payload: "Your passwords did not match"
            })

        }

        const json_payload = {
            "action": "SET_NEW_PASSWORD",
            "action_payload": {
                "reset_token": reset_token,
                "email_address": email_address,
                "new_password": new_password
            }
        }

        return axios({
            method: 'post',
            url: `//${config["api_hostname"]}/v1/reset_password`,
            data: json_payload
        })

            .then((response) => {

                dispatch({
                    type: FORGOT_PASSWORD_RESET_POST_SUCCESS,
                    payload: {}
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: FORGOT_PASSWORD_RESET_POST_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case FORGOT_PASSWORD_RESET_RESET:
            return {
                ...state,
                currently_sending_forgot_password_reset_post: false,
                forgot_password_reset_error: null,
                forgot_password_reset_return_data: null
            }

        case FORGOT_PASSWORD_RESET_POST_REQUESTED:
            return {
                ...state,
                currently_sending_forgot_password_reset_post: true,
                forgot_password_reset_error: null
            }

        case FORGOT_PASSWORD_RESET_POST_SUCCESS:
            return {
                ...state,
                currently_sending_forgot_password_reset_post: false,
                forgot_password_reset_error: null,
                forgot_password_reset_return_data: action.payload
            }

        case FORGOT_PASSWORD_RESET_POST_ERROR:
            return {
                ...state,
                currently_sending_forgot_password_reset_post: false,
                forgot_password_reset_error: action.payload, // Loading error text
                forgot_password_reset_return_data: null
            }

        default:
            return state

    }

}

import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import {
    Link
} from 'react-router-dom'

import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'
import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
// import AlertBox from 'components/shared/AlertBox/AlertBox'

import './VariableVaultListItemCreator.scss'

class RawVariableVaultListItemCreator extends React.Component {

    componentDidUpdate (prevProps, prevState) {

        if (
            this.props.isSubmitting === true &&
            this.props.currently_sending_post === true
        ) {

            this.props.setSubmitting(false)

        }

    }

    render () {

        const {

            // dirty, // boolean
            errors, // { [field: string]: string }
            handleBlur, // (e: any) => void
            handleChange, // (e: React.ChangeEvent<any>) => void
            // handleReset, // () => void
            handleSubmit, // (e: React.FormEvent<HTMLFormEvent>) => void
            isSubmitting, // boolean
            // isValid, // boolean
            // isValidating, // boolean
            // resetForm, // (nextValues?: Values) => void
            // setErrors, // (fields: { [field: string]: string }) => void
            // setFieldError, // (field: string, errorMsg: string) => void
            // setFieldTouched, // (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
            // submitForm, // () => void
            // submitCount, // number
            // setFieldValue, // (field: string, value: any, shouldValidate?: boolean) => void
            // setStatus, // (status?: any) => void
            // setSubmitting, // (isSubmitting: boolean) => void
            // setTouched, // (fields: { [field: string]: boolean }) => void
            // setValues, // (fields: { [field: string]: any }) => void
            // status, // any
            touched, // { [field: string]: boolean }
            values, // { [field: string]: any }
            // validateForm, // (values?: any) => Promise<FormikErrors<Values>>
            // validateField, // (field: string) => void

        } = this.props

        const currently_submitting_form = isSubmitting || this.props.currently_sending_post

        let error_message_markup = ""
        if (this.props.variable_vault_key_post_error) {

            error_message_markup = (
                <div className="variable-vault-list-item-edit-error-message text-danger">
                    {this.props.variable_vault_key_post_error}
                </div>
            )

        }

        const form_has_errors = Boolean(Object.keys(errors).length) && Boolean(Object.keys(touched).length)

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        const cancel_url = `/o/${organization_id}/variable_vault`

        let cancel_button = ""

        // console.log("this.props.mode", this.props.mode)

        if (
            this.props.mode === "editor" ||
            this.props.mode === "add_new"
        ) {

            let cancel_button_classes = "btn btn-sm btn-secondary"

            cancel_button = (

                <Link to={cancel_url} disabled={currently_submitting_form} className={cancel_button_classes}>Cancel</Link>

            )

            if (currently_submitting_form) {

                cancel_button_classes = `${cancel_button_classes} pointer-disabled`
                cancel_button = (

                    <button type="button" disabled="disabled" className={cancel_button_classes}>Cancel</button>

                )

            }

        }

        return (

            <form onSubmit={handleSubmit} className="row variable-vault-list-item-edit-row" key="creator">
                {error_message_markup}
                <div className="col-3 variable-vault-list-item-edit-key-col">
                    <FancyFormElementText
                        id="variable_vault_creator_key_field_id"
                        type="text"
                        name="variable_vault_creator_key_field"
                        placeholder="KEY_NAME"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.variable_vault_creator_key_field}
                        disabled={currently_submitting_form}
                        error_message={errors.variable_vault_creator_key_field}
                        show_error_message={errors.variable_vault_creator_key_field && touched.variable_vault_creator_key_field && errors.variable_vault_creator_key_field}
                    />
                </div>
                <div className="col-5 col-lg-6 variable-vault-list-item-edit-key-value-col">
                    <FancyFormElementText
                        id="variable_vault_creator_key_value_field_id"
                        type="text"
                        name="variable_vault_creator_key_value_field"
                        placeholder="Secret Data"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.variable_vault_creator_key_value_field}
                        disabled={currently_submitting_form}
                        error_message={errors.variable_vault_creator_key_value_field}
                        show_error_message={errors.variable_vault_creator_key_value_field && touched.variable_vault_creator_key_value_field && errors.variable_vault_creator_key_value_field}
                    />
                </div>
                <div className="col-4 col-lg-3 variable-vault-list-item-edit-action-col">
                    <FancyLoadingButton
                        button_type="primary"
                        className="mr-1 btn-sm"
                        button_submit_type="submit"
                        currently_loading={currently_submitting_form}
                        disabled={form_has_errors}
                    >
                        Save
                    </FancyLoadingButton>
                    {cancel_button}
                </div>
            </form>

        )

    }

}

export default withFormik({
    validationSchema: Yup.object().shape({

        variable_vault_creator_key_field: Yup.string()
            .required('Required'),

        variable_vault_creator_key_value_field: Yup.string()
            .required('Required')

    }),
    mapPropsToValues: (props) => ({

        variable_vault_creator_key_field: props.vault_key || "",
        variable_vault_creator_key_value_field: props.vault_key_value || "",

        currently_sending_post: props.currently_sending_post || false,
        variable_vault_key_post_error: props.variable_vault_key_post_error || false,
        variable_vault_key_post_successfully_posted: props.variable_vault_key_post_successfully_posted || false,

        onVaultKeySubmit: props.onVaultKeySubmit || null,

    }),
    handleSubmit: (payload, { props }) => {

        // console.log("handle submit hit", props.vault_key)
        props.onVaultKeySubmit(payload["variable_vault_creator_key_field"], payload["variable_vault_creator_key_value_field"], props.vault_key)

    },
    displayName: 'CreateVaultKey',
    // enableReinitialize: true,
    isInitialValid: false
})(RawVariableVaultListItemCreator)

// import $ from 'jquery'
import React, { Component } from 'react'

import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'


class CanaryTestTitleField extends Component {

    // constructor (props) {

        // super(props)

    // }

    // Return the completely assembled component
    render () {

        return (
            <div className="form-row">
                <div className="form-group col-lg-9">
                    <label>Title</label>
                    <FancyFormElementText
                        id={this.props.id}
                        type="text"
                        name={this.props.name}
                        placeholder="API Endpoint Test 1"
                        onChange={this.props.onChange}
                        onBlur={this.props.onBlur}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        error_message={this.props.error_message}
                        show_error_message={this.props.show_error_message}
                    />

                    {/*
                    <pre>{JSON.stringify(this.props, null, 4)}</pre>
                    */}

                </div>
                <div className="form-group col-lg-3">
                    <label className="d-none d-lg-block">&nbsp;</label>
                    <div className="form-info-bubble">
                        <i className="fa fa-lightbulb-o" />
                        <p>
                            This is what your production test will be
                            identified as across the API Canary dashboard
                        </p>
                    </div>
                </div>
            </div>
        )

    }

}


export default CanaryTestTitleField


import $ from 'jquery'
import React, { Component } from 'react'
// import {
    // Link // , NavLink
// } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    canaryTestsReportsReset,
    canaryTestsReportsFetch
} from 'modules/canary_tests_reports_module'

import TopDatacenterMapModule from './TopDatacenterMapModule/TopDatacenterMapModule'
import DatacenterMapModule from './DatacenterMapModule/DatacenterMapModule'

import TotalCanaryTestsDashboardModule from 'components/shared/TotalCanaryTestsDashboardModule/TotalCanaryTestsDashboardModule'
import SuccessFailureDashboardModule from 'components/shared/SuccessFailureDashboardModule/SuccessFailureDashboardModule'
import AverageDurationDashboardModule from 'components/shared/AverageDurationDashboardModule/AverageDurationDashboardModule'

import LatestUpdatesModule from './LatestUpdatesModule/LatestUpdatesModule'
import RecentFailuresModule from './RecentFailuresModule/RecentFailuresModule'
import OverTimeGraphModule from './OverTimeGraphModule/OverTimeGraphModule'
import ActionsButton from './ActionsButton/ActionsButton'

import "./OverviewDashboard.scss"

class OverviewDashboard extends Component {

    constructor (props) {

        super(props)
        this.pullAllData = this.pullAllData.bind(this)
        this.current_time_duration = "MONTH"

    }

    componentDidMount () {

        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.pullAllData(this.current_time_duration)

    }

    pullAllData (time_duration) {

        this.current_time_duration = time_duration

        this.props.canaryTestsReportsFetch({
            organization_id: this.organization_id,
            report_id: "PULL_DATACENTERS_RESULTS_REPORT",
            time_range: time_duration
        })

        this.props.canaryTestsReportsFetch({
            organization_id: this.organization_id,
            report_id: "PULL_CANARY_TESTS_OVER_TIME_AVERAGE_REPORT",
            time_range: time_duration
        })

        this.props.canaryTestsReportsFetch({
            organization_id: this.organization_id,
            report_id: "PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT",
            time_range: time_duration
        })

        this.props.canaryTestsReportsFetch({
            organization_id: this.organization_id,
            report_id: "PULL_RECENT_CANARY_TEST_FAILURES_REPORT",
            time_range: time_duration
        })

    }


    renderOLD () {

        // console.log("r this.props.login_return_data", this.props.login_return_data)
        // console.log("typeof this.props.login_return_data", typeof this.props.login_return_data)

        // console.dir(this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"])

        return (

            <div className="overview-dashboard-top-container">

                <div className="container-fluid dashboard-body-header-container">
                    <div className="row">
                        <div className="col-sm">
                            <h1>Overview</h1>
                        </div>
                        <div className="col-sm text-right">
                            <ActionsButton
                                current_time_duration={this.current_time_duration}
                                handleTimeDurationClick={this.pullAllData}
                            />
                            {/*
                                <button type="button" className="btn btn-success"><i className="fa fa-plus" aria-hidden="true" /> New Dashboard</button>
                            */}
                        </div>
                    </div>
                </div>

                <div className="container-fluid">

                    {/* start row */}
                    <div className="row mb-4">
                        <div className="col-md-3">
                            {/* start col */}

                            <div className="card overview-dashboard-card mb-4" style={{ height: '100px' }}>
                                {/*
                                <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"], null, 2)}</pre>
                                Overall PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT
                                */}
                                <TotalCanaryTestsDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                            <div className="card overview-dashboard-card mb-4" style={{ height: '100px' }}>
                                <SuccessFailureDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                            <div className="card overview-dashboard-card mb-4 mb-md-0" style={{ height: '100px' }}>
                                <AverageDurationDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>
                        <div className="col-md-9">
                            {/* start col */}

                            <div className="card overview-dashboard-card" style={{ height: '347px' }}>
                                XXX
                                <DatacenterMapModule
                                    report_data={this.props.canary_tests_reports_data["PULL_DATACENTERS_RESULTS_REPORT"]}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>
                    </div>
                    {/* end row */}

                    {/* start row */}
                    <div className="row mb-4">
                        <div className="col-md-8 mb-4 mb-sm-0">
                            {/* start col */}

                            <div className="card overview-dashboard-card" style={{ height: '400px' }}>
                                <OverTimeGraphModule
                                    report_data={this.props.canary_tests_reports_data["PULL_CANARY_TESTS_OVER_TIME_AVERAGE_REPORT"]}
                                    {...this.props}
                                />

                                {/*
                                <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_tests_reports_data["PULL_CANARY_TESTS_OVER_TIME_AVERAGE_REPORT"], null, 2)}</pre>
                                */}

                            </div>

                            {/* end col */}
                        </div>
                        <div className="col-md-4">
                            {/* start col */}

                            <div className="card overview-dashboard-card">
                                <RecentFailuresModule
                                    report_data={this.props.canary_tests_reports_data["PULL_RECENT_CANARY_TEST_FAILURES_REPORT"]}
                                    {...this.props}
                                />
                                {/*
                                <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_tests_reports_data["PULL_RECENT_CANARY_TEST_FAILURES_REPORT"], null, 2)}</pre>
                                */}
                            </div>

                            {/* end col */}
                        </div>
                    </div>
                    {/* end row */}

                    {/* start row */}
                    {/*
                    <div className="row">
                        <div className="col-md-8">

                            <div className="card overview-dashboard-card mb-4 mb-md-0" style={{ height: '200px' }}>
                                ???
                            </div>

                        </div>
                        <div className="col-md-4">

                            <div className="card">
                                <img className="card-img-top" src="https://www.apicanary.com/hubfs/legooffice.png?t=1535132764008" alt="Card cap" />
                                <div className="card-body">
                                    <h4 className="card-title">API Canary News</h4>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the cards content.</p>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Cras justo odio</li>
                                    <li className="list-group-item">Dapibus ac facilisis in</li>
                                    <li className="list-group-item">Vestibulum at eros</li>
                                </ul>
                                <div className="card-body">
                                    whats this for?
                                </div>
                            </div>

                        </div>
                    </div>
                    */}
                    {/* end row */}


                </div>
            </div>

        )

    }

    // Return the completely assembled component
    renderNew () {

        return (

            <div className="overview-dashboard">

                <div className="container-fluid dashboard-body-header-container">
                    <div className="row">
                        <div className="col-sm">
                            <h1>Overview Dashboard</h1>
                        </div>
                        <div className="col-sm text-right">
                        </div>
                    </div>
                </div>

                <div className="container-fluid top-row">
                    <div className="row">
                        <div className="col-md-4">
                            {/* start col */}
                            <div className="card overview-dashboard-card" style={{ height: '100px' }}>
                                {/*
                                <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"], null, 2)}</pre>
                                Overall PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT
                                */}
                                <TotalCanaryTestsDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                            {/* end col */}
                        </div>
                        <div className="col-md-4">
                            {/* start col */}
                            <div className="card overview-dashboard-card" style={{ height: '100px' }}>
                                <SuccessFailureDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* start col */}
                            <div className="card overview-dashboard-card" style={{ height: '100px' }}>
                                <AverageDurationDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                            {/* end col */}
                        </div>
                    </div>
                </div>

                <div className="container-fluid bottom-row">
                    <div className="row">
                        <div className="col-md-7">
                            {/* start col */}
                            <OverTimeGraphModule
                                report_data={this.props.canary_tests_reports_data["PULL_CANARY_TESTS_OVER_TIME_AVERAGE_REPORT"]}
                                module_height="263px"
                                {...this.props}
                            />
                            {/* end col */}
                        </div>
                        <div className="col-md-5">
                            {/* start col */}
                            <div className="card overview-dashboard-card" style={{ height: '347px' }}>
                                <DatacenterMapModule
                                    report_data={this.props.canary_tests_reports_data["PULL_DATACENTERS_RESULTS_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                            {/* end col */}
                        </div>
                    </div>
                </div>

            </div>

        )

    }

    renderLeftColumn () {

        return (

            <>

                <OverTimeGraphModule
                    report_data={this.props.canary_tests_reports_data["PULL_CANARY_TESTS_OVER_TIME_AVERAGE_REPORT"]}
                    module_height="267px"
                    className="mb-4"
                    {...this.props}
                />

                <LatestUpdatesModule
                    module_height="267px"
                    className="mb-4"
                    {...this.props}
                />

            </>

        )

    }

    handleMapIconClick (e) {

        console.log("e", e)
        const datacenter = e.currentTarget.getAttribute('data-datacenter')
        $(".datacenter-map-container dl dd").addClass("d-none")
        $(`.datacenter-map-container dl.${datacenter} dd`).removeClass("d-none")

    }

    renderRightColumn () {

        return (
            <>
                <div className="card overview-dashboard-card">
                    <RecentFailuresModule
                        report_data={this.props.canary_tests_reports_data["PULL_RECENT_CANARY_TEST_FAILURES_REPORT"]}
                        {...this.props}
                    />
                    {/*
                    <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_tests_reports_data["PULL_RECENT_CANARY_TEST_FAILURES_REPORT"], null, 2)}</pre>
                    */}
                </div>

                {/*
                <div className="card overview-dashboard-card mb-4" style={{ height: '312px' }}>
                    <DatacenterMapModule
                        report_data={this.props.canary_tests_reports_data["PULL_DATACENTERS_RESULTS_REPORT"]}
                        {...this.props}
                    />
                </div>

                <div className="card fix-rounded-corners mb-4 datacenter-map-card" style={{ border: "0px" }}>
                    <div className="card-header text-white bg-dark">
                        Your Active Datacenters
                    </div>
                    <div className="datacenter-map-container">

                        <dl className="AWS-US-EAST-1">
                            <dt data-datacenter="AWS-US-EAST-1" onClick={this.handleMapIconClick} />
                            <dd className="d-none">AWS-US-EAST-1</dd>
                        </dl>
                        <dl className="AWS-US-WEST-1">
                            <dt data-datacenter="AWS-US-WEST-1" onClick={this.handleMapIconClick} />
                            <dd className="d-none">AWS-US-WEST-1</dd>
                        </dl>

                    </div>
                </div>

                <div className="card fix-rounded-corners mb-4" style={{ border: "0px" }}>
                    <div className="card-header text-white bg-dark">
                        Chart
                    </div>
                    <div className="card-body">
                        fancy chart
                    </div>
                </div>
                */}
            </>
        )

    }

    renderOLD2 () {

        return (

            <div className="overview-dashboard-top-container">

                <div className="container-fluid dashboard-body-header-container">
                    <div className="row">
                        <div className="col-sm">
                            <h1>Overview</h1>
                        </div>
                        <div className="col-sm text-right">
                            <ActionsButton
                                current_time_duration={this.current_time_duration}
                                handleTimeDurationClick={this.pullAllData}
                            />
                        </div>
                    </div>
                </div>

                <div className="container-fluid">

                    <div className="row mb-4">
                        {/* start row */}

                        <div className="col-md-4">
                            {/* start col */}

                            <div className="card overview-dashboard-card mb-4 mb-md-0" style={{ height: '100px' }}>
                                <TotalCanaryTestsDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>
                        <div className="col-md-4">
                            {/* start col */}

                            <div className="card overview-dashboard-card mb-4 mb-md-0" style={{ height: '100px' }}>
                                <SuccessFailureDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>
                        <div className="col-md-4">
                            {/* start col */}

                            <div className="card overview-dashboard-card " style={{ height: '100px' }}>
                                <AverageDurationDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>

                        {/* end row */}
                    </div>

                    <div className="row mb-4">
                        {/* start row */}


                        <div className="col-md-7">
                            {/* start col */}

                            { this.renderLeftColumn() }

                            {/* end col */}
                        </div>

                        <div className="col-md-5">
                            {/* start col */}

                            { this.renderRightColumn() }

                            {/* end col */}
                        </div>

                        {/* end row */}
                    </div>

                </div>
            </div>
        )

    }

    render () {

        // console.log("current_time_duration", this.current_time_duration)

        return (

            <div className="overview-dashboard-top-container">

                <div className="container-fluid dashboard-body-header-container">
                    <div className="row">
                        <div className="col-sm">
                            <h1>Overview</h1>
                        </div>
                        <div className="col-sm text-right">
                            <ActionsButton
                                handleTimeDurationClick={this.pullAllData}
                                current_time_duration={this.current_time_duration}
                            />
                        </div>
                    </div>
                </div>

                <div className="container-fluid">

                    <div className="row mb-4">
                        {/* start row */}

                        <div className="col-sm">

                            <TopDatacenterMapModule
                                report_data={this.props.canary_tests_reports_data["PULL_DATACENTERS_RESULTS_REPORT"]}
                                current_time_duration={this.current_time_duration}
                                {...this.props}
                            />

                        </div>

                        {/* end row */}
                    </div>
                    <div className="row mb-4">
                        {/* start row */}

                        <div className="col-md-4">
                            {/* start col */}

                            <div className="card overview-dashboard-card mb-4 mb-md-0" style={{ height: '100px' }}>
                                <TotalCanaryTestsDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    current_time_duration={this.current_time_duration}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>
                        <div className="col-md-4">
                            {/* start col */}

                            <div className="card overview-dashboard-card mb-4 mb-md-0" style={{ height: '100px' }}>
                                <SuccessFailureDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>
                        <div className="col-md-4">
                            {/* start col */}

                            <div className="card overview-dashboard-card " style={{ height: '100px' }}>
                                <AverageDurationDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>

                            {/* end col */}
                        </div>

                        {/* end row */}
                    </div>
                    <div className="row mb-4">
                        {/* start row */}


                        <div className="col-md-7">
                            {/* start col */}

                            { this.renderLeftColumn() }

                            {/* end col */}
                        </div>

                        <div className="col-md-5">
                            {/* start col */}

                            { this.renderRightColumn() }

                            {/* end col */}
                        </div>

                        {/* end row */}
                    </div>
                </div>
            </div>
        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    canary_tests_reports_data: state.canary_tests_reports.canary_tests_reports_data
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    canaryTestsReportsReset,
    canaryTestsReportsFetch
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverviewDashboard)

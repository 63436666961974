import React, { Component } from 'react'

import { ALERT_RULE_SCHEDULE_TYPES } from 'lib/constants'

import FancySingleSelectDropdown from 'components/shared/FancySingleSelectDropdown/FancySingleSelectDropdown'


class AlertRuleFrequencyField extends Component {

    constructor (props) {

        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.renderFieldErrorMessage = this.renderFieldErrorMessage.bind(this)

        this.minimum_field_value_length = 2

        this.state = {
            field_value: this.props.fieldValue || "X",
            field_error_flag: this.props.fieldErrorFlag || false,
            field_error_message: this.props.fieldErrorMessage || ''
        }

    }

    componentWillMount () {

        // console.log("componentWillMount - AlertRuleFrequencyField", this.props.fieldValue)

    }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps", nextProps)

        if (nextProps.fieldValue !== null) {

            this.setState({ field_value: nextProps.fieldValue })

        }

    }

    handleSelectChange (new_select_value) {

        // console.log("handleSelectChange", new_select_value)

        // No errors in entered text
        this.setState({
            field_value: new_select_value.value,
            field_error_flag: false,
            field_error_message: ''
        })

        this.props.onFieldChange(false, new_select_value.value)

    }

    handleChange (e) {

        if (e.target.value.length < this.minimum_field_value_length) {

            // Entered text is too short

            this.setState({
                field_value: e.target.value,
                field_error_flag: true,
                field_error_message: `Alert rule frequency must be at least ${this.minimum_field_value_length} characters long`
            })

            this.props.onFieldChange(true, e.target.value)

        } else {

            // No errors in entered text
            this.setState({
                field_value: e.target.value,
                field_error_flag: false,
                field_error_message: ''
            })

            this.props.onFieldChange(false, e.target.value)

        }

    }

    renderFieldErrorMessage () {

        if (this.state.field_error_flag !== true) {

            return (<div />)

        }

        return (

            <div className="text-danger mt-1">
                {this.state.field_error_message}
            </div>

        )

    }

    // Return the completely assembled component
    render () {

        // let field_class_string = `form-control border`
        // if (this.state.field_error_flag === true) {
            // field_class_string = `${field_class_string} border-danger`
        // }

        // console.log("!!! Rendering this.state.field_value", this.state.field_value)

        if (typeof this.state.field_value === "undefined") {

            return (
                <span>Loading</span>
            )

        }

        return (

            <div className="row form-group mb-4">

                <div className="col editor-label-column">

                    <label className="col-form-label">Frequency</label>

                </div>
                <div className="col">

                    {this.renderFieldErrorMessage()}

                    <FancySingleSelectDropdown
                        id="alert_rule_frequency"
                        name="alert_rule_frequency"
                        placeholder="FREQUENCY"
                        selected_option={this.state.field_value}
                        onChange={this.handleSelectChange}
                        options={ALERT_RULE_SCHEDULE_TYPES}
                    />

                </div>

            </div>

        )

    }

}

export default AlertRuleFrequencyField

import React, { Component } from 'react'

import { Switch } from 'react-router'

import MyTeamList from './MyTeamList/MyTeamList'
import MyTeamListReadOnly from './MyTeamListReadOnly/MyTeamListReadOnly'

import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import './MyTeam.scss'

class MyTeam extends Component {

    renderMyTeam () {

        if (window.ac_session.current_organization.organization_role_type === "READ_ONLY") {

            return (<MyTeamListReadOnly />)

        }

        return (
            <Switch>
                <PrivateRoute exact path="/o/:organization_id/settings/my_team/:user_account_id" component={MyTeamList} />
                <PrivateRoute exact path="/o/:organization_id/settings/my_team" component={MyTeamList} />
            </Switch>
        )

    }

    render () {

        // console.log("r this.props.login_return_data", this.props.login_return_data)
        // console.log("typeof this.props.login_return_data", typeof this.props.login_return_data)
        return (

            <div className="variable-vault-top-container">

                <div className="container-fluid dashboard-body-header-container">
                    <div className="row">
                        <div className="col-sm">
                            <h1>My Team</h1>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row dashboard-body-row">

                        <div className="col-12 col-md-8 col-lg-9">
                            {this.renderMyTeam()}
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-3">

                            <div className="card border-0">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <i className="fa fa-lightbulb-o" aria-hidden="true" /> My Team
                                    </h5>
                                    <p className="card-text">
                                        Invite your team members to view or edit your tests and test executions.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        )

    }

}

export default MyTeam

import React from 'react'
// import {
    // Link
// } from 'react-router-dom'

import AlertBox from 'components/shared/AlertBox/AlertBox'

import CanaryTestTitleField from './CanaryTestTitleField/CanaryTestTitleField'
import CanaryTestCodeField from './CanaryTestCodeField/CanaryTestCodeField'
import CanaryTestScheduleTypeField from './CanaryTestScheduleTypeField/CanaryTestScheduleTypeField'
import CanaryTestExecutionNodesField from './CanaryTestExecutionNodesField/CanaryTestExecutionNodesField'
import CanaryTestAlertRulesField from './CanaryTestAlertRulesField/CanaryTestAlertRulesField'

import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
import FancyCancelButton from 'components/shared/FancyCancelButton/FancyCancelButton'

import './CanaryTestFormBody.scss'
import DashboardModule from 'components/shared/DashboardModule/DashboardModule'


class CanaryTestFormBody extends DashboardModule {

    constructor (props) {

        super(props)

        const { match: { params } } = this.props
        this.organization_id = params.organization_id

        this.renderAlertBox = this.renderAlertBox.bind(this)
        this.renderDeleteButton = this.renderDeleteButton.bind(this)
        this.renderCancelButton = this.renderCancelButton.bind(this)
        this.renderFormBody = this.renderFormBody.bind(this)

        this.handleManualRun = this.handleManualRun.bind(this)

    }

    componentDidUpdate (prevProps) {

        if (
            this.props.isSubmitting === true &&
            prevProps.currently_submitting_canary_test_form_post === true &&
            this.props.currently_submitting_canary_test_form_post === false
        ) {

            // stops the save button spinner
            this.props.setSubmitting(false)

        }

    }

    handleManualRun () {

        // console.log(1, this.props.values.canary_test_code)
        // console.log(2, this.props.values.canary_test_execution_nodes[0])

        this.props.onCanaryTestManualRunRequest(
            this.props.values.canary_test_execution_nodes[0],
            this.props.values.canary_test_code
        )

    }

    renderAlertBox () {

        let error_message = false

        if (this.props.canary_test_submit_error) {

            error_message = this.props.canary_test_submit_error

        } else if (this.props.canary_test_delete_error) {

            error_message = this.props.canary_test_delete_error

        }

        if (error_message) {

            return (

                <div className="row">

                    <div className="col-sm">

                        <AlertBox
                            error_level="danger"
                            error_title="Error:"
                            error_description={error_message}
                        />

                    </div>

                </div>

            )

        }

        return (<div />)

    }


    renderCancelButton () {

        const currently_submitting_form = this.props.isSubmitting || this.props.currently_submitting_canary_test_form_post

        // Creator
        let cancel_url = `/o/${this.organization_id}/canary_tests`

        if (this.props.edit_mode === true) {

            // Editor
            cancel_url = `/o/${this.organization_id}/canary_tests/d/${this.props.canary_test_id}`

        }

        return (
            <FancyCancelButton
                button_type="light"
                button_submit_type="button"
                disabled={currently_submitting_form}
                cancel_url={cancel_url}
            >
                Cancel
            </FancyCancelButton>
        )

    }

    renderDeleteButton () {

        const currently_submitting_form = this.props.isSubmitting || this.props.currently_submitting_canary_test_form_post

        if (this.props.edit_mode === false) {

            return (<div />)

        }

        return (
            <FancyLoadingButton
                button_type="light"
                button_submit_type="button"
                currently_loading={this.props.currently_sending_canary_test_delete_post}
                disabled={currently_submitting_form}
                onClick={this.props.onCanaryTestDeleteClick}
            >
                Delete Test
            </FancyLoadingButton>
        )

    }

    renderFormBody () {

        const currently_submitting_form = this.props.isSubmitting || this.props.currently_submitting_canary_test_form_post

        // console.group("form submitting")
        // console.log("currently_submitting_form", currently_submitting_form)
        // console.log("this.props.isSubmitting", this.props.isSubmitting)
        // console.log("this.props.currently_submitting_canary_test_form_post", this.props.currently_submitting_canary_test_form_post)
        // console.groupEnd()

        const form_has_errors = Boolean(Object.keys(this.props.errors).length) && Boolean(Object.keys(this.props.touched).length)

        return (
            <div className="p-3 canary-test-form-body-container">

                {this.renderAlertBox()}

                <form className="mb-5" onSubmit={this.props.handleSubmit}>

                    {/* Title */}
                    <CanaryTestTitleField
                        id="canary_test_title_form_element_id"
                        name="canary_test_title"
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleBlur}
                        value={this.props.values.canary_test_title}
                        disabled={currently_submitting_form}
                        error_message={this.props.errors.canary_test_title}
                        show_error_message={this.props.errors.canary_test_title && this.props.touched.canary_test_title && this.props.errors.canary_test_title}
                    />

                    {/* NodeJS Code */}
                    <CanaryTestCodeField
                        id="canary_test_code_form_element_id"
                        name="canary_test_code"
                        onChange={this.props.setFieldValue}
                        onBlur={this.props.setFieldTouched}
                        value={this.props.values.canary_test_code}
                        disabled={currently_submitting_form}
                        error_message={this.props.errors.canary_test_code}
                        show_error_message={this.props.errors.canary_test_code && this.props.touched.canary_test_code && this.props.errors.canary_test_code}

                        /* manually running */
                        currently_sending_canary_test_manually_run_post={this.props.currently_sending_canary_test_manually_run_post}
                        canary_test_manually_run_execution_node_id={this.props.canary_test_manually_run_execution_node_id}
                        canary_test_manually_run_error={this.props.canary_test_manually_run_error}
                        canary_test_manually_run_success={this.props.canary_test_manually_run_success}
                        canary_test_manually_run_return_data={this.props.canary_test_manually_run_return_data}
                        onCanaryTestManualRunRequest={this.handleManualRun}
                        canaryTestManuallyRunReset={this.props.canaryTestManuallyRunReset}

                        selected_execution_nodes={this.props.values.canary_test_execution_nodes}

                        /* fetching execution nodes */
                        currently_fetching_execution_nodes={this.props.currently_fetching_execution_nodes}
                        all_execution_nodes_fetch_error={this.props.all_execution_nodes_fetch_error}
                        all_execution_nodes_data={this.props.all_execution_nodes_data}

                        /* datacenters */
                        currently_fetching_datacenters={this.props.currently_fetching_datacenters}
                        datacenters_fetch_error={this.props.datacenters_fetch_error}
                        datacenters_data={this.props.datacenters_data}
                    />

                    {/* Schedule */}
                    <CanaryTestScheduleTypeField
                        id="canary_test_schedule_type_form_element_id"
                        name="canary_test_schedule_type"
                        onChange={this.props.setFieldValue}
                        onBlur={this.props.setFieldTouched}
                        value={this.props.values.canary_test_schedule_type}
                        disabled={currently_submitting_form}
                        error_message={this.props.errors.canary_test_schedule_type}
                        show_error_message={this.props.errors.canary_test_schedule_type && this.props.touched.canary_test_schedule_type && this.props.errors.canary_test_schedule_type}
                    />

                    {/* Data Centers */}
                    <CanaryTestExecutionNodesField

                        {...this.props}

                        id="canary_test_execution_nodes_form_element_id"
                        name="canary_test_execution_nodes"
                        onChange={this.props.setFieldValue}
                        onBlur={this.props.setFieldTouched}
                        value={this.props.values.canary_test_execution_nodes}
                        disabled={currently_submitting_form}
                        error_message={this.props.errors.canary_test_execution_nodes}
                        show_error_message={this.props.errors.canary_test_execution_nodes && this.props.touched.canary_test_execution_nodes && this.props.errors.canary_test_execution_nodes}

                        /* fetching execution nodes */
                        currently_fetching_execution_nodes={this.props.currently_fetching_execution_nodes}
                        all_execution_nodes_fetch_error={this.props.all_execution_nodes_fetch_error}
                        all_execution_nodes_data={this.props.all_execution_nodes_data}

                        /* datacenters */
                        currently_fetching_datacenters={this.props.currently_fetching_datacenters}
                        datacenters_fetch_error={this.props.datacenters_fetch_error}
                        datacenters_data={this.props.datacenters_data}
                    />

                    {/* Alert Rules */}
                    <CanaryTestAlertRulesField

                        {...this.props}

                        id="canary_test_alert_rules_form_element_id"
                        name="canary_test_alert_rules"

                        timeout_checkbox_id="canary_test_alert_rules_form_timeout_checkbox_element_id"
                        timeout_checkbox_name="canary_test_fire_alert_rules_on_timeout"
                        
                        onChange={this.props.setFieldValue}
                        onBlur={this.props.setFieldTouched}
                        value={this.props.values.canary_test_alert_rules}

                        timeoutCheckboxOnChange={this.props.handleChange}
                        timeoutCheckboxOnBlur={this.props.handleBlur}
                        timeoutCheckboxValue={this.props.values.canary_test_fire_alert_rules_on_timeout}

                        disabled={currently_submitting_form}
                        error_message={this.props.errors.canary_test_alert_rules}
                        show_error_message={this.props.errors.canary_test_alert_rules && this.props.touched.canary_test_alert_rules && this.props.errors.canary_test_alert_rules}

                        /* fetching */
                        currently_fetching_all_alert_rules={this.props.currently_fetching_all_alert_rules}
                        alert_rules_fetch_error={this.props.alert_rules_fetch_error}
                        all_alert_rules={this.props.all_alert_rules}
                    />

                    {/* Buttons */}
                    <div className="form-row">
                        <div className="form-group col-9">

                            <FancyLoadingButton
                                button_type="primary"
                                button_submit_type="submit"
                                currently_loading={currently_submitting_form}
                                disabled={form_has_errors}
                            >
                                Save
                            </FancyLoadingButton>

                            { this.renderCancelButton() }

                        </div>
                        <div className="form-group col-3 text-right">
                            { this.renderDeleteButton() }
                        </div>
                    </div>

                </form>

                {/*
                <div className="p-3 bg-primary text-white">.bg-primary</div>
                <div className="p-3 bg-secondary text-white">.bg-secondary</div>
                <div className="p-3 bg-success text-white">.bg-success</div>
                <div className="p-3 bg-light text-dark">.bg-light</div>
                <div className="p-3 bg-danger text-white">.bg-danger</div>
                <div className="p-3 bg-warning text-dark">.bg-warning</div>
                <div className="p-3 bg-info text-white">.bg-info</div>
                <h4>Props</h4>
                <pre>{JSON.stringify(this.props, null, 4)}</pre>
                <h4>Values</h4>
                <pre>{JSON.stringify(this.props.values, null, 4)}</pre>
                */}

            </div>
        )

    }

    render () {

        return (

            <div className="card-bodyx">
                <div className="container-fluid dashboard-body-header-container">
                    <div className="row">

                        <div className="col-12 col-11-xl">
                            <h1>{this.props.form_title}</h1>
                        </div>

                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row dashboard-body-row">

                        <div className="col-12 mb-4">
                            {
                                /*
                                */
                                this.renderCard(
                                    this.props.form_title,
                                    this.renderFormBody()
                                )
                            }
                        </div>

                    </div>
                </div>
            </div>

        )

    }

}

export default CanaryTestFormBody

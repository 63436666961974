import React, { Component } from 'react'
import {
    Redirect
    // Link,
    // , NavLink
} from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// Fetch
import {
    variableVaultKeysReset,
    variableVaultKeysFetch
} from 'modules/variable_vault_keys_module'

// Create
import {
    variableVaultKeyCreateReset,
    variableVaultKeyCreatePost
} from 'modules/variable_vault_key_create_module'

// Update
import {
    variableVaultKeyUpdateReset,
    variableVaultKeyUpdatePost
} from 'modules/variable_vault_key_update_module'

// Delete
import {
    variableVaultKeyDeleteReset,
    variableVaultKeyDeletePost
} from 'modules/variable_vault_key_delete_module'


import VariableVaultListBody from './VariableVaultListBody/VariableVaultListBody'


class VariableVaultList extends Component {

    constructor (props) {

        super(props)

        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.fetchAllData = this.fetchAllData.bind(this)
        this.resetAllData = this.resetAllData.bind(this)
        this.resetAndFetchAllData = this.resetAndFetchAllData.bind(this)
        this.onVaultKeyCreate = this.onVaultKeyCreate.bind(this)
        this.onVaultKeyUpdate = this.onVaultKeyUpdate.bind(this)
        this.onVaultKeyDelete = this.onVaultKeyDelete.bind(this)

        this.state = {
            fireRedirectOnSuccess: false
        }

        this.vault_url = `/o/${this.organization_id}/variable_vault`

    }

    componentDidMount () {

        // console.log("VariableVaultList mount")

        this.resetAndFetchAllData()

    }

    componentDidUpdate (prevProps, prevState) {

        // console.dir("componentDidUpdate")

        if (
            (prevProps.location.pathname !== this.props.location.pathname) &&
            this.props.location.pathname === this.vault_url
        ) {

            // console.log("pull and reset all data here?")
            // this.resetAndFetchAllData()
            this.setState({ fireRedirectOnSuccess: false })

        }

    }

    resetAndFetchAllData () {

        this.resetAllData()
        this.fetchAllData()

    }

    resetAllData () {

        this.props.variableVaultKeyCreateReset()
        this.props.variableVaultKeyDeleteReset()
        this.props.variableVaultKeyUpdateReset()

    }

    fetchAllData () {

        // console.log("FetchAllData hit - VariableVaultList.js")
        this.props.variableVaultKeysFetch(this.organization_id)

    }

    onVaultKeyCreate (variable_vault_key, variable_vault_key_value) {

        // console.log("key create called!", variable_vault_key, variable_vault_key_value)
        this.setState({ fireRedirectOnSuccess: true })
        this.props.variableVaultKeyCreatePost(this.organization_id, variable_vault_key, variable_vault_key_value, this.resetAndFetchAllData)

    }

    onVaultKeyUpdate (variable_vault_key, variable_vault_key_value, old_variable_vault_key) {

        // console.log("key update called", variable_vault_key, variable_vault_key_value)

        // console.log("onVaultKeyUpdate", this.props.variable_vault_data)

        // const old_key = this.props.variable_vault_data.map((item) => {

            // console.log(item)

        // })

        this.setState({ fireRedirectOnSuccess: true })
        this.props.variableVaultKeyUpdatePost(this.organization_id, variable_vault_key, variable_vault_key_value, old_variable_vault_key, this.resetAndFetchAllData)

    }

    onVaultKeyDelete (e) {

        const vault_key = e.target.getAttribute('data-vault-key')
        // console.log("key delete called", vault_key)
        this.props.variableVaultKeyDeletePost(this.organization_id, vault_key, this.resetAndFetchAllData)

    }

    // Return the completely assembled component
    render () {

        if (
            (
                (this.props.variable_vault_key_create_successfully_posted && this.state.fireRedirectOnSuccess) ||
                (this.props.variable_vault_key_update_successfully_posted && this.state.fireRedirectOnSuccess)
            ) &&
            this.props.location.pathname !== this.vault_url
        ) {

            // console.log("redir hit")
            return (
                <Redirect to={this.vault_url} />
            )

        }

        return (

            <VariableVaultListBody
                /* Fetch */
                is_currently_loading={this.props.currently_fetching_variable_vault_keys}
                loading_error={this.props.variable_vault_keys_fetch_error}
                loaded_data={this.props.variable_vault_data}

                /* Create */
                handleOnVaultKeyCreate={this.onVaultKeyCreate}
                currently_sending_variable_vault_key_create_post={this.props.currently_sending_variable_vault_key_create_post}
                variable_vault_key_create_error={this.props.variable_vault_key_create_error}
                variable_vault_key_create_return_data={this.props.variable_vault_key_create_return_data}
                variable_vault_key_create_successfully_posted={this.props.variable_vault_key_create_successfully_posted}

                /* Update */
                handleOnVaultKeyUpdate={this.onVaultKeyUpdate}
                currently_sending_variable_vault_key_update_post={this.props.currently_sending_variable_vault_key_update_post}
                variable_vault_key_update_error={this.props.variable_vault_key_update_error}
                variable_vault_key_update_return_data={this.props.variable_vault_key_update_return_data}
                variable_vault_key_update_successfully_posted={this.props.variable_vault_key_update_successfully_posted}

                /* Delete */
                handleOnVaultKeyDelete={this.onVaultKeyDelete}
                currently_sending_variable_vault_key_delete_post={this.props.currently_sending_variable_vault_key_delete_post}
                variable_vault_key_delete_error={this.props.variable_vault_key_delete_error}
                variable_vault_key_delete_return_data={this.props.variable_vault_key_delete_return_data}
                variable_vault_key_delete_successfully_posted={this.props.variable_vault_key_delete_successfully_posted}

                {...this.props}
            />

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    // Fetch
    currently_fetching_variable_vault_keys:           state.variable_vault_keys.currently_fetching_variable_vault_keys,
    variable_vault_keys_fetch_error:                  state.variable_vault_keys.variable_vault_keys_fetch_error,
    variable_vault_data:                              state.variable_vault_keys.variable_vault_data,
    // Create
    currently_sending_variable_vault_key_create_post: state.variable_vault_key_create.currently_sending_variable_vault_key_create_post,
    variable_vault_key_create_error:                  state.variable_vault_key_create.variable_vault_key_create_error,
    variable_vault_key_create_return_data:            state.variable_vault_key_create.variable_vault_key_create_return_data,
    variable_vault_key_create_successfully_posted:    state.variable_vault_key_create.variable_vault_key_create_successfully_posted,
    // Update
    currently_sending_variable_vault_key_update_post: state.variable_vault_key_update.currently_sending_variable_vault_key_update_post,
    variable_vault_key_update_error:                  state.variable_vault_key_update.variable_vault_key_update_error,
    variable_vault_key_update_return_data:            state.variable_vault_key_update.variable_vault_key_update_return_data,
    variable_vault_key_update_successfully_posted:    state.variable_vault_key_update.variable_vault_key_update_successfully_posted,
    // Delete
    currently_sending_variable_vault_key_delete_post: state.variable_vault_key_delete.currently_sending_variable_vault_key_delete_post,
    variable_vault_key_delete_error:                  state.variable_vault_key_delete.variable_vault_key_delete_error,
    variable_vault_key_delete_return_data:            state.variable_vault_key_delete.variable_vault_key_delete_return_data,
    variable_vault_key_delete_successfully_posted:    state.variable_vault_key_delete.variable_vault_key_delete_successfully_posted,

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    // Fetch
    variableVaultKeysReset,
    variableVaultKeysFetch,
    // Create
    variableVaultKeyCreateReset,
    variableVaultKeyCreatePost,
    // Update
    variableVaultKeyUpdateReset,
    variableVaultKeyUpdatePost,
    // Delete
    variableVaultKeyDeleteReset,
    variableVaultKeyDeletePost
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VariableVaultList)

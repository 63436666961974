import React, { Component } from 'react'
import {
    Link // , NavLink
} from 'react-router-dom'

// import './ActionsButton.css'

class ActionsButton extends Component {

    constructor (props) {

        super(props)
        this.renderButtonBody = this.renderButtonBody.bind(this)
        this.handleTimeDurationClick = this.handleTimeDurationClick.bind(this)

    }

    handleTimeDurationClick (e) {

        this.props.handleTimeDurationClick(e.target.getAttribute('data-time-duration'))

    }

    renderButtonBody () {

        return (

            <div className="dropdown-menu dropdown-menu-right">
                <Link className="dropdown-item" to={this.props.edit_canary_test_anchor_url}>Edit Test</Link>
                <div className="dropdown-divider" />
                <button className="dropdown-item" onClick={this.props.onClearDataClick} type="button"><span className="text-danger"><i className="fa fa-exclamation-circle mr-1" aria-hidden="true" /> Clear All Execution Result Data</span></button>
            </div>

        )

    }

    render () {

        return (

            <div className="btn-group">
                <button type="button" className="btn btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-lightbulb-o mr-1" aria-hidden="true" /> Actions
                </button>
                { this.renderButtonBody() }
            </div>

        )

    }

}

export default ActionsButton

// import $ from 'jquery'
import React, { Component } from 'react'

import { CANARY_TEST_SCHEDULE_TYPES } from 'lib/constants'

import FancyFormElementSingleSelectDropdown from 'components/shared/FancyFormElementSingleSelectDropdown/FancyFormElementSingleSelectDropdown'
// import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'


class CanaryTestScheduleTypeField extends Component {

    constructor (props) {

        super(props)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnBlur = this.handleOnBlur.bind(this)

    }

    handleOnBlur () {

        this.props.onBlur(this.props.name)

    }

    handleOnChange (new_value) {

        // console.log("e", form_element_name, xxx)
        this.props.onChange(this.props.name, new_value)

    }

    // Return the completely assembled component
    render () {

        return (
            <div className="form-row">
                <div className="form-group col-lg-9">
                    <label>Test Schedule</label>
                    <FancyFormElementSingleSelectDropdown
                        id={this.props.id}
                        name={this.props.name}
                        onChange={this.handleOnChange}
                        value={this.props.value}
                        dropdown_options={CANARY_TEST_SCHEDULE_TYPES}
                        onBlur={this.handleOnBlur}
                        disabled={this.props.disabled}
                        error_message={this.props.error_message}
                        show_error_message={this.props.show_error_message}
                    />
                    {/*
                    <FancyFormElementText
                        id={this.props.id}
                        type="text"
                        name={this.props.name}
                        placeholder="DEBUG"
                        onChange={this.props.onChange}
                        onBlur={this.props.onBlur}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        error_message={this.props.error_message}
                        show_error_message={this.props.show_error_message}
                    />
                    */}

                    {/*
                    <pre>{JSON.stringify(this.props, null, 4)}</pre>
                    */}

                </div>
                <div className="form-group col-lg-3">
                    <label className="d-none d-lg-block">&nbsp;</label>
                    <div className="form-info-bubble">
                        <i className="fa fa-lightbulb-o" />
                        <p>
                            Production tests are executed on a schedule.
                            Choose how often you want this test to be executed.
                        </p>
                    </div>
                </div>
            </div>
        )

    }

}


export default CanaryTestScheduleTypeField


import { auth } from 'lib/authentication'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'


class NavLinks extends Component {

    constructor (props) {

        super(props)
        this.handleLogoutClick = this.handleLogoutClick.bind(this)

    }

    // componentWillMount () {

        // console.log("NavLinks")
        // console.dir(this.props)

    // }

    handleLogoutClick (event) {

        event.preventDefault()
        auth.clear()
        window.location.href = "/login"

    }

    // Return the completely assembled component
    render () {

        return (

            <div className="mt-2 mt-md-0">
                <ul className="navbar-nav">
                    <li className="nav-item active">
                        <a className="nav-link" href="https://www.apicanary.com/blog" target="_BLANK" rel="noreferrer noopener">Blog</a>
                    </li>
                    <li className="nav-item dropdown active">
                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown">
                            {this.props.user_account_user_data.user_account_username}
                            <i className="fa fa-user-o ml-2" />
                            {/*
                            */}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link className="dropdown-item small" to="/account_settings">Account Settings</Link>
                            <div className="dropdown-divider small" />
                            <button className="dropdown-item small" onClick={this.handleLogoutClick} type="button">Logout</button>
                        </div>
                    </li>
                </ul>
            </div>

        )

    }

}


export default NavLinks

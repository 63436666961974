import $ from 'jquery'
import React, { Component } from 'react'
import {
    Link,
    Redirect
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    signupPost,
    signupReset
} from 'modules/signup_module'
import {
    loginPost,
    loginReset
} from 'modules/login_module'
import { auth } from 'lib/authentication'
import config from 'config'


class Signup extends Component {

    constructor (props) {

        super(props)
        this.handleSignupFormSubmit = this.handleSignupFormSubmit.bind(this)
        this.renderSignupButton = this.renderSignupButton.bind(this)

    }

    componentWillMount () {

        // console.log("componentWillMount hit")
        this.props.signupReset()
        this.props.loginReset()

    }

    handleSignupFormSubmit (event) {

        event.preventDefault()
        // console.log(event)
        // console.log("fs this.props", this.props)
        // console.log("calling signupPost()...")
        const email_address = $("#emailSignupFormInput").val()
        const username = $("#usernameSignupFormInput").val()
        const password = $("#passwordSignupFormInput").val()
        const organization_name = $("#organizationNameSignupFormInput").val()


        this.props.signupPost(username, email_address, organization_name, password)

    }

    renderSignupStatus () {

        // console.log("re this.props", this.props)
        // If we have a signup error, show this...
        const { signup_error } = this.props
        if (signup_error !== null) {

            return (
                <div className="alert alert-danger" role="alert">
                    <strong>{ signup_error }</strong>
                </div>
            )

        }

        return (

            <div />

        )

    }

    renderSignupButton () {

        const { currently_sending_signup_post } = this.props

        if (currently_sending_signup_post === true) {

            return (
                <button type="submit" className="btn mr-2 btn-secondary" disabled>
                    <i className="fa fa-refresh fa-spin fa-fw mr-2 text-secondary" />Signing Up
                </button>
            )

        }

        return (
            <button type="submit" className="btn mr-2 btn-success">
                <i className="fa fa-lock fa-fw mr-1" />Take Me To My Dashboard
            </button>
        )

    }

    // Return the completely assembled component
    render () {

        if (auth.has("SU")) {

            if (auth.get("auth_token")) {

                return (
                    <Redirect to="/o" push={true} />
                )

            }

            return (
                <Redirect to="/login?sm=a" push={true} />
            )

        }

        // kick ppl out if signups are turned off
        if (!config["allow_signups"]) {

            return (
                <Redirect to="/login" push={true} />
            )

        }

        return (

            <form method="POST" onSubmit={this.handleSignupFormSubmit}>

                <h1 className="text-center text-dark">Join API Canary</h1>

                <div className="card">
                    <div className="card-body">

                        { this.renderSignupStatus() }

                        <div className="form-group">
                            <label>Username</label>
                            <input defaultValue="" type="text" className="form-control" id="usernameSignupFormInput" placeholder="MattCCrampton" />
                            <small className="form-text text-muted">
                                You will be known by your username inside your company&apos;s organization as well as our public forums and galleries.
                            </small>
                        </div>
                        <div className="form-group">
                            <label>Email Address</label>
                            <input defaultValue="" type="email" className="form-control" id="emailSignupFormInput" placeholder="email@domain.com" />
                            <small id="emailHelp" className="form-text text-muted">We&apos;ll never share your email with anyone else.</small>
                        </div>
                        <div className="form-group">
                            <label>Company Name</label>
                            <input defaultValue="" type="text" className="form-control" id="organizationNameSignupFormInput" placeholder="Your Company&apos;s Name" />
                            <small className="form-text text-muted">
                                All your canary tests will be organized under your Company&apos;s organization name
                            </small>
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input defaultValue="" type="password" className="form-control" id="passwordSignupFormInput" placeholder="Password" />
                            <small className="form-text text-muted">
                                Your password must be at least 8 characters
                                long, must contain at least 1 lower case
                                letter, 1 upper case letter, and 1 numeric character
                            </small>
                        </div>

                        <div className="form-group">
                            { this.renderSignupButton() }
                        </div>

                    </div>

                    <div className="card-footer text-muted">
                        Already have an account?&nbsp;&nbsp;&nbsp;<Link to="/login">Login</Link>
                    </div>
                </div>

            </form>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    signup_return_data: state.signup.signup_return_data,
    currently_sending_signup_post: state.signup.currently_sending_signup_post,
    signup_error: state.signup.signup_error
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    loginPost,
    loginReset,
    signupPost,
    signupReset
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signup)

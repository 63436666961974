import React, { Component } from 'react'

import './FancyTextInputField.css'


class FancyTextInputField extends Component {

    constructor (props) {

        super(props)
        this.renderLoadingUI = this.renderLoadingUI.bind(this)

    }

    renderLoadingUI () {

        if (!this.props.currently_loading) {

            return (<div />)

        }

        return (
            <div className="fancy-text-input-loading-spinner">
                <i className="fa fa-refresh fa-spin text-secondary" />
            </div>
        )

    }

    render () {

        let field_class_string = `form-control border`
        let error_message_ui = (<div />)
        let error_message_icon = (<div />)
        if (this.props.has_error === true) {

            field_class_string = `${field_class_string} border-danger`
            error_message_ui = (
                <div>
                    {this.props.error_message}
                </div>
            )
            error_message_icon = (
                <div className="fancy-text-input-error-icon text-danger">
                    <i className="fa fa-2x fa-exclamation-circle" />
                </div>
            )


        }

        let disabled_flag = false
        let placeholder_text = this.props.placeholder
        let value_text = this.props.value

        if (this.props.currently_loading) {

            value_text = ""
            placeholder_text = ""
            disabled_flag = true

        }

        return (
            <div className="fancy-text-input-field-outer-container">
                {this.renderLoadingUI()}
                <div className="fancy-text-input-field-container">
                    {error_message_icon}
                    <input
                        value={value_text}
                        type="text"
                        className={field_class_string}
                        id={this.props.id}
                        onChange={this.props.onChange}
                        placeholder={placeholder_text}
                        disabled={disabled_flag}
                    />
                </div>
                <div className="fancy-text-input-error-box text-danger">
                    {error_message_ui}
                </div>
            </div>
        )

    }

}


export default FancyTextInputField

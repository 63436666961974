import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import { Route } from 'react-router-dom'

import NavBreadcrumbLink from '../NavBreadcrumbLink/NavBreadcrumbLink'

class NavBreadcrumbAlertRuleNew extends Component {

    // constructor (props) {

        // super(props)

    // }

    // componentWillMount () {

        // console.log("NavBreadcrumbAlertRuleNew")
        // console.dir(this.props)

    // }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        const canary_tests_url = `/o/${organization_id}/canary_tests`

        return (

            <>
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Tests"
                    breadcrumb_link_url={canary_tests_url}
                />
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="New"
                />
            </>

        )

    }

}


export default NavBreadcrumbAlertRuleNew

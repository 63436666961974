import React from 'react'
import {
    Link
} from 'react-router-dom'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import "./SuccessFailureDashboardModule.css"

class SuccessFailureDashboardModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.organization_id = false
        this.renderContent = this.renderContent.bind(this)
        this.show_outer_border = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    renderContent () {

        if (!this.props.report_data || this.props.report_data["_meta"].currently_fetching) {

            return ""

        }

        if (this.props.report_data["_meta"].fetch_error) {

            return "Error"

        }

        return (
            <>
                <strong>
                    {this.props.report_data["data"][0]["organization_canary_test_execution_result_total_count"].toLocaleString('en-EN', { minimumFractionDigits: 0 })}
                </strong> TEST EXECUTIONS
            </>
        )
                    // Success:
                    // {this.props.report_data["data"][0]["organization_canary_test_execution_result_success_count"]}

    }

    render () {

        const canary_tests_url = `/o/${this.organization_id}/canary_tests`

        return this.renderRawCard(

            <Link to={canary_tests_url} className="successFailureDashboardModule">

                <div className="icon-column">
                    <div className="left-icon">
                        <i className="fa fa-4x fa-tasks" aria-hidden="true" />
                    </div>
                </div>

                <div className="module-text-container">
                    {this.renderContent()}
                </div>

            </Link>

        )

    }

}

export default SuccessFailureDashboardModule


import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'


// Initial Module State
const initialState = {
    currently_fetching_canary_test_execution_result: false,
    canary_test_execution_result_fetch_error: null,
    canary_test_execution_result_data: null,
    canary_test_execution_result_fetch_successful: false
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const CANARY_TEST_EXECUTION_RESULT_RESET_ALL = 'canary_test_execution_result/CANARY_TEST_EXECUTION_RESULT_RESET_ALL'
export const CANARY_TEST_EXECUTION_RESULT_FETCH_REQUESTED = 'canary_test_execution_result/CANARY_TEST_EXECUTION_RESULT_FETCH_REQUESTED'
export const CANARY_TEST_EXECUTION_RESULT_FETCH_SUCCESS = 'canary_test_execution_result/CANARY_TEST_EXECUTION_RESULT_FETCH_SUCCESS'
export const CANARY_TEST_EXECUTION_RESULT_FETCH_ERROR = 'canary_test_execution_result/CANARY_TEST_EXECUTION_RESULT_FETCH_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const canaryTestExecutionResultReset = () => {

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_EXECUTION_RESULT_RESET_ALL
        })

    }

}

export const canaryTestExecutionResultFetch = (organization_id, canary_test_id, execution_result_id) => {

    // console.log("canary_testPost called")

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_EXECUTION_RESULT_RESET_ALL
        })

        dispatch({
            type: CANARY_TEST_EXECUTION_RESULT_FETCH_REQUESTED
        })

        const auth_token = auth.get("auth_token")

        // console.log("canaryTestExecutionResultFetch called")

        return axios({
            method: 'get',
            url: `//${config["api_hostname"]}/v1/o/${organization_id}/canary_tests/${canary_test_id}/execution_results/${execution_result_id}`,
            headers: {
                Authorization: `Token ${auth_token}`
            }
        })

            .then((response) => {

                const canary_test_execution_result_data = response.data

                // console.log("canary tests fetch success", response)

                dispatch({
                    type: CANARY_TEST_EXECUTION_RESULT_FETCH_SUCCESS,
                    payload: canary_test_execution_result_data
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    console.log("error1", error)
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    console.log("error2", error)
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    console.log("error3", error)
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: CANARY_TEST_EXECUTION_RESULT_FETCH_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case CANARY_TEST_EXECUTION_RESULT_RESET_ALL:
            return {
                ...state,
                currently_fetching_canary_test_execution_result: false,
                canary_test_execution_result_fetch_error: null,
                canary_test_execution_result_data: null,
                canary_test_execution_result_fetch_successful: false
            }

        case CANARY_TEST_EXECUTION_RESULT_FETCH_REQUESTED:
            return {
                ...state,
                currently_fetching_canary_test_execution_result: true
            }

        case CANARY_TEST_EXECUTION_RESULT_FETCH_SUCCESS:
            return {
                ...state,
                currently_fetching_canary_test_execution_result: false,
                canary_test_execution_result_fetch_error: null,
                canary_test_execution_result_data: action.payload["data"][0], // load the return data
                canary_test_execution_result_fetch_successful: true
            }

        case CANARY_TEST_EXECUTION_RESULT_FETCH_ERROR:
            return {
                ...state,
                currently_fetching_canary_test_execution_result: false,
                canary_test_execution_result_fetch_error: action.payload, // Loading error text
                canary_test_execution_result_data: null,
                canary_test_execution_result_fetch_successful: false
            }

        default:
            return state

    }

}

import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'
import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
import AlertBox from 'components/shared/AlertBox/AlertBox'

class RawChangePasswordForm extends React.Component {

    componentDidUpdate (prevProps, prevState) {

        if (
            this.props.isSubmitting === true &&
            this.props.currently_sending_user_account_password_update_post === true
        ) {

            // console.log("Caught change:")
            // console.log("componentDidUpdate this.props.isSubmitting", this.props.isSubmitting)
            // console.log("componentDidUpdate prevProps.isSubmitting", prevProps.isSubmitting)
            // this.props.setStatus("setting a status I guess")
            // console.log("Fix isSubmitting here")
            this.props.setSubmitting(false)

        }

    }

    render () {

        const {

            // dirty, // boolean
            errors, // { [field: string]: string }
            handleBlur, // (e: any) => void
            handleChange, // (e: React.ChangeEvent<any>) => void
            // handleReset, // () => void
            handleSubmit, // (e: React.FormEvent<HTMLFormEvent>) => void
            isSubmitting, // boolean
            // isValid, // boolean
            // isValidating, // boolean
            // resetForm, // (nextValues?: Values) => void
            // setErrors, // (fields: { [field: string]: string }) => void
            // setFieldError, // (field: string, errorMsg: string) => void
            // setFieldTouched, // (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
            // submitForm, // () => void
            // submitCount, // number
            // setFieldValue, // (field: string, value: any, shouldValidate?: boolean) => void
            // setStatus, // (status?: any) => void
            // setSubmitting, // (isSubmitting: boolean) => void
            // setTouched, // (fields: { [field: string]: boolean }) => void
            // setValues, // (fields: { [field: string]: any }) => void
            // status, // any
            touched, // { [field: string]: boolean }
            values, // { [field: string]: any }
            // validateForm, // (values?: any) => Promise<FormikErrors<Values>>
            // validateField, // (field: string) => void

        } = this.props

        const currently_submitting_form = isSubmitting || this.props.currently_sending_user_account_password_update_post

        let alert_box_markup = ""
        if (this.props.user_account_password_update_error) {

            alert_box_markup = (
                <AlertBox
                    error_level="danger"
                    error_title="Error:"
                    error_description={this.props.user_account_password_update_error}
                />
            )

        } else if (this.props.user_account_password_successfully_posted) {

            alert_box_markup = (
                <AlertBox
                    error_level="success"
                    error_title="Successfully updated email address"
                    error_description=""
                />
            )

        }

        const form_has_errors = Boolean(Object.keys(errors).length) && Boolean(Object.keys(touched).length)

        return (
            <div className="">
                <strong className="d-block mb-2">Change Password</strong>
                {alert_box_markup}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="user_account_password_old_form_element">Old Password</label>
                        <FancyFormElementText
                            id="user_account_password_old_form_element"
                            type="password"
                            name="user_account_password_old"
                            placeholder="Password1!"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.user_account_password_old}
                            disabled={currently_submitting_form}
                            error_message={errors.user_account_password_old}
                            show_error_message={errors.user_account_password_old && touched.user_account_password_old && errors.user_account_password_old}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="user_account_password_form_element">New Password</label>
                        <FancyFormElementText
                            id="user_account_password_form_element"
                            type="password"
                            name="user_account_password"
                            placeholder="Password1!"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.user_account_password}
                            disabled={currently_submitting_form}
                            error_message={errors.user_account_password}
                            show_error_message={errors.user_account_password && touched.user_account_password && errors.user_account_password}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="user_account_password_confirm_form_element">Confirm New Password</label>
                        <FancyFormElementText
                            id="user_account_password_confirm_form_element"
                            type="password"
                            name="user_account_password_confirm"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.user_account_password_confirm}
                            disabled={currently_submitting_form}
                            error_message={errors.user_account_password_confirm}
                            show_error_message={errors.user_account_password_confirm && touched.user_account_password_confirm && errors.user_account_password_confirm}
                        />
                        <small className="form-text text-muted">
                            Your password must be at least 8 characters
                            long, must contain at least 1 lower case
                            letter, 1 upper case letter, and 1 numeric character
                        </small>
                    </div>
                    <div className="form-group">
                        <FancyLoadingButton
                            button_type="primary"
                            button_submit_type="submit"
                            currently_loading={currently_submitting_form}
                            disabled={form_has_errors}
                        >
                            Change Password
                        </FancyLoadingButton>
                    </div>

                </form>
                {/*
                <pre style={{ color: '#000' }}>{JSON.stringify(this.props, null, 2)}</pre>
                */}
            </div>
        )

    }

}

export default withFormik({
    validationSchema: Yup.object().shape({

        user_account_password_old: Yup.string()
            .required('Required'),

        user_account_password: Yup.string()
            .required('Required')
            .min(8, 'Must be longer than 8 characters')
            .matches(/[a-z]/u, 'Must have at least one lowercase char')
            .matches(/[A-Z]/u, 'Must have at least one uppercase char')
            .matches(/[a-zA-Z]+[^a-zA-Z\s]+/u, 'Must have at least 1 number'),

        user_account_password_confirm: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('user_account_password'), null], 'Doesn\'t match your password')

    }),
    mapPropsToValues: (props) => ({

        user_account_password_old: "",
        user_account_password: "",
        user_account_password_confirm: "",

        currently_sending_user_account_password_update_post: props.currently_sending_user_account_password_update_post || false,
        user_account_password_update_error: props.user_account_password_update_error || false,
        user_account_password_successfully_posted: props.user_account_password_successfully_posted || false,

        onFormSubmit: props.onFormSubmit || null,

    }),
    handleSubmit: (payload, { props }) => {

        console.log("handle submit hit")
        props.onFormSubmit(payload["user_account_password_old"], payload["user_account_password"])

    },
    displayName: 'ChangePasswordForm',
    // enableReinitialize: true,
    isInitialValid: false
})(RawChangePasswordForm)

import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'

// Initial Module State
const initialState = {
    currently_sending_alert_rule_update_post: false,
    alert_rule_update_error: null,
    alert_rule_update_return_data: null,
    alert_rule_successfully_posted: false
}

// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const ALERT_RULE_UPDATE_RESET = 'alert_rule_update/ALERT_RULE_UPDATE_RESET'
export const ALERT_RULE_UPDATE_POST_REQUESTED = 'alert_rule_update/ALERT_RULE_UPDATE_POST_REQUESTED'
export const ALERT_RULE_UPDATE_POST_SUCCESS = 'alert_rule_update/ALERT_RULE_UPDATE_POST_SUCCESS'
export const ALERT_RULE_UPDATE_POST_ERROR = 'alert_rule_update/ALERT_RULE_UPDATE_POST_ERROR'

// Redux Action Creators - Functions that update actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const alertRuleUpdateReset = () => {

    return (dispatch) => {

        dispatch({
            type: ALERT_RULE_UPDATE_RESET
        })

    }

}

export const alertRuleUpdatePost = (organization_id, alert_rule_id, alert_rule_title, alert_rule_type, alert_rule_details, alert_rule_frequency) => {

    // console.log("alert_rulePost called")

    return (dispatch) => {

        dispatch({
            type: ALERT_RULE_UPDATE_RESET
        })

        dispatch({
            type: ALERT_RULE_UPDATE_POST_REQUESTED
        })

        const auth_token = auth.get("auth_token")

        const json_payload = {
            "action": "UPDATE_ALERT_RULE",
            "action_payload": {
                // "alert_rule_id": alert_rule_id,
                "alert_rule_title": alert_rule_title,
                // "alert_rule_type": alert_rule_type,
                "alert_rule_details": alert_rule_details,
                "alert_rule_notification_frequency": alert_rule_frequency
            }
        }

        return axios({
            method: 'post',
            url: `//${config["api_hostname"]}/v1/o/${organization_id}/alert_rules/${alert_rule_id}`,
            headers: {
                Authorization: `Token ${auth_token}`
            },
            data: json_payload
        })

            .then((response) => {

                const alert_rule_data = response.data

                // console.log("Alert rule update response", response)

                dispatch({
                    type: ALERT_RULE_UPDATE_POST_SUCCESS,
                    payload: alert_rule_data
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // console.log("hit1")
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }

                // console.log("error", error)
                // console.log("error_string", error_string)
                // console.log("error.config", error.config)

                dispatch({
                    type: ALERT_RULE_UPDATE_POST_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case ALERT_RULE_UPDATE_RESET:
            return {
                ...state,
                currently_sending_alert_rule_update_post: false,
                alert_rule_update_error: null,
                alert_rule_update_return_data: null,
                alert_rule_successfully_posted: false
            }

        case ALERT_RULE_UPDATE_POST_REQUESTED:
            return {
                ...state,
                currently_sending_alert_rule_update_post: true,
                alert_rule_update_return_data: null
            }

        case ALERT_RULE_UPDATE_POST_SUCCESS:
            return {
                ...state,
                currently_sending_alert_rule_update_post: false,
                alert_rule_update_error: null,
                alert_rule_update_return_data: action.payload, // load the return data
                alert_rule_successfully_posted: true
            }

        case ALERT_RULE_UPDATE_POST_ERROR:
            return {
                ...state,
                currently_sending_alert_rule_update_post: false,
                alert_rule_update_error: action.payload, // Loading error text
                alert_rule_update_return_data: null,
                alert_rule_successfully_posted: false
            }

        default:
            return state

    }

}

import React from 'react'
// import {
    // Link
// } from 'react-router-dom'

// import * as moment from 'moment'

import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker
} from "react-simple-maps"

import { PRETTY_TEXT_STRINGS } from 'lib/constants'

import { Motion, spring } from "react-motion"

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import "./TopDatacenterMapModule.scss"

class TopDatacenterMapModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.generateMarkerArray = this.generateMarkerArray.bind(this)
        this.generateMarker = this.generateMarker.bind(this)
        this.handleMarkerClick = this.handleMarkerClick.bind(this)
        this.handleCloseBoxClick = this.handleCloseBoxClick.bind(this)

        this.handleZoomIn = this.handleZoomIn.bind(this)
        this.handleZoomOut = this.handleZoomOut.bind(this)

        this.simulateMapClick = this.simulateMapClick.bind(this)

        this.organization_id = false

        this.map_zoom = 2

        this.initial_map_center = [0, 50]

        this.map_center = [0, 20]

        this.map_height = 330

        this.state = {
            map_has_been_clicked: false,
            simulated_map_click_tried_to_fire: false,
            clicked_datacenter: null,
            center: this.initial_map_center,
            zoom: this.map_zoom,
        }

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    simulateMapClick () {

        if (this.state.simulated_map_click_tried_to_fire || this.state.map_has_been_clicked) {

            return

        }

        this.setState({
            simulated_map_click_tried_to_fire: true
        },
        () => {

            // console.log("simulateMapClick would have fired here")

            if (this.props.report_data["data"].length < 1) {

                return

            }
            const index = Math.floor(Math.random() * this.props.report_data["data"].length)
            const execution_node = this.props.report_data["data"][index]
            const datacenter_name_string = `${execution_node["execution_node_datacenter_cloud_provider_name"]} - ${execution_node["execution_node_datacenter_name"]}`

            this.setState({
                map_has_been_clicked: true,
                clicked_datacenter: datacenter_name_string,
                center: [
                    execution_node["execution_node_datacenter_long"],
                    execution_node["execution_node_datacenter_lat"]
                ],
                zoom: 2
            })

        })

    }

    handleZoomIn () {

        this.setState({
            map_has_been_clicked: true,
            center: this.map_center,
            zoom: 2
        })

    }

    handleZoomOut () {

        this.setState({
            map_has_been_clicked: true,
            clicked_datacenter: null,
            center: this.map_center,
            zoom: 1,
        })

    }

    handleCloseBoxClick () {

        this.setState({
            map_has_been_clicked: true,
            clicked_datacenter: null
        })

    }

    handleMarkerClick (clicked_marker, datacenter_name) {

        this.setState({
            map_has_been_clicked: true,
            clicked_datacenter: datacenter_name,
            center: clicked_marker.coordinates,
            zoom: 2
        })

    }

    getSuccessPercent (execution_node) {

        let success_percent = ((execution_node["organization_canary_test_execution_result_success_count"] / execution_node["organization_canary_test_execution_result_total_count"]) * 100).toFixed(1)

        if (success_percent > 99.9) {

            success_percent = 100

        }

        if ((success_percent % 1) === 0) {

            // remove unneeded ".0" from numbers
            success_percent = Math.floor(success_percent)

        }

        return success_percent

    }

    generateMarker (execution_node) {

        const success_percent = this.getSuccessPercent(execution_node)

        let success_percent_string = "N/A"

        if (!isNaN(success_percent)) {

            success_percent_string = `${success_percent}%`

        }

        // GREEN
        let outer_bubble_fill = '#00ff00'
        let bubble_fill = '#009900'

        if (isNaN(success_percent)) {

            // GREY
            outer_bubble_fill = '#eee'
            bubble_fill = '#aaa'

        } else if (success_percent < 99.7) {

            // RED
            bubble_fill = '#dc3545'
            outer_bubble_fill = '#ff4e4e'

        } else if (success_percent < 100) {

            // YELLOW
            bubble_fill = '#ffff00'
            outer_bubble_fill = '#ffff44'

        }

        const datacenter_name_string = `${execution_node["execution_node_datacenter_cloud_provider_name"]} - ${execution_node["execution_node_datacenter_name"]}`

        let info_box = null

        let on_click_function = (clicked_marker) => this.handleMarkerClick(clicked_marker, datacenter_name_string)

        if (this.state.clicked_datacenter === datacenter_name_string) {

            on_click_function = null

            info_box = (
                <>
                    <polygon
                        points="10,-10 0,0 10,10 10,74 280,74 280,-20 10,-20"
                        fill="#eee"
                        stroke="#343a40"
                    />
                    <text x={18} y={0}>
                        <tspan className="info_box_lable">Cloud Provider: </tspan>
                        <tspan className="info_box_lable_text">{execution_node["execution_node_datacenter_cloud_provider_name"]}</tspan>
                    </text>
                    <text x={18} y={20}>
                        <tspan className="info_box_lable">Data Center: </tspan>
                        <tspan className="info_box_lable_text">{execution_node["execution_node_datacenter_name"]}</tspan>
                    </text>
                    <text x={18} y={42}>
                        <tspan className="info_box_lable">Your Test Success Rate: </tspan>
                        <tspan className="info_box_lable_text">{success_percent_string}</tspan>
                    </text>
                    <text x={18} y={64}>
                        <tspan className="info_box_lable">Total Test Executions: </tspan>
                        <tspan className="info_box_lable_text">{execution_node["organization_canary_test_execution_result_total_count"].toLocaleString('en-EN', { minimumFractionDigits: 0 })}</tspan>
                    </text>
                    <text x={267} y={-5} className="info_box_close_button" onClick={this.handleCloseBoxClick}>x</text>
                </>
            )

        }

        return (
            <Marker
                key={datacenter_name_string}
                marker={{
                    coordinates: [
                        execution_node["execution_node_datacenter_long"],
                        execution_node["execution_node_datacenter_lat"]
                    ]
                }}
                onClick={on_click_function}
                style={{ outline: "none" }}
            >
                <circle
                    cx={0}
                    cy={0}
                    r={13}
                    fill={outer_bubble_fill}
                    strokeWidth="0"
                    style={{ cursor: 'pointer ! important', opacity: 0.3 }}
                />
                <circle
                    cx={0}
                    cy={0}
                    r={10}
                    fill={outer_bubble_fill}
                    strokeWidth="0"
                    style={{ cursor: 'pointer ! important', opacity: 0.7 }}
                />
                <circle
                    cx={0}
                    cy={0}
                    r={7}
                    fill={bubble_fill}
                    strokeWidth="0"
                    style={{ cursor: 'pointer ! important' }}
                />
                {info_box}
            </Marker>
        )

    }

    generateMarkerArray () {

        let selected_marker_data = false

        // console.group("datacenter_name_string")
        const marker_data = this.props.report_data["data"].map((execution_node) => {

            const datacenter_name_string = `${execution_node["execution_node_datacenter_cloud_provider_name"]} - ${execution_node["execution_node_datacenter_name"]}`

            // console.log("execution_node_datacenter", execution_node["execution_node_datacenter"])

            if (this.state.clicked_datacenter === datacenter_name_string) {

                selected_marker_data = this.generateMarker(execution_node)
                return false

            }

            return this.generateMarker(execution_node)

        })
        // console.groupEnd()

        // return []

        if (selected_marker_data) {

            // ensures the selected infobox is always rendered last (and on top)
            marker_data.push(selected_marker_data)

        }

        return marker_data

    }

    render () {

        // console.log("map is rendering")

        if (!this.props.report_data || this.props.report_data["_meta"].currently_fetching) {

            return this.renderCard(
                `Your Test Executions Over The Last ${PRETTY_TEXT_STRINGS[this.props.current_time_duration]}`,
                (
                    <div
                        style={{
                            width: "100%",
                            height: (this.map_height - 24),
                        }}
                        className="datacenter-map-module-container"
                    >
                        <p className="m-4 text-center pt-5">
                            <i className="mt-5 fa fa-refresh fa-spin fa-fw fa-5x text-secondary" />
                        </p>
                    </div>
                )
            )

        }

        if (this.props.report_data["_meta"].fetch_error) {

            return this.renderCard(
                `Your Test Executions Over The Last ${PRETTY_TEXT_STRINGS[this.props.current_time_duration]}`,
                (
                    <div className="datacenter-map-module-container">
                        <p className="m-4">{this.props.report_data["_meta"].fetch_error}</p>
                    </div>
                )
            )

        }
        const marker_data = this.generateMarkerArray()

        // open a random info box on load
        setTimeout(this.simulateMapClick, 1000)

        // console.log("reports:", this.props.canary_tests_reports_data)
        // console.log("props:", this.props)

        return this.renderCard(
            `Your Test Executions Over The Last ${PRETTY_TEXT_STRINGS[this.props.current_time_duration]}`,
            (
                <div className="datacenter-map-module-container">
                    <div className="zoom-button-container">
                        <div type="button" onClick={this.handleZoomIn}>
                            <i className="fa fa-search-plus" />
                        </div>
                        <div type="button" onClick={this.handleZoomOut}>
                            <i className="fa fa-search-minus" />
                        </div>
                    </div>
                    <Motion
                        defaultStyle={{
                            zoom: this.map_zoom,
                            x: this.initial_map_center[0],
                            y: this.initial_map_center[1],
                        }}
                        style={{
                            zoom: spring(this.state.zoom, {stiffness: 210, damping: 20}),
                            x: spring(this.state.center[0], {stiffness: 210, damping: 20}),
                            y: spring(this.state.center[1], {stiffness: 210, damping: 20}),
                        }}
                    >
                        {({zoom, x, y}) => (

                            <ComposableMap
                                style={{
                                    width: "100%",
                                    height: this.map_height,
                                }}
                                height={this.map_height}
                            >
                                <ZoomableGroup center={[x, y]} zoom={zoom}>
                                    <Geographies geography="/topo/world-110m.json" onClick="" >

                                        {(geographies, projection) => geographies.map((geography, i) => {

                                            const key = i + geography.properties.NAME_LONG

                                            return (
                                                <Geography
                                                    key={key}
                                                    round
                                                    geography={geography}
                                                    projection={projection}
                                                    style={{
                                                        default: {
                                                            // fill: "#ECEFF1",
                                                            // stroke: "#607D8B",
                                                            fill: "#2b2b2b",
                                                            // stroke: "#607D8B",
                                                            stroke: "#fff",
                                                            strokeWidth: 0.75,
                                                            outline: "none",
                                                        },
                                                        hover: {
                                                            fill: "#2b2b2b",
                                                            stroke: "#fff",
                                                            strokeWidth: 0.75,
                                                            outline: "none",
                                                        },
                                                        pressed: {
                                                            fill: "#2b2b2b",
                                                            stroke: "#fff",
                                                            strokeWidth: 0.75,
                                                            outline: "none",
                                                        }
                                                    }}
                                                />
                                            )

                                        })}

                                    </Geographies>

                                    <Markers>

                                        {marker_data}

                                    </Markers>

                                </ZoomableGroup>
                            </ComposableMap>

                        )}

                    </Motion>

                    {/*
                    <div className="bottom-bar-container">
                        <div className="row">
                            <div className="col">
                                xxx
                            </div>
                            <div className="col">
                                xxx
                            </div>
                        </div>
                    </div>
                    */}

                </div>
            )
        )

    }

}

export default TopDatacenterMapModule


import axios from 'axios'
import {
    auth
} from 'lib/authentication'
import config from 'config'


// Initial Module State
const initialState = {
    currently_sending_signup_post: false,
    signup_error: null,
    signup_return_data: null
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const SIGNUP_RESET = 'signup/SIGNUP_RESET'
export const SIGNUP_POST_REQUESTED = 'signup/SIGNUP_POST_REQUESTED'
export const SIGNUP_POST_SUCCESS = 'signup/SIGNUP_POST_SUCCESS'
export const SIGNUP_POST_ERROR = 'signup/SIGNUP_POST_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const signupReset = () => {

    return (dispatch) => {

        dispatch({
            type: SIGNUP_RESET
        })

    }

}

export const signupPost = (username, email_address, organization_name, password) => {

    // console.log("signupPost called")

    return (dispatch) => {

        auth.clear()

        // Get signup data from form here.

        dispatch({
            type: SIGNUP_POST_REQUESTED
        })

        // return axios.get(`https://jsonplaceholder.typicode.com/posts`) // faking signup AJAX here, use your signup api instead
        return axios.post(
            `//${config["api_hostname"]}/v1/signup`,
            {
                "email_address": email_address,
                "username": username,
                "password": password,
                "organization_name": organization_name
            }
        )

            .then((response) => {

                // console.log("response from signup:", response)

                const signup_data = {
                    temp_un: username,
                    temp_pw: password
                }

                // console.log("signup response", response)

                auth.set(signup_data)

                dispatch({
                    type: SIGNUP_POST_SUCCESS,
                    payload: signup_data
                })

            })

            .catch((error) => {

                auth.clear()

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: SIGNUP_POST_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case SIGNUP_RESET:
            return {
                ...state,
                currently_sending_signup_post: false,
                signup_error: null,
                signup_return_data: null
            }

        case SIGNUP_POST_REQUESTED:
            return {
                ...state,
                currently_sending_signup_post: true,
                signup_error: null
            }

        case SIGNUP_POST_SUCCESS:
            return {
                ...state,
                currently_sending_signup_post: false,
                signup_error: null,
                signup_return_data: action.payload
            }

        case SIGNUP_POST_ERROR:
            return {
                ...state,
                currently_sending_signup_post: false,
                signup_error: action.payload, // Loading error text
                signup_return_data: null
            }

        default:
            return state

    }

}

import React from 'react'
// import {
// Link
// } from 'react-router-dom'

import AlertBox from 'components/shared/AlertBox/AlertBox'
import DashboardModule from 'components/shared/DashboardModule/DashboardModule'
import AlertRuleFormBody from './AlertRuleFormBody/AlertRuleFormBody'

class AlertRuleForm extends DashboardModule {

    constructor (props) {

        super(props)
        this.renderFullFormBody = this.renderFullFormBody.bind(this)
        this.renderFullFormHeader = this.renderFullFormHeader.bind(this)
        this.renderAlertBox = this.renderAlertBox.bind(this)
        this.organization_id = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    renderFullFormBody () {

        return (

            <AlertRuleFormBody
                alert_rule_title={this.props.alert_rule_title}

                edit_mode={this.props.edit_mode}

                alert_rule_type={this.props.alert_rule_type}
                alert_rule_details={this.props.alert_rule_details}
                alert_rule_frequency={this.props.alert_rule_frequency}

                onAlertRuleFormSubmit={this.props.onAlertRuleFormSubmit}

                onAlertRuleDeleteSubmit={this.props.onAlertRuleDeleteSubmit}

                {...this.props}
            />

        )

    }

    renderFullFormHeader () {

        return (
            <span>Editor</span>
        )

    }


    renderDashboardModule () {

        if (this.props.loading_error) {

            return this.renderCard(
                "Error",
                this.props.loading_error
            )

        } else if (this.props.token_loaded_data_for_verification === null) {

            // console.log("this.props.token_loaded_data_for_verification", this.props.token_loaded_data_for_verification)

            // This is dumb I know, will fix when I have a sec.
            return this.renderCard(
                "\u00a0",
                (
                    <div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                        <div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div>
                    </div>
                )
            )

        }

        return this.renderCard(
            this.renderFullFormHeader(),
            this.renderFullFormBody()
        )

    }

    renderAlertBox () {

        if (this.props.alert_rule_form_submit_error) {

            return (

                <div className="row">

                    <div className="col-sm">

                        <AlertBox
                            error_level="danger"
                            error_title="Error:"
                            error_description={this.props.alert_rule_form_submit_error}
                        />

                    </div>

                </div>

            )

        }

        return (<div />)

    }

    render () {

        return (

            <div>
                <div className="container-fluid dashboard-body-header-container">
                    {this.renderAlertBox()}
                    <div className="row">

                        <div className="col-sm">
                            <h1>{this.props.form_title}</h1>
                        </div>

                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row dashboard-body-row">
                        <div className="col-12">
                            {this.renderDashboardModule()}
                        </div>
                    </div>
                </div>
            </div>

        )

    }


}

export default AlertRuleForm

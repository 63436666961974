import React, { Component } from 'react'

import { Switch } from 'react-router'

import AlertRulesList from './AlertRulesList/AlertRulesList'
import AlertRuleCreator from './AlertRuleCreator/AlertRuleCreator'
import AlertRuleEditor from './AlertRuleEditor/AlertRuleEditor'

import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import './AlertRules.scss'

class AlertRules extends Component {

    render () {

        // console.log("r this.props.login_return_data", this.props.login_return_data)
        // console.log("typeof this.props.login_return_data", typeof this.props.login_return_data)
        return (

            <div className="alert-rules-top-container">
                <Switch>
                    {/*
                    */}
                    <PrivateRoute exact path="/o/:organization_id/alert_rules/:alert_rule_id/edit" component={AlertRuleEditor} />
                    <PrivateRoute exact path="/o/:organization_id/alert_rules/new" component={AlertRuleCreator} />
                    <PrivateRoute exact path="/o/:organization_id/alert_rules" component={AlertRulesList} />
                </Switch>
            </div>

        )

    }

}


export default AlertRules

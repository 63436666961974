import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import {
    Link
} from 'react-router-dom'

import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'
import FancyFormElementSingleSelectDropdown from 'components/shared/FancyFormElementSingleSelectDropdown/FancyFormElementSingleSelectDropdown'
import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
// import AlertBox from 'components/shared/AlertBox/AlertBox'

import { MY_TEAM_ROLE_TYPES } from 'lib/constants'

import './NewAccountCreate.scss'

class NewAccountCreate extends React.Component {

    // constructor (props) {

        // super(props)

        // this.state = {
            // create_team_member_role_step: "SEARCH_EMAIL"
        // }
        // this.setState({ create_team_member_role_step: "CREATE_ACCOUNT" })

    // }

    componentDidUpdate () {

        if (
            this.props.isSubmitting === true &&
            this.props.currently_sending_team_member_create_account_post === true
        ) {

            this.props.setSubmitting(false)

        }

    }

    render () {

        const {

            // dirty, // boolean
            errors, // { [field: string]: string }
            handleBlur, // (e: any) => void
            handleChange, // (e: React.ChangeEvent<any>) => void
            // handleReset, // () => void
            handleSubmit, // (e: React.FormEvent<HTMLFormEvent>) => void
            isSubmitting, // boolean
            // isValid, // boolean
            // isValidating, // boolean
            // resetForm, // (nextValues?: Values) => void
            // setErrors, // (fields: { [field: string]: string }) => void
            // setFieldError, // (field: string, errorMsg: string) => void
            setFieldTouched, // (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
            // submitForm, // () => void
            // submitCount, // number
            setFieldValue, // (field: string, value: any, shouldValidate?: boolean) => void
            // setStatus, // (status?: any) => void
            // setSubmitting, // (isSubmitting: boolean) => void
            // setTouched, // (fields: { [field: string]: boolean }) => void
            // setValues, // (fields: { [field: string]: any }) => void
            // status, // any
            touched, // { [field: string]: boolean }
            values, // { [field: string]: any }
            // validateForm, // (values?: any) => Promise<FormikErrors<Values>>
            // validateField, // (field: string) => void

        } = this.props

        const currently_submitting_form = isSubmitting || this.props.currently_sending_team_member_create_account_post

        let error_message_markup = ""
        if (this.props.team_member_create_account_error) {

            error_message_markup = (
                <div className="my-team-add-create-new-account-form-error-message text-danger">
                    <i className="fa fa-exclamation-circle text-danger mr-1" />{this.props.team_member_create_account_error}
                </div>
            )

        }

        const form_has_errors = Boolean(Object.keys(errors).length) && Boolean(Object.keys(touched).length)

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        const cancel_url = `/o/${organization_id}/settings/my_team`

        let cancel_button_classes = "btn btn-sm btn-secondary"

        let cancel_button = (

            <Link to={cancel_url} disabled={currently_submitting_form} className={cancel_button_classes}>Cancel</Link>

        )

        if (currently_submitting_form) {

            cancel_button_classes = `${cancel_button_classes} pointer-disabled`
            cancel_button = (

                <button type="button" disabled="disabled" className={cancel_button_classes}>Cancel</button>

            )

        }


        let email_not_found_markup = (
            <></>
        )

        let plus_symbol_markup = (
            <i className="fa fa-plus mr-2"></i>
        )

        if (currently_submitting_form) {

            plus_symbol_markup = (<></>)

        }

        return (
            <div className="my-team-create-new-account-form-container">
                <div className="card">
                    <div className="card-header font-weight-bold">
                        Create New Team Member Account
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            {error_message_markup}
                            <div className="form-group">
                                <label>Username</label>
                                <FancyFormElementText
                                    id="team_member_username_field_id"
                                    type="text"
                                    name="team_member_username_field"
                                    placeholder="my_user_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.team_member_username_field}
                                    disabled={currently_submitting_form}
                                    error_message={errors.team_member_username_field}
                                    show_error_message={errors.team_member_username_field && touched.team_member_username_field && errors.team_member_username_field}
                                />
                            </div>
                            <div className="form-group">
                                <label>Email Address</label>
                                <FancyFormElementText
                                    id="team_member_email_address_field_id"
                                    type="text"
                                    name="team_member_email_address_field"
                                    placeholder="name@domain.com"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.team_member_email_address_field}
                                    disabled={currently_submitting_form}
                                    error_message={errors.team_member_email_address_field}
                                    show_error_message={errors.team_member_email_address_field && touched.team_member_email_address_field && errors.team_member_email_address_field}
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <FancyFormElementText
                                    id="team_member_password_form_element"
                                    type="password"
                                    name="team_member_password_field"
                                    placeholder="Password1!"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.team_member__password}
                                    disabled={currently_submitting_form}
                                    error_message={errors.team_member__password}
                                    show_error_message={errors.team_member__password && touched.team_member__password && errors.team_member__password}
                                />
                            </div>
                            <div className="form-group">
                                <label>Confirm Password</label>
                                <FancyFormElementText
                                    id="team_member_password_confirm_form_element"
                                    type="password"
                                    name="team_member_password_confirm_field"
                                    placeholder="Confirm Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.team_member__password_confirm}
                                    disabled={currently_submitting_form}
                                    error_message={errors.team_member__password_confirm}
                                    show_error_message={errors.team_member__password_confirm && touched.team_member__password_confirm && errors.team_member__password_confirm}
                                />
                                <small className="form-text text-muted">
                                    Passwords must be at least 8 characters
                                    long, must contain at least 1 lower case
                                    letter, 1 upper case letter, and 1 numeric character
                                </small>
                            </div>
                            <div className="form-group">
                                <label>New Team Member&apos;s Role</label>
                                <FancyFormElementSingleSelectDropdown
                                    id="team_member_role_type_field_id"
                                    name="team_member_role_type_field"
                                    onChange={(new_value) => {

                                        setFieldValue("team_member_role_type_field", new_value)

                                    }}
                                    onBlur={setFieldTouched}
                                    value={values.team_member_role_type_field}
                                    dropdown_options={MY_TEAM_ROLE_TYPES}
                                    disabled={currently_submitting_form}
                                    error_message={errors.team_member_role_type_field}
                                    show_error_message={errors.team_member_role_type_field && touched.team_member_role_type_field && errors.team_member_role_type_field}
                                />
                            </div>
                            <FancyLoadingButton
                                button_type="success"
                                className="mr-1 btn-sm"
                                button_submit_type="submit"
                                currently_loading={currently_submitting_form}
                                disabled={form_has_errors}
                            >
                                {plus_symbol_markup}Create New User
                            </FancyLoadingButton>
                            {cancel_button}
                            {email_not_found_markup}
                        </form>
                    </div>
                </div>
            </div>

        )

    }

}

export default withFormik({
    validationSchema: Yup.object().shape({

        team_member_username_field: Yup.string()
            .required('Required'),

        team_member_email_address_field: Yup.string()
            .email('Invalid email address')
            .required('Required'),

        team_member_password_field: Yup.string()
            .required('Required')
            .min(8, 'Must be longer than 8 characters')
            .matches(/[a-z]/u, 'Must have at least one lowercase char')
            .matches(/[A-Z]/u, 'Must have at least one uppercase char')
            .matches(/[a-zA-Z]+[^a-zA-Z\s]+/u, 'Must have at least 1 number'),

        team_member_password_confirm_field: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('team_member_password_field'), null], 'Doesn\'t match the first password'),

        team_member_role_type_field: Yup.string()
            .required('Required')

    }),
    mapPropsToValues: (props) => ({

        team_member_username_field: props.user_account_username || "",
        team_member_email_address_field: props.user_account_email_address || "",
        team_member_role_type_field: props.organization_role_type || "READ_ONLY",
        team_member_password: props.user_account_password || "",

        currently_sending_team_member_create_account_post: props.currently_sending_team_member_create_account_post || false,
        team_member_create_account_error: props.team_member_create_account_error || false,
        team_member_create_account_successfully_posted: props.team_member_create_account_successfully_posted || false,

        handleOnTeamMemberCreateAccount: props.handleOnTeamMemberCreateAccount || null,

    }),
    handleSubmit: (payload, { props }) => {

        props.handleOnTeamMemberCreateAccount(
            payload["team_member_email_address_field"],
            payload["team_member_username_field"],
            payload["team_member_password_field"],
            payload["team_member_role_type_field"]
        )

    },
    displayName: 'CreateNewAccountForTeamMemberRole',
    // enableReinitialize: true,
    isInitialValid: false
})(NewAccountCreate)



import React from 'react'
// import {
    // Link
// } from 'react-router-dom'

// import * as moment from 'moment'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import DashboardMapModule from 'components/shared/DashboardMapModule/DashboardMapModule'

import "./DatacenterMapModule.css"

class DatacenterMapModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.generateMarkerArray = this.generateMarkerArray.bind(this)
        this.organization_id = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    generateMarkerArray () {

        if (
            this.props.datacenters_data === null ||
            this.props.all_execution_nodes_data === null
        ) {

            return []

        }

        const marker_data = this.props.report_data["data"].map((execution_node) => {

            const success_percent = ((execution_node["organization_canary_test_execution_result_success_count"] / execution_node["organization_canary_test_execution_result_total_count"]) * 100).toFixed(2)

            let success_rate_class = "text-success"

            if (success_percent < 80) {

                success_rate_class = "text-danger"

            } else if (success_percent < 97) {

                success_rate_class = "text-warning"

            }

            // console.log(execution_node)
            return {
                "lat": execution_node["execution_node_datacenter_lat"],
                "long": execution_node["execution_node_datacenter_long"],
                "key": execution_node["execution_node_id"],
                "title": (
                    <div>
                        <div className="font-weight-bold">
                            {execution_node["execution_node_datacenter_name"]} - {execution_node["execution_node_datacenter_cloud_provider_name"]}
                        </div>
                        <div className="text-center pt-2 pb-1">
                            Test Success Rate: <span className={success_rate_class}>{success_percent}%</span>
                        </div>
                    </div>
                ),
                "icon_type": "ACTIVE_DATACENTER"
            }

        })

        // console.log("marker_data", marker_data)
        // return []
        return marker_data

    }

    render () {

        if (!this.props.report_data || this.props.report_data["_meta"].currently_fetching) {

            return this.renderCard(
                "Your Active Datacenters",
                (
                    <p className="m-4"></p>
                )
            )

        }

        if (this.props.report_data["_meta"].fetch_error) {

            return this.renderCard(
                "Your Active Datacenters",
                (
                    <p className="m-4">There was a problem fetching data</p>
                )
            )

        }

        return this.renderCard(
            "Your Active Datacenters",
            (
                <DashboardMapModule
                    markers={this.generateMarkerArray()}
                    is_currently_loading={this.props.is_currently_loading}
                    loading_error={this.props.loading_error}
                />
            )
        )

        // return this.renderRawCard(
            // <DashboardMapModule
                // markers={this.generateMarkerArray()}
                // is_currently_loading={this.props.is_currently_loading}
                // loading_error={this.props.loading_error}
            // />
        // )

    }

}

export default DatacenterMapModule


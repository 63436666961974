import React, { Component } from 'react'

import { Switch } from 'react-router'

import ExecutionNodesList from './ExecutionNodesList/ExecutionNodesList'
// import AlertRuleEditor from './AlertRuleEditor/AlertRuleEditor'
// import AlertRuleCreator from './AlertRuleCreator/AlertRuleCreator'
// import AlertRuleEditor from './AlertRuleEditor/AlertRuleEditor'

import PrivateRoute from 'components/shared/PrivateRoute/PrivateRoute'

import './ExecutionNodes.scss'

class ExecutionNodes extends Component {

    // Return the completely assembled component
    render () {

        // console.log("r this.props.login_return_data", this.props.login_return_data)
        // console.log("typeof this.props.login_return_data", typeof this.props.login_return_data)
        return (

            <div className="execution-nodes-top-container">
                <Switch>
                    {/*
                    <PrivateRoute exact path="/o/:organization_id/data_centers/:execution_node_id/edit" component={AlertRuleEditor} />
                    <PrivateRoute exact path="/o/:organization_id/data_centers/new" component={AlertRuleCreator} />
                    */}
                    <PrivateRoute exact path="/o/:organization_id/data_centers" component={ExecutionNodesList} />
                </Switch>
            </div>

        )

    }

}


export default ExecutionNodes

import React, { Component } from 'react'
import {
    Link
} from 'react-router-dom'
import './FancyCancelButton.css'


class FancyCancelButton extends Component {

    constructor (props) {

        super(props)
        this.renderDisabledButton = this.renderDisabledButton.bind(this)

    }

    renderDisabledButton () {

        let { className } = this.props

        if (!className) {

            className = ""

        }

        const full_button_classes = `btn mr-2 btn-secondary fancy-button-disabled ${className}`

        return (
            <button type="button" className={full_button_classes} disabled>
                {this.props.children}
            </button>
        )

    }

    // Return the completely assembled component
    render () {

        const { disabled } = this.props
        let { button_type, className } = this.props

        if (!className) {

            className = ""

        }

        if (!button_type) {

            button_type = "light"

        }


        if (disabled) {

            return this.renderDisabledButton()

        }

        const full_button_classes = `btn mr-2 btn-${button_type} fancy-button-clickable ${className}`

        return (
            <Link to={this.props.cancel_url}>
                <button type="button" className={full_button_classes}>
                    {this.props.children}
                </button>
            </Link>
        )

    }

}


export default FancyCancelButton

// import $ from 'jquery'
import React, { Component } from 'react'
import {
    Link
} from 'react-router-dom'

import FancyFormElementMultiSelectDropdown from 'components/shared/FancyFormElementMultiSelectDropdown/FancyFormElementMultiSelectDropdown'
// import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'


class CanaryTestExecutionNodesField extends Component {

    constructor (props) {

        super(props)
        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnBlur = this.handleOnBlur.bind(this)

    }

    handleOnBlur () {

        this.props.onBlur(this.props.name)

    }

    handleOnChange (new_value) {

        // console.log("CanaryTestExecutionNodesField handleOnChange", new_value)
        this.props.onChange(this.props.name, new_value)

    }

    // Return the completely assembled component
    render () {

        if (
            this.props.all_execution_nodes_data === null ||
            this.props.datacenters_data === null
        ) {

            return (<div />)

        }

        // console.log("CANARY_TEST_SCHEDULE_TYPES", CANARY_TEST_SCHEDULE_TYPES)
        // console.log("all_execution_nodes_data", this.props.all_execution_nodes_data)

        const dropdown_options = this.props.all_execution_nodes_data.map((execution_node) => {

            const data_center = this.props.datacenters_data["EXECUTION_NODE_DATACENTERS"][execution_node["execution_node_datacenter"]]
            const cloud_provider = data_center["cloud_provider"]
            const cloud_provider_name = this.props.datacenters_data["CLOUD_PROVIDERS"][cloud_provider]["name"]
            const data_center_name = data_center["name"]

            return {
                "value": execution_node["execution_node_id"],
                "label": `${cloud_provider_name} - ${data_center_name}`
            }

        })

        // console.dir("dropdown_options", dropdown_options)

        // console.log("value", this.props.value)

        const link_url = `/o/${this.organization_id}/data_centers`

        return (
            <div className="form-row">
                <div className="form-group col-lg-9">
                    <label>Data Centers</label>
                    <FancyFormElementMultiSelectDropdown
                        id={this.props.id}
                        name={this.props.name}
                        onChange={this.handleOnChange}
                        value={this.props.value}
                        dropdown_options={dropdown_options}
                        onBlur={this.handleOnBlur}
                        disabled={this.props.disabled}
                        error_message={this.props.error_message}
                        show_error_message={this.props.show_error_message}
                    />

                    {/*
                    <pre>{JSON.stringify(this.props, null, 4)}</pre>
                    */}

                </div>
                <div className="form-group col-lg-3">
                    <label className="d-none d-lg-block">&nbsp;</label>
                    <div className="form-info-bubble">
                        <i className="fa fa-lightbulb-o" />
                        <p>
                            Canary tests are executed in one of the many data centers we support across the globe.
                        </p>
                        <p>
                            Enable new ones in the <Link to={link_url}>Data Centers</Link> tab.
                        </p>
                    </div>
                </div>
            </div>
        )

    }

}


export default CanaryTestExecutionNodesField


import React from 'react'

import FancyFormElement from 'components/shared/FancyFormElement/FancyFormElement'

import './FancyFormElementText.css'


class FancyFormElementText extends FancyFormElement {

    renderFormElement () {

        let field_class_string = `form-control border`
        if (this.props.show_error_message) {

            field_class_string = `${field_class_string} border-danger`

        }

        let disabled_flag = this.props.disabled
        let placeholder_text = this.props.placeholder
        let value_text = this.props.value

        if (this.props.currently_loading) {

            value_text = ""
            placeholder_text = ""
            disabled_flag = true

        }

        if (disabled_flag) {

            field_class_string = `${field_class_string} fancy-text-input-disabled`

        }

        // console.log(this.props.show_error_message)

        return (
            <input
                value={value_text}
                type={this.props.type}
                className={field_class_string}
                id={this.props.id}
                name={this.props.name}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                placeholder={placeholder_text}
                disabled={disabled_flag}
            />
        )

    }

}

export default FancyFormElementText

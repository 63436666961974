import React from 'react'
import {
    Link
} from 'react-router-dom'

import * as moment from 'moment'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import "./LatestUpdatesModule.scss"

class LatestUpdatesModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.generateArticleArray = this.generateArticleArray.bind(this)
        this.organization_id = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    generateArticleArray () {

        if (
            this.props.datacenters_data === null ||
            this.props.all_execution_nodes_data === null
        ) {

            return []

        }

        // console.log("START")
        const markup_data = this.props.report_data["data"].map((execution_result) => {

            // console.log("i", i)
            // console.log("ER", execution_result)

            const detail_url = `/o/${this.organization_id}/canary_tests/d/${execution_result["canary_test_id"]}/r/${execution_result["canary_test_execution_result_id"]}/`
            // {execution_result["canary_test_execution_result_failure_message"]}

            return (

                <li key={execution_result["canary_test_execution_result_id"]} className="list-group-item">
                    <Link to={detail_url}>

                        <dl className="d-flex">
                            <dt className="flex-grow-1">{execution_result["canary_test_title"]}</dt>
                            <dd className="failure-badge">
                                <span className="badge badge-pill badge-danger px-2">Failed</span>
                            </dd>
                            <dd className="failure-time">
                                {moment(execution_result["canary_test_execution_result_date_created"]).fromNow()}
                            </dd>
                        </dl>

                    </Link>
                </li>

            )

        })
        // console.log("END")

        // console.log("markup", markup_data)
        // return []
        return markup_data

    }

    render () {

                    // <li className="nav-item">
                        // <a className="nav-link text-white" href="#">Recent Gallery Additions</a>
                    // </li>
        const utm_parameters = "utm_source=dashboard&utm_medium=web&utm_campaign=internal"
        const blog1_url = `https://www.apicanary.com/blog/motivating-engineering-teams/?${utm_parameters}`
        const blog2_url = `https://www.apicanary.com/blog/building-a-software-engineering-team-from-scratch/?${utm_parameters}`

        return this.renderCard(
            (
                <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                        <a className="nav-link active" href="https://www.apicanary.com/blog" rel="noreferrer noopener" target="_BLANK">Latest API Canary News</a>
                    </li>
                </ul>
            ),
            (
                <div className="latest-updates-module-body">

                    <a className="media mt-4" href={blog1_url} target="_BLANK" rel="noopener noreferrer">
                        <img className="mr-3" src="https://www.apicanary.com/images/blog/2019-01-08-motivating-engineering-teams/header.png" alt="Motivating Engineering Teams" />
                        <div className="media-body">
                            <h5 className="mt-0">Motivating Engineering Teams</h5>
                            It’s hard to overemphasize just how important it is to have committed, enthusiastic team willing to put it all on the line, day in and day out...
                        </div>
                    </a>

                    <a className="media mt-4" href={blog2_url} target="_BLANK" rel="noopener noreferrer">
                        <img className="mr-3" src="https://www.apicanary.com/images/blog/2019-01-08-building-a-software-engineering-team-from-scratch/header.png" alt="Building a Software Engineering Team from Scratch" />
                        <div className="media-body">
                            <h5 className="mt-0">Building a Software Engineering Team from Scratch</h5>
                             We live in an age of technology and software giants, some of which have reached unfathomable levels of wealth and value...
                        </div>
                    </a>

                </div>
            )
        )

    }

}

export default LatestUpdatesModule


// import $ from 'jquery'
import React, { Component } from 'react'

import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
import ExecutionLog from 'components/shared/ExecutionLog/ExecutionLog'

import './ActionBar.scss'

class ActionBar extends Component {

    constructor (props) {

        super(props)
        this.renderExecutionLog = this.renderExecutionLog.bind(this)
        this.renderExecutionNodeName = this.renderExecutionNodeName.bind(this)
        this.manualRunRequest = this.manualRunRequest.bind(this)

        this.state = {
            error_flag: false,
            error_message: ""
        }

    }

    manualRunRequest () {

        // console.log("hit", this.props.selected_execution_nodes)
        if (this.props.selected_execution_nodes.length < 1) {

            this.setState({
                error_flag: true,
                error_message: "You must pick at least one data center (below) before manually running your test"
            })
            return

        }

        this.setState({
            error_flag: false,
            error_message: ""
        })
        this.props.onCanaryTestManualRunRequest()

    }

    renderExecutionNodeName () {

        // console.dir("debug", this.props.all_execution_nodes_data)

        const filtered_execution_node = this.props.all_execution_nodes_data.filter((execution_node) => {

            return (execution_node["execution_node_id"] === this.props.canary_test_manually_run_execution_node_id)

        })

        const data_center = this.props.datacenters_data["EXECUTION_NODE_DATACENTERS"][filtered_execution_node[0]["execution_node_datacenter"]]

        const cloud_provider = data_center["cloud_provider"]
        const cloud_provider_name = this.props.datacenters_data["CLOUD_PROVIDERS"][cloud_provider]["name"]
        const data_center_name = data_center["name"]

        return `${cloud_provider_name} - ${data_center_name}`

    }

    renderExecutionLog () {

        let log_lines = []
        let start_execution_timestamp = null

        if (this.state.error_flag === true) {

            log_lines.push(
                {
                    "type": "FATAL_ERROR",
                    "log_line": this.state.error_message,
                    "timestamp": null
                }
            )

        } else if (this.props.canary_test_manually_run_error) {

            log_lines.push(
                {
                    "type": "FATAL_ERROR",
                    "log_line": this.props.canary_test_manually_run_error,
                    "timestamp": null
                }
            )

        } else if (this.props.currently_sending_canary_test_manually_run_post) {

            log_lines.push(
                {
                    "type": "LOADING",
                    "log_line": `Executing test - ${this.renderExecutionNodeName()}`,
                    "timestamp": null
                }
            )

        } else if (this.props.canary_test_manually_run_return_data !== null) {

            start_execution_timestamp = this.props.canary_test_manually_run_return_data.start_execution_timestamp

            log_lines.push(
                {
                    "type": "LOADED",
                    "log_line": `Executing test - ${this.renderExecutionNodeName()}`,
                    "timestamp": null
                }
            )

            log_lines = log_lines.concat(this.props.canary_test_manually_run_return_data.log_lines)

        }

        // console.log(log_lines)

        if (log_lines.length > 0) {

            let clear_button = (<div />)

            if (log_lines.length > 1) {

                clear_button = (
                    <button className="btn btn-outline-dark btn-sm" type="button" onClick={this.props.onCanaryTestManualRunReset}>
                        Clear
                    </button>
                )

            }

            return (
                <div className="execution-log-action-bar-container">
                    <ExecutionLog
                        start_execution_timestamp={start_execution_timestamp}
                        log_lines={log_lines}

                        /*
                            We arent using these right now, but maybe we will...
                            end_execution_timestamp={this.props.canary_test_manually_run_return_data.end_execution_timestamp}
                            error_text={this.props.canary_test_manually_run_return_data.error_text}
                            status_flag={this.props.canary_test_manually_run_return_data.status_flag}
                            success_flag={this.props.canary_test_manually_run_return_data.success_flag}
                            success_value={this.props.canary_test_manually_run_return_data.success_value}
                        */
                    />
                    <div className="execution-log-action-bar-clear-button-container">
                        {clear_button}
                    </div>
                </div>
            )

        }

        return (
            <div />
        )

    }

    // Return the completely assembled component
    render () {

        return (
            <div className="code-editor-action-bar">

                <div className="button-box">

                    <FancyLoadingButton
                        button_type="outline-dark"
                        button_loading_type="outline-dark"
                        button_disabled_type="outline-secondary"
                        button_submit_type="button"
                        className="btn-sm"
                        disabled={this.props.disabled || this.props.currently_sending_canary_test_manually_run_post}
                        onClick={this.manualRunRequest}
                    >
                        Manually Run
                    </FancyLoadingButton>

                </div>

                <div className="xborder xborder-danger">
                    {this.renderExecutionLog()}
                </div>

            </div>
        )

    }

}


export default ActionBar




// Exporting constants

// Canary Test Schedule types
export const NEVER = "NEVER"
export const EVERY_MINUTE = "EVERY_MINUTE"
export const EVERY_FIVE_MINUTES = "EVERY_FIVE_MINUTES"
export const EVERY_HOUR = "EVERY_HOUR"
export const EVERY_DAY = "EVERY_DAY"

// Canary Test Schedule types
export const ONCE_PER_DAY = "ONCE_PER_DAY"
export const ONCE_PER_HOUR = "ONCE_PER_HOUR"
export const ONCE_PER_FAILURE = "ONCE_PER_FAILURE"

// Dashboard Durations
export const DAY = "DAY"
export const WEEK = "WEEK"
export const MONTH = "MONTH"
export const NINETY_DAYS = "NINETY_DAYS"

// Team Member Role Types
export const OWNER = "OWNER"
export const ADMIN = "ADMIN"
export const READ_ONLY = "READ_ONLY"

// Alert Rule Webhook Method Types
export const GET = "GET"
export const POST = "POST"

export const PRETTY_TEXT_STRINGS = {

    // Canary Test Schedule types
    NEVER: "Never",
    EVERY_MINUTE: "Every Minute",
    EVERY_FIVE_MINUTES: "Every Five Minutes",
    EVERY_HOUR: "Every Hour",
    EVERY_DAY: "Every Day",

    // Alert rules schedule types
    ONCE_PER_DAY: "Once Per Day",
    ONCE_PER_HOUR: "Once Per Hour",
    ONCE_PER_FAILURE: "Once Per Test Failure",

    // Alert rule types
    SMS: "SMS",
    EMAIL: "Email",
    WEBHOOK: "Webhook",

    // Execution Node Status
    ASSIGNED: "Assigned",
    BUILDING: "Building",
    BUILD_FAILED: "Build Failed",
    FAILING_HEALTH_CHECK: "Failed Health Check",

    // Dashboard Durations
    DAY: "24 Hours",
    WEEK: "Week",
    MONTH: "Month",
    NINETY_DAYS: "90 Days",

    // Team Member Role Types
    OWNER: "Owner",
    ADMIN: "Admin",
    READ_ONLY: "Read Only",

    // Alert Rule Webhook Method Types
    GET: "GET",
    POST: "POST"
}

export const DASHBOARD_TIME_DURATION_TYPES = [
    {
        "value": DAY,
        "label": PRETTY_TEXT_STRINGS[DAY]
    },
    {
        "value": WEEK,
        "label": PRETTY_TEXT_STRINGS[WEEK]
    },
    {
        "value": MONTH,
        "label": PRETTY_TEXT_STRINGS[MONTH]
    },
    {
        "value": NINETY_DAYS,
        "label": PRETTY_TEXT_STRINGS[NINETY_DAYS]
    },
]

export const ALERT_RULE_SCHEDULE_TYPES = [
    {
        "value": ONCE_PER_FAILURE,
        "label": PRETTY_TEXT_STRINGS[ONCE_PER_FAILURE]
    },
    {
        "value": ONCE_PER_HOUR,
        "label": PRETTY_TEXT_STRINGS[ONCE_PER_HOUR]
    },
    {
        "value": ONCE_PER_DAY,
        "label": PRETTY_TEXT_STRINGS[ONCE_PER_DAY]
    }
]

export const CANARY_TEST_SCHEDULE_TYPES = [
    {
        "value": EVERY_MINUTE,
        "label": PRETTY_TEXT_STRINGS[EVERY_MINUTE]
    },
    {
        "value": EVERY_FIVE_MINUTES,
        "label": PRETTY_TEXT_STRINGS[EVERY_FIVE_MINUTES]
    },
    {
        "value": EVERY_HOUR,
        "label": PRETTY_TEXT_STRINGS[EVERY_HOUR]
    },
    {
        "value": EVERY_DAY,
        "label": PRETTY_TEXT_STRINGS[EVERY_DAY]
    },
    {
        "value": NEVER,
        "label": PRETTY_TEXT_STRINGS[NEVER]
    }
]

export const MY_TEAM_ROLE_TYPES = [
    {
        "value": ADMIN,
        "label": PRETTY_TEXT_STRINGS[ADMIN]
    },
    {
        "value": READ_ONLY,
        "label": PRETTY_TEXT_STRINGS[READ_ONLY]
    }
]

export const ALERT_RULE_WEBHOOK_METHOD_TYPES = [
    {
        "value": GET,
        "label": PRETTY_TEXT_STRINGS[GET]
    },
    {
        "value": POST,
        "label": PRETTY_TEXT_STRINGS[POST]
    }
]

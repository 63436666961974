import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import {
    Link
} from 'react-router-dom'

// import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'
import FancyFormElementSingleSelectDropdown from 'components/shared/FancyFormElementSingleSelectDropdown/FancyFormElementSingleSelectDropdown'
import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
// import AlertBox from 'components/shared/AlertBox/AlertBox'

import { MY_TEAM_ROLE_TYPES } from 'lib/constants'

import './MyTeamListItemEditor.scss'

class RawMyTeamListItemEditor extends React.Component {

    componentDidUpdate () {

        if (
            this.props.isSubmitting === true &&
            this.props.currently_sending_post === true
        ) {

            this.props.setSubmitting(false)

        }

    }

    render () {

        const {

            // dirty, // boolean
            errors, // { [field: string]: string }
            // handleBlur, // (e: any) => void
            // handleChange, // (e: React.ChangeEvent<any>) => void
            // handleReset, // () => void
            handleSubmit, // (e: React.FormEvent<HTMLFormEvent>) => void
            isSubmitting, // boolean
            // isValid, // boolean
            // isValidating, // boolean
            // resetForm, // (nextValues?: Values) => void
            // setErrors, // (fields: { [field: string]: string }) => void
            // setFieldError, // (field: string, errorMsg: string) => void
            setFieldTouched, // (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
            // submitForm, // () => void
            // submitCount, // number
            setFieldValue, // (field: string, value: any, shouldValidate?: boolean) => void
            // setStatus, // (status?: any) => void
            // setSubmitting, // (isSubmitting: boolean) => void
            // setTouched, // (fields: { [field: string]: boolean }) => void
            // setValues, // (fields: { [field: string]: any }) => void
            // status, // any
            touched, // { [field: string]: boolean }
            values, // { [field: string]: any }
            // validateForm, // (values?: any) => Promise<FormikErrors<Values>>
            // validateField, // (field: string) => void

        } = this.props

        const currently_submitting_form = isSubmitting || this.props.currently_sending_post

        let error_message_markup = ""
        if (this.props.team_member_post_error) {

            error_message_markup = (
                <div className="my-team-list-item-edit-error-message text-danger">
                    {this.props.team_member_post_error}
                </div>
            )

        }

        const form_has_errors = Boolean(Object.keys(errors).length) && Boolean(Object.keys(touched).length)

        let cancel_button_classes = "btn btn-sm btn-secondary"

        const cancel_button_url = `/o/${window.ac_session.current_organization.organization_id}/settings/my_team`

        let cancel_button = (

            <Link to={cancel_button_url} disabled={currently_submitting_form} className={cancel_button_classes}>Cancel</Link>

        )

        if (currently_submitting_form) {

            cancel_button_classes = `${cancel_button_classes} pointer-disabled`
            cancel_button = (

                <button type="button" disabled="disabled" className={cancel_button_classes}>Cancel</button>

            )

        }

        return (

            <form onSubmit={handleSubmit} className="row my-team-list-item-edit-row" key="creator">
                {error_message_markup}
                <div className="col-4 col-xl-3 my-team-list-item-edit-key-col xbg-primary">
                    <div className="placeholder">
                        <i className="fa fa-user-o" /> {this.props.user_account_username}
                    </div>
                </div>
                <div className="col-xl-3 d-none d-xl-block my-team-list-item-edit-key-col xbg-secondary">
                    <div className="placeholder">
                        {values.team_member_email_address_field}
                    </div>
                </div>
                <div className="col-4 col-xl-3 my-team-list-item-edit-key-value-col xbg-success">
                    <FancyFormElementSingleSelectDropdown
                        id="team_member_role_type_field_id"
                        name="team_member_role_type_field"
                        onChange={(new_value) => {

                            setFieldValue("team_member_role_type_field", new_value)

                        }}
                        onBlur={setFieldTouched}
                        value={values.team_member_role_type_field}
                        dropdown_options={MY_TEAM_ROLE_TYPES}
                        disabled={currently_submitting_form}
                        error_message={errors.team_member_role_type_field}
                        show_error_message={errors.team_member_role_type_field && touched.team_member_role_type_field && errors.team_member_role_type_field}
                    />
                </div>
                <div className="col-4 col-xl-3 text-right my-team-list-item-edit-action-col xbg-danger">
                    <FancyLoadingButton
                        button_type="primary"
                        className="mr-1 btn-sm"
                        button_submit_type="submit"
                        currently_loading={currently_submitting_form}
                        disabled={form_has_errors}
                    >
                        Save
                    </FancyLoadingButton>
                    {cancel_button}
                </div>
            </form>

        )

    }
    // <pre>{JSON.stringify(this.props.values, null, 4)}</pre>

}

export default withFormik({
    validationSchema: Yup.object().shape({

        team_member_role_type_field: Yup.string()
            .required('Required')

    }),
    mapPropsToValues: (props) => ({

        team_member_user_account_id: props.user_account_id,
        team_member_email_address_field: props.user_account_email_address,
        team_member_role_type_field: props.organization_role_type,

        currently_sending_post: props.currently_sending_post || false,
        team_member_post_error: props.team_member_post_error || false,
        team_member_post_successfully_posted: props.team_member_post_successfully_posted || false,

        onTeamMemberFormSubmit: props.onTeamMemberFormSubmit || null,

    }),
    handleSubmit: (payload, { props }) => {

        // console.log("handle submit hit", props.vault_key)
        props.onTeamMemberFormSubmit(payload["team_member_user_account_id"], payload["team_member_role_type_field"])

    },
    displayName: 'CreateTeamMemberRole',
    // enableReinitialize: true,
    isInitialValid: false
})(RawMyTeamListItemEditor)

// This file is included by modules/all_modules.js (For the reducers)
// This file is also included by stuff (For the actions)

import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'

// Initial Module State
const initialState = {

    // Flags
    currently_fetching_canary_test: false,
    canary_test_fetch_error: null,

    // Canary Test Identifiers
    organization_id: null,
    canary_test_id: null,
    canary_test_date_created: null,

    // Canary Test Fields
    canary_test_status_flag: null,
    canary_test_title: null,
    canary_test_language: null,
    canary_test_code: null,
    canary_test_schedule_type: null,
    canary_test_execution_nodes: null,
    alert_rules: null,
    canary_test_fire_alert_rules_on_timeout: null,

}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const CANARY_TEST_RESET = 'canary_test/CANARY_TEST_RESET'
export const CANARY_TEST_FETCH_REQUESTED = 'canary_test/CANARY_TEST_FETCH_REQUESTED'
export const CANARY_TEST_FETCH_SUCCESS = 'canary_test/CANARY_TEST_FETCH_SUCCESS'
export const CANARY_TEST_FETCH_ERROR = 'canary_test/CANARY_TEST_FETCH_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const canaryTestReset = () => {

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_RESET
        })

    }

}

export const canaryTestFetch = (organization_id, canary_test_id) => {

    return (dispatch) => {

        dispatch({
            type: CANARY_TEST_RESET
        })

        dispatch({
            type: CANARY_TEST_FETCH_REQUESTED
        })

        const auth_token = auth.get("auth_token")

        // console.log("canaryTestFetch called")

        return axios({
            method: 'get',
            url: `//${config["api_hostname"]}/v1/o/${organization_id}/canary_tests/${canary_test_id}`,
            headers: {
                Authorization: `Token ${auth_token}`
            }
        })

            .then((response) => {

                const canary_test_data = response.data

                // console.log("canary tests fetch response", response)

                // console.log("calling canary test fetch success reducer", response.data)

                dispatch({
                    type: CANARY_TEST_FETCH_SUCCESS,
                    payload: canary_test_data
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: CANARY_TEST_FETCH_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case CANARY_TEST_RESET: {

            return {
                ...state,

                // Flags
                currently_fetching_canary_test: false,
                canary_test_fetch_error: null,

                // Canary Test Identifiers
                organization_id: null,
                canary_test_id: null,
                canary_test_date_created: null,

                // Canary Test Fields
                canary_test_status_flag: null,
                canary_test_title: null,
                canary_test_language: null,
                canary_test_code: null,
                canary_test_schedule_type: null,
                canary_test_execution_nodes: null,
                alert_rules: null,
                canary_test_fire_alert_rules_on_timeout: null,

            }

        }

        case CANARY_TEST_FETCH_REQUESTED:
            return {
                ...state,

                // Flags
                currently_fetching_canary_test: true,
                canary_test_fetch_error: null,

                // Canary Test Identifiers
                organization_id: null,
                canary_test_id: null,
                canary_test_date_created: null,

                // Canary Test Fields
                canary_test_status_flag: null,
                canary_test_title: null,
                canary_test_language: null,
                canary_test_code: null,
                canary_test_schedule_type: null,
                canary_test_execution_nodes: null,
                alert_rules: null,
                canary_test_fire_alert_rules_on_timeout: null,

            }

        case CANARY_TEST_FETCH_SUCCESS: {

            // console.log("canary test fetch success", action.payload)
            const canary_test = action.payload.data[0]
            // console.log("!!!canary test")
            // console.dir(canary_test)

            return {
                ...state,

                // Flags
                currently_fetching_canary_test: false,
                canary_test_fetch_error: null,

                // Canary Test Identifiers
                organization_id: canary_test["organization_id"],
                canary_test_id: canary_test["canary_test_id"],
                canary_test_date_created: canary_test["canary_test_date_created"],

                // Canary Test Fields
                canary_test_status_flag: canary_test["canary_test_status_flag"],
                canary_test_title: canary_test["canary_test_title"],
                canary_test_language: canary_test["canary_test_language"],
                canary_test_code: canary_test["canary_test_code"],
                canary_test_schedule_type: canary_test["canary_test_schedule"][0]["canary_test_schedule_type"],
                canary_test_execution_nodes: canary_test["canary_test_execution_nodes"],
                alert_rules: canary_test["alert_rules"],
                canary_test_fire_alert_rules_on_timeout: canary_test["canary_test_fire_alert_rules_on_timeout"]
            }

        }

        case CANARY_TEST_FETCH_ERROR:
            return {
                ...state,

                // Flags
                currently_fetching_canary_test: false,
                canary_test_fetch_error: action.payload, // Loading error text

                // Canary Test Identifiers
                organization_id: null,
                canary_test_id: null,
                canary_test_date_created: null,

                // Canary Test Fields
                canary_test_status_flag: null,
                canary_test_title: null,
                canary_test_language: null,
                canary_test_code: null,
                canary_test_schedule_type: null,
                canary_test_execution_nodes: null,
                canary_test_fire_alert_rules_on_timeout: null

            }

        default:
            return state

    }

}

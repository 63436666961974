import React from 'react'
import {
    Link
} from 'react-router-dom'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

// import { PRETTY_TEXT_STRINGS } from 'lib/constants'

import VariableVaultListItem from './VariableVaultListItem/VariableVaultListItem'

import './VariableVaultListBody.scss'

class VariableVaultListBody extends DashboardModule {

    constructor (props) {

        super(props)
        this.getListItem = this.getListItem.bind(this)
        this.handleItemClick = this.handleItemClick.bind(this)

        this.organization_id = false
        this.show_outer_border = false

        this.state = {
            open_vault_form_key: null
        }

    }

    componentWillMount () {

        const { match: { params } } = this.props
        this.organization_id = params.organization_id

    }

    handleItemClick (e) {

        console.log("item clicked")
        // this.setState({ open_vault_form_key: xxx })

    }

    getListItem (mode, vault_key, vault_key_value) {

        return (
            <VariableVaultListItem
                key={vault_key}
                mode={mode}

                /* Fetch */
                vault_key={vault_key}
                vault_key_value={vault_key_value}

                /* Create */
                onVaultKeyCreate={this.props.handleOnVaultKeyCreate}
                currently_sending_variable_vault_key_create_post={this.props.currently_sending_variable_vault_key_create_post}
                variable_vault_key_create_error={this.props.variable_vault_key_create_error}
                variable_vault_key_create_return_data={this.props.variable_vault_key_create_return_data}
                variable_vault_key_create_successfully_posted={this.props.variable_vault_key_create_successfully_posted}

                /* Update */
                onVaultKeyUpdate={this.props.handleOnVaultKeyUpdate}
                currently_sending_variable_vault_key_update_post={this.props.currently_sending_variable_vault_key_update_post}
                variable_vault_key_update_error={this.props.variable_vault_key_update_error}
                variable_vault_key_update_return_data={this.props.variable_vault_key_update_return_data}
                variable_vault_key_update_successfully_posted={this.props.variable_vault_key_update_successfully_posted}

                /* Delete */
                onVaultKeyDelete={this.props.handleOnVaultKeyDelete}
                currently_sending_variable_vault_key_delete_post={this.props.currently_sending_variable_vault_key_delete_post}
                variable_vault_key_delete_error={this.props.variable_vault_key_delete_error}
                variable_vault_key_delete_return_data={this.props.variable_vault_key_delete_return_data}
                variable_vault_key_delete_successfully_posted={this.props.variable_vault_key_delete_successfully_posted}

                {...this.props}
            />
        )

    }

    render () {

        let vault_rows = []
        // let empty_vault_message = ""

        if (this.props.loading_error) {

            // Loading error

            vault_rows.push(
                <div key="loading_error">
                    We&apos;re having a problem pulling your data.
                    Please <a href="/">refresh</a> and try again.
                    <div className="mt-5">
                        {this.props.loading_error}
                    </div>
                </div>
            )

        } else if (
            this.props.loaded_data === null
        ) {

            // Still Loading

            vault_rows.push(
                <div key="loading">
                    Loading...
                </div>
            )

        } else if (this.props.loaded_data.length < 1) {

            // Empty variable vault

            // empty_vault_message = (
                // <h1 className="pt-4 pl-4 pr-4 text-center">Add your first vault variable</h1>
            // )

            vault_rows.push(
                this.getListItem("creator", null, null)
            )

        } else {

            // Variable vault has data

            const { match: { params } } = this.props
            const vault_key = params.vault_key

            vault_rows = this.props.loaded_data.map((vault_item) => {

                if (vault_item["key"] === vault_key) {

                    return this.getListItem("editor", vault_item["key"], vault_item["value"])

                }

                return this.getListItem("view", vault_item["key"], vault_item["value"])

            })

            // console.log("vault_key", vault_key)

            if (vault_key === "add_new") {

                vault_rows.push(
                    this.getListItem("add_new", null, null)
                )

            } else {

                const add_another_link_url = `/o/${this.organization_id}/variable_vault/add_new`
                vault_rows.push(
                    <Link key="add_another_link" to={add_another_link_url} type="button" className="btn btn-light mt-4" >
                        <i className="fa fa-plus" aria-hidden="true" /> Add another vault variable
                    </Link>
                )

            }

        }

        return this.renderRawCard(
            <div className="p-4">
                {/* empty_vault_message */}
                <div className="p-0">
                    <div className="row variable-vault-list-item-title-row">
                        <div className="col-3 variable-vault-list-item-title-key-col">
                            Vault&nbsp;Key
                        </div>
                        <div className="col-9 variable-vault-list-item-title-key-value-col">
                            Value
                        </div>
                    </div>
                    <div>{vault_rows}</div>
                </div>
            </div>
        )

    }

}

export default VariableVaultListBody

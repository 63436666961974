import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    accountDataFetch
} from 'modules/user_account_module'

import {
    userAccountEmailAddressUpdatePost,
    userAccountEmailAddressUpdateReset
} from 'modules/user_account_email_address_update_module'

import {
    userAccountPasswordUpdatePost,
    userAccountPasswordUpdateReset
} from 'modules/user_account_password_update_module'

// import FancyTextInputField from 'components/shared/FancyTextInputField/FancyTextInputField'
// import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
// import AlertBox from 'components/shared/AlertBox/AlertBox'

import ChangeEmailForm from './ChangeEmailForm/ChangeEmailForm'
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm'

import './AccountSettings.css'

class AccountSettings extends Component {

    constructor (props) {

        super(props)
        this.handleEmailChangeFormSubmit = this.handleEmailChangeFormSubmit.bind(this)
        this.handlePasswordChangeFormSubmit = this.handlePasswordChangeFormSubmit.bind(this)
        this.renderChangeEmailForm = this.renderChangeEmailForm.bind(this)
        this.state = {}

    }

    componentDidMount () {

        this.props.userAccountEmailAddressUpdateReset()
        this.props.userAccountPasswordUpdateReset()

        // setTimeout(() => {

            // console.log("Fetching account data")
        this.props.accountDataFetch()

        // }, 1000)

    }


    // componentDidUpdate (prevProps, prevState) {

        // console.dir("componentDidUpdate", prevProps, prevState)
        // if(prevProps.someValue!==this.props.someValue){
            // Perform some operation here
            // this.setState({someState: someValue});
            // this.classMethod();
        // }

    // }

    handleEmailChangeFormSubmit (new_user_account_email_address) {

        // const email_address = $("#emailChangeFormInput").val()
        // console.dir(new_user_account_email_address)

        // setTimeout(() => {

            // setSubmitting(false)
            // setStatus("Saved")
        // }, 1000)

        // this.props.userAccountEmailAddressUpdateReset()

        // console.log("Starting handleEmailChangeFormSubmit")
        // setTimeout(() => {

            // console.log("userAccountEmailAddressUpdatePost", new_user_account_email_address)
        this.props.userAccountEmailAddressUpdatePost(new_user_account_email_address)

        // }, 2000)
        // console.log("Done handleEmailChangeFormSubmit")

    }

    handlePasswordChangeFormSubmit (old_user_account_password, new_user_account_password) {

        this.props.userAccountPasswordUpdatePost(old_user_account_password, new_user_account_password)

    }

    renderChangeEmailForm () {

        if (this.props.user_account_user_data === null) {

            return (<div className="p-5 text-center"><i className="fa fa-refresh fa-spin fa-fw text-secondary" /></div>)

        }

        // console.dir(this.props.user_account_user_data)

        const current_email_address = this.props.user_account_user_data["user_account_email_address"]
        // const current_email_address = "XXX"

        return (
            <div>
                {/*
                <pre style={{ color: '#000' }}>{JSON.stringify(this.props, null, 2)}</pre>
                */}
                <ChangeEmailForm
                    onFormSubmit={this.handleEmailChangeFormSubmit}
                    user_account_email_address={current_email_address}
                    currently_sending_user_account_email_address_update_post={this.props.currently_sending_user_account_email_address_update_post}
                    user_account_email_address_update_error={this.props.user_account_email_address_update_error}
                    user_account_email_address_successfully_posted={this.props.user_account_email_address_successfully_posted}
                />
            </div>
        )

    }

    render () {

        // console.log("Rendering AccountSettings", this.props)

        return (

            <div>
                <a href="/"><img className="d-block mx-auto mt-4" src="/images/goldie_square_transparent.png" alt="" width="100" height="100" /></a>
                <div className="accountSettingsContainer container mt-3 mb-5">

                    <h1 className="text-center text-dark">Account Settings</h1>

                    <div className="card">
                        <div className="card-body">

                            <div className="mb-5">
                                { this.renderChangeEmailForm() }
                            </div>
                            <div className="mb-5">
                                <ChangePasswordForm
                                    onFormSubmit={this.handlePasswordChangeFormSubmit}
                                    currently_sending_user_account_password_update_post={this.props.currently_sending_user_account_password_update_post}
                                    user_account_password_update_error={this.props.user_account_password_update_error}
                                    user_account_password_successfully_posted={this.props.user_account_password_successfully_posted}
                                />
                            </div>

                        </div>

                        <div className="card-footer text-right">
                            <a href="/" className="btn btn-secondary">Back To Dashboard</a>
                        </div>
                    </div>

                </div>
                <ul className="nav justify-content-center mb-5">
                    <li className="nav-item small">
                        <a className="nav-link" href="https://www.apicanary.com/privacy_policy">Terms</a>
                    </li>
                    <li className="nav-item small">
                        <a className="nav-link" href="https://www.apicanary.com/privacy_policy">Privacy</a>
                    </li>
                    <li className="nav-item small">
                        <a className="nav-link" href="mailto:hello@apicanary.com">Contact API Canary</a>
                    </li>
                </ul>
            </div>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    currently_fetching_user_account_data: state.user_account.currently_fetching_user_account_data,
    user_account_data_has_been_fetched: state.user_account.user_account_data_has_been_fetched,
    user_account_data_fetch_error: state.user_account.user_account_data_fetch_error,
    user_account_data_fetch_error_message: state.user_account.user_account_data_fetch_error_message,

    user_account_user_data: state.user_account.user_account_user_data,

    currently_sending_user_account_email_address_update_post: state.user_account_email_address_update.currently_sending_user_account_email_address_update_post,
    user_account_email_address_update_error: state.user_account_email_address_update.user_account_email_address_update_error,
    user_account_email_address_update_return_data: state.user_account_email_address_update.user_account_email_address_update_return_data,
    user_account_email_address_successfully_posted: state.user_account_email_address_update.user_account_email_address_successfully_posted,

    currently_sending_user_account_password_update_post: state.user_account_password_update.currently_sending_user_account_password_update_post,
    user_account_password_update_error: state.user_account_password_update.user_account_password_update_error,
    user_account_password_update_return_data: state.user_account_password_update.user_account_password_update_return_data,
    user_account_password_successfully_posted: state.user_account_password_update.user_account_password_successfully_posted,

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    accountDataFetch,
    userAccountEmailAddressUpdatePost,
    userAccountEmailAddressUpdateReset,
    userAccountPasswordUpdatePost,
    userAccountPasswordUpdateReset,
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountSettings)

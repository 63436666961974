import React from 'react'
import {
    Link
} from 'react-router-dom'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

// import { PRETTY_TEXT_STRINGS } from 'lib/constants'

import MyTeamListItem from './MyTeamListItem/MyTeamListItem'
import MyTeamAddNew from './MyTeamAddNew/MyTeamAddNew'

import './MyTeamListBody.scss'

class MyTeamListBody extends DashboardModule {

    constructor (props) {

        super(props)
        this.getListItem = this.getListItem.bind(this)

        this.organization_id = false
        this.show_outer_border = false

    }

    componentWillMount () {

        const { match: { params } } = this.props
        this.organization_id = params.organization_id

    }

    getListItem (mode, user_account_id, user_account_username, user_account_email_address, organization_role_type) {

        return (
            <MyTeamListItem
                key={user_account_id}
                mode={mode}

                /* Fetch */
                user_account_id={user_account_id}
                user_account_username={user_account_username}
                user_account_email_address={user_account_email_address}
                organization_role_type={organization_role_type}

                /* OLD */
                vault_key={user_account_id}
                vault_key_value={organization_role_type}

                /* Update */
                onTeamMemberEmailUpdate={this.props.handleOnTeamMemberEmailUpdate}
                currently_sending_team_member_update_post={this.props.currently_sending_team_member_update_post}
                team_member_update_error={this.props.team_member_update_error}
                team_member_update_return_data={this.props.team_member_update_return_data}
                team_member_update_successfully_posted={this.props.team_member_update_successfully_posted}

                /* Delete */
                onTeamMemberDelete={this.props.handleOnTeamMemberDelete}
                currently_sending_team_member_delete_post={this.props.currently_sending_team_member_delete_post}
                team_member_delete_error={this.props.team_member_delete_error}
                team_member_delete_return_data={this.props.team_member_delete_return_data}
                team_member_delete_successfully_posted={this.props.team_member_delete_successfully_posted}

                {...this.props}
            />
        )

    }

    render () {

        let team_member_rows = []
        // let empty_vault_message = ""

        if (this.props.loading_error) {

            // Loading error

            team_member_rows.push(
                <div key="loading_error">
                    We&apos;re having a problem pulling your data.
                    Please <a href="/">refresh</a> and try again.
                    <div className="mt-5">
                        {this.props.loading_error}
                    </div>
                </div>
            )

        } else if (
            this.props.loaded_data === null
        ) {

            // Still Loading

            team_member_rows.push(
                <div key="loading">
                    Loading...
                </div>
            )

        } else if (this.props.loaded_data.length < 1) {

            // Empty team members list (should never happen)

            // empty_vault_message = (
                // <h1 className="pt-4 pl-4 pr-4 text-center">Add your first vault variable</h1>
            // )

            team_member_rows.push(
                <div key="loading_error">
                    We encountered bad data.
                    Please <a href="/">refresh</a> and try again.
                </div>
            )

        } else {

            // Team Members has data
            // console.log("debug hit", this.props.loaded_data)
            // return (<h1>debug</h1>)


            const { match: { params } } = this.props
            const user_account_id = params.user_account_id

            team_member_rows = this.props.loaded_data.map((team_member) => {

                // console.log("team_member", team_member)

                if (team_member["user_account_id"] === user_account_id) {

                    return this.getListItem("editor", team_member["user_account_id"], team_member["user_account_username"], team_member["user_account_email_address"], team_member["organization_role_type"])

                }

                return this.getListItem("view", team_member["user_account_id"], team_member["user_account_username"], team_member["user_account_email_address"], team_member["organization_role_type"])

            })

            // console.log("vault_key", vault_key)

            if (user_account_id === "add_new") {

                /*
                */
                team_member_rows.push(
                    <MyTeamAddNew
                        key="add_new_team_member"
                        /* add by email */
                        onTeamMemberFormSubmit={this.props.handleOnTeamMemberEmailAdd}
                        currently_sending_post={this.props.currently_sending_team_member_add_email_post}
                        team_member_post_error={this.props.team_member_add_email_error}
                        team_member_post_return_data={this.props.team_member_add_email_return_data}
                        team_member_post_successfully_posted={this.props.team_member_add_email_successfully_posted}
                        team_member_add_email_not_found_error={this.props.team_member_add_email_not_found_error}
                        onResetCreateData={this.props.handleResetCreateData}

                        /* Creating new account */
                        handleOnTeamMemberCreateAccount={this.props.handleOnTeamMemberCreateAccount}
                        currently_sending_team_member_create_account_post={this.props.currently_sending_team_member_create_account_post}
                        team_member_create_account_error={this.props.team_member_create_account_error}
                        team_member_create_account_return_data={this.props.team_member_create_account_return_data}
                        team_member_create_account_successfully_posted={this.props.team_member_create_account_successfully_posted}
                        handleResetCreateAccountData={this.props.teamMemberCreateAccountReset}

                        {...this.props}
                    />
                )

            } else {

                const add_another_link_url = `/o/${this.organization_id}/settings/my_team/add_new`
                team_member_rows.push(
                    <Link key="add_another_link" to={add_another_link_url} onClick={this.props.handleResetCreateData} type="button" className="btn btn-light mt-4" >
                        <i className="fa fa-plus" aria-hidden="true" /> Add another team member
                    </Link>
                )

            }

        }

        return this.renderRawCard(
            <div className="p-4 mb-2">
                {/* empty_vault_message */}
                <div className="p-0">
                    <div className="row my-team-list-item-title-row">
                        <div className="col-4 col-xl-3">Team&nbsp;Member</div>
                        <div className="col-xl-3 d-none d-xl-block">Email&nbsp;Address</div>
                        <div className="col-4 col-xl-3">Role</div>
                        <div className="col-4 col-xl-3">&nbsp;</div>
                    </div>
                    <div>{team_member_rows}</div>
                </div>
            </div>
        )

    }

}

export default MyTeamListBody

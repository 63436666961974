import React from 'react'
// import {
// Link, NavLink
// } from 'react-router-dom'

import * as moment from 'moment'

// https://github.com/jerairrest/react-chartjs-2
import { Bar } from 'react-chartjs-2'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'


class CanaryTestDetailTimeGraphModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.renderFullDetailBody = this.renderFullDetailBody.bind(this)

        this.module_height = "400px"

        if (this.props.module_height) {

            this.module_height = this.props.module_height

        }

        this.legend = {
            display: false
        }

        this.chart_options = {
            layout: {
                padding: {
                    left: 10,
                    right: 20,
                    top: 20,
                    bottom: 0
                }
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        stacked: true
                    }
                ],
                yAxes: [
                    {
                        stacked: true,
                        position: 'left',
                        id: 'y-axis-1'
                    },
                    {
                        position: 'right',
                        id: 'y-axis-2'
                    }
                ]
            }
        }

        this.chart_data = {
            labels: [],
            datasets: [
                {
                    label: 'Average Execution Duration',
                    type: 'line',
                    data: [],
                    fill: false,
                    borderColor: '#EC932F',
                    backgroundColor: '#EC932F',
                    pointBorderColor: '#EC932F',
                    pointBackgroundColor: '#EC932F',
                    pointHoverBackgroundColor: '#EC932F',
                    pointHoverBorderColor: '#EC932F',
                    yAxisID: 'y-axis-2'
                },
                {
                    label: 'Success',
                    stack: 'Stack 0',
                    backgroundColor: '#8cff8c',
                    borderColor: '#00aa00',
                    borderWidth: 1,
                    hoverBackgroundColor: '#38ff38',
                    hoverBorderColor: '#00aa00',
                    data: []
                },
                {
                    label: 'Failure',
                    stack: 'Stack 0',
                    backgroundColor: '#ff9d9d',
                    borderColor: '#ff0000',
                    borderWidth: 1,
                    hoverBackgroundColor: '#ff4e4e',
                    hoverBorderColor: '#ff0000',
                    data: []
                }
            ]
        }

    }

    componentWillMount () {

        // const { match: { params } } = this.props

        // this.organization_id = params.organization_id

    }

    renderFullDetailBody () {

        if (this.getLoadingError()) {

            return (
                <div>
                    Error: {this.getLoadingError()}
                </div>
            )

        }

        if (this.isCurrentlyLoading()) {

            return this.renderGenericLoadingUI()

        }

        if (!this.didFetchHaveData()) {

            return (
                <div>
                    No Data Returned
                </div>
            )

        }

        // console.log(this.props.report_data["data"])

        this.chart_data.labels = this.props.report_data["data"].map((report_item) => {

            // return moment(report_item["timestamp"]).format('MMMM Do YYYY, h:mm:ss a')
            return moment(report_item["timestamp"]).format('l')

        })

        this.chart_data.datasets[0]["data"] = this.props.report_data["data"].map((report_item) => {

            return report_item["execution_result_average_duration"]

        })

        this.chart_data.datasets[1]["data"] = this.props.report_data["data"].map((report_item) => {

            return report_item["organization_canary_test_execution_result_success_count"]

        })

        this.chart_data.datasets[2]["data"] = this.props.report_data["data"].map((report_item) => {

            return report_item["organization_canary_test_execution_result_total_count"] - report_item["organization_canary_test_execution_result_success_count"]

        })

        return (

            <div style={{ height: this.module_height }} className="p-2">

                {/*
                <pre className="mt-5x">
                    works
                </pre>
                <pre className="mt-5x">{JSON.stringify(this.props.report_data, null, 2)}</pre>
                */}

                <Bar
                    data={this.chart_data}
                    width={100}
                    height={50}
                    legend={this.legend}
                    options={this.chart_options}
                />

            </div>

        )

    }

    render () {

        // set loading flags from the report data (instead of setting them via individual props)
        this.updateModuleFlagsFromDataReport(this.props.report_data)

        if (this.getLoadingError()) {

            return this.renderRawCard(
                this.getLoadingError()
            )

        }

        return this.renderRawCard(

            <div style={{ height: this.module_height }} className="">
                { this.renderFullDetailBody() }
            </div>

        )

    }

}

export default CanaryTestDetailTimeGraphModule

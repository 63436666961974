import axios from 'axios'
import {
    auth
} from '../lib/authentication'
import config from 'config'


// Initial Module State
const initialState = {
    currently_sending_login_post: false,
    login_error: null,
    login_return_data: null
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const LOGIN_RESET = 'login/LOGIN_RESET'
export const LOGIN_POST_REQUESTED = 'login/LOGIN_POST_REQUESTED'
export const LOGIN_POST_SUCCESS = 'login/LOGIN_POST_SUCCESS'
export const LOGIN_POST_ERROR = 'login/LOGIN_POST_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const loginReset = () => {

    return (dispatch) => {

        dispatch({
            type: LOGIN_RESET
        })

    }

}

export const loginPost = (username, password) => {

    // console.log("loginPost called", username, password)

    return (dispatch) => {

        auth.clear()

        // Get login data from form here.

        dispatch({
            type: LOGIN_POST_REQUESTED
        })

        return axios({
            method: 'post',
            url: `//${config["api_hostname"]}/v1/authenticate`,
            auth: {
                "username": username,
                "password": password
            }
        })

            .then((response) => {

                // console.log("Login response", response)

                // console.log("Login data", response.data)

                const auth_token = response.data["_metadata"]["auth_token"]
                const user_account_email_address = response.data["data"][0]["user_account_email_address"]
                const user_account_id = response.data["data"][0]["user_account_id"]
                const user_account_username = response.data["data"][0]["user_account_username"]

                // console.log(auth_token)
                // console.log(user_account_email_address)
                // console.log(user_account_id)
                // console.log(user_account_username)

                // console.log("user_account_email_address", user_account_email_address)

                // return
                const auth_data = {
                    auth_token,
                    user_account_email_address,
                    user_account_id,
                    user_account_username
                }

                // console.dir(auth_data)

                auth.set(auth_data)

                dispatch({
                    type: LOGIN_POST_SUCCESS,
                    payload: auth_data
                })

            })

            .catch((error) => {

                auth.clear()

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data)
                    // console.log(error.response.status)
                    // console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: LOGIN_POST_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case LOGIN_RESET:
            return {
                ...state,
                currently_sending_login_post: false,
                login_error: null,
                login_return_data: null
            }

        case LOGIN_POST_REQUESTED:
            return {
                ...state,
                currently_sending_login_post: true,
                login_error: null
            }

        case LOGIN_POST_SUCCESS:
            return {
                ...state,
                currently_sending_login_post: false,
                login_error: null,
                login_return_data: action.payload
            }

        case LOGIN_POST_ERROR:
            return {
                ...state,
                currently_sending_login_post: false,
                login_error: action.payload, // Loading error text
                login_return_data: null
            }

        default:
            return state

    }

}

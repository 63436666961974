// This file is included by modules/all_modules.js (For the reducers)
// This file is also included by stuff (For the actions)

import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'

// Initial Module State
const initialState = {

    // Flags
    currently_fetching_alert_rule: false,
    alert_rule_fetch_error: null,

    // Alert Rule Identifiers
    alert_rule_id: null,
    alert_rule_date_created: null,

    // Alert Rule Fields
    alert_rule_notification_frequency: null,
    alert_rule_title: null,
    alert_rule_status_flag: null,
    alert_rule_type: null,
    alert_rule_details: null

}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const ALERT_RULE_RESET = 'alert_rule/ALERT_RULE_RESET'
export const ALERT_RULE_FETCH_REQUESTED = 'alert_rule/ALERT_RULE_FETCH_REQUESTED'
export const ALERT_RULE_FETCH_SUCCESS = 'alert_rule/ALERT_RULE_FETCH_SUCCESS'
export const ALERT_RULE_FETCH_ERROR = 'alert_rule/ALERT_RULE_FETCH_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const alertRuleReset = () => {

    return (dispatch) => {

        dispatch({
            type: ALERT_RULE_RESET
        })

    }

}

export const alertRuleFetch = (organization_id, alert_rule_id) => {

    return (dispatch) => {

        dispatch({
            type: ALERT_RULE_RESET
        })

        dispatch({
            type: ALERT_RULE_FETCH_REQUESTED
        })

        const auth_token = auth.get("auth_token")

        // console.log("alertRuleFetch called")

        return axios({
            method: 'get',
            url: `//${config["api_hostname"]}/v1/o/${organization_id}/alert_rules`,
            headers: {
                Authorization: `Token ${auth_token}`
            }
        })

            .then((response) => {

                // console.dir("alert rule fetch response", response.data.data)

                // pulling out alert rule that matches alert_rule_id.
                let alert_rule = null
                for (const x in response.data.data) {

                    if ({}.hasOwnProperty.call(response.data.data, x)) {

                        if (alert_rule_id === response.data.data[x]["alert_rule_id"]) {

                            alert_rule = response.data.data[x]

                        }

                    }

                }

                if (!alert_rule) {

                    return dispatch({
                        type: ALERT_RULE_FETCH_ERROR,
                        payload: "That alert rule was not found"
                    })

                }

                // console.log(alert_rule)

                dispatch({
                    type: ALERT_RULE_FETCH_SUCCESS,
                    payload: alert_rule
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: ALERT_RULE_FETCH_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case ALERT_RULE_RESET: {

            return {
                ...state,

                // Flags
                currently_fetching_alert_rule: false,
                alert_rule_fetch_error: null,

                // Alert Rule Identifiers
                alert_rule_id: null,
                alert_rule_date_created: null,

                // Alert Rule Fields
                alert_rule_notification_frequency: null,
                alert_rule_title: null,
                alert_rule_status_flag: null,
                alert_rule_type: null,
                alert_rule_details: null

            }

        }

        case ALERT_RULE_FETCH_REQUESTED:
            return {
                ...state,

                // Flags
                currently_fetching_alert_rule: true,
                alert_rule_fetch_error: null,

                // Alert Rule Identifiers
                alert_rule_id: null,
                alert_rule_date_created: null,

                // Alert Rule Fields
                alert_rule_notification_frequency: null,
                alert_rule_title: null,
                alert_rule_status_flag: null,
                alert_rule_type: null,
                alert_rule_details: null
            }

        case ALERT_RULE_FETCH_SUCCESS: {

            // console.log("canary test fetch success reducer called", action.payload)
            const alert_rule = action.payload
            // console.log("!!!canary test")
            // console.dir(alert_rule)

            return {
                ...state,

                // Flags
                currently_fetching_alert_rule: false,
                alert_rule_fetch_error: null,

                // Alert Rule Identifiers
                alert_rule_id: alert_rule["alert_rule_id"],
                alert_rule_date_created: alert_rule["alert_rule_date_created"],

                // Alert Rule Fields
                alert_rule_notification_frequency: alert_rule["alert_rule_notification_frequency"],
                alert_rule_title: alert_rule["alert_rule_title"],
                alert_rule_status_flag: alert_rule["alert_rule_status_flag"],
                alert_rule_type: alert_rule["alert_rule_type"],
                alert_rule_details: alert_rule["alert_rule_details"]
            }

        }

        case ALERT_RULE_FETCH_ERROR:
            return {
                ...state,

                // Flags
                currently_fetching_alert_rule: false,
                alert_rule_fetch_error: action.payload, // Loading error text

                // Alert Rule Identifiers
                alert_rule_id: null,
                alert_rule_date_created: null,

                // Alert Rule Fields
                alert_rule_notification_frequency: null,
                alert_rule_title: null,
                alert_rule_status_flag: null,
                alert_rule_type: null,
                alert_rule_details: null

            }

        default:
            return state

    }

}

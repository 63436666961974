import React, { Component } from 'react'
import './HeaderDropdown.scss'

let id_count = 0

class HeaderDropdown extends Component {

    constructor (props) {

        super(props)
        this.renderOptions = this.renderOptions.bind(this)
        this.handleOnClick = this.handleOnClick.bind(this)

        this.guid = `fancy-header-dropdown-id-${id_count++}`

    }

    renderOptions () {

        return this.props.dropdown_data["options"].map((option) => {

            return (
                <button key={option["value"]} type="button" className="dropdown-item" data-option-key={option["value"]}>{option["name"]}</button>
            )

        })

    }

    handleOnClick (e) {

        // console.log("hit", e.target)
        // console.log("hit", e.target.getAttribute('data-option-key'))
        // console.log("hit", e.target.parentElement)
        // console.log("hit", e.target.parentElement.getAttribute('data-option-key'))
        // console.log("hit", e.target.getAttribute('data-option-key'))

        if (this.props.dropdown_data["onClick"]) {

            let option_key = e.target.getAttribute('data-option-key')

            if (!option_key) {

                //Hack.  Make this better.  Doing this because chrome is picking up sub elements on click sometimes.
                option_key = e.target.parentElement.getAttribute('data-option-key')

            }
            
            // console.log("option_key", option_key)

            this.props.dropdown_data["onClick"](option_key)

        }

    }

    // Return the completely assembled component
    render () {

        return (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id={this.guid} role="button" data-toggle="dropdown">
                    {this.props.dropdown_data["title"]}
                </a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby={this.guid} onClick={this.handleOnClick}>
                    {this.renderOptions()}
                </div>
            </li>
        )

    }

}


export default HeaderDropdown

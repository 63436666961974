import React, { Component } from 'react'
import {
    Link // , NavLink
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    alertRulesReset,
    alertRulesFetch
} from 'modules/alert_rules_module'

import AlertRulesListBody from './AlertRulesListBody/AlertRulesListBody'


class AlertRulesList extends Component {

    constructor (props) {

        super(props)
        this.renderAlertBox = this.renderAlertBox.bind(this)

    }

    componentWillMount () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        this.props.alertRulesFetch(organization_id)

    }

    renderAlertBox () {

        const searchParams = new URLSearchParams(this.props.location.search)

        if (searchParams.has("sm")) {

            if (searchParams.get("sm") === "deleted") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your alert rule has been deleted</strong>
                    </div>
                )

            } else if (searchParams.get("sm") === "created") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your alert rule has been created</strong>
                    </div>
                )

            }

        } else if (searchParams.has("em")) {

            if (searchParams.get("em") === "a") {

                return (
                    <div className="alert alert-danger mb-5" role="alert">
                        <i className="fa fa-fw fa-times text-danger mr-2" />
                        <strong>We had trouble</strong>
                    </div>
                )

            }

        }

        return (

            <div />

        )

    }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        const new_alert_rule_anchor_url = `/o/${organization_id}/alert_rules/new`

        return (

            <div>

                <div className="container-fluid dashboard-body-header-container">
                    { this.renderAlertBox() }
                    <div className="row">
                        <div className="col-sm">
                            <h1>Alert Rules</h1>
                        </div>
                        <div className="col-sm text-right">
                            <Link className="btn btn-success" to={new_alert_rule_anchor_url}><i className="fa fa-plus" aria-hidden="true" /> New Alert Rule</Link>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row dashboard-body-row">
                        <div className="col-12">
                            <AlertRulesListBody
                                is_currently_loading={this.props.currently_fetching_alert_rules}
                                loading_error={this.props.alert_rules_fetch_error}
                                loaded_data={this.props.alert_rules_fetch_return_data}
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>

            </div>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    currently_fetching_alert_rules: state.alert_rules.currently_fetching_alert_rules,
    alert_rules_fetch_error: state.alert_rules.alert_rules_fetch_error,
    alert_rules_fetch_return_data: state.alert_rules.alert_rules_fetch_return_data
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    alertRulesReset,
    alertRulesFetch
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertRulesList)

import React, { Component } from 'react'

import FancyFormElementErrorMessage from 'components/shared/FancyFormElementErrorMessage/FancyFormElementErrorMessage'

import './FancyFormElement.css'


class FancyFormElement extends Component {

    constructor (props) {

        super(props)
        this.renderLoadingUI = this.renderLoadingUI.bind(this)

    }

    renderLoadingUI () {

        if (!this.props.currently_loading) {

            return (<div />)

        }

        return (
            <div className="fancy-input-loading-spinner">
                <i className="fa fa-refresh fa-spin text-secondary" />
            </div>
        )

    }

    renderFormElement () {

        return (
            <h1>Dummy form element</h1>
        )

    }

    render () {

        let error_message_ui = (<div />)
        if (this.props.show_error_message) {

            error_message_ui = (
                <div className="fancy-input-error-box text-danger">
                    <FancyFormElementErrorMessage
                        show_error_message={this.props.show_error_message}
                        error_message={this.props.error_message}
                    />
                </div>
            )

        }

        return (
            <div className="fancy-form-element-outer-container">
                {this.renderLoadingUI()}
                <div className="fancy-form-element-container">
                    {this.renderFormElement()}
                </div>
                {error_message_ui}
            </div>
        )

    }

}


export default FancyFormElement

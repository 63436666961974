import React from 'react'
// import { withFormik } from 'formik'
// import * as Yup from 'yup'

import {
    Link
} from 'react-router-dom'

// import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'
// import FancyLoadingButton from 'components/shared/FancyLoadingButton/FancyLoadingButton'
// import AlertBox from 'components/shared/AlertBox/AlertBox'

import EmailSearch from './EmailSearch/EmailSearch'
import NewAccountCreate from './NewAccountCreate/NewAccountCreate'

import './MyTeamAddNew.scss'

class MyTeamAddNew extends React.Component {

    constructor (props) {

        super(props)

        this.ABC = "DEF"

        this.state = {
            create_team_member_role_step: "SEARCH_EMAIL"
        }

    }

    componentDidMount () {

        // console.log("MyTeamAddNew mounted")
        this.setState({ create_team_member_role_step: "SEARCH_EMAIL" })

    }

    handleCreateNewAccountButtonClick = () => {

        // console.log("Hit")
        this.setState({ create_team_member_role_step: "CREATE_ACCOUNT" })

    }

    render () {

        // if (true) {
        if (this.state.create_team_member_role_step === "CREATE_ACCOUNT") {

            return (
                <NewAccountCreate

                    /*
                    user_account_email_address=?
                    role_type=?
                    */

                    handleOnTeamMemberCreateAccount={this.props.handleOnTeamMemberCreateAccount}
                    currently_sending_team_member_create_account_post={this.props.currently_sending_team_member_create_account_post}
                    team_member_create_account_error={this.props.team_member_create_account_error}
                    team_member_create_account_return_data={this.props.team_member_create_account_return_data}
                    team_member_create_account_successfully_posted={this.props.team_member_create_account_successfully_posted}
                    handleResetCreateAccountData={this.props.teamMemberCreateAccountReset}

                    {...this.props}
                />
            )

        }
        
        // if (true) {
        if (this.props.team_member_add_email_not_found_error) {

            return (

                <div className="my-team-create-new-question-container">
                    <div className="card">
                        <div className="card-header font-weight-bold">
                            Add New Team Member
                        </div>
                        <div className="card-body">
                            <p className="text-danger">
                                <i className="fa fa-exclamation-circle text-danger mr-1" />We didn&apos;t find an account matching that email address
                            </p>
                            <p>
                                Do you want to create a new API Canary account for this user?
                            </p>
                            <div className="text-center">
                                <button type="button" className="btn btn-success mr-2" onClick={this.handleCreateNewAccountButtonClick}>Yes</button>
                                <Link key="add_another_link" to={`/o/${window.ac_session.current_organization.organization_id}/settings/my_team`} type="button" className="btn btn-light" >No</Link>
                            </div>

                        </div>
                    </div>
                </div>

            )

        }

        if (this.state.create_team_member_role_step === "SEARCH_EMAIL") {

            return (

                <EmailSearch
                    currently_sending_post={this.props.currently_sending_team_member_add_email_post}
                    team_member_post_error={this.props.team_member_add_email_error}
                    team_member_post_return_data={this.props.team_member_add_email_return_data}
                    team_member_post_successfully_posted={this.props.team_member_add_email_successfully_posted}
                    team_member_add_email_not_found_error={this.props.team_member_add_email_not_found_error}

                    {...this.props}
                />

            )

        }

        return (

            <div>
                Should not ever see this.
            </div>

        )

    }

    // <pre>{JSON.stringify(this.props.values, null, 4)}</pre>

}

export default MyTeamAddNew

// import $ from 'jquery'
import React, { Component } from 'react'
import {
    Link
} from 'react-router-dom'

import FancyFormElementMultiSelectDropdown from 'components/shared/FancyFormElementMultiSelectDropdown/FancyFormElementMultiSelectDropdown'
// import FancyFormElementText from 'components/shared/FancyFormElementText/FancyFormElementText'
import './CanaryTestAlertRulesField.scss'


class CanaryTestAlertRulesField extends Component {

    constructor (props) {

        super(props)
        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnBlur = this.handleOnBlur.bind(this)

    }

    handleOnBlur () {

        this.props.onBlur(this.props.name)

    }

    handleOnChange (new_value) {

        // console.log("CanaryTestAlertRulesField handleOnChange", new_value)
        this.props.onChange(this.props.name, new_value)

    }

    // Return the completely assembled component
    render () {

        if (this.props.all_alert_rules === null) {

            return (<div />)

        }

        // console.log("CANARY_TEST_SCHEDULE_TYPES", CANARY_TEST_SCHEDULE_TYPES)
        // console.log("all_execution_nodes_data", this.props.all_execution_nodes_data)

        const dropdown_options = this.props.all_alert_rules.map((alert_rule) => {

            return {
                "value": alert_rule["alert_rule_id"],
                "label": alert_rule["alert_rule_title"]
            }

        })

        // console.log("dropdown_options", dropdown_options)

        // console.log("value", this.props.value)

        const link_url = `/o/${this.organization_id}/alert_rules`

        return (
            <div className="form-row">
                <div className="col-lg-9">
                    <div className="form-group">
                        <label>Alert Rules</label>
                        <FancyFormElementMultiSelectDropdown
                            id={this.props.id}
                            name={this.props.name}
                            onChange={this.handleOnChange}
                            value={this.props.value}
                            dropdown_options={dropdown_options}
                            onBlur={this.handleOnBlur}
                            disabled={this.props.disabled}
                            error_message={this.props.error_message}
                            show_error_message={this.props.show_error_message}
                        />

                        {/*
                        <pre>{JSON.stringify(this.props, null, 4)}</pre>
                        */}

                    </div>
                    <div className="form-group form-check time_out_check_box_container">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={this.props.timeout_checkbox_id}
                            name={this.props.timeout_checkbox_name}
                            onChange={this.props.timeoutCheckboxOnChange}
                            checked={this.props.timeoutCheckboxValue}
                            onBlur={this.props.timeoutCheckboxOnBlur}
                            disabled={this.props.disabled}
                        />
                        <label className="form-check-label" htmlFor={this.props.timeout_checkbox_id}>Fire alert rule(s) if the test times out</label>
                    </div>
                </div>

                <div className="form-group col-lg-3">
                    <label className="d-none d-lg-block">&nbsp;</label>
                    <div className="form-info-bubble">
                        <i className="fa fa-lightbulb-o" />
                        <p>
                            When a test fails, you can be alerted via several alert methods.
                        </p>
                        <p>
                            Enable new ones in the <Link to={link_url}>Alert Rules</Link> tab.
                        </p>
                    </div>
                </div>
            </div>
        )

    }

}


export default CanaryTestAlertRulesField


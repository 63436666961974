import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import NavBreadcrumb from './NavBreadcrumb/NavBreadcrumb'
import NavLinks from './NavLinks/NavLinks'

import './DashboardNavbar.css'


class DashboardNavbar extends Component {

    constructor (props) {

        super(props)
        this.renderNavbarContent = this.renderNavbarContent.bind(this)

    }

    renderNavbarContent () {

        // console.log("re this.props", this.props)
        const { user_account_data_has_been_fetched } = this.props

        if (user_account_data_has_been_fetched) {

            return (

                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                    <NavBreadcrumb {...this.props} />
                    <NavLinks {...this.props} />
                </div>

            )

        }

        return (

            <ul className="navbar-nav">
                <span className="navbar-text"><i className="fa fa-refresh fa-spin fa-fw text-secondary" /></span>
            </ul>

        )

    }

    // Return the completely assembled component
    render () {

        return (

            <nav className="navbar navbar-expand-md navbar-light bg-light">

                <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                    { this.renderNavbarContent() }
                </div>

            </nav>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    currently_fetching_user_account_data: state.user_account.currently_fetching_user_account_data,
    user_account_data_has_been_fetched: state.user_account.user_account_data_has_been_fetched,
    user_account_user_data: state.user_account.user_account_user_data,
    user_account_organizations: state.user_account.user_account_organizations
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardNavbar)



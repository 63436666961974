import React from 'react'
// import {
// Link, NavLink
// } from 'react-router-dom'

import { PRETTY_TEXT_STRINGS } from 'lib/constants'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import "./CanaryTestDetailFrequencyModule.scss"

class CanaryTestDetailFrequencyModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.renderFullDetailBody = this.renderFullDetailBody.bind(this)

    }

    componentWillMount () {

        // const { match: { params } } = this.props

        // this.organization_id = params.organization_id

    }

    renderFullDetailBody () {

        if (this.getLoadingError()) {

            return (
                <div>
                    Error: {this.getLoadingError()}
                </div>
            )

        }

        if (this.isCurrentlyLoading()) {

            return this.renderGenericLoadingUI()

        }

        if (!this.didFetchHaveData(this.props.canary_test_schedule_type)) {

            return (
                <div className="p-4">
                    No Data Returned
                </div>
            )

        }

        // console.log("this.props.canary_test_schedule_type", this.props.canary_test_schedule_type.replace("_", " "))

        const frequency_string = PRETTY_TEXT_STRINGS[this.props.canary_test_schedule_type]

        return (

            <div className="text-left pt-2 pl-3">
                Scheduled to run: <strong className="d-block">{frequency_string}</strong>
            </div>

        )

    }

    render () {

        if (this.getLoadingError()) {

            return this.renderRawCard(
                this.getLoadingError()
            )

        }

        let module_height = "400px"

        if (this.props.module_height) {

            module_height = this.props.module_height

        }

        return this.renderRawCard(

            <div style={{ height: module_height }} className="canary-test-detail-frequency-module-container">
                { this.renderFullDetailBody() }
            </div>

        )

    }

}

export default CanaryTestDetailFrequencyModule

import React from 'react'
// import {
// Link, NavLink
// } from 'react-router-dom'

// https://github.com/jerairrest/react-chartjs-2
import { Doughnut } from 'react-chartjs-2'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import "./CanaryTestDetailOverviewModule.scss"

class CanaryTestDetailOverviewModule extends DashboardModule {

    constructor (props) {

        super(props)
        this.renderFullDetailBody = this.renderFullDetailBody.bind(this)

        this.module_height = "400px"

        if (this.props.module_height) {

            this.module_height = this.props.module_height

        }

        this.legend = {
            display: false
        }

        this.chart_options = {
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            maintainAspectRatio: false
        }

        this.chart_data = {
            labels: [
                'Success',
                'Failure'
            ],
            datasets: [
                {
                    data: [123, 456],
                    backgroundColor: [

                        // s
                        '#00aa00',
                        // '#8cff8c',
                        // '#38ff38',

                        // r
                        // '#ff0000',
                        // '#ff9d9d',
                        '#ff4e4e',

                    ],
                    hoverBackgroundColor: [

                        // s
                        '#38ff38',

                        // r
                        '#ff0000',
                        // '#ff9d9d',
                        // '#ff4e4e'

                    ]
                }
            ]
        }

        this.empty_chart_data = {
            labels: ['executions'],
            datasets: [
                {
                    data: [100],
                    backgroundColor: [

                        '#eeeeee',
                        // '#ff9d9d',
                        // '#ff4e4e',

                    ],
                    hoverBackgroundColor: [

                        // r
                        '#eeeeee',

                    ]
                }
            ]
        }

    }

    componentWillMount () {

        // const { match: { params } } = this.props

        // this.organization_id = params.organization_id

    }

    renderFullDetailBody () {

        if (this.getLoadingError()) {

            return (
                <div>
                    Error: {this.getLoadingError()}
                </div>
            )

        }

        if (this.isCurrentlyLoading()) {

            return this.renderGenericLoadingUI()

        }

        if (!this.didFetchHaveData()) {

            return (
                <div>
                    No Data Returned
                </div>
            )

        }

        let this_chart_data = { ...this.chart_data }

        // console.log("this_chart_data", this_chart_data)

        this_chart_data.datasets[0].data = [
            this.props.report_data["data"][0]["organization_canary_test_execution_result_success_count"],
            (this.props.report_data["data"][0]["organization_canary_test_execution_result_total_count"] - this.props.report_data["data"][0]["organization_canary_test_execution_result_success_count"])
        ]

        let success_percent_markup = ""

        if (
            this_chart_data.datasets[0].data[0] === 0 &&
            this_chart_data.datasets[0].data[1] === 0
        ) {

            // No results returned from server, this is probably a new canary test, show "Zero" results UI.

            this_chart_data = { ...this.empty_chart_data }

        } else {

            // OK we got data.

            let success_percent = ((this.props.report_data["data"][0]["organization_canary_test_execution_result_success_count"] / this.props.report_data["data"][0]["organization_canary_test_execution_result_total_count"]) * 100).toFixed(1)

            if (success_percent > 99.9) {

                success_percent = 100

            }

            if ((success_percent % 1) === 0) {

                // remove unneeded ".0" from numbers
                success_percent = Math.floor(success_percent)

            }

            if (!isNaN(success_percent)) {

                success_percent_markup = (
                    <div className="success-percent-for-doughnut">{success_percent}%</div>
                )

            }

        }

        // console.log(success_percent, success_percent === NaN)
        // console.log("this.props.report_data[data]", this.props.report_data["data"])

        return (

            <div style={{ height: this.module_height, widthx: "655px" }} className="canary-test-overview-module-container">
                <div style={{ height: this.module_height }} className="doughnut-container">
                    {success_percent_markup}
                    <Doughnut
                        data={this_chart_data}
                        width={100}
                        height={30}
                        legend={this.legend}
                        options={this.chart_options}
                    />
                </div>
                <div className="overview-stats-container">
                    <dl>
                        <dt className="text-nowrap">
                            {this.props.report_data["data"][0]["organization_canary_test_execution_result_success_count"].toLocaleString('en-EN', { minimumFractionDigits: 0 })}
                        </dt>
                        <dd className="text-nowrap">
                            Successful
                        </dd>
                    </dl>
                    <dl>
                        <dt className="text-nowrap">
                            {this.props.report_data["data"][0]["organization_canary_test_execution_result_total_count"].toLocaleString('en-EN', { minimumFractionDigits: 0 })}
                        </dt>
                        <dd className="text-nowrap">
                            Total
                        </dd>
                    </dl>
                </div>

                {/*

                overview:
                <pre className="mt-5x">
                    {this.props.report_data["data"][0]["organization_canary_test_execution_result_success_count"]}
                    &nbsp;out of&nbsp;
                    {this.props.report_data["data"][0]["organization_canary_test_execution_result_total_count"]}
                </pre>

                <pre className="mt-5x">{JSON.stringify(this.props.report_data, null, 2)}</pre>
                */}

            </div>

        )

    }

    render () {

        // set loading flags from the report data (instead of setting them via individual props)
        this.updateModuleFlagsFromDataReport(this.props.report_data)

        if (this.getLoadingError()) {

            return this.renderRawCard(
                this.getLoadingError()
            )

        }

        return this.renderRawCard(

            <div style={{ height: this.module_height }} className="p-1">
                { this.renderFullDetailBody() }
            </div>

        )

    }

}

export default CanaryTestDetailOverviewModule

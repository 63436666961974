import React, { Component } from 'react'
import {
    Link // , NavLink
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    canaryTestsReset,
    canaryTestsFetch
} from 'modules/canary_tests_module'

import {
    canaryTestsReportsReset,
    canaryTestsReportsFetch
} from 'modules/canary_tests_reports_module'

import {
    // allDatacentersReset,
    allDatacentersFetch
} from 'modules/datacenters_module'

import TotalCanaryTestsDashboardModule from 'components/shared/TotalCanaryTestsDashboardModule/TotalCanaryTestsDashboardModule'
import SuccessFailureDashboardModule from 'components/shared/SuccessFailureDashboardModule/SuccessFailureDashboardModule'
import AverageDurationDashboardModule from 'components/shared/AverageDurationDashboardModule/AverageDurationDashboardModule'

import CanaryTestsListBody from './CanaryTestsListBody/CanaryTestsListBody'

class CanaryTestsList extends Component {

    constructor (props) {

        super(props)
        this.renderAlertBox = this.renderAlertBox.bind(this)

    }

    componentDidMount () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        this.props.canaryTestsFetch(organization_id)
        this.props.allDatacentersFetch(organization_id)

        this.pullAllData("MONTH")

    }

    pullAllData (time_duration) {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        this.props.canaryTestsReportsFetch({
            organization_id: organization_id,
            report_id: "PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT",
            time_range: time_duration
        })

    }

    renderAlertBox () {

        const searchParams = new URLSearchParams(this.props.location.search)

        if (searchParams.has("sm")) {

            if (searchParams.get("sm") === "deleted") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your Test has been deleted</strong>
                    </div>
                )

            } else if (searchParams.get("sm") === "created") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your Test has been created</strong>
                    </div>
                )

            } else if (searchParams.get("sm") === "updated") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your Test has been updated</strong>
                    </div>
                )

            }

        } else if (searchParams.has("em")) {

            if (searchParams.get("em") === "a") {

                return (
                    <div className="alert alert-danger mb-5" role="alert">
                        <i className="fa fa-fw fa-times text-danger mr-2" />
                        <strong>We had trouble</strong>
                    </div>
                )

            }

        }

        return (

            <div />

        )

    }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        const new_canary_test_anchor_url = `/o/${organization_id}/canary_tests/new`

        return (

            <div>

                <div className="container-fluid dashboard-body-header-container">
                    { this.renderAlertBox() }
                    <div className="row">
                        <div className="col-sm">
                            <h1>Tests</h1>
                        </div>
                        <div className="col-sm text-right">
                            <Link className="btn btn-success" to={new_canary_test_anchor_url}><i className="fa fa-plus" aria-hidden="true" /> New Test</Link>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">

                    <div className="row dashboard-body-row">
                        <div className="col-lg-4">
                            <div className="card overview-dashboard-card mb-4" style={{ height: '100px' }}>
                                {/*
                                <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"], null, 2)}</pre>
                                Overall (PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT
                                */}
                                <TotalCanaryTestsDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card overview-dashboard-card mb-4" style={{ height: '100px' }}>
                                <SuccessFailureDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card overview-dashboard-card mb-4 mb-lg-0" style={{ height: '100px' }}>
                                <AverageDurationDashboardModule
                                    report_data={this.props.canary_tests_reports_data["PULL_OVER_ALL_SUCCESS_FAILURE_DURATION_REPORT"]}
                                    {...this.props}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="row dashboard-body-row">
                        <div className="col-12">

                            <CanaryTestsListBody
                                is_currently_loading={this.props.currently_fetching_canary_tests || this.props.currently_fetching_datacenters}
                                loadingError={this.props.canary_tests_fetch_error || this.props.datacenters_fetch_error}
                                canary_tests_fetch_successful={this.props.canary_tests_fetch_successful}
                                canary_tests={this.props.canary_tests}
                                datacenters_data={this.props.datacenters_data}
                                {...this.props}
                            />

                        </div>
                    </div>
                </div>

            </div>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({

    currently_fetching_datacenters: state.datacenters.currently_fetching_datacenters,
    datacenters_fetch_error: state.datacenters.datacenters_fetch_error,
    datacenters_data: state.datacenters.datacenters_data,

    currently_fetching_canary_tests: state.canary_tests.currently_fetching_canary_tests,
    canary_tests_fetch_error: state.canary_tests.canary_tests_fetch_error,
    canary_tests_fetch_successful: state.canary_tests.canary_tests_fetch_successful,
    canary_tests: state.canary_tests.canary_tests,

    canary_tests_reports_data: state.canary_tests_reports.canary_tests_reports_data
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    allDatacentersFetch,
    canaryTestsReset,
    canaryTestsFetch,
    canaryTestsReportsReset,
    canaryTestsReportsFetch
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CanaryTestsList)

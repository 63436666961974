import React, { Component } from 'react'
// Google maps
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from 'react-google-maps'

import './DashboardMapModule.scss'

const dashboard_map_module_styles = require("./DashboardMapModule_styles.json")


// const { Map: LeafletMap, TileLayer, Marker, Popup } = ReactLeaflet

class DashboardMapModule extends Component {

    constructor (props) {

        super(props)

        this.generateMapMarkers = this.generateMapMarkers.bind(this)
        this.handleToggleOpen = this.handleToggleOpen.bind(this)
        this.handleToggleClose = this.handleToggleClose.bind(this)

        this.state = {
            is_info_window_open: false,
            open_window_marker_key: null
        }

        this.datacenter_map_icon = {
            url: '/images/cube_map_icon_transparent.png',
            scaledSize: {
                width: 25,
                height: 25
            }
        }

        this.active_datacenter_map_icon = {
            url: '/images/cube_map_icon_gold_transparent.png',
            scaledSize: {
                width: 25,
                height: 25
            }
        }

        this.map_styles = dashboard_map_module_styles

        this.default_map_options = {
            mapTypeControl: false,
            zoomControl: true,
            streetViewControl: false,
            draggableCursor: 'default',
            draggingCursor: 'move',
            disableDefaultUI: true,
            styles: this.map_styles,
            backgroundColor: "#ff0000"
        }

    }

    handleToggleOpen (marker_key) {

        this.setState({
            is_info_window_open: true,
            open_window_marker_key: marker_key
        })

    }

    handleToggleClose () {

        this.setState({
            is_info_window_open: false,
            open_window_marker_key: null
        })

    }

    generateMapMarkers () {

        const markers = this.props.markers.map((marker) => {

            let marker_icon = this.datacenter_map_icon

            if (marker["icon_type"] === "ACTIVE_DATACENTER") {

                marker_icon = this.active_datacenter_map_icon

            }

            return (
                <Marker
                    key={marker["key"]}
                    icon={marker_icon}
                    position={{
                        lat: marker["lat"],
                        lng: marker["long"]
                    }}
                    onClick={() => this.handleToggleOpen(marker["title"])}
                >
                    {
                        this.state.is_info_window_open && this.state.open_window_marker_key === marker["title"] &&
                        (
                            <InfoWindow onCloseClick={this.props.handleClose}>
                                <div>{marker["title"]}</div>
                            </InfoWindow>
                        )
                    }
                </Marker>
            )

        })

        return markers

    }

    renderLoadingUI () {

        return (
            <div className="dashboard-map-module-placeholder-container" />
        )

    }

    renderErrorUI () {

        return (
            <div className="dashboard-map-module-placeholder-container" />
        )

    }

    // Return the completely assembled component
    render () {

        if (this.props.is_currently_loading) {

            return this.renderLoadingUI()

        }

        if (this.props.loading_error) {

            return this.renderErrorUI()

        }

        // DEBUG
        // return this.renderLoadingUI()

        const GoogleMapExample = withScriptjs(withGoogleMap((props) => (

            <GoogleMap
                defaultCenter={{
                    lat: 40.756795,
                    lng: -73.954298
                }}
                defaultOptions={this.default_map_options}
                defaultZoom={2}
                options={{
                    ...this.default_map_options,
                    backgroundColor: "orange"
                }}
            >
                {this.generateMapMarkers()}
            </GoogleMap>

        )))

        const google_map_element = (
            <div className="google_map_element" />
        )

        return (

            <div className="dashboard-map-module-container">
                <div className="dashboard-map-module-map-container">
                    <GoogleMapExample
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAVOTaVGs0lv6rHG78kFPuSbjbi7OOVxm8&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={google_map_element}
                        containerElement={google_map_element}
                        mapElement={google_map_element}
                    />
                </div>
            </div>

        )

    }

}


export default DashboardMapModule

import React, { Component } from 'react'

import './URLField.css'


class URLField extends Component {

    constructor (props) {

        super(props)
        this.handleFieldOnChange = this.handleFieldOnChange.bind(this)

        this.minimum_field_value_length = 2

        this.show_success_flag = false

        this.state = {
            url: this.props.url,
            url_error_flag: false
        }

    }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps hit:", nextProps)

        if (nextProps.url !== null) {

            this.setState({ url: nextProps.url })

        }

    }

    handleFieldOnChange (e) {

        // console.log("handleFieldOnChange hit")

        const new_url = e.target.value.trimStart()

        this.setState({
            url: new_url,
            url_error_flag: false
        })

        if (this.props.handleURLChange) {

            this.props.handleURLChange(false, new_url)

        }

    }

    // Return the completely assembled component
    render () {

        // console.log("Render hit: ", this.state.url)

        let field_class_string = `form-control border`
        let fail_success = (<i className="fa fa-2x fa-fw" />)

        if (this.state.url_error_flag) {

            this.show_success_flag = true

            field_class_string = `${field_class_string} border-danger`
            fail_success = (<i className="fa fa-2x fa-fw fa-times text-danger" />)

        } else if (this.show_success_flag) {

            fail_success = (<i className="fa fa-2x fa-fw fa-check text-success" />)

        }

        return (

            <div className="row form-group mt-4 mb-4 mr-4">
                <div className="col editor-label-column">
                    <label className="col-form-label">URL:</label>
                </div>
                <div className="col">
                    <div className="d-flex flex-row">

                        <div className="w-100">
                            <input
                                type="text"
                                placeholder="http://domain.com/endpoint"
                                name="alert_rule_url"
                                value={this.state.url}
                                id="sms_alert_type_url_field"
                                onChange={this.handleFieldOnChange}
                                className={field_class_string}
                            />
                            <small className="ml-1 text-secondary">
                                https://www.mattcrampton.com
                            </small>
                        </div>
                        <div className="">
                            {fail_success}
                        </div>

                    </div>

                </div>
            </div>

        )

    }

}

export default URLField

import React, { Component } from 'react'
// import {
// Link, NavLink
// } from 'react-router-dom'

// import './ActionsButton.css'

class ActionsButton extends Component {

    constructor (props) {

        super(props)
        this.handleTimeDurationClick = this.handleTimeDurationClick.bind(this)
        this.renderButton = this.renderButton.bind(this)

    }

    handleTimeDurationClick (e) {

        this.props.handleTimeDurationClick(e.target.getAttribute('data-time-duration'))

    }

    renderButton (time_duration, text) {

        let button_classname = "btn btn-light"

        if (this.props.current_time_duration === time_duration) {

            button_classname += " active"

        }

        return (
            <button className={button_classname} data-time-duration={time_duration} onClick={this.handleTimeDurationClick} type="button">{text}</button>
        )

    }

    render () {

        return (
            <div className="btn-group" role="group" aria-label="Basic example">
                {this.renderButton("DAY", "Day")}
                {this.renderButton("WEEK", "Week")}
                {this.renderButton("MONTH", "Month")}
                {this.renderButton("NINETY_DAYS", "90 Days")}
            </div>
        )

    }

}

export default ActionsButton

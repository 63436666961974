import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    Redirect
} from 'react-router-dom'

import {
    canaryTestDeletePost,
    canaryTestDeleteReset
} from 'modules/canary_test_delete_module'

import {
    canaryTestUpdatePost,
    canaryTestUpdateReset
} from 'modules/canary_test_update_module'

import {
    allExecutionNodesFetch
} from 'modules/execution_nodes_module'

import {
    allDatacentersFetch
} from 'modules/datacenters_module'

import {
    alertRulesFetch
} from 'modules/alert_rules_module'

import {
    canaryTestReset,
    canaryTestFetch
} from 'modules/canary_test_module'

import {
    canaryTestManuallyRunReset,
    canaryTestManuallyRunPost
} from 'modules/canary_test_manually_run_module'


// import CanaryTestForm from '../CanaryTestForm/CanaryTestForm'
import CanaryTestForm2 from '../CanaryTestForm2/CanaryTestForm2'

class CanaryTestEditor extends Component {

    constructor (props) {

        super(props)

        this.organization_id = null
        this.canary_test_id = null

        this.handleCanaryTestEditorFormSubmit = this.handleCanaryTestEditorFormSubmit.bind(this)
        this.handleCanaryTestEditorManualRunRequest = this.handleCanaryTestEditorManualRunRequest.bind(this)
        this.handleCanaryTestEditorDeleteClick = this.handleCanaryTestEditorDeleteClick.bind(this)
        this.runAllResets = this.runAllResets.bind(this)
        this.runAllFetches = this.runAllFetches.bind(this)

        this.state = {
            fireRedirectOnSuccess: false
        }

    }

    componentDidMount () {

        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.canary_test_id = params.canary_test_id
        this.runAllResets()
        this.runAllFetches()

    }

    runAllResets () {

        this.props.canaryTestUpdateReset()
        this.props.canaryTestManuallyRunReset()
        this.props.canaryTestDeleteReset()

    }

    runAllFetches () {

        this.props.canaryTestFetch(this.organization_id, this.canary_test_id)
        this.props.alertRulesFetch(this.organization_id)
        this.props.allExecutionNodesFetch(this.organization_id)
        this.props.allDatacentersFetch(this.organization_id)

    }

    handleCanaryTestEditorManualRunRequest (execution_node_id, code_to_run) {

        // console.log("handleCanaryTestEditorManualRunRequest hit", execution_node_id, code_to_run)
        this.props.canaryTestManuallyRunPost(this.organization_id, this.canary_test_id, execution_node_id, code_to_run)

    }

    handleCanaryTestEditorDeleteClick () {

        if (window.confirm("Are you sure you want to delete this test?\n\nNote: All the data will be purged from our system.")) {

            // alert("I would delete here")
            this.setState({ fireRedirectOnSuccess: true })
            this.props.canaryTestDeletePost(this.organization_id, this.canary_test_id)

        }

    }

    handleCanaryTestEditorFormSubmit (
        canary_test_title,
        canary_test_language,
        canary_test_code,
        canary_test_schedule_type,
        canary_test_execution_node_ids,
        canary_test_alert_rule_ids,
        canary_test_fire_alert_rules_on_timeout
    ) {

        this.setState({ fireRedirectOnSuccess: true })

        this.props.canaryTestUpdatePost(
            this.organization_id,
            this.canary_test_id,
            canary_test_title,
            canary_test_language,
            canary_test_code,
            canary_test_schedule_type,
            canary_test_execution_node_ids,
            canary_test_alert_rule_ids,
            canary_test_fire_alert_rules_on_timeout
        )

    }

    // Return the completely assembled component
    render () {

        // if we just saved successfully, redirect back to the canary test list page.
        if (this.props.canary_test_submit_success && this.state.fireRedirectOnSuccess) {

            const canary_test_list_uri = `/o/${this.organization_id}/canary_tests/d/${this.canary_test_id}?sm=updated`
            // this.props.canaryTestUpdateReset()

            return (
                <Redirect to={canary_test_list_uri} />
            )

        }

        // if we just deleted successfully, redirect back to the canary test list page.
        if (this.props.canary_test_delete_successfully_posted && this.state.fireRedirectOnSuccess) {

            const canary_test_list_uri = `/o/${this.organization_id}/canary_tests?sm=deleted`
            // this.props.canaryTestUpdateReset()

            return (
                <Redirect to={canary_test_list_uri} />
            )

        }

        let is_currently_loading = false
        if (
            this.props.currently_fetching_canary_test ||
            this.props.currently_fetching_all_execution_nodes ||
            this.props.currently_fetching_all_alert_rules
        ) {

            is_currently_loading = true

        }

        if (
            this.props.currently_fetching_canary_test ||
            this.props.canary_test_title === null
        ) {

            return (<div />)

        }

        const selected_execution_node_ids = this.props.canary_test_execution_nodes.map((execution_node) => {

            return execution_node["execution_node_id"]

        })

        const selected_alert_rule_ids = this.props.canary_test_alert_rules.map((alert_rule) => {

            return alert_rule["alert_rule_id"]

        })

        return (

            <CanaryTestForm2
                {...this.props}

                form_title="Test - Edit"

                edit_mode={true}

                /* Generic */
                is_currently_loading={is_currently_loading}
                loading_error={this.props.canary_test_fetch_error}
                token_loaded_data_for_verification={this.props.canary_test_title}

                /* fetching canary test */
                currently_fetching_canary_test={this.props.currently_fetching_canary_test}
                canary_test_fetch_error={this.props.canary_test_fetch_error}
                canary_test_code={this.props.canary_test_code}
                canary_test_id={this.props.canary_test_id}
                canary_test_language={this.props.canary_test_language}
                canary_test_schedule_type={this.props.canary_test_schedule_type}
                canary_test_title={this.props.canary_test_title}
                canary_test_execution_nodes={selected_execution_node_ids} /* note the different names here */
                canary_test_alert_rules={selected_alert_rule_ids} /* and here */
                canary_test_fire_alert_rules_on_timeout={this.props.canary_test_fire_alert_rules_on_timeout}

                /* fetching execution nodes */
                currently_fetching_all_execution_nodes={this.props.currently_fetching_all_execution_nodes}
                all_execution_nodes_fetch_error={this.props.all_execution_nodes_fetch_error}
                all_execution_nodes_data={this.props.all_execution_nodes_data}

                /* datacenters */
                currently_fetching_datacenters={this.props.currently_fetching_datacenters}
                datacenters_fetch_error={this.props.datacenters_fetch_error}
                datacenters_data={this.props.datacenters_data}

                /* fetching alert rules */
                currently_fetching_all_alert_rules={this.props.currently_fetching_all_alert_rules}
                alert_rules_fetch_error={this.props.alert_rules_fetch_error}
                all_alert_rules={this.props.all_alert_rules}

                /* saving */
                currently_submitting_canary_test_form_post={this.props.currently_submitting_canary_test_form_post}
                canary_test_submit_error={this.props.canary_test_submit_error}
                canary_test_submit_success={this.props.canary_test_submit_success}
                canary_test_submit_return_data={this.props.canary_test_submit_return_data}

                /* deleting */
                currently_sending_canary_test_delete_post={this.props.currently_sending_canary_test_delete_post}
                canary_test_delete_error={this.props.canary_test_delete_error}
                canary_test_delete_return_data={this.props.canary_test_delete_return_data}
                canary_test_delete_successfully_posted={this.props.canary_test_delete_successfully_posted}

                /* manually executing */
                currently_sending_canary_test_manually_run_post={this.props.currently_sending_canary_test_manually_run_post}
                canary_test_manually_run_execution_node_id={this.props.canary_test_manually_run_execution_node_id}
                canary_test_manually_run_error={this.props.canary_test_manually_run_error}
                canary_test_manually_run_success={this.props.canary_test_manually_run_success}
                canary_test_manually_run_return_data={this.props.canary_test_manually_run_return_data}

                /* Actions */
                onCanaryTestFormSubmit={this.handleCanaryTestEditorFormSubmit}
                onCanaryTestManualRunRequest={this.handleCanaryTestEditorManualRunRequest}
                onCanaryTestDeleteClick={this.handleCanaryTestEditorDeleteClick}
                canaryTestManuallyRunReset={this.props.canaryTestManuallyRunReset}
            />

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({

    // fetching canary test
    currently_fetching_canary_test: state.canary_test.currently_fetching_canary_test,
    canary_test_fetch_error: state.canary_test.canary_test_fetch_error,
    canary_test_code: state.canary_test.canary_test_code,
    canary_test_date_created: state.canary_test.canary_test_date_created,
    canary_test_id: state.canary_test.canary_test_id,
    canary_test_language: state.canary_test.canary_test_language,
    canary_test_schedule_type: state.canary_test.canary_test_schedule_type,
    canary_test_status_flag: state.canary_test.canary_test_status_flag,
    canary_test_title: state.canary_test.canary_test_title,
    canary_test_execution_nodes: state.canary_test.canary_test_execution_nodes,
    canary_test_alert_rules: state.canary_test.alert_rules,
    canary_test_fire_alert_rules_on_timeout: state.canary_test.canary_test_fire_alert_rules_on_timeout,
    organization_id: state.canary_test.organization_id,

    // fetching execution nodes
    currently_fetching_all_execution_nodes: state.all_execution_nodes.currently_fetching_all_execution_nodes,
    all_execution_nodes_fetch_error: state.all_execution_nodes.all_execution_nodes_fetch_error,
    all_execution_nodes_data: state.all_execution_nodes.all_execution_nodes_data,

    // datacenters
    currently_fetching_datacenters: state.datacenters.currently_fetching_datacenters,
    datacenters_fetch_error: state.datacenters.datacenters_fetch_error,
    datacenters_data: state.datacenters.datacenters_data,

    // fetching alert rules
    currently_fetching_all_alert_rules: state.alert_rules.currently_fetching_alert_rules,
    alert_rules_fetch_error: state.alert_rules.alert_rules_fetch_error,
    all_alert_rules: state.alert_rules.alert_rules_fetch_return_data,

    // saving
    currently_submitting_canary_test_form_post: state.canary_test_update.currently_sending_canary_test_update_post,
    canary_test_submit_error: state.canary_test_update.canary_test_update_error,
    canary_test_submit_success: state.canary_test_update.canary_test_update_success,
    canary_test_submit_return_data: state.canary_test_update.canary_test_update_return_data,

    // deleting
    currently_sending_canary_test_delete_post: state.canary_test_delete.currently_sending_canary_test_delete_post,
    canary_test_delete_error: state.canary_test_delete.canary_test_delete_error,
    canary_test_delete_return_data: state.canary_test_delete.canary_test_delete_return_data,
    canary_test_delete_successfully_posted: state.canary_test_delete.canary_test_delete_successfully_posted,

    // manually executing
    currently_sending_canary_test_manually_run_post: state.canary_test_manually_run.currently_sending_canary_test_manually_run_post,
    canary_test_manually_run_execution_node_id: state.canary_test_manually_run.canary_test_manually_run_execution_node_id,
    canary_test_manually_run_error: state.canary_test_manually_run.canary_test_manually_run_error,
    canary_test_manually_run_success: state.canary_test_manually_run.canary_test_manually_run_success,
    canary_test_manually_run_return_data: state.canary_test_manually_run.canary_test_manually_run_return_data,

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    canaryTestReset,
    canaryTestFetch,
    allExecutionNodesFetch,
    allDatacentersFetch,
    canaryTestUpdateReset,
    canaryTestUpdatePost,
    canaryTestDeleteReset,
    canaryTestDeletePost,
    alertRulesFetch,
    canaryTestManuallyRunReset,
    canaryTestManuallyRunPost
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CanaryTestEditor)

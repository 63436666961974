import $ from 'jquery'
// import queryString from 'query-string'
import React, { Component } from 'react'
import {
    Link,
    Redirect
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    forgotPasswordResetPost,
    forgotPasswordResetReset
} from 'modules/forgot_password_reset_module'
import { auth } from 'lib/authentication'


class ForgotPasswordReset extends Component {

    constructor (props) {

        super(props)
        this.handleForgotPasswordResetFormSubmit = this.handleForgotPasswordResetFormSubmit.bind(this)
        this.renderForgotPasswordResetStatus = this.renderForgotPasswordResetStatus.bind(this)
        this.renderResetPasswordButton = this.renderResetPasswordButton.bind(this)

    }

    componentWillMount () {

        this.props.forgotPasswordResetReset()

    }

    handleForgotPasswordResetFormSubmit (event) {

        console.log("submit called")

        event.preventDefault()
        const reset_token = $("#resetTokenForgotPasswordResetFormInput").val()
        const email_address = $("#emailForgotPasswordResetFormInput").val()
        const new_password = $("#passwordForgotPasswordResetFormInput").val()
        const new_password_confirm = $("#passwordConfirmForgotPasswordResetFormInput").val()

        this.props.forgotPasswordResetPost(reset_token, email_address, new_password, new_password_confirm)

    }

    renderResetPasswordButton () {

        const { currently_sending_forgot_password_reset_post } = this.props

        if (currently_sending_forgot_password_reset_post === true) {

            return (
                <button type="submit" className="btn mr-2 btn-secondary" disabled>
                    <i className="fa fa-refresh fa-spin fa-fw mr-2 text-secondary" />
                    Resetting Password
                </button>
            )

        }

        return (
            <button type="submit" className="btn mr-2 btn-success">Reset Password</button>
        )

    }

    renderForgotPasswordResetStatus () {

        // console.log("re this.props", this.props)
        // If we have a forgotPassword error, show this...
        const { forgot_password_reset_error } = this.props
        if (forgot_password_reset_error !== null) {

            return (
                <div className="alert alert-danger" role="alert">
                    <i className="fa fa-exclamation-triangle mr-2" />
                    <strong>{ forgot_password_reset_error }</strong>
                </div>
            )

        }

        return (
            <div />
        )

    }

    renderForgotPasswordResetErrorPage () {

        return (
            <form>

                <h1 className="text-center text-dark">Forgot Password</h1>

                <div className="card">

                    <div className="card-body">

                        <div className="form-group">
                            <p>
                                The password reset link you clicked on
                                seems to be expired or invalid.
                            </p>
                            <p>
                                Please try again or contact our support
                                team.
                            </p>
                        </div>
                        <div className="form-group">
                            <Link to="/login" className="btn mr-2 btn-primary">Back to login</Link>
                        </div>

                    </div>

                </div>

            </form>
        )

    }

    // Return the completely assembled component
    render () {

        if (auth.get("auth_token")) {

            return (
                <Redirect to="/o" />
            )

        }

        const { forgot_password_reset_return_data } = this.props

        if (forgot_password_reset_return_data) {

            return (
                <Redirect to="/login?sm=b" />
            )

        }

        // const url_get_params = queryString.parse(this.props.location.search)
        const searchParams = new URLSearchParams(this.props.location.search)
        // if (!("token" in url_get_params)) {
        if (!searchParams.has("token")) {

            return this.renderForgotPasswordResetErrorPage()

        }

        const reset_token = searchParams.get("token")

        return (

            <form method="POST" onSubmit={this.handleForgotPasswordResetFormSubmit}>
                <input type="hidden" id="resetTokenForgotPasswordResetFormInput" value={reset_token} />

                <h1 className="text-center text-dark">Reset Password</h1>

                <div className="card">
                    <div className="card-body">

                        { this.renderForgotPasswordResetStatus() }

                        <div className="form-group">
                            <label>Email Address</label>
                            <input defaultValue="" type="email" className="form-control" id="emailForgotPasswordResetFormInput" placeholder="email@domain.com" />
                            <small className="form-text text-muted">
                                Enter the email address you used to reset your password.
                            </small>
                        </div>
                        <div className="form-group">
                            <label>New Password</label>
                            <input defaultValue="" type="password" className="form-control" id="passwordForgotPasswordResetFormInput" placeholder="new password" />
                            <small className="form-text text-muted">
                                Your password must be at least 8 characters
                                long, must contain at least 1 lower case
                                letter, 1 upper case letter, and 1 numeric character.
                            </small>
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input defaultValue="" type="password" className="form-control" id="passwordConfirmForgotPasswordResetFormInput" placeholder="new password again" />
                        </div>
                        <div className="form-group">
                            { this.renderResetPasswordButton() }
                        </div>

                    </div>

                    <div className="card-footer text-muted">
                        Remembered your old password?&nbsp;&nbsp;&nbsp;<Link to="/login">Login</Link>
                    </div>

                </div>

            </form>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    forgot_password_reset_return_data: state.forgot_password_reset.forgot_password_reset_return_data,
    currently_sending_forgot_password_reset_post: state.forgot_password_reset.currently_sending_forgot_password_reset_post,
    forgot_password_reset_error: state.forgot_password_reset.forgot_password_reset_error
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    forgotPasswordResetPost,
    forgotPasswordResetReset
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordReset)

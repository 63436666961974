import $ from 'jquery'
import React, { Component } from 'react'
import {
    Link,
    Redirect
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    forgotPasswordPost,
    forgotPasswordReset
} from 'modules/forgot_password_module'
import { auth } from 'lib/authentication'


class ForgotPassword extends Component {

    constructor (props) {

        super(props)
        this.handleForgotPasswordFormSubmit = this.handleForgotPasswordFormSubmit.bind(this)
        this.renderForgotPasswordStatus = this.renderForgotPasswordStatus.bind(this)
        this.renderSendResetPasswordLinkButton = this.renderSendResetPasswordLinkButton.bind(this)

    }

    componentWillMount () {

        this.props.forgotPasswordReset()

    }

    handleForgotPasswordFormSubmit (event) {

        event.preventDefault()
        // console.log(event)
        // console.log("fs this.props", this.props)
        // console.log("calling forgotPasswordPost()...")
        const email_address = $("#emailForgotPasswordFormInput").val()
        this.props.forgotPasswordPost(email_address)

    }

    renderForgotPasswordStatus () {

        // console.log("re this.props", this.props)
        // If we have a forgotPassword error, show this...
        const { forgot_password_error} = this.props
        if (forgot_password_error !== null) {

            return (
                <div className="alert alert-danger" role="alert">
                    <strong>{ forgot_password_error }</strong>
                </div>
            )

        }

        return (
            <div />
        )

    }

    renderSendResetPasswordLinkButton () {

        const { currently_sending_forgot_password_post } = this.props

        if (currently_sending_forgot_password_post === true) {

            return (
                <button type="submit" className="btn mr-2 btn-secondary" disabled>
                    <i className="fa fa-refresh fa-spin fa-fw mr-2 text-secondary" />
                    Submitting Rquest
                </button>
            )

        }

        return (
            <button type="submit" className="btn mr-2 btn-primary">Send Reset Password Link</button>
        )

    }

    // Return the completely assembled component
    render () {

        if (auth.get("auth_token")) {

            return (
                <Redirect to="/o" />
            )

        }

        const { forgot_password_return_data } = this.props

        if (forgot_password_return_data) {

            // console.log("Getting logindata")
            // console.log("rld this.props", this.props)

            return (
                <form>

                    <h1 className="text-center text-dark">Forgot Password</h1>

                    <div className="card">

                        <div className="card-body">

                            <div className="form-group">
                                Please check your email inbox for an email we just
                                sent out containing a link to reset your password.
                            </div>
                            <div className="form-group">
                                <Link to="/login" className="btn mr-2 btn-primary">OK</Link>
                            </div>

                        </div>

                    </div>

                </form>
            )

        }

        return (

            <form method="POST" onSubmit={this.handleForgotPasswordFormSubmit}>

                <h1 className="text-center text-dark">Forgot Password</h1>

                <div className="card">
                    <div className="card-body">

                        { this.renderForgotPasswordStatus() }

                        <div className="form-group">
                            <label>Email Address</label>
                            <input defaultValue="" type="email" className="form-control" id="emailForgotPasswordFormInput" placeholder="email@domain.com" />
                            <small className="form-text text-muted">
                                Enter the email address you used to sign up for API Canary and we will attempt to send you a link to reset your password.
                            </small>
                        </div>
                        <div className="form-group">
                            { this.renderSendResetPasswordLinkButton() }
                        </div>

                    </div>

                    <div className="card-footer text-muted">
                        Remembered your password?&nbsp;&nbsp;&nbsp;<Link to="/login">Login</Link>
                    </div>

                </div>

            </form>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({
    root_state: state,
    forgot_password_return_data: state.forgot_password.forgot_password_return_data,
    currently_sending_forgot_password_post: state.forgot_password.currently_sending_forgot_password_post,
    forgot_password_error: state.forgot_password.forgot_password_error
})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    forgotPasswordPost,
    forgotPasswordReset
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword)

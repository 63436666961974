// import $ from 'jquery'
import React, { Component } from 'react'

import './DashboardModule.scss'

class DashboardModule extends Component {

    constructor (props) {

        super(props)

        this.show_outer_border = true

        this.progress_bar_percent = 0
        this.progress_bar_first_hop_percent = 75
        this.progress_bar_interval = null
        this.progress_bar_increment_speed = 200
        // this.progress_bar_hide_speed = 2000
        this.progress_bar_hide_speed = 200
        this.progress_bar_rate = 15
        this.should_show_progress_bar = true
        // this.mount_date = null
        this.mount_date = new Date()

        this.data_is_currently_loading = false
        this.data_loading_error = false
        this.data_fetching_success = false
        this.data_fetch_was_empty = false

        this.was_previously_loading = false

        this.renderRawCard = this.renderRawCard.bind(this)
        this.renderCard = this.renderCard.bind(this)
        this.renderLoadingBar = this.renderLoadingBar.bind(this)
        this.renderProgressBarFinish = this.renderProgressBarFinish.bind(this)
        this.renderProgressBar = this.renderProgressBar.bind(this)
        this.clearProgressBarAnimation = this.clearProgressBarAnimation.bind(this)
        this.startProgressBarAnimation = this.startProgressBarAnimation.bind(this)
        this.incrementProgressBar = this.incrementProgressBar.bind(this)
        this.updateUIWithNewProgressBarPercentValue = this.updateUIWithNewProgressBarPercentValue.bind(this)

        this.isCurrentlyLoading = this.isCurrentlyLoading.bind(this)
        this.wasPreviouslyLoading = this.wasPreviouslyLoading.bind(this)
        this.getLoadingError = this.getLoadingError.bind(this)
        this.wasFetchingSuccessful = this.wasFetchingSuccessful.bind(this)
        this.didFetchHaveData = this.didFetchHaveData.bind(this)

        this.updateModuleFlagsFromDataReport = this.updateModuleFlagsFromDataReport.bind(this)

        this.progress_bar_dom_element = React.createRef()

    }

    componentDidMount () {

        // console.log("DashboardModule didMount")
        this.mount_date = new Date()

        /*
        this.should_show_progress_bar = false

        setTimeout(() => {

            this.should_show_progress_bar = true

        }, (1000))
        */

    }

    componentDidUpdate () {

        // console.log("did update", this.module_id, this.props.is_currently_loading)

        if (this.isCurrentlyLoading()) {

            this.startProgressBarAnimation()

        } else {

            // this.clearProgressBarAnimation()

        }

    }

    isCurrentlyLoading () {

        return (this.props.is_currently_loading || this.data_is_currently_loading)

    }

    getLoadingError () {

        return (this.props.loading_error || this.data_loading_error)

    }

    wasPreviouslyLoading () {

        return this.was_previously_loading

    }

    wasFetchingSuccessful (data_to_check) {

        if (data_to_check) { // is this useful?

            return true

        } else if (this.data_fetching_success) {

            return true

        }

        return false

    }

    didFetchHaveData (data_to_check) {

        if (typeof data_to_check !== "undefined") {

            return !this.isDataEmpty(data_to_check)

        }

        return !this.data_fetch_was_empty

    }

    isDataEmpty (data) {

        if (!data) {

            return true

        } else if (data === null) {

            return true

        } else if (Array.isArray(data) && data.length < 1) { // array check

            return true

        } else if (Object.keys(data).length === 0) { // object check

            return true

        } else if (data.length < 1) { // string check

            return true

        }

        // ok we have data
        return false

    }

    updateModuleFlagsFromDataReport (report_data) {

        // set loading flags from the report data (instead of setting them via individual props)

        if (!report_data || !report_data["_meta"]) {

            this.data_is_currently_loading = true // I guess we're loading at this point
            return

        }

        // console.dir("report_data", report_data["_meta"])

        this.data_is_currently_loading = report_data["_meta"]["currently_fetching"]
        this.data_loading_error = report_data["_meta"]["fetch_error"]
        this.data_fetching_success = report_data["_meta"]["fetching_success"]

        this.data_fetch_was_empty = this.isDataEmpty(report_data["data"])
        // console.dir([
        //     { "data_is_currently_loading": this.data_is_currently_loading },
        //     { "data_loading_error": this.data_loading_error },
        //     { "data_fetching_success": this.data_fetching_success },
        //     { "data_fetch_was_empty": this.data_fetch_was_empty }
        // ])

    }


    updateUIWithNewProgressBarPercentValue (new_progress_bar_percent) {

        const diff = Date.now() - this.mount_date.getTime()

        // Only show the progress bar if it's taken longer than a second to load stuff.
        if (diff < 1000) {

            // return

        }

        if (this.progress_bar_dom_element.current) {

            this.progress_bar_dom_element.current.style.width = `${new_progress_bar_percent}%`

        } else {

            this.clearProgressBarAnimation()

        }

        // console.log("new prog bar value: ", new_progress_bar_percent)
        // console.log("this.progress_bar_dom_element.current", this.progress_bar_dom_element.current)

    }

    incrementProgressBar () {

        let new_progress_bar_percent = 0

        if (this.progress_bar_percent < this.progress_bar_first_hop_percent) {

            // First progress bar hop
            new_progress_bar_percent = this.progress_bar_first_hop_percent

        } else {

            // post 50 percent  progress bar hop
            new_progress_bar_percent = ((100 - this.progress_bar_percent) / this.progress_bar_rate) + this.progress_bar_percent

        }

        this.progress_bar_percent = new_progress_bar_percent

        this.updateUIWithNewProgressBarPercentValue(new_progress_bar_percent)

    }

    clearProgressBarAnimation () {

        clearInterval(this.progress_bar_interval)
        this.progress_bar_percent = 0
        this.progress_bar_interval = null

    }

    startProgressBarAnimation () {

        if (this.progress_bar_interval === null) {

            this.progress_bar_interval = setInterval(this.incrementProgressBar, this.progress_bar_increment_speed)

        }

    }

    renderProgressBar () {

        if (
            !this.isCurrentlyLoading() &&
            this.wasPreviouslyLoading()
        ) {

            return this.renderProgressBarFinish()

        } else if (this.isCurrentlyLoading()) {

            return this.renderLoadingBar()

        }

        return null

    }

    renderProgressBarFinish () {

        const diff = Date.now() - this.mount_date.getTime()

        // Only show the progress bar if it's taken longer than a second to load stuff.
        if (diff < 1000) {

            return null

        }

        this.clearProgressBarAnimation()

        this.was_previously_loading = false

        const bar_progress_style = {
            width: "100%"
        }

        let progress_bar_color_class = "progress-bar bg-success"

        if (this.getLoadingError()) {

            progress_bar_color_class = "progress-bar bg-danger"

        }

        // remove success bar after we show it for a bit
        setTimeout(() => {

            if (this.progress_bar_dom_element.current) {

                this.progress_bar_dom_element.current.classList.add("invisible")
                // this.progress_bar_dom_element.remove()

            }

        }, (this.progress_bar_hide_speed))

        return (

            <div className="dashboard-module-loading-bar-container" ref={this.progress_bar_dom_element}>
                <div className="progress w-100" style={{ height: '2px' }}>
                    <div className={progress_bar_color_class} role="progressbar" style={bar_progress_style} />
                </div>
            </div>

        )

    }

    renderLoadingBar () {

        this.was_previously_loading = true

        const bar_progress_style = {
            width: `${this.progress_bar_percent}%`
        }

        return (

            <div className="dashboard-module-loading-bar-container">
                <div className="progress w-100" style={{ height: '2px' }}>
                    <div className="progress-bar" role="progressbar" ref={this.progress_bar_dom_element} style={bar_progress_style} />
                </div>
            </div>

        )

    }

    renderGenericLoadingUI () {

        return (
            <div style={{ height: '400px' }} />
        )

    }

    renderRawCard (raw_markup) {

        let classes = "card fix-rounded-corners dashboard-module-container"

        // if (this.show_outer_border) {

            // classes = `${classes} dashboard-module-container-border`

        // } else {

        classes = `${classes} dashboard-module-container-no-border`

        // }

        return (
            <div className={classes}>

                {this.renderProgressBar()}

                {raw_markup}

            </div>
        )

    }

    renderCard (header_content, body_content) {

        let header_markup = ""

        if (header_content) {

            header_markup = (
                <div className="card-header text-dark bg-white font-weight-bold">
                    <div className="card-header-inner">
                        {header_content}
                    </div>
                </div>
            )

        }

        let body_markup = ""

        if (body_content) {

            body_markup = (
                <div className="card-bodyx">
                    {body_content}
                </div>
            )

        }

        return this.renderRawCard(
            <>
                {header_markup}
                {body_markup}
            </>
        )

    }

}

export default DashboardModule

// This file is included by modules/all_modules.js (For the reducers)
// This file is also included by stuff (For the actions)

import axios from 'axios'
import config from 'config'

import {
    auth
} from 'lib/authentication'


// Initial Module State
const initialState = {
    currently_fetching_all_execution_nodes: false,
    all_execution_nodes_fetch_error: null,
    all_execution_nodes_data: null
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const ALL_EXECUTION_NODES_RESET = 'all_execution_nodes/EXECUTION_NODES_RESET'
export const ALL_EXECUTION_NODES_FETCH_REQUESTED = 'all_execution_nodes/EXECUTION_NODES_FETCH_REQUESTED'
export const ALL_EXECUTION_NODES_FETCH_SUCCESS = 'all_execution_nodes/EXECUTION_NODES_FETCH_SUCCESS'
export const ALL_EXECUTION_NODES_FETCH_ERROR = 'all_execution_nodes/EXECUTION_NODES_FETCH_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const allExecutionNodesReset = () => {

    return (dispatch) => {

        dispatch({
            type: ALL_EXECUTION_NODES_RESET
        })

    }

}

export const allExecutionNodesFetch = (organization_id) => {

    return (dispatch) => {

        // dispatch({
            // type: ALL_EXECUTION_NODES_RESET
        // })

        dispatch({
            type: ALL_EXECUTION_NODES_FETCH_REQUESTED
        })

        const auth_token = auth.get("auth_token")

        // console.log("executionNodesFetch called")

        return axios({
            method: 'get',
            url: `//${config["api_hostname"]}/v1/o/${organization_id}/execution_nodes`,
            headers: {
                Authorization: `Token ${auth_token}`
            }
        })

            .then((response) => {

                const all_execution_nodes_data = response.data.data

                // console.log("execution nodes fetch success", response)

                dispatch({
                    type: ALL_EXECUTION_NODES_FETCH_SUCCESS,
                    payload: all_execution_nodes_data
                })

            })

            .catch((error) => {

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.table(error)
                    // console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"

                } else {

                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: ALL_EXECUTION_NODES_FETCH_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case ALL_EXECUTION_NODES_RESET:
            return {
                ...state,
                currently_fetching_all_execution_nodes: false,
                all_execution_nodes_fetch_error: null,
                all_execution_nodes_data: null
            }

        case ALL_EXECUTION_NODES_FETCH_REQUESTED:
            return {
                ...state,
                currently_fetching_all_execution_nodes: true
            }

        case ALL_EXECUTION_NODES_FETCH_SUCCESS:
            // console.dir("ALL_EXECUTION_NODES_FETCH_SUCCESS", action.payload)

            return {
                ...state,
                currently_fetching_all_execution_nodes: false,
                all_execution_nodes_fetch_error: null,
                all_execution_nodes_data: action.payload // load the return data
            }

        case ALL_EXECUTION_NODES_FETCH_ERROR:
            return {
                ...state,
                currently_fetching_all_execution_nodes: false,
                all_execution_nodes_fetch_error: action.payload, // Loading error text
                all_execution_nodes_data: null
            }

        default:
            return state

    }

}

import React from 'react'
// import {
    // Link
// } from 'react-router-dom'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

// import { PRETTY_TEXT_STRINGS } from 'lib/constants'

// import './VariableVaultReadOnly.scss'

class VariableVaultReadOnly extends DashboardModule {

    render () {

        return this.renderRawCard(
            <div className="p-4">
                {/* empty_vault_message */}
                <div className="p-1">

                    <i className="fa fa-fw fa-lock" /> You must be an administrator to manage the variable vault.

                </div>
            </div>
        )

    }

}

export default VariableVaultReadOnly

import React, { Component } from 'react'

import * as moment from 'moment-timezone'

import './ExecutionLog.scss'

class ExecutionLog extends Component {

    constructor (props) {

        super(props)
        this.renderIcon = this.renderIcon.bind(this)
        this.renderLogLineMarkup = this.renderLogLineMarkup.bind(this)
        this.renderLogLinesMarkup = this.renderLogLinesMarkup.bind(this)
        this.renderTimeSinceString = this.renderTimeSinceString.bind(this)

    }

    renderIcon (log_line_type) {

        switch (log_line_type) {

            case "START_EXECUTION": {

                return (<i className="fa fa-play mr-2 log-line-icon" />)

            }
            case "FINISH_EXECUTION": {

                return (<i className="fa fa-stop mr-2 log-line-icon" />)

            }
            case "SUCCESS": {

                return (<i className="fa fa-check text-success mr-2 log-line-icon" />)

            }
            case "LOG": {

                return (<i className="fa fa-th-list mr-2 log-line-icon" />)

            }
            case "FATAL_ERROR": {

                return (<i className="fa fa-exclamation-circle text-danger mr-2 log-line-icon" />)

            }
            case "LOADING": {

                return (<i className="fa fa-refresh fa-spin mr-2 log-line-icon" />)

            }
            case "LOADED": {

                return (<i className="fa fa-check text-success mr-2 log-line-icon" />)

            }
            default: {

                return (<i className="fa fa-question mr-2 log-line-icon" />)

            }

        }

    }

    renderTimeSinceString (valid_current_time_string) {

        if (!valid_current_time_string) {

            return ""

        }

        const begin_time_milliseconds = moment(this.props.start_execution_timestamp).valueOf()
        const current_time_milliseconds = moment(valid_current_time_string).valueOf()
        const diff_milliseconds = current_time_milliseconds - begin_time_milliseconds

        if (diff_milliseconds < 1) {

            return ""

        }

        return `${(diff_milliseconds / 1000).toLocaleString('en-EN', { minimumFractionDigits: 0 })} Seconds`

    }

    renderLogLineMarkup (log_line, key_string) {

        // console.log(log_string)

        // log_string = "xxx"
        const key = `log_line_key_${key_string}`

        // console.log('log_line["timestamp"]', log_line["timestamp"])

        return (
            <div key={key} className="log_line_item_container" >
                <div className="vertLineContainer" />
                {this.renderIcon(log_line["type"])}
                <dl>
                    <dt>
                        {log_line["log_line"]}
                    </dt>
                    <dd>
                        {this.renderTimeSinceString(log_line["timestamp"])}
                    </dd>
                </dl>
            </div>
        )

    }

    renderLogLinesMarkup (log_lines) {

        return log_lines.map(this.renderLogLineMarkup)

    }

    renderExecutionResult = () => {

        if (
            this.props.execution_successs_flag !== true &&
            this.props.log_lines.length < 1
        ) {

            // return this.renderLogLineMarkup({
                // log_line: this.props.success_message,
                // timestamp: this.props.end_execution_timestamp,
                // type: "SUCCESS"
            // }, "FINISH_EXECUTION_SUCCESS")
            return this.renderLogLineMarkup({
                log_line: this.props.failure_message,
                timestamp: this.props.end_execution_timestamp,
                type: "FATAL_ERROR"
            }, "FINISH_EXECUTION_FAILURE")

        }
        return ""

    }

    // Return the completely assembled component
    render () {

        // console.log(this.props.log_lines)

        return (

            <div className="executionLogLinesContainer">
                {this.renderLogLineMarkup({
                    log_line: "Started Execution",
                    timestamp: this.props.start_execution_timestamp,
                    type: "START_EXECUTION"
                }, "START_EXECUTION")}
                {this.renderLogLinesMarkup(this.props.log_lines)}
                {this.renderExecutionResult()}
            </div>

        )

    }

    // <pre>{JSON.stringify(this.props, null, 4)}</pre>

}


export default ExecutionLog

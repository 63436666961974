import React, { Component } from 'react'
import './FancyLoadingButton.css'


class FancyLoadingButton extends Component {

    constructor (props) {

        super(props)
        this.renderDisabledButton = this.renderDisabledButton.bind(this)
        this.renderLoadingButton = this.renderLoadingButton.bind(this)

    }

    renderLoadingButton () {

        let { className, button_loading_type } = this.props

        if (!className) {

            className = ""

        }

        if (!button_loading_type) {

            button_loading_type = "secondary"

        }

        const full_button_loading_classes = `btn mr-2 btn-${button_loading_type} fancy-button-loading ${className}`

        return (
            <button type="button" className={full_button_loading_classes} disabled>
                <i className="fa fa-refresh fa-spin fa-fw mr-2" />
                {this.props.children}
            </button>
        )

    }

    renderDisabledButton () {

        let { className, button_disabled_type } = this.props

        if (!className) {

            className = ""

        }

        if (!button_disabled_type) {

            button_disabled_type = "secondary"

        }

        const full_button_classes = `btn mr-2 btn-${button_disabled_type} fancy-button-disabled ${className}`

        return (
            <button type="button" className={full_button_classes} disabled>
                {this.renderNonLoadingIcon()}
                {this.props.children}
            </button>
        )

    }

    renderNonLoadingIcon = () => {

        const { non_loading_icon } = this.props
        let non_loading_icon_markup = ""
        if (non_loading_icon) {

            const non_loading_icon_classes = `fa ${non_loading_icon} mr-2 mt-1`

            non_loading_icon_markup = (
                <i className={non_loading_icon_classes} aria-hidden="true" />
            )

        }
        return non_loading_icon_markup

    }

    // Return the completely assembled component
    render () {

        const { currently_loading, disabled } = this.props
        let { button_type, button_submit_type, className } = this.props

        if (!className) {

            className = ""

        }

        if (!button_submit_type) {

            button_submit_type = "button"

        }

        if (!button_type) {

            button_type = "success"

        }


        if (disabled) {

            return this.renderDisabledButton()

        }

        if (currently_loading === true) {

            return this.renderLoadingButton()

        }

        const full_button_classes = `btn mr-2 btn-${button_type} fancy-button-clickable ${className}`

        return (
            <button type={button_submit_type} className={full_button_classes} onClick={this.props.onClick} data-passthrough={this.props.data_passthrough}>
                {this.renderNonLoadingIcon()}
                {this.props.children}
            </button>
        )

    }

}


export default FancyLoadingButton

import React from 'react'
import {
    Link
} from 'react-router-dom'

import DashboardDataTable from 'components/shared/DashboardDataTable/DashboardDataTable'
import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import { PRETTY_TEXT_STRINGS } from 'lib/constants'

import './AlertRulesListBody.css'

class AlertRulesListBody extends DashboardModule {

    constructor (props) {

        super(props)

        this.generateDataTableData = this.generateDataTableData.bind(this)
        this.generateDataTableBodyRow = this.generateDataTableBodyRow.bind(this)

        this.organization_id = false
        this.show_outer_border = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    generateDataTableBodyRow (alert_rule) {

        const return_columns = []

        const alert_rule_edit_anchor_url = `/o/${this.organization_id}/alert_rules/${alert_rule["alert_rule_id"]}/edit`
        let alert_rule_details_text = ""
        let alert_rule_type_text = ""

        if (alert_rule["alert_rule_type"] === "EMAIL") {

            alert_rule_type_text = PRETTY_TEXT_STRINGS["EMAIL"]
            // alert_rule_details_text = alert_rule["alert_rule_details"]["email_address"]
            alert_rule_details_text = `Receive an ${PRETTY_TEXT_STRINGS["EMAIL"]} ${PRETTY_TEXT_STRINGS[alert_rule["alert_rule_notification_frequency"]]} to ${alert_rule["alert_rule_details"]["email_address"]}`

        } else if (alert_rule["alert_rule_type"] === "SMS") {

            alert_rule_type_text = PRETTY_TEXT_STRINGS["SMS"]

            try {

                alert_rule_details_text = `Receive an ${PRETTY_TEXT_STRINGS["SMS"]} ${PRETTY_TEXT_STRINGS[alert_rule["alert_rule_notification_frequency"]]} to ${alert_rule["alert_rule_details"]["phone_number"]}`

            } catch (e) {

                alert_rule_details_text = "N/A"

            }

        } else if (alert_rule["alert_rule_type"] === "WEBHOOK") {

            alert_rule_type_text = PRETTY_TEXT_STRINGS["WEBHOOK"]

            try {

                alert_rule_details_text = `Receive an ${alert_rule["alert_rule_details"]["method"]} request to ${alert_rule["alert_rule_details"]["url"]}`

            } catch (e) {

                alert_rule_details_text = "N/A"

            }

        } else {

            alert_rule_type_text = alert_rule["alert_rule_type"]
            alert_rule_details_text = "N/A"

        }

        return_columns.push(
            {
                "column_key": "title",
                "column_markup": (<Link to={alert_rule_edit_anchor_url}>{alert_rule["alert_rule_title"]}</Link>),
                "column_classes": "text-left align-middle text-nowrap"
            }
        )

        return_columns.push(
            {
                "column_key": "type",
                "column_markup": alert_rule_type_text,
                "column_classes": "text-left align-middle text-nowrap text-capitalize"
            }
        )

        return_columns.push(
            {
                "column_key": "detail",
                "column_markup": alert_rule_details_text,
                "column_classes": "text-left align-middle text-nowrap text-capitalizex"
            }
        )

        return return_columns

    }

    generateDataTableData () {

        const dashboard_data_table_data = {
            "fancy_header": {
                "left_markup": (
                    <div className="navbar-text text-dark">
                        Alert Rules
                    </div>
                ),
                "right_dropdown_data": []
            },
            "body": this.props.loaded_data.map(this.generateDataTableBodyRow),
            "pagination": null
        }

        // console.log("dashboard_data_table_data", dashboard_data_table_data)

        return dashboard_data_table_data

    }

    render () {

        if (this.props.loading_error) {

            return this.renderRawCard(
                <DashboardDataTable loading_error={this.props.loading_error} className="alert-rules-list-data-table" />
            )

        } else if (
            this.props.loaded_data === null
        ) {

            return this.renderRawCard(
                " "
            )

        }

        // console.log("this.props.loaded_data", this.props.loaded_data)

        return this.renderRawCard(
            <DashboardDataTable table_data={this.generateDataTableData()} className="alert-rules-list-data-table" />
        )

    }

}

export default AlertRulesListBody

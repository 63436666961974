import React, { Component } from 'react'
import { Route } from 'react-router-dom'
// import { bindActionCreators } from 'redux'
// import { connect } from 'react-redux'
import Login from './Login/Login'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ForgotPasswordReset from './ForgotPassword/ForgotPasswordReset/ForgotPasswordReset'
import Signup from './Signup/Signup'
// import { auth } from 'lib/authentication'

import './ObtainAuth.css'


class ObtainAuth extends Component {

    // Return the completely assembled component
    render () {

        return (

            <div>
                <img className="d-block mx-auto mt-4" src="/images/goldie_square_transparent.png" alt="" width="100" height="100" />
                <div className="obtainAuthLoginCardContainer container mt-3 mb-5">
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/forgot_password/reset" component={ForgotPasswordReset} />
                </div>
                <ul className="nav justify-content-center mb-5">
                    <li className="nav-item small">
                        <a className="nav-link" href="https://www.apicanary.com/privacy_policy">Terms</a>
                    </li>
                    <li className="nav-item small">
                        <a className="nav-link" href="https://www.apicanary.com/privacy_policy">Privacy</a>
                    </li>
                    <li className="nav-item small">
                        <a className="nav-link text-secondary" href="mailto:hello@apicanary.com">Contact API Canary</a>
                    </li>
                </ul>
            </div>

        )

    }

}


export default ObtainAuth
import axios from 'axios'
import {
    auth
} from 'lib/authentication'
import config from 'config'

import * as moment from 'moment-timezone'

// Initial Module State
const initialState = {
    currently_fetching_user_account_data: false,
    user_account_data_has_been_fetched: false,
    user_account_data_fetch_error: false,
    user_account_data_fetch_error_message: null,
    user_account_user_data: null,
    user_account_organizations: null,
    user_account_preferences: null,
    user_timezone: (moment.tz.guess() || "America/Los_Angeles")
}


// Redux Actions - Payloads of information that send data from your application to your store.
// http://redux.js.org/docs/basics/Actions.html
export const USER_ACCOUNT_DATA_RESET = 'user_account/USER_ACCOUNT_DATA_RESET'
export const USER_ACCOUNT_DATA_FETCH_REQUESTED = 'user_account/USER_ACCOUNT_DATA_FETCH_REQUESTED'
export const USER_ACCOUNT_DATA_FETCH_SUCCESS = 'user_account/USER_ACCOUNT_DATA_FETCH_SUCCESS'
export const USER_ACCOUNT_DATA_FETCH_ERROR = 'user_account/USER_ACCOUNT_DATA_FETCH_ERROR'


// Redux Action Creators - Functions that create actions
// http://redux.js.org/docs/basics/Actions.html#action-creators

export const accountReset = () => {

    return (dispatch) => {

        dispatch({
            type: USER_ACCOUNT_DATA_RESET
        })

    }

}

export const accountDataFetch = () => {

    return (dispatch) => {

        dispatch({
            type: USER_ACCOUNT_DATA_FETCH_REQUESTED
        })

        const auth_token = auth.get("auth_token")

        return axios({
            method: 'get',
            url: `//${config["api_hostname"]}/v1/me`,
            headers: {
                Authorization: `Token ${auth_token}`
            }
        })

            .then((response) => {

                // console.table(response.data)
                // console.dir(response.data)
                // return

                const user_account_organizations = response.data.data[0]["organizations"]
                const user_account_preferences = response.data.data[0]["preferences"]
                const user_account_user_data = {
                    user_account_email_address: response.data.data[0]["user_account_email_address"],
                    user_account_id: response.data.data[0]["user_account_id"],
                    user_account_username: response.data.data[0]["user_account_username"]
                }

                const user_account_data_payload = {
                    user_account_user_data,
                    user_account_organizations,
                    user_account_preferences
                }

                // console.dir(user_account_data_payload)
                // return

                dispatch({
                    type: USER_ACCOUNT_DATA_FETCH_SUCCESS,
                    payload: user_account_data_payload
                })

            })

            .catch((error) => {


                /*

                auth.clear()
                window.location.href = "/login"

                */

                let error_string = "An error occured"

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    auth.clear()
                    // console.dir("error1", error)
                    error_string = error.response.data["_metadata"]["error_message"]

                } else if (error.request) {

                    auth.clear()
                    window.location.href = "/login?em=a"
                    return

                    /*
                    console.dir("error2", error)
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.dir(error.request)
                    error_string = "We are having trouble connecting to the internet, please check your connection and try again"
                    */

                } else {

                    console.dir("error3", error)
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                    error_string = error.message

                }
                // console.log("error.config", error.config)

                dispatch({
                    type: USER_ACCOUNT_DATA_FETCH_ERROR,
                    payload: error_string
                })

            })

    }

}


// Redux Reducers - Specify how the application's state changes in response to actions
// http://redux.js.org/docs/basics/Reducers.html
export default (state = initialState, action = {}) => {

    switch (action.type) {

        case USER_ACCOUNT_DATA_RESET:
            // Setting state back to initial values
            return {
                ...state,
                currently_fetching_user_account_data: false,
                user_account_data_has_been_fetched: false,
                user_account_data_fetch_error: false,
                user_account_data_fetch_error_message: null,
                user_account_user_data: null,
                user_account_organizations: null,
                user_account_preferences: null
            }

        case USER_ACCOUNT_DATA_FETCH_REQUESTED:
            // In process of fetching data
            return {
                ...state,
                currently_fetching_user_account_data: true,
                user_account_data_has_been_fetched: false,
                user_account_data_fetch_error: false,
                user_account_data_fetch_error_message: null
            }


        case USER_ACCOUNT_DATA_FETCH_SUCCESS:
            // setting success data into the state
            return {
                ...state,
                currently_fetching_user_account_data: false,
                user_account_data_has_been_fetched: true,
                user_account_data_fetch_error: false,
                user_account_data_fetch_error_message: null,
                user_account_user_data: action.payload["user_account_user_data"],
                user_account_organizations: action.payload["user_account_organizations"],
                user_account_preferences: action.payload["user_account_preferences"]
            }

        case USER_ACCOUNT_DATA_FETCH_ERROR:
            // there was an error, loading it into the state
            return {
                ...state,
                currently_fetching_user_account_data: false,
                user_account_data_has_been_fetched: false,
                user_account_data_fetch_error: true,
                user_account_data_fetch_error_message: action.payload

            }

        default:
            return state

    }

}

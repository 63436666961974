
const config = {}

config["api_hostname"] = "api.apicanary.com"
// config["allow_signups"] = true
config["allow_signups"] = false

if (process.env.REACT_APP_ENV_TYPE === "local") {

    config["api_hostname"] = "127.0.0.1:22281"
    config["allow_signups"] = true

} else if (process.env.REACT_APP_ENV_TYPE === "staging") {

    config["api_hostname"] = "staging2-api.apicanary.com"
    config["allow_signups"] = true

}


config["execution_result_page_item_count"] = 10

window.app_config = config

export default config

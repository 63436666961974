import React from 'react'

// https://www.npmjs.com/package/react-ace
// https://github.com/securingsincity/react-ace
import AceEditor from 'react-ace'

import 'brace/mode/javascript'
import 'brace/theme/github'
import 'brace/ext/language_tools'

import FancyFormElement from 'components/shared/FancyFormElement/FancyFormElement'

import './FancyFormElementCodeEditor.scss'


class FancyFormElementCodeEditor extends FancyFormElement {

    constructor (props) {

        super(props)
        this.renderLoadingUI = this.renderLoadingUI.bind(this)

    }

    renderFormElement () {

        // console.log("fancysingle defaultValue", this.props.value)
        // console.log("fancysingle options", this.props.dropdown_options)

        return (
            <AceEditor
                mode="javascript"
                theme="github"
                editorProps={{ $blockScrolling: true }}
                height={this.props.height}
                width="100%"
                setOptions={{
                    // tabSize: 4,
                    // enableBasicAutocompletion: true,
                    // enableLiveAutocompletion: true
                }}
                showPrintMargin={false}

                value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                disabled={this.props.disabled}
            />
        )

    }

}

export default FancyFormElementCodeEditor

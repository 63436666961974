import React from 'react'
import {
    Link // , NavLink
} from 'react-router-dom'

import { PRETTY_TEXT_STRINGS } from 'lib/constants'

// import * as moment from 'moment'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'
import DashboardDataTable from 'components/shared/DashboardDataTable/DashboardDataTable'

import './CanaryTestsListBody.css'

class CanaryTestsListBody extends DashboardModule {

    // const test_link_anchor_url = `/o/${this.organization_id}/canary_tests/d/${canary_test["canary_test_id"]}`
    // let canary_test_title_string = canary_test["canary_test_title"]
    // if (canary_test_title_string.length < 1) {
        // canary_test_title_string = "N/A"
    // }
    // console.table(canary_test)
    // https://momentjs.com/
    // const canary_test_schedule_type_pretty_string = PRETTY_TEXT_STRINGS[canary_test["canary_test_schedule"][0]["canary_test_schedule_type"]]
    // <td>{moment(canary_test["canary_test_date_created"]).fromNow()}</td>

    constructor (props) {

        super(props)
        this.renderFullListBody = this.renderFullListBody.bind(this)
        this.sortClickHandler = this.sortClickHandler.bind(this)
        this.renderTitleColumnCell = this.renderTitleColumnCell.bind(this)
        this.organization_id = false
        this.show_disabled = false
        this.show_outer_border = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    sortClickHandler (e) {

        console.log("sortClickHandler: ", e.target.getAttribute('data-column-key'))

    }

    renderTitleColumnCell (canary_test) {

        // const date_string = moment(execution_result["canary_test_execution_result_date_created"]).tz(this.props.user_timezone).format("MMM Do YYYY, h:mma z")

        const detail_url = `/o/${this.organization_id}/canary_tests/d/${canary_test["canary_test_id"]}`

        return (

            <Link to={detail_url}>{canary_test["canary_test_title"]}</Link>

        )


    }

    renderDataCentersColumnCell (canary_test) {

        // console.log("datacenters_data", this.props.datacenters_data)

        let cell_string = ""

        if (canary_test["canary_test_execution_nodes"].length < 2) {

            const datacenter = this.props.datacenters_data["EXECUTION_NODE_DATACENTERS"][canary_test["canary_test_execution_nodes"][0]["execution_node_datacenter"]]
            // console.log(datacenter)

            cell_string = `${datacenter["cloud_provider"]} - ${datacenter["name"]}`

        } else {

            cell_string = `${canary_test["canary_test_execution_nodes"].length} Data Centers`

        }

        return (
            <div className="d-flex flex-row">
                <div className="">
                    <div className="fa fa-fw fa-cube execution-node-list-icon" />
                </div>
                <div className="">
                    <div className="ml-2 mt-1">{cell_string}</div>
                </div>
            </div>
        )

    }

    renderTestingScheduleColumnCell (canary_test) {

        return PRETTY_TEXT_STRINGS[canary_test["canary_test_schedule"][0]["canary_test_schedule_type"]]

    }

    renderLastTestColumnCell (canary_test) {

        if (canary_test["execution_results"].length < 1) {

            return ""

        }

        if (canary_test["execution_results"][0]["canary_test_execution_result_success_flag"] === true) {

            return (<span className="badge badge-pill badge-success">Passed</span>)

        }

        return (<span className="badge badge-pill badge-danger px-2">Failed</span>)

    }

    renderLastTestResultColumnCell (canary_test) {

        if (canary_test["execution_results"].length < 1) {

            return ""

        }

        if (canary_test["execution_results"][0]["canary_test_execution_result_success_flag"] === true) {

            return canary_test["execution_results"][0]["canary_test_execution_result_success_message"]

        }

        return canary_test["execution_results"][0]["canary_test_execution_result_failure_message"]

    }

    renderFullListBody () {

        const dashboard_data_table_header_data = [
            {
                "column_key": "title",
                "column_markup": "Title",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap"
            },
            {
                "column_key": "data_centers",
                "column_markup": "Data Centers",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap d-none d-lg-table-cell"
            },
            {
                "column_key": "testing_schedule",
                "column_markup": "Testing Schedule",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap d-none d-lg-table-cell"
            },
            {
                "column_key": "last_test",
                "column_markup": "Last Test",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap"
            },
            {
                "column_key": "last_test_result",
                "column_markup": "Last Test Result",
                "on_click_function": this.sortClickHandler,
                "column_classes": "text-nowrap d-none d-md-table-cell"
            }
        ]

        // we can remove this filter when https://github.com/apicanary/api.apicanary.com/issues/101 is fixed
        const non_deleted_canary_tests = this.props.canary_tests.filter((canary_test) => {

            return canary_test["canary_test_status_flag"] !== "DELETED"

        })

        const dashboard_data_table_body_data = non_deleted_canary_tests.map((canary_test) => {

            const return_columns = []

            // console.log("canary_test", canary_test)

            return_columns.push(
                {
                    "column_key": "title",
                    "column_markup": this.renderTitleColumnCell(canary_test),
                    "column_classes": "text-left align-middle"
                }
            )

            return_columns.push(
                {
                    "column_key": "data_centers",
                    "column_markup": this.renderDataCentersColumnCell(canary_test),
                    "column_classes": "text-left align-middle text-nowrap d-none d-lg-table-cell"
                }
            )

            return_columns.push(
                {
                    "column_key": "testing_schedule",
                    "column_markup": this.renderTestingScheduleColumnCell(canary_test),
                    "column_classes": "text-left align-middle text-nowrap d-none d-lg-table-cell"
                }
            )

            return_columns.push(
                {
                    "column_key": "last_test",
                    "column_markup": this.renderLastTestColumnCell(canary_test),
                    "column_classes": "text-left align-middle text-nowrap"
                }
            )

            return_columns.push(
                {
                    "column_key": "last_test_result",
                    "column_markup": this.renderLastTestResultColumnCell(canary_test),
                    "column_classes": "text-left align-middle d-none d-md-table-cell"
                }
            )

            return return_columns

        })

        const dashboard_data_table_data = {
            "header": dashboard_data_table_header_data,
            "body": dashboard_data_table_body_data
        }

        return (

            <DashboardDataTable table_data={dashboard_data_table_data} className="canary-test-list-data-table" />
        )

    }

    render () {

        if (this.isCurrentlyLoading()) {

            return this.renderRawCard(
                <div className="p-5">&nbsp;</div>
            )

        } else if (

            this.getLoadingError() ||
            !this.wasFetchingSuccessful(this.props.canary_tests_fetch_successful)

        ) {

            const refresh_url = `/o/${this.organization_id}/canary_tests`
            return this.renderRawCard(
                <div className="p-5">
                    We&apos;re having a problem pulling your data.
                    Please <a href={refresh_url}>refresh</a> and try again.
                    <div className="mt-5">
                        {this.getLoadingError()}
                    </div>
                </div>
            )

        }

        // console.log(this.props.canary_tests)
        // return this.renderRawCard(
            // <h1>ok</h1>
        // )

        return this.renderRawCard(
            this.renderFullListBody()
        )

    }

}

export default CanaryTestsListBody

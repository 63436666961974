// https://www.npmjs.com/package/js-cookie
import Cookie from 'js-cookie'

const auth_cookie_name = "API_CANARY_AUTH"

export const auth = {

    "get": (key) => {

        const auth_cookie_data = Cookie.getJSON(auth_cookie_name)
        if (!auth_cookie_data) {

            return auth_cookie_data

        }

        // console.log(typeof auth_cookie_data)
        if (key) {

            return auth_cookie_data[key]

        }

        return auth_cookie_data

    },

    "clear": () => {

        // console.log("Clearing auth cookie")
        return Cookie.remove(auth_cookie_name)

    },

    "has": (from = "n/a") => {

        // why are we passing "from" here?  What was it supposed to be used for?

        const cookie_data = auth.get()

        // console.log("checking for auth", cookie_data)

        if (
            typeof cookie_data === "undefined" ||
            cookie_data === null
        ) {

            // console.log("auth was NOT found")
            return false

        }

        // console.log("auth was found")
        return true

    },

    "set": (auth_data) => {

        // console.log("Setting auth data to:", auth_data)
        return Cookie.set(auth_cookie_name, auth_data)

    }

}

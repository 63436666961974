import { withFormik } from 'formik'
import * as Yup from 'yup'

import { CANARY_TEST_SCHEDULE_TYPES } from 'lib/constants'

import CanaryTestFormBody from './CanaryTestFormBody/CanaryTestFormBody'

export default withFormik({
    validationSchema: Yup.object().shape({

        canary_test_title: Yup.string()
            .required('Required')
            .min(5, 'Must be longer than 5 characters')
            .max(255, 'Must be less than 255 characters'),

        canary_test_code: Yup.string()
            .max(5000000, 'Your code cannot be more than 5,000,000 characters in length')
            .test('Export required', 'You must export your code as a module using "module.exports"',
                (value) => {

                    if (typeof value !== "string") {

                        return false

                    }

                    return value.includes("module.exports")

                })
            .required('Required'),

        canary_test_schedule_type: Yup.string()
            .test('Valid values', 'Invalid value',
                (value) => {

                    // console.log("hit canary_test_schedule_type valudation", value)

                    return CANARY_TEST_SCHEDULE_TYPES.filter((canary_test_schedule_type) => {

                        return canary_test_schedule_type["value"] === value

                    }).length

                })
            .required('Required'),
            // .matches(/[a-z]/u, 'Must have at least one lowercase char')
            // .matches(/[A-Z]/u, 'Must have at least one uppercase char')
            // .matches(/[a-zA-Z]+[^a-zA-Z\s]+/u, 'Must have at least 1 number'),

        canary_test_execution_nodes: Yup.array()
            // .ensure().min(1, "Please add at least one data center.")
            .test('Valid values', 'Please choose at least one data center',
                function (value) {

                    // console.dir("hit canary_test_execution_nodes valudation", value, value.length, value.length > 0)

                    return value.length > 0
                    // return CANARY_TEST_SCHEDULE_TYPES.filter((canary_test_schedule_type) => {

                        // return canary_test_schedule_type["value"] === value

                    // }).length

                })
            .required('Required'),
            // .of(Yup.string().ensure().required("Tag cannot be empty"))

        // user_account_password_confirm: Yup.string()
            // .required('Required')
            // .oneOf([Yup.ref('user_account_password'), null], 'Doesn\'t match your password')

    }),
    mapPropsToValues: (props) => ({

        canary_test_title: props.canary_test_title,
        canary_test_code: props.canary_test_code,
        canary_test_language: props.canary_test_language,
        canary_test_schedule_type: props.canary_test_schedule_type,
        canary_test_execution_nodes: props.canary_test_execution_nodes,
        canary_test_alert_rules: props.canary_test_alert_rules,
        canary_test_fire_alert_rules_on_timeout: props.canary_test_fire_alert_rules_on_timeout,

        onCanaryTestFormSubmit: props.onCanaryTestFormSubmit || null,

    }),
    handleSubmit: (payload, { props }) => {

        // console.log("handle submit hit", payload)
        // console.dir(payload["canary_test_title"])
        // console.dir(payload["canary_test_language"])
        // console.dir(payload["canary_test_code"])
        // console.dir(payload["canary_test_schedule_type"])
        // console.dir(payload["canary_test_execution_nodes"])
        // console.dir(payload["canary_test_alert_rules"])

        props.onCanaryTestFormSubmit(
            payload["canary_test_title"],
            payload["canary_test_language"],
            payload["canary_test_code"],
            payload["canary_test_schedule_type"],
            payload["canary_test_execution_nodes"],
            payload["canary_test_alert_rules"],
            payload["canary_test_fire_alert_rules_on_timeout"]
        )

    },
    displayName: 'CanaryTestEditor',
    // enableReinitialize: true,
    isInitialValid: false
})(CanaryTestFormBody)

// import $ from 'jquery'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    Redirect
} from 'react-router-dom'

import {
    alertRuleCreatePost,
    alertRuleCreateReset
} from 'modules/alert_rule_create_module'

import AlertRuleForm from '../AlertRuleForm/AlertRuleForm'


class AlertRuleCreator extends Component {

    constructor (props) {

        super(props)
        this.handleAlertRuleCreatorFormSubmit = this.handleAlertRuleCreatorFormSubmit.bind(this)

        this.state = {
            fireRedirectOnSuccess: false
        }

    }

    componentWillMount () {

        this.props.alertRuleCreateReset()

    }

    handleAlertRuleCreatorFormSubmit (
        alert_rule_title,
        alert_rule_type,
        alert_rule_details,
        alert_rule_frequency
    ) {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        this.setState({ fireRedirectOnSuccess: true })

        this.props.alertRuleCreatePost(
            organization_id,
            alert_rule_title,
            alert_rule_type,
            alert_rule_details,
            alert_rule_frequency
        )

    }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        if (this.props.alert_rule_successfully_posted && this.state.fireRedirectOnSuccess) {

            const alert_rules_list_uri = `/o/${organization_id}/alert_rules?sm=created`
            return (
                <Redirect to={alert_rules_list_uri} />
            )

        }

        return (

            <AlertRuleForm
                form_title="New Alert Rule"

                edit_mode={false}

                is_currently_loading={false}
                loading_error={false}

                token_loaded_data_for_verification="foo"

                alert_rule_title=""
                alert_rule_type=""
                alert_rule_details=""
                alert_rule_frequency="ONCE_PER_FAILURE"

                onAlertRuleFormSubmit={this.handleAlertRuleCreatorFormSubmit}

                alert_rule_form_submit_error={this.props.alert_rule_create_error}
                alert_rule_successfully_posted={this.props.alert_rule_successfully_posted}

                {...this.props}

            />

        )

    }

}


// Makes the following data available in the Components this.props object
const mapStateToProps = (state) => ({

    root_state: state,

    alert_rule_create_error: state.alert_rule_create.alert_rule_create_error,
    alert_rule_successfully_posted: state.alert_rule_create.alert_rule_successfully_posted,

    currently_sending_alert_rule_create_post: state.alert_rule_create.currently_sending_alert_rule_create_post

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    alertRuleCreatePost,
    alertRuleCreateReset
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertRuleCreator)

import React from 'react'
// import {
    // Link
// } from 'react-router-dom'

// import * as moment from 'moment'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'

import DashboardMapModule from 'components/shared/DashboardMapModule/DashboardMapModule'

class ExecutionNodesMap extends DashboardModule {

    constructor (props) {

        super(props)
        this.generateMarkerArray = this.generateMarkerArray.bind(this)
        this.organization_id = false
        this.show_outer_border = false

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id

    }

    generateMarkerArray () {

        const marker_data = []
        if (
            this.props.datacenters_data === null ||
            this.props.all_execution_nodes_data === null
        ) {

            return marker_data

        }
        const datacenters = this.props.datacenters_data["EXECUTION_NODE_DATACENTERS"]
        const cloud_providers = this.props.datacenters_data["CLOUD_PROVIDERS"]
        // console.log(cloud_providers)
        const all_execution_nodes = this.props.all_execution_nodes_data

        // console.log(all_execution_nodes[0])

        Object.entries(datacenters).forEach(([datacenter_name, datacenter_data]) => {

            // console.log(`key= ${datacenter_name}`)
            // console.dir(datacenter_data)
            // console.dir(cloud_providers[datacenter_data["cloud_provider"]]["name"])
            // console.dir(datacenter_data["lat"])
            // console.dir(datacenter_data["lon"])
            // console.dir(datacenter_data["name"])

            const execution_node = all_execution_nodes.find((node) => node.execution_node_datacenter === datacenter_name)

            let icon_type = "INACTIVE_DATACENTER"
            if (execution_node) {

                // console.log("found ex node", execution_node)
                icon_type = "ACTIVE_DATACENTER"

            }

            marker_data.push({
                "key": datacenter_name,
                "lat": datacenter_data["lat"],
                "long": datacenter_data["lon"],
                "title": `${datacenter_data["name"]} - ${cloud_providers[datacenter_data["cloud_provider"]]["name"]}`,
                "icon_type": icon_type
            })

        })

        // console.log("marker_data", marker_data)

        return marker_data

    }

    render () {

        return this.renderRawCard(
            <DashboardMapModule
                markers={this.generateMarkerArray()}
                is_currently_loading={this.props.is_currently_loading}
                loading_error={this.props.loading_error}
            />
        )

    }

}

export default ExecutionNodesMap

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
// import { ConnectedRouter } from 'react-router-redux'
// import store, { history } from './store'
import store from './store'
import Root from './components/Root/Root'

// sets global config on window.app_config
import './config'

import 'sanitize.css/sanitize.css'

// import './styles/sass_vars.scss'

const target = document.querySelector('#root')

// console.log("s", store)
// console.log("h", history)

/*
render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <Root />
            </div>
        </ConnectedRouter>
    </Provider>
    ,
    target
)
*/
render(
    <Provider store={store}>
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    </Provider>
    ,
    target
)

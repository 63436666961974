// import $ from 'jquery'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    Redirect
} from 'react-router-dom'

import {
    alertRuleFetch,
    alertRuleReset
} from 'modules/alert_rule_module'

import {
    alertRuleUpdatePost,
    alertRuleUpdateReset
} from 'modules/alert_rule_update_module'

import {
    alertRuleDeletePost,
    alertRuleDeleteReset
} from 'modules/alert_rule_delete_module'

import AlertRuleForm from '../AlertRuleForm/AlertRuleForm'


class AlertRuleEditor extends Component {

    constructor (props) {

        super(props)
        this.handleAlertRuleEditorFormSubmit = this.handleAlertRuleEditorFormSubmit.bind(this)
        this.handleAlertRuleEditorDeleteSubmit = this.handleAlertRuleEditorDeleteSubmit.bind(this)

        this.state = {
            fireRedirectOnSuccess: false
        }

    }

    componentWillMount () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        const alert_rule_id = params.alert_rule_id

        this.props.alertRuleDeleteReset()
        this.props.alertRuleUpdateReset()
        this.props.alertRuleReset()

        this.props.alertRuleFetch(organization_id, alert_rule_id)

    }

    handleAlertRuleEditorDeleteSubmit () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        const alert_rule_id = params.alert_rule_id

        console.log("setting fireRedirectOnSuccess: true")
        this.setState({ fireRedirectOnSuccess: true })

        this.props.alertRuleDeletePost(
            organization_id,
            alert_rule_id
        )

    }

    handleAlertRuleEditorFormSubmit (
        alert_rule_title,
        alert_rule_type,
        alert_rule_details,
        alert_rule_frequency
    ) {

        const { match: { params } } = this.props
        const organization_id = params.organization_id
        const alert_rule_id = params.alert_rule_id

        console.log("setting fireRedirectOnSuccess: true")
        this.setState({ fireRedirectOnSuccess: true })

        this.props.alertRuleUpdatePost(
            organization_id,
            alert_rule_id,
            alert_rule_title,
            alert_rule_type,
            alert_rule_details,
            alert_rule_frequency
        )

    }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        const alert_rules_list_uri = `/o/${organization_id}/alert_rules`

        if (this.props.alert_rule_successfully_posted && this.state.fireRedirectOnSuccess) {

            return (
                <Redirect to={alert_rules_list_uri} />
            )

        }

        if (this.props.alert_rule_delete_successfully_posted && this.state.fireRedirectOnSuccess) {

            const alert_rule_deleted_list_uri = `${alert_rules_list_uri}?sm=deleted`
            return (
                <Redirect to={alert_rule_deleted_list_uri} />
            )

        }

        return (

            <AlertRuleForm
                form_title="Alert Rule"

                edit_mode={true}

                is_currently_loading={this.props.currently_fetching_alert_rule}
                loading_error={this.props.alert_rule_fetch_error}

                token_loaded_data_for_verification={this.props.alert_rule_title}

                alert_rule_title={this.props.alert_rule_title}
                alert_rule_type={this.props.alert_rule_type}
                alert_rule_details={this.props.alert_rule_details}
                alert_rule_frequency={this.props.alert_rule_notification_frequency}

                onAlertRuleFormSubmit={this.handleAlertRuleEditorFormSubmit}
                onAlertRuleDeleteSubmit={this.handleAlertRuleEditorDeleteSubmit}

                alert_rule_form_submit_error={this.props.alert_rule_update_error}
                alert_rule_successfully_posted={this.props.alert_rule_successfully_posted}

                {...this.props}

            />

        )

    }

}


/*
export default AlertRuleEditor
*/

// Makes the following data available in the Components this.props object
const mapStateToProps = (state) => ({

    root_state: state,

    alert_rule_delete_successfully_posted: state.alert_rule_delete.alert_rule_delete_successfully_posted,

    alert_rule_update_error: state.alert_rule_update.alert_rule_update_error,
    alert_rule_successfully_posted: state.alert_rule_update.alert_rule_successfully_posted,

    currently_sending_alert_rule_update_post: state.alert_rule_update.currently_sending_alert_rule_update_post,

    // Flags
    currently_fetching_alert_rule: state.alert_rule.currently_fetching_alert_rule,
    alert_rule_fetch_error: state.alert_rule.alert_rule_fetch_error,

    // Alert Rule Identifiers
    alert_rule_id: state.alert_rule.alert_rule_id,
    alert_rule_date_created: state.alert_rule.alert_rule_date_created,

    // Alert Rule Fields
    alert_rule_notification_frequency: state.alert_rule.alert_rule_notification_frequency,
    alert_rule_title: state.alert_rule.alert_rule_title,
    alert_rule_status_flag: state.alert_rule.alert_rule_status_flag,
    alert_rule_type: state.alert_rule.alert_rule_type,
    alert_rule_details: state.alert_rule.alert_rule_details

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    alertRuleDeletePost,
    alertRuleDeleteReset,
    alertRuleUpdatePost,
    alertRuleUpdateReset,
    alertRuleFetch,
    alertRuleReset
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertRuleEditor)

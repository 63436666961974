import React, { Component } from 'react'
// import {
    // Link // , NavLink
// } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
    canaryTestReset,
    canaryTestFetch
} from 'modules/canary_test_module'

import {
    canaryTestReportsReset,
    canaryTestReportsFetch
} from 'modules/canary_test_reports_module'

import {
    canaryTestExecutionResultsReset,
    canaryTestExecutionResultsFetch
} from 'modules/canary_test_execution_results_module'

import {
    canaryTestExecutionResultsDeleteReset,
    canaryTestExecutionResultsDeletePost
} from 'modules/canary_test_execution_results_delete_module'

import AlertBox from 'components/shared/AlertBox/AlertBox'

import CanaryTestDetailTimeGraphModule from './CanaryTestDetailTimeGraphModule/CanaryTestDetailTimeGraphModule'
import CanaryTestDetailOverviewModule from './CanaryTestDetailOverviewModule/CanaryTestDetailOverviewModule'
import CanaryTestDetailFrequencyModule from './CanaryTestDetailFrequencyModule/CanaryTestDetailFrequencyModule'
import CanaryTestDetailResultsList from './CanaryTestDetailResultsList/CanaryTestDetailResultsList'

import ActionsButton from './ActionsButton/ActionsButton'

class CanaryTestDetail extends Component {

    constructor (props) {

        super(props)

        const { match: { params } } = this.props
        this.organization_id = params.organization_id
        this.canary_test_id = params.canary_test_id

        this.resetAllData = this.resetAllData.bind(this)
        this.pullAllData = this.pullAllData.bind(this)
        this.pullCanaryTestExecutionResults = this.pullCanaryTestExecutionResults.bind(this)
        this.pullCanaryTestReports = this.pullCanaryTestReports.bind(this)
        this.renderAlertBox = this.renderAlertBox.bind(this)

        this.handleClearDataClick = this.handleClearDataClick.bind(this)

        this.state = {
            repullOnSuccess: false,
            saved_location: ""
        }

    }

    componentDidMount () {

        this.resetAllData()
        this.pullAllData()

    }

    componentDidUpdate (prevProps, prevState) {

        let repull_reports = false
        let repull_results = false

        if (this.props.history.location.search !== this.state.saved_location) {

            this.setState({ saved_location: this.props.history.location.search })
            // console.log("repull now")
            repull_results = true

        }

        if (this.props.canary_test_execution_results_delete_successfully_posted && this.state.repullOnSuccess) {

            this.setState({ repullOnSuccess: false })
            repull_reports = true

        }

        if (repull_reports) {

            this.resetAllData()
            this.pullAllData()

        }

        if (repull_results) {

            this.pullCanaryTestExecutionResults()

        }

    }

    resetAllData () {

        // console.log("reset running")
        // Canary test details
        this.props.canaryTestReset()

        // Reports
        this.props.canaryTestReportsReset()

        // execution results
        this.props.canaryTestExecutionResultsReset()
        // console.log("reset done")

        // delete
        this.props.canaryTestExecutionResultsDeleteReset()

    }

    pullAllData () {

        // console.log("pulling all data")

        this.props.canaryTestFetch(this.organization_id, this.canary_test_id)
        this.pullCanaryTestExecutionResults()
        this.pullCanaryTestReports()

    }

    pullCanaryTestReports () {

        this.props.canaryTestReportsFetch({
            organization_id: this.organization_id,
            canary_test_id: this.canary_test_id,
            report_id: "PULL_CANARY_TEST_OVER_TIME_AVERAGE_REPORT",
            time_range: "WEEK"
        })
        this.props.canaryTestReportsFetch({
            organization_id: this.organization_id,
            canary_test_id: this.canary_test_id,
            report_id: "PULL_CANARY_TEST_SUCCESS_FAILURE_REPORT",
            time_range: "WEEK"
        })

    }

    pullCanaryTestExecutionResults () {

        const searchParams = new URLSearchParams(this.props.location.search)

        const filter = searchParams.get("filter")
        const offset = searchParams.get("offset")

        this.props.canaryTestExecutionResultsFetch(this.organization_id, this.canary_test_id, offset, filter)

    }

    handleClearDataClick () {

        if (window.confirm("Are you sure you want to delete all execution results for this Test?\n\nNote: All the data will be purged from our system.")) {

            // this.props.onCanaryTestDeleteClick()

            this.setState({ repullOnSuccess: true })
            this.props.canaryTestExecutionResultsDeletePost(this.organization_id, this.canary_test_id)

        }

    }

    renderAlertBox () {

        const searchParams = new URLSearchParams(this.props.location.search)

        if (this.props.canary_test_execution_results_delete_error) {

            return (
                <AlertBox
                    className="mb-4"
                    error_level="danger"
                    error_title="Error:"
                    error_description={this.props.canary_test_execution_results_delete_error}
                />
            )

        }

        if (searchParams.has("sm")) {

            if (searchParams.get("sm") === "deleted") {

                return (
                    <AlertBox
                        className="mb-4"
                        error_level="success"
                        error_title="Your execution result has been deleted"
                    />
                )

            } else if (searchParams.get("sm") === "updated") {

                return (
                    <div className="alert alert-success mb-5" role="alert">
                        <i className="fa fa-fw fa-check text-success mr-2" />
                        <strong>Your Test has been updated</strong>
                    </div>
                )

            }

        } else if (searchParams.has("em")) {

            if (searchParams.get("em") === "a") {

                return (
                    <AlertBox
                        className="mb-4"
                        error_level="danger"
                        error_title="Sorry, we had an error"
                    />
                )

            }

        }

        return (

            <div />

        )

    }

    // Return the completely assembled component
    render () {

        const edit_canary_test_anchor_url = `/o/${this.organization_id}/canary_tests/d/${this.canary_test_id}/edit`

        let title_string = (<i className="fa fa-refresh fa-spin fa-fw text-secondary" />)

        if (this.props.canary_test_fetch_error) {

            title_string = "Error"

        } else if (this.props.canary_test_title !== null) {

            title_string = this.props.canary_test_title

        }

        return (

            <div>

                <div className="container-fluid dashboard-body-header-container">

                    { this.renderAlertBox() }

                    <div className="row">

                        <div className="col-12 col-md-8">
                            <h1 className="text-truncate">{title_string}</h1>
                        </div>

                        <div className="col-12 col-md-4 text-md-right">

                            <ActionsButton
                                edit_canary_test_anchor_url={edit_canary_test_anchor_url}
                                onClearDataClick={this.handleClearDataClick}
                            />

                        </div>
                        {/*
                            <Link className="btn btn-success" to={edit_canary_test_anchor_url}><i className="fa fa-plus" aria-hidden="true" /> Edit Test</Link>
                        */}

                    </div>
                </div>

                <div className="container-fluid">

                    {/* start row */}
                    <div className="row dashboard-body-row mb-4">

                        <div className="col-12 col-lg-7 col-xl-8">
                            {/* start col */}

                            <div className="mb-4 mb-lg-0">
                                {/*
                                Graph over time
                                <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_test_reports_data["PULL_CANARY_TEST_OVER_TIME_AVERAGE_REPORT"], null, 2)}</pre>
                                */}

                                <CanaryTestDetailTimeGraphModule
                                    report_data={this.props.canary_test_reports_data["PULL_CANARY_TEST_OVER_TIME_AVERAGE_REPORT"]}
                                    module_height="248px"
                                />
                            </div>

                            {/* end col */}
                        </div>

                        <div className="col-12 col-lg-5 col-xl-4">
                            {/* start col */}

                            {/*
                            Overall
                            <pre style={{ color: '#000' }}>{JSON.stringify(this.props.canary_test_reports_data["PULL_CANARY_TEST_SUCCESS_FAILURE_REPORT"], null, 2)}</pre>
                            */}

                            <div className="mb-4">
                                <CanaryTestDetailOverviewModule
                                    report_data={this.props.canary_test_reports_data["PULL_CANARY_TEST_SUCCESS_FAILURE_REPORT"]}
                                    module_height="122px"
                                />
                            </div>

                            <div>
                                <CanaryTestDetailFrequencyModule
                                    is_currently_loading={this.props.currently_fetching_canary_test}
                                    loading_error={this.props.canary_test_fetch_error}
                                    canary_test_schedule_type={this.props.canary_test_schedule_type}
                                    module_height="102px"
                                />
                            </div>

                            {/* end col */}
                        </div>
                        {/*
                        */}

                    </div>
                    {/* end row */}

                    {/* start row */}
                    <div className="row dashboard-body-row">
                        <div className="col-12">

                            <CanaryTestDetailResultsList
                                {...this.props}
                                is_currently_loading={this.props.currently_fetching_canary_test_execution_results}
                                loading_error={this.props.canary_test_execution_results_fetch_error}
                                canary_test_execution_results_fetch_return_data={this.props.canary_test_execution_results_fetch_return_data}
                                user_timezone={this.props.user_timezone}
                            />

                        </div>
                    </div>
                    {/* end row */}

                </div>

            </div>

        )

    }

}

// Makes the following data available in the Component's this.props object
const mapStateToProps = (state) => ({

    // Fetching canary test
    currently_fetching_canary_test: state.canary_test.currently_fetching_canary_test,
    canary_test_fetch_error: state.canary_test.canary_test_fetch_error,
    canary_test: state.canary_test,
    canary_test_code: state.canary_test.canary_test_code,
    canary_test_date_created: state.canary_test.canary_test_date_created,
    canary_test_id: state.canary_test.canary_test_id,
    canary_test_language: state.canary_test.canary_test_language,
    canary_test_schedule_type: state.canary_test.canary_test_schedule_type,
    canary_test_status_flag: state.canary_test.canary_test_status_flag,
    canary_test_title: state.canary_test.canary_test_title,
    execution_nodes: state.canary_test.execution_nodes,
    organization_id: state.canary_test.organization_id,

    // Fetching reports data
    canary_test_reports_data: state.canary_test_reports.canary_test_reports_data,

    // fetching execution results
    currently_fetching_canary_test_execution_results: state.canary_test_execution_results.currently_fetching_canary_test_execution_results,
    canary_test_execution_results_fetch_error: state.canary_test_execution_results.canary_test_execution_results_fetch_error,
    canary_test_execution_results_fetch_return_data: state.canary_test_execution_results.canary_test_execution_results_fetch_return_data,

    // Deleting execution results
    currently_sending_canary_test_execution_results_delete_post: state.canary_test_execution_results_delete.currently_sending_canary_test_execution_results_delete_post,
    canary_test_execution_results_delete_error: state.canary_test_execution_results_delete.canary_test_execution_results_delete_error,
    canary_test_execution_results_delete_return_data: state.canary_test_execution_results_delete.canary_test_execution_results_delete_return_data,
    canary_test_execution_results_delete_successfully_posted: state.canary_test_execution_results_delete.canary_test_execution_results_delete_successfully_posted,

    // From user account
    user_timezone: state.user_account.user_timezone

})

// Makes the following dispatch functions (defined in various modules) available in the Component's this.props object
const mapDispatchToProps = (dispatch) => bindActionCreators({
    canaryTestReset,
    canaryTestFetch,
    canaryTestReportsReset,
    canaryTestReportsFetch,
    canaryTestExecutionResultsReset,
    canaryTestExecutionResultsFetch,
    canaryTestExecutionResultsDeleteReset,
    canaryTestExecutionResultsDeletePost,
}, dispatch)

// Connects the state vars and dispatch functions to the Component and exports it to it's React parent component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CanaryTestDetail)

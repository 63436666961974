// This file is included by

import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import { Route } from 'react-router-dom'

import NavBreadcrumbLink from '../NavBreadcrumbLink/NavBreadcrumbLink'

class NavBreadcrumbAlertRuleNew extends Component {

    // constructor (props) {

        // super(props)

    // }

    // componentWillMount () {

        // console.log("NavBreadcrumbAlertRuleNew")
        // console.dir(this.props)

    // }

    // Return the completely assembled component
    render () {

        const { match: { params } } = this.props
        const organization_id = params.organization_id

        const alert_rules_url = `/o/${organization_id}/alert_rules`

        return (

            <React.Fragment>
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="Alert Rules"
                    breadcrumb_link_url={alert_rules_url}
                />
                <span className="navbar-text">/</span>
                <NavBreadcrumbLink
                    breadcrumb_link_text="New"
                />
            </React.Fragment>

        )

    }

}


export default NavBreadcrumbAlertRuleNew


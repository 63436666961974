import React, { Component } from 'react'

import FancyFormElementSingleSelectDropdown from 'components/shared/FancyFormElementSingleSelectDropdown/FancyFormElementSingleSelectDropdown'

import { ALERT_RULE_WEBHOOK_METHOD_TYPES } from 'lib/constants'

import './MethodField.css'


class MethodField extends Component {

    constructor (props) {

        super(props)
        this.handleFieldOnChange = this.handleFieldOnChange.bind(this)

        this.minimum_field_value_length = 2

        this.show_success_flag = false

        this.state = {
            method: this.props.method,
            method_error_flag: false
        }

    }

    componentWillReceiveProps (nextProps) {

        // console.log("componentWillReceiveProps hit:", nextProps)

        if (nextProps.method !== null) {

            this.setState({ method: nextProps.method })

        }

    }

    // handleFieldOnChange (e) {
    handleFieldOnChange (new_method) {
    
        // const new_method = e.target.value.trimStart()

        console.log("handleFieldOnChange hit", new_method)

        this.setState({
            method: new_method,
            method_error_flag: false
        })

        if (this.props.handleMethodChange) {

            this.props.handleMethodChange(false, new_method)

        }

    }

    // Return the completely assembled component
    render () {

        // console.log("Render hit: ", this.state.method)

        // let field_class_string = `form-control border`
        let fail_success = (<i className="fa fa-2x fa-fw" />)

        if (this.state.method_error_flag) {

            this.show_success_flag = true

            // field_class_string = `${field_class_string} border-danger`
            fail_success = (<i className="fa fa-2x fa-fw fa-times text-danger" />)

        } else if (this.show_success_flag) {

            fail_success = (<i className="fa fa-2x fa-fw fa-check text-success" />)

        }

        return (

            <div className="row form-group mt-4 mb-4 mr-4">
                <div className="col editor-label-column">
                    <label className="col-form-label">Method:</label>
                </div>
                <div className="col">
                    <div className="d-flex flex-row">

                        <div className="w-100">
                            {/*
                            <input
                                type="text"
                                placeholder="POST or GET"
                                name="alert_rule_method"
                                value={this.state.method}
                                id="sms_alert_type_method_field"
                                onChange={this.handleFieldOnChange}
                                className={field_class_string}
                            />
                            */}

                            <FancyFormElementSingleSelectDropdown
                                id="sms_alert_type_method_field"
                                name="alert_rule_method"
                                onChange={this.handleFieldOnChange}
                                /*
                                onBlur={setFieldTouched}
                                disabled={currently_submitting_form}
                                error_message={errors.team_member_role_type_field}
                                show_error_message={errors.team_member_role_type_field && touched.team_member_role_type_field && errors.team_member_role_type_field}
                                */
                                value={this.state.method}
                                dropdown_options={ALERT_RULE_WEBHOOK_METHOD_TYPES}
                            />


                            <small className="ml-1 text-secondary">
                                GET or POST
                            </small>
                        </div>
                        <div className="">
                            {fail_success}
                        </div>

                    </div>

                </div>
            </div>

        )

    }

}


export default MethodField

import React from 'react'
// import {
    // Link // , NavLink
// } from 'react-router-dom'

import * as moment from 'moment-timezone'

import DashboardModule from 'components/shared/DashboardModule/DashboardModule'
// import DashboardDataTable from 'components/shared/DashboardDataTable/DashboardDataTable'

import './ExecutionInformation.css'

class ExecutionInformation extends DashboardModule {

    constructor (props) {

        super(props)

        this.organization_id = false
        this.show_disabled = false

        this.begin_execution_timestamp = null

    }

    componentWillMount () {

        const { match: { params } } = this.props

        this.organization_id = params.organization_id
        this.canary_test_id = params.canary_test_id

    }

    render () {

        if (this.isCurrentlyLoading()) {

            return this.renderRawCard(
                <div style={{ height: '500px' }} />
            )

        } else if (!this.wasFetchingSuccessful(this.props.canary_test_execution_result_data)) {

            return this.renderRawCard(
                <div className="p-5">
                    We&apos;re having a problem pulling your data.
                    Please <a href="/">refresh</a> and try again.
                    <div className="mt-5">
                        {this.props.loading_error}
                    </div>
                </div>
            )

        }

        const execution_result = this.props.canary_test_execution_result_data

        let success_message_markup = ""

        if (execution_result["canary_test_execution_result_success_flag"] === true) {

            success_message_markup = (
                <dl>
                    <dt>Success Message:</dt>
                    <dd>{ String(execution_result["canary_test_execution_result_success_message"]) }</dd>
                </dl>
            )

        } else {

            success_message_markup = (
                <dl>
                    <dt>Failure Message:</dt>
                    <dd>{ String(execution_result["canary_test_execution_result_failure_message"]) }</dd>
                </dl>
            )

        }

        let status_pill = ""

        if (execution_result["canary_test_execution_result_success_flag"] === true) {

            status_pill = (<span className="badge badge-pill badge-success">Passed</span>)

        } else {

            status_pill = (<span className="badge badge-pill badge-danger px-2">Failed</span>)

        }

        return this.renderRawCard(
            <div className="executionResultDetailExecutionInformationContainer">
                <dl>
                    <dt>Test:</dt>
                    <dd>{this.props.canary_test_title_string}</dd>
                </dl>
                <dl>
                    <dt>Created:</dt>
                    <dd>
                        {moment(execution_result["canary_test_execution_result_date_created"]).tz(this.props.user_timezone).format("MMM Do YYYY, h:mma z")}
                    </dd>
                </dl>
                <dl>
                    <dt>Status:</dt>
                    <dd>{execution_result["canary_test_execution_result_status_flag"]}</dd>
                </dl>
                <dl>
                    <dt>Success:</dt>
                    <dd>{status_pill}</dd>
                </dl>

                {success_message_markup}

                {/*
                <pre style={{ color: '#000' }}>{JSON.stringify(execution_result, null, 2)}</pre>
                */}
            </div>
        )


    }

}

export default ExecutionInformation
